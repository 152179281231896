import React, { BaseSyntheticEvent, useCallback } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/system';
import {
  NumberFormatConfigV1,
  DecimalPlaceOptions,
} from '@madeinventive/core-types';

const initialFormat: NumberFormatConfigV1 = {
  decimalPlace: DecimalPlaceOptions.DEFAULT,
  unit: '',
  isAfter: false,
};

interface FormatNumberProps {
  numberConfig?: NumberFormatConfigV1;
  setFormat: (format: NumberFormatConfigV1) => void;
}

const StyledAccordion = styled(Accordion)({
  boxShadow: 'none',
  '&:before': {
    display: 'none',
  },
});

const FormatNumberPanel = ({
  numberConfig = initialFormat,
  setFormat,
}: FormatNumberProps) => {
  const handleDecimalPlaceChange = useCallback(
    (event: SelectChangeEvent) => {
      const newFormat: NumberFormatConfigV1 = {
        ...numberConfig,
        decimalPlace: event.target.value as DecimalPlaceOptions,
      };
      setFormat(newFormat);
    },
    [numberConfig, setFormat],
  );

  const handleUnitChange = useCallback(
    (event: BaseSyntheticEvent) => {
      const newFormat: NumberFormatConfigV1 = {
        ...numberConfig,
        unit: event.target.value,
      };
      setFormat(newFormat);
    },
    [numberConfig, setFormat],
  );

  const handleIsAfterChange = useCallback(
    (event: BaseSyntheticEvent) => {
      const newFormat: NumberFormatConfigV1 = {
        ...numberConfig,
        isAfter: event.target.checked,
      };
      setFormat(newFormat);
    },
    [numberConfig, setFormat],
  );

  return (
    <StyledAccordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant='subtitle2'>Format number</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl fullWidth>
          <Grid container flexDirection='row' spacing={1}>
            <Grid item flex={1}>
              <FormControl size='small' fullWidth>
                <InputLabel id='precision-select-label'>
                  Decimal Place
                </InputLabel>
                <Select
                  labelId='precision-select-label'
                  id='precision-select'
                  value={numberConfig?.decimalPlace}
                  label='Decimal Place'
                  onChange={handleDecimalPlaceChange}
                >
                  <MenuItem value={undefined}>Default</MenuItem>
                  <MenuItem value={DecimalPlaceOptions.ZERO}>1</MenuItem>
                  <MenuItem value={DecimalPlaceOptions.ONE}>1.0</MenuItem>
                  <MenuItem value={DecimalPlaceOptions.TWO}>1.00</MenuItem>
                  <MenuItem value={DecimalPlaceOptions.THREE}>1.000</MenuItem>
                  <MenuItem value={DecimalPlaceOptions.FOUR}>1.0000</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item flex={2}>
              <Grid container flexDirection='column'>
                <FormControl size='small'>
                  <TextField
                    size='small'
                    id='unit'
                    label='Units'
                    onChange={handleUnitChange}
                    value={numberConfig?.unit}
                  />
                </FormControl>
                <FormControlLabel
                  label='Display units after number'
                  control={
                    <Checkbox
                      size='small'
                      checked={numberConfig?.isAfter}
                      onChange={handleIsAfterChange}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </FormControl>
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default FormatNumberPanel;
