import { useState, useMemo, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { useAppSelector } from '../../hooks';
import { SchemaNode } from '../../store/slices/exploreExtracts';
import { useExploreSchema } from '../../hooks/useExploreSchema';

// data field accordions options get generated from the schema,
// when it is used as selected field, it will be formatted to DynamicFieldV1

// data field accordion is dependent on the featureEditData's wsExploreId or exploreId(envExploreId)
// feature edit data must have envExploreId or wsExploreId to get the schema
const useDataFieldAccordion = () => {
  const [searchPhrase, setSearchPhrase] = useState('');
  const [filteredNodes, setFilteredNodes] = useState<
    SchemaNode[] | undefined
  >();

  const envExploreId = useAppSelector(
    (store) => store.featureEditData.value,
  ).exploreId;

  const { schema } = useExploreSchema({
    envExploreId,
  });

  const fullNodeList = useMemo(() => schema?.nodes || [], [schema?.nodes]);

  const getNodesWithMatchingChildren = useCallback(
    (matchStr: string) => {
      if (matchStr === '') return [];
      return fullNodeList
        ?.map((node) => {
          if (node.name.toLocaleLowerCase().includes(matchStr)) {
            return node;
          } else {
            const children = node.children?.filter((fieldNode) =>
              fieldNode.name.toLocaleLowerCase().includes(matchStr),
            );
            return { ...node, children };
          }
        })
        .filter((node) => node.children && node.children.length > 0);
    },
    [fullNodeList],
  );

  const filterNodes = useMemo(
    () =>
      debounce((matchStr) => {
        const filteredNodes = getNodesWithMatchingChildren(matchStr);
        setFilteredNodes(filteredNodes);
      }, 100),
    [getNodesWithMatchingChildren],
  );

  const updateSearchPhrase = useCallback(
    (inputText: string) => {
      const matchStr = inputText.toLocaleLowerCase();
      setSearchPhrase(matchStr);
      filterNodes(matchStr);
    },
    [filterNodes],
  );

  const clearInput = useCallback(() => {
    setSearchPhrase('');
    setFilteredNodes([]);
  }, []);

  return {
    schema,
    fullNodeList,
    activeNodes: filteredNodes,
    searchPhrase,
    updateSearchPhrase,
    clearInput,
  };
};

export default useDataFieldAccordion;
