import { Chip } from '@mui/material';
import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';

type Props = {
  label: string;
};

const CategoryChip = ({ label }: Props) => {
  return (
    <Chip
      icon={
        <CircleIcon
          sx={{
            '&&': {
              color: '#9268F6',
              ml: 1,
              width: '6px',
              height: '6px',
            },
          }}
        />
      }
      sx={{
        backgroundColor: '#DDD1FC ',
        px: 0.6,
        '& .MuiChip-label': {
          fontSize: '10px',
          color: '#9268F6',
          fontWeight: 500,
        },
      }}
      size='small'
      label={label}
    />
  );
};

export default CategoryChip;
