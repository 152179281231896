import { Box, Typography } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
interface UpdatingIndicatorProps {
  updating: boolean;
}

const UpdatingIndicator = ({ updating }: UpdatingIndicatorProps) => {
  if (updating) {
    return (
      <Typography variant='body2' fontWeight={600}>
        Updating...
      </Typography>
    );
  }
  return (
    <Box color='primary' display='flex' flexDirection='row' alignItems='center'>
      <TaskAltIcon fontSize='inherit' />
      <Typography variant='body2' fontWeight={600} ml={0.25}>
        Updated
      </Typography>
    </Box>
  );
};

export default UpdatingIndicator;
