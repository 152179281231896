import { noop } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

// custom material UI components
import Card from '../shared/Card';
import LastUpdated from './LastUpdated';
import DeleteTemplate from '../modals/DeleteTemplate';
import MenuButton, { MenuItemOption } from '../MenuButton';

// material UI components
import { Grid, Box, Typography } from '@mui/material';

// icon
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CategoryChip from '../modals/TemplateGalleryModal/CategoryChip';

import { CategoryItem } from '../../hooks';
import { useDeleteFeatureTemplateMutation } from '../../generated/types';

interface TitleProps {
  title: string;
}

interface SubheaderProps {
  subheader: string;
}

interface TemplateBoxProps {
  title: string;
  description: string;
  category?: CategoryItem;
  updatedAt: string;
  actions: {
    setPage: (value: string) => void;
    setTemplate: () => void;
  };
  templateID: string;
  fetchTemplates: () => void;
}

interface BodyItemsProps {
  category?: CategoryItem;
  updatedAt: string;
}

const Title = ({ title }: TitleProps) => {
  return (
    <Typography variant='h5' fontWeight='bold'>
      {title}
    </Typography>
  );
};

const Subheader = ({ subheader }: SubheaderProps) => {
  return (
    <Typography variant='body2' color='text.secondary'>
      {subheader}
    </Typography>
  );
};
const BodyItems = ({ category, updatedAt }: BodyItemsProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      gap={1}
    >
      <Box sx={{ display: 'flex', maxWidth: '50%' }}>
        {category ? <CategoryChip label={category.name} /> : <Box />}
      </Box>
      <Box>
        <LastUpdated updatedAt={updatedAt} />
      </Box>
    </Box>
  );
};

const TemplateBox = ({
  title,
  description,
  category,
  actions,
  templateID,
  fetchTemplates,
  updatedAt,
}: TemplateBoxProps) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [success, setSuccess] = useState(false);
  const [deleteFeatureTemplate] = useDeleteFeatureTemplateMutation();

  const handleDeleteFeatureTemplate = useCallback(
    async (id: string) => {
      await deleteFeatureTemplate({
        variables: {
          input: {
            templateId: id,
          },
        },
      }).catch(noop);
    },
    [deleteFeatureTemplate],
  );

  const options: MenuItemOption[] = useMemo(
    () => [
      {
        label: 'Delete',
        icon: <DeleteIcon />,
        onClick: () => setShowDeleteConfirmation(true),
      },
    ],
    [setShowDeleteConfirmation],
  );

  return (
    <Grid item>
      <Card
        sx={{
          height: 'auto',
          width: 240,
          justifyContent: 'space-between',
        }}
        action={
          <MenuButton menuItemOptions={options}>
            <MoreVertIcon />
          </MenuButton>
        }
        sxHeader={{ p: 2 }}
        sxBody={{ p: 2 }}
        title={<Title title={title} />}
        subheader={<Subheader subheader={description} />}
        bodyItems={<BodyItems category={category} updatedAt={updatedAt} />}
        cardOnClick={() => {
          actions.setPage('secondPage');
          actions.setTemplate();
        }}
      />
      <DeleteTemplate
        open={showDeleteConfirmation}
        setOpen={setShowDeleteConfirmation}
        success={success}
        setSuccess={setSuccess}
        templateID={templateID}
        fetchTemplates={fetchTemplates}
        deleteTemplate={handleDeleteFeatureTemplate}
        boldTitle={title}
      />
    </Grid>
  );
};

export default TemplateBox;
