import { useEffect } from 'react';
import { useWorkspaceComponents } from '../hooks';

const WorkspaceComponentsPreFetcher = ({
  workspaceId,
}: {
  workspaceId: string;
}) => {
  const { fetchComponents, loadNewComponentIdsFromLocalStorage } =
    useWorkspaceComponents(workspaceId);

  useEffect(() => {
    fetchComponents();
    loadNewComponentIdsFromLocalStorage();
  }, [fetchComponents, loadNewComponentIdsFromLocalStorage]);

  return null;
};

export default WorkspaceComponentsPreFetcher;
