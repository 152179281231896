import {
  useReducer,
  useCallback,
  useContext,
  createContext,
  Dispatch,
  ReactNode,
} from 'react';

type StateType = {
  isOpen: boolean;
  isPinned: boolean;
  onHold: boolean;
};

type ActionType =
  | { type: 'open' }
  | { type: 'close' }
  | { type: 'pin' }
  | { type: 'unpin' }
  | { type: 'hold' }
  | { type: 'unhold' }
  | { type: 'forceClose' };

const sidebarReducer = (state: StateType, action: ActionType): StateType => {
  switch (action.type) {
    case 'open':
      if (!state.isPinned || !state.onHold) {
        return {
          ...state,
          isOpen: true,
        };
      }
      return state;
    case 'close':
      if (!state.isPinned && !state.onHold) {
        return {
          ...state,
          isOpen: false,
        };
      }
      return state;
    case 'pin':
      return {
        ...state,
        isPinned: true,
      };
    case 'unpin': {
      return {
        ...state,
        isOpen: false,
        isPinned: false,
      };
    }
    case 'hold': {
      return {
        ...state,
        isOpen: true,
        onHold: true,
      };
    }
    case 'unhold': {
      return {
        ...state,
        onHold: false,
      };
    }
    case 'forceClose': {
      return {
        isOpen: false,
        onHold: false,
        isPinned: false,
      };
    }
    default:
      return state;
  }
};

interface SidebarContextStore {
  isOpen: boolean;
  isPinned: boolean;
  onHold: boolean;
  dispatch: Dispatch<ActionType>;
}

const sidebarInitialState: SidebarContextStore = {
  isOpen: false,
  isPinned: false,
  onHold: false,
  dispatch: () => {
    /* */
  },
};

export const SidebarContext = createContext(sidebarInitialState);

export const useSidebar = () => {
  const { isOpen, isPinned, onHold, dispatch } = useContext(SidebarContext);

  const openSidebar = useCallback(() => {
    dispatch({ type: 'open' });
  }, [dispatch]);

  const closeSidebar = useCallback(() => {
    dispatch({ type: 'close' });
  }, [dispatch]);

  const pinSidebar = useCallback(() => {
    dispatch({ type: 'pin' });
  }, [dispatch]);

  const unpinSidebar = useCallback(() => {
    dispatch({ type: 'unpin' });
  }, [dispatch]);

  const holdSidebar = useCallback(() => {
    dispatch({ type: 'hold' });
  }, [dispatch]);

  const unholdSidebar = useCallback(() => {
    dispatch({ type: 'unhold' });
  }, [dispatch]);

  const forceCloseSidebar = useCallback(() => {
    dispatch({ type: 'forceClose' });
  }, [dispatch]);

  return {
    isOpen,
    isPinned,
    onHold,
    openSidebar,
    closeSidebar,
    pinSidebar,
    unpinSidebar,
    holdSidebar,
    unholdSidebar,
    forceCloseSidebar,
  };
};

interface SidebarProviderProps {
  children?: ReactNode;
}

export const SidebarProvider = ({ children }: SidebarProviderProps) => {
  const [sidebarStates, dispatch] = useReducer(
    sidebarReducer,
    sidebarInitialState,
  );

  return (
    <SidebarContext.Provider value={{ ...sidebarStates, dispatch }}>
      {children}
    </SidebarContext.Provider>
  );
};
