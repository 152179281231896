import { useEffect, createContext, useRef, ReactNode, useMemo } from 'react';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { useSessionInfo } from '../hooks';

const LogRocketContext = createContext({});

interface LogRocketProviderProps {
  children: ReactNode;
}

const LogRocketProvider = ({ children }: LogRocketProviderProps) => {
  const { isSignedIn, email } = useSessionInfo();
  const isInitialized = useRef(false);

  useEffect(() => {
    // NODE_ENV production is for dev, staging, and prod
    const isProduction = process.env.NODE_ENV === 'production';
    const isDemo =
      process.env.API_ENDPOINT_BASE === 'https://demo-api.madeinventive.com';

    if (
      isInitialized.current === false &&
      typeof window !== 'undefined' &&
      (isProduction || isDemo) &&
      // this email is Datadog monitoring email. Exclude it from Logrocket.
      isSignedIn &&
      !email.endsWith('@madeinventive.com')
    ) {
      LogRocket.init('rfhwxd/inventive-web');
      setupLogRocketReact(LogRocket);
      isInitialized.current = true;
    }
  }, [email, isSignedIn]);

  const contextValue = useMemo(() => ({}), []);

  return (
    <LogRocketContext.Provider value={contextValue}>
      {children}
    </LogRocketContext.Provider>
  );
};

export default LogRocketProvider;
