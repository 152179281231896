import { useState } from 'react';
import { Stack, OutlinedInput, Typography } from '@mui/material';
import Button from '../../shared/Button';
import { BaseRegisteredDrawerComponentProps } from '../../registeredDrawers/types';

export interface AnalystHintsFormProps
  extends BaseRegisteredDrawerComponentProps {
  isEditable: boolean;
  hintValue?: string;
  saveEdit: (value?: string) => void;
}

// a registered drawer component
const AnalystHintsForm = ({
  isEditable,
  hintValue,
  saveEdit,
  hideDrawer,
}: AnalystHintsFormProps) => {
  const [hintText, setHintText] = useState(hintValue);

  const handleSave = () => {
    saveEdit(hintText);
    hideDrawer();
  };

  if (isEditable) {
    return (
      <Stack height='100%' width='100%' justifyContent='space-between'>
        <OutlinedInput
          size='small'
          multiline
          value={hintText}
          onChange={(e) => setHintText(e.target.value)}
          sx={{
            height: '100%',
            marginBottom: 2,
            '& textarea': {
              height: 'inherit !important',
              overflowY: 'auto !important',
            },
          }}
        />
        <Stack
          width='100%'
          direction='row'
          justifyContent='flex-end'
          spacing={1.5}
        >
          <Button variant='outlined' onClick={hideDrawer}>
            Cancel
          </Button>
          <Button onClick={handleSave}>Save</Button>
        </Stack>
      </Stack>
    );
  }

  return hintText && hintText.length > 0 ? (
    <Stack p={2}>
      <Typography variant='body1' color='text.secondary'>
        {hintText}
      </Typography>
    </Stack>
  ) : (
    <Stack pt={8} alignItems='center'>
      <Typography variant='body1' color='text.secondary'>
        No hints added... yet
      </Typography>
    </Stack>
  );
};

export default AnalystHintsForm;
