import { getTokens } from './localStorage';

// Set up custom headers such as api and authorization bearer token
export const getCustomHeaders = (
  includeAuth = true,
): Record<string, string> | undefined => {
  const headers: Record<string, string> = {};
  if (process.env.NEXT_PUBLIC_API_TOKEN) {
    headers['x-inventive-token'] = process.env.NEXT_PUBLIC_API_TOKEN;
  }

  if (includeAuth) {
    const tokens = getTokens();
    if (tokens) {
      headers['authorization'] = `Bearer ${tokens.accessToken}`;
    }
  }
  return headers;
};
