import { createSlice } from '@reduxjs/toolkit';

export interface UiControlsState {
  isDebugMode: boolean;
}

const initialState: UiControlsState = {
  isDebugMode: false,
};

export const uiControlsSlice = createSlice({
  name: 'uiControls',
  initialState,
  reducers: {
    setDebugMode: (state, action) => {
      state.isDebugMode = action.payload;
    },
  },
});

export const { setDebugMode } = uiControlsSlice.actions;

export default uiControlsSlice.reducer;
