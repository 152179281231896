import { KeyboardEvent, useCallback } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper, { PaperProps } from '@mui/material/Paper';

import Popper, { PopperProps } from '@mui/material/Popper';
import CommentThread, { CommentThreadProps } from './CommentThread';

const DEFAULT_MIN_MAX = {
  maxHeight: '480px',
  minWidth: '200px',
  maxWidth: '250px',
};

export interface CommentsPopperProps extends CommentThreadProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  popupAnchorEl: PopperProps['anchorEl'];
  sx?: PaperProps['sx'];
}

const CommentsPopper = (props: CommentsPopperProps) => {
  const {
    title,
    onNewTitle,
    description,
    onNewDescription,
    comments,
    onNewComment,
    addEnabled,
    editableTitleEnabled,
    menuItemOptions,
    open,
    setOpen,
    popupAnchorEl,
    sx,
  } = props;

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case 'Escape':
          {
            handleClose();
            event.stopPropagation();
          }
          break;
      }
    },
    [handleClose],
  );

  return (
    <Popper
      open={open}
      anchorEl={popupAnchorEl}
      placement='bottom-start'
      sx={{
        ...DEFAULT_MIN_MAX,
        ...sx,
        display: 'flex',
        justifyContent: 'center',
        zIndex: (theme) => theme.zIndex.modal,
      }}
      onKeyDown={onKeyDown}
    >
      <ClickAwayListener onClickAway={handleClose}>
        <Paper
          elevation={4}
          sx={{
            width: '100%',
            overflowY: 'auto',
          }}
        >
          <CommentThread
            title={title}
            onNewTitle={onNewTitle}
            description={description}
            onNewDescription={onNewDescription}
            comments={comments}
            onNewComment={onNewComment}
            addEnabled={addEnabled}
            editableTitleEnabled={editableTitleEnabled}
            menuItemOptions={menuItemOptions}
            onMenuClose={handleClose}
          />
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

export default CommentsPopper;
