import * as Sentry from '@sentry/nextjs';
import { User } from '../../generated/types';
import * as uuid from 'uuid';

function getEventsSampleRate(): number {
  if (process.env.NEXT_PUBLIC_SENTRY_EVENT_SAMPLE_RATE) {
    return parseFloat(process.env.NEXT_PUBLIC_SENTRY_EVENT_SAMPLE_RATE);
  }
  // Set sampleRate to 1.0 to capture 100%
  // of events for event monitoring.
  // We recommend adjusting this value in production.
  return 1.0;
}

function enableTracing(): boolean {
  return process.env.NEXT_PUBLIC_SENTRY_TRACE_ENABLED === 'true';
}

function getTracesSampleRate(): number {
  if (!enableTracing()) {
    return 0.0;
  }

  if (process.env.NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE) {
    return parseFloat(process.env.NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE);
  }
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production.
  return 1.0;
}

const sentrySessionID = uuid.v4();

function getInitialScope(user?: User) {
  let initialScope = {};
  initialScope = {
    ...initialScope,
    tags: {
      sentry_session_id: sentrySessionID,
    },
  };
  if (user) {
    initialScope = {
      ...initialScope,
      user: {
        id: user.id,
        email: user.email,
      },
    };
  }
  return initialScope;
}

export function getSentrySessionID(): string {
  return sentrySessionID;
}

export function initSentry(user?: User) {
  if (!process.env.NEXT_PUBLIC_SENTRY_DSN) {
    return;
  }

  console.debug('Initializing Sentry Client');

  const config: Sentry.NodeOptions = {
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    tracesSampleRate: getTracesSampleRate(),
    sampleRate: getEventsSampleRate(),
    environment: process.env.NEXT_PUBLIC_ENV,
    initialScope: getInitialScope(user),
  };

  Sentry.init(config);
}
