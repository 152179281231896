import { ReactNode, useState } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import MenuButton, { MenuItemOption } from '../../MenuButton';
import { IconSize } from '../../shared/dimensions';

export interface NavCardProps {
  title: string;
  subTitle?: string;
  icon: (iconSize: IconSize) => ReactNode;
  onClick?: BoxProps['onClick'];
  moreMenuItemOptions?: MenuItemOption[];
  selected?: boolean;
  highlightBackgroundOnHover?: boolean;
}

const NavCard = (props: NavCardProps) => {
  const [hovered, setHovered] = useState(false);

  const selectedBorderColor = props.selected ? 'primary.main' : undefined;
  const hoveredBorderColor = hovered ? 'primary.wash' : 'transparent';

  return (
    <Box
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={props.onClick}
      display='flex'
      flexDirection='row'
      flexGrow={1}
      alignItems='center'
      sx={{
        position: 'relative', // for arrow
        backgroundColor: (theme) => theme.palette.background.paper,
        boxShadow: 1,
        border: 3,
        borderRadius: 1,
        borderColor: selectedBorderColor ?? hoveredBorderColor,
        p: 1,
        cursor: 'pointer',
        width: '100%',
      }}
    >
      {props.icon('large')}
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        flexGrow={1}
        flexShrink={1}
        marginLeft={1}
      >
        <Stack
          alignItems='flex-start'
          paddingLeft={1}
          paddingRight={1}
          flexShrink={1}
        >
          <Typography variant='body1'>{props.title}</Typography>
          {props.subTitle && (
            <Typography
              variant='body2'
              sx={{ color: 'text.secondary', fontStyle: 'italic' }}
            >
              {props.subTitle}
            </Typography>
          )}
        </Stack>
        {props.moreMenuItemOptions &&
          props.moreMenuItemOptions.length > 0 &&
          hovered && (
            <MenuButton
              menuItemOptions={props.moreMenuItemOptions}
              buttonProps={{
                sx: { p: 0, minWidth: 0 },
              }}
            >
              <MoreVertIcon />
            </MenuButton>
          )}
        {/* Arrow for panel that points to selected card */}
        {props.selected && (
          <Box
            sx={{
              position: 'absolute',
              width: '20px',
              height: '20px',
              rotate: '45deg',
              backgroundColor: (theme) => theme.palette.background.paper,
              right: -46,
              border: 1,
              borderColor: 'rgba(0, 0, 0, 0.12)',
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default NavCard;
