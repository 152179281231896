import { Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useFeature } from '../../hooks';
import Button from '../shared/Button';
import ConditionList from './ConditionList';

interface ConditionFormProps {
  workspaceId: string;
  inputsDisabled?: boolean;
  showFilterText?: boolean;
}

const ConditionForm = ({
  workspaceId,
  inputsDisabled,
  showFilterText,
}: ConditionFormProps) => {
  const { featureEditData, addCondition } = useFeature();
  const selectedExploreId = featureEditData.exploreId;
  const conditions = featureEditData.conditions;

  return (
    <>
      <ConditionList
        conditions={conditions}
        inputsDisabled={inputsDisabled}
        workspaceId={workspaceId}
      />
      <Box display='flex' flexDirection='row' justifyContent='flex-start'>
        <Button
          variant='text'
          startIcon={<AddIcon />}
          onClick={addCondition}
          sx={{
            textTransform: 'none',
          }}
          disabled={!selectedExploreId || inputsDisabled}
        >
          Add {showFilterText ? 'filter' : 'condition'}
        </Button>
      </Box>
    </>
  );
};

export default ConditionForm;
