import { ChipProps as MuiChipProps } from '@mui/material/Chip';
import { Typography } from '@mui/material';
import Button, { ButtonProps } from '../Button';
import CloseIcon from '@mui/icons-material/Close';

export interface ChipProps extends Omit<ButtonProps, 'variant' | 'size'> {
  size?: 'small' | 'large';
  label: MuiChipProps['label'];
  onDelete?: MuiChipProps['onDelete'];
  icon?: MuiChipProps['icon'];
  selected?: boolean;
  hasError?: boolean;
}

const Chip = ({
  label,
  size,
  icon,
  onDelete,
  onClick,
  selected,
  hasError,
  sx,
  ...rest
}: ChipProps) => {
  const baseBorderColor = selected ? 'border.active' : 'border.actionable';
  const borderColor = hasError ? 'error.main' : baseBorderColor;
  return (
    <Button
      aria-label={`${label}${selected ? '-selected' : ''}`}
      size={size}
      variant='outlined'
      startIcon={icon}
      endIcon={
        onDelete ? (
          <CloseIcon onClick={onDelete} sx={{ cursor: 'pointer' }} />
        ) : undefined
      }
      onClick={onClick}
      sx={{
        cursor: onClick ? 'pointer' : 'default',
        borderRadius: 2,
        borderColor,
        backgroundColor: selected
          ? (theme) => theme.palette.primary.wash
          : (theme) => theme.palette.background.paper,
        padding: size === 'small' ? '3px 5px' : '7px 10px',
        minWidth: 'unset',
        '& .MuiButton-startIcon': {
          marginRight: size === 'small' ? '4px' : '8px',
        },
        '& .MuiButton-endIcon': {
          marginLeft: size === 'small' ? '4px' : '8px',
        },
        ...sx,
      }}
      {...rest}
    >
      <Typography variant={size === 'small' ? 'body2' : 'body1'}>
        {label}
      </Typography>
    </Button>
  );
};

export default Chip;
