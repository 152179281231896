import { useEffect, useMemo, ReactNode } from 'react';
import { Box, DialogContent, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import WarningAmberOutlined from '@mui/icons-material/WarningAmberOutlined';

import { useRouter } from 'next/router';

import { Routes } from '@madeinventive/core-types';
import { FeatureState } from '../../generated/types';

import { BaseRegisteredDialogComponentProps } from './types';
import { useWorkspaceComponent } from '../../hooks/useWorkspaceComponent';

export interface DeleteComponentConfirmationProps
  extends BaseRegisteredDialogComponentProps {
  workspaceId: string;
  componentId: string;
  componentName: string;
}

const StyledAnchor = styled('a')(() => ({
  cursor: 'pointer',
}));

const DeleteComponentConfirmation = ({
  workspaceId,
  componentId,
  componentName,
  hideDialog,
}: DeleteComponentConfirmationProps) => {
  const router = useRouter();
  const { componentReferencesData, fetchComponentReferences } =
    useWorkspaceComponent();

  useEffect(() => {
    fetchComponentReferences({
      workspaceId,
      componentId,
    });
  }, [workspaceId, componentId, fetchComponentReferences]);

  const deleteConfirmationContent = useMemo(() => {
    const baseMessage = (
      <Typography variant='body1' color='text.secondary'>
        <strong>{componentName}</strong> will be removed from your workspace.
        All users in your company will no longer be able to access this view.
      </Typography>
    );

    if (
      componentReferencesData?.node?.__typename !== 'Workspace' ||
      componentReferencesData.node.component.__typename !== 'Component' ||
      !componentReferencesData.node.component.referencingFeatures.length
    ) {
      return baseMessage;
    }

    const { referencingFeatures } = componentReferencesData.node.component;
    const draftFeatureLinks: ReactNode[] = [];
    const liveFeatureLinks: ReactNode[] = [];

    referencingFeatures.forEach((featureSummary) => {
      const featureLink = (
        <StyledAnchor
          onClick={() => {
            hideDialog();
            router.push(Routes.feature(workspaceId, featureSummary.id));
          }}
        >
          {featureSummary.name}
        </StyledAnchor>
      );
      switch (featureSummary.state) {
        case FeatureState.DRAFT:
          draftFeatureLinks.push(featureLink);
          break;
        case FeatureState.ACTIVE:
          liveFeatureLinks.push(featureLink);
          break;
        // no default
      }
    });

    const renderSection = (heading: string, featureLinks: ReactNode[]) => {
      if (!featureLinks.length) return <></>;
      return (
        <>
          <Typography variant='caption' fontWeight='bold'>
            {heading}
          </Typography>
          <Typography variant='body2'>
            <ul style={{ margin: 0, marginLeft: '-1rem' }}>
              {featureLinks.map((link, index) => (
                <li key={index}>{link}</li>
              ))}
            </ul>
          </Typography>
        </>
      );
    };

    return (
      <Stack spacing={2}>
        {baseMessage}
        <Box sx={{ paddingX: 2, paddingY: 1, backgroundColor: 'warning.wash' }}>
          <Stack direction='row' alignItems='top' spacing={1}>
            <WarningAmberOutlined color='warning' fontSize='small' />
            <Stack spacing={0.5}>
              <Typography variant='h6'>
                Deleting will also delete associated alerts. Do you want to
                delete them all?
              </Typography>
              {renderSection('Live', liveFeatureLinks)}
              {renderSection('Draft', draftFeatureLinks)}
            </Stack>
          </Stack>
        </Box>
      </Stack>
    );
  }, [componentName, workspaceId, componentReferencesData, hideDialog, router]);

  return (
    <DialogContent sx={{ padding: 0 }}>
      {deleteConfirmationContent}
    </DialogContent>
  );
};

export default DeleteComponentConfirmation;
