import { Stack, Typography } from '@mui/material';

import TruncatedTypography from '../../shared/TruncatedTypography';
import { useAppSelector } from '../../../hooks';
import { useVisualFieldPicker } from '../../VFPInputField/useVisualFieldPicker';
import { NormalizedConditionExpression } from '@madeinventive/core-types';
import { getDynamicFieldConditionStrings } from './util';
import DataFieldTypeIcon from '../../DataFieldSelector/DataFieldTypeIcon';

const ReadOnlyCondition = ({
  condition,
}: {
  condition: NormalizedConditionExpression;
}) => {
  const { workspace } = useAppSelector((state) => state.workspace.value);
  const { schema } = useVisualFieldPicker({ workspaceId: workspace?.id ?? '' });

  // This ReadOnlyCondition supports only conditions with DynamicFieldV1
  // When the variable type of the condition is PlaceholderV1, it will return null
  const strings = getDynamicFieldConditionStrings(condition, schema);

  if (!strings) {
    return null;
  }

  const { field, operator, value, normalizedType } = strings;

  return (
    <Stack
      aria-label='read-only-condition-container'
      direction='row'
      overflow='hidden'
      padding={1}
      spacing={1}
      borderRadius={2}
      bgcolor={'background.disabled'}
      alignItems='center'
    >
      {normalizedType && <DataFieldTypeIcon normalizedType={normalizedType} />}
      {field && (
        <TruncatedTypography variant='body2' color='text.secondary'>
          {field}
        </TruncatedTypography>
      )}
      {operator && (
        <Typography
          variant='h6'
          sx={{
            whiteSpace: 'nowrap',
          }}
        >
          {operator}
        </Typography>
      )}
      {value && <TruncatedTypography variant='h6'>{value}</TruncatedTypography>}
    </Stack>
  );
};

export default ReadOnlyCondition;
