import * as React from 'react';

// components
import { Grid, Avatar, Badge, Stack } from '@mui/material';

// style
import { createTheme } from '@mui/material/styles';

// store
import { useSessionInfo } from '../../../hooks';

const StyledAvatar = () => {
  const theme = createTheme();
  const { firstName, lastName } = useSessionInfo();

  return (
    <Grid
      display='flex'
      justifyContent='flex-start'
      alignItems='center'
      sx={{
        margin: theme.spacing(0, '8%'),
        padding: theme.spacing(1.5, 0.5),
      }}
    >
      <Stack mr={1} alignItems='center' alignContent='center'>
        <Badge>
          <Avatar
            alt={`${firstName} ${lastName}`}
            sx={{
              width: '28px',
              height: '28px',
              fontWeight: 500,
              lineHeight: '28px',
              textTransform: 'uppercase',
            }}
          >
            {firstName && firstName[0]}
          </Avatar>
        </Badge>
      </Stack>
    </Grid>
  );
};
export default StyledAvatar;
