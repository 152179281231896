import { useCallback } from 'react';
import {
  CreatePolicyInput,
  EditPolicyInput,
  Policy as GQLPolicy,
  useCreateEnvironmentPolicyMutation,
  useDeleteEnvironmentPolicyMutation,
  useEditEnvironmentPolicyMutation,
} from '../generated/types';
import { useAppSelector, useAppDispatch } from './store';
import {
  Policy as ReduxPolicy,
  addPolicyAction,
  editPolicyAction,
  deletePolicyAction,
} from '../store/slices/environment';
import { useToast } from './useToast';

export const covertGQLPolicyToReduxPolicy = (
  policy: GQLPolicy,
): ReduxPolicy => {
  const createdBy = policy.createdBy;
  const reduxPolicy: ReduxPolicy = {
    id: policy.id,
    createdBy: createdBy ? `${createdBy.firstname} ${createdBy.lastname}` : '',
    lastUpdated: policy.updatedAt,
    context: policy.config.context,
    response: policy.config.response,
    constraint: policy.config.constraint,
  };
  return reduxPolicy;
};

const useEnvironmentPolicies = () => {
  const storeDispatch = useAppDispatch();
  const { showSuccessToast, showErrorToast } = useToast();
  const { policies, environment, isLoading } = useAppSelector(
    (state) => state.environment.value,
  );
  const [createPolicy] = useCreateEnvironmentPolicyMutation();
  const [editPolicy] = useEditEnvironmentPolicyMutation();
  const [deletePolicy] = useDeleteEnvironmentPolicyMutation();

  const createEnvPolicy = useCallback(
    async (values: {
      context: string;
      response: string;
      constraint: string;
    }) => {
      if (!environment?.id) {
        return;
      }

      const input: CreatePolicyInput = {
        environmentId: environment?.id,
        policyConfig: {
          context: values.context,
          response: values.response,
          constraint: values.constraint,
        },
      };

      const result = await createPolicy({
        variables: { input },
      });
      if (result.data?.createPolicy.policy) {
        showSuccessToast('Policy created.');
        const newPolicy = covertGQLPolicyToReduxPolicy(
          result.data.createPolicy.policy as GQLPolicy,
        );
        storeDispatch(addPolicyAction(newPolicy));
      } else {
        showErrorToast('Failed to create policy.');
      }
    },
    [
      createPolicy,
      environment?.id,
      showErrorToast,
      showSuccessToast,
      storeDispatch,
    ],
  );

  const editEnvPolicy = useCallback(
    async (
      policyId: string,
      values: {
        context: string;
        response: string;
        constraint: string;
      },
    ) => {
      const input: EditPolicyInput = {
        id: policyId,
        policyConfig: {
          context: values.context,
          response: values.response,
          constraint: values.constraint,
        },
      };
      const result = await editPolicy({ variables: { input } });
      if (result.data?.editPolicy.policy) {
        showSuccessToast('Policy updated.');
        const updatedPolicy = covertGQLPolicyToReduxPolicy(
          result.data.editPolicy.policy as GQLPolicy,
        );
        storeDispatch(editPolicyAction(updatedPolicy));
      } else {
        showErrorToast('Failed to update policy.');
      }
    },
    [editPolicy, showErrorToast, showSuccessToast, storeDispatch],
  );

  const deleteEnvPolicy = useCallback(
    async (policyId: string) => {
      const result = await deletePolicy({
        variables: {
          input: {
            id: policyId,
          },
        },
      });
      if (result.data?.deletePolicy) {
        showSuccessToast('Policy deleted.');
        storeDispatch(deletePolicyAction(policyId));
      } else {
        showErrorToast('Failed to delete policy.');
      }
    },
    [deletePolicy, showErrorToast, showSuccessToast, storeDispatch],
  );

  return {
    policies,
    createEnvPolicy,
    editEnvPolicy,
    deleteEnvPolicy,
    isLoadingPolicies: isLoading,
  };
};

export default useEnvironmentPolicies;
