import { useFormikContext } from 'formik';
import * as yup from 'yup';
import { Stack, Typography } from '@mui/material';

// components
import ClickableCard from '../shared/ClickableCard';
import FormField from '../shared/Inputs/FormField';
import ScheduleVizEmail from './ScheduleVizEmail';
import ScheduleVizSlack from './ScheduleVizSlack';

// types
import { ScheduleVizFormValues, ACTION_TYPE } from './types';
import { BaseRegisteredDrawerComponentProps } from '../registeredDrawers/types';

export const DEFAULT_CRON = '0 0 6 * * *';

export const initialFormValues: ScheduleVizFormValues = {
  actionType: ACTION_TYPE.EMAIL,
  cron: DEFAULT_CRON,
  recipients: [],
};

export const validationSchema = yup.object({
  actionType: yup
    .string()
    .oneOf([ACTION_TYPE.EMAIL, ACTION_TYPE.SLACK])
    .required(),
  cron: yup.string().required('Please select a schedule.'),
  recipients: yup.array().when('actionType', {
    is: ACTION_TYPE.EMAIL,
    then: yup
      .array()
      .of(yup.string())
      .min(1, 'Please enter at least one recipient.'),
  }),
});

export interface ScheduleVizFormProps
  extends BaseRegisteredDrawerComponentProps {
  readOnly?: boolean;
  onRecipientsChange?: (recipients: string) => void;
  onScheduleChange?: (cron: string) => void;
}

const ScheduleVisualizationForm = ({
  readOnly,
  onRecipientsChange,
  onScheduleChange,
}: ScheduleVizFormProps) => {
  const formik = useFormikContext<ScheduleVizFormValues>();

  return (
    <Stack id='schedule-viz-form-container' flex={1}>
      <Stack
        spacing={3}
        sx={{
          overflowY: 'auto',
        }}
      >
        <Typography variant='body1' color='text.secondary'>
          Schedules will automatically send at 6:00 AM Pacific Time. Each report
          includes your selected chart and a downloadable CSV file of your data.
        </Typography>

        {/* Action Type */}
        {readOnly && (
          <FormField label='Send methods'>
            <Typography>{formik.values.actionType}</Typography>
          </FormField>
        )}
        {!readOnly && (
          <Stack id='action-type-buttons' direction='row' spacing={1}>
            <ClickableCard
              label='email'
              selected={formik.values.actionType === ACTION_TYPE.EMAIL}
              onClick={() =>
                formik.setFieldValue('actionType', ACTION_TYPE.EMAIL)
              }
              icon='mail'
              title='Send via email'
              text='Create a recurring email to be delivered on a regular basis.'
            />
            <ClickableCard
              label='slack'
              selected={formik.values.actionType === ACTION_TYPE.SLACK}
              onClick={() =>
                formik.setFieldValue('actionType', ACTION_TYPE.SLACK)
              }
              icon='slack'
              title='Send via Slack'
              text='Schedule a regular post in a Slack channel.'
            />
          </Stack>
        )}
        {formik.values.actionType === ACTION_TYPE.EMAIL && (
          <ScheduleVizEmail
            formik={formik}
            readOnly={readOnly}
            onRecipientsChange={onRecipientsChange}
            onScheduleChange={onScheduleChange}
          />
        )}
        {formik.values.actionType === ACTION_TYPE.SLACK && (
          <ScheduleVizSlack formik={formik} />
        )}
      </Stack>
    </Stack>
  );
};

export default ScheduleVisualizationForm;
