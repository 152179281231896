import React, { useCallback, ReactNode } from 'react';
import { capitalize } from 'lodash';
import {
  ButtonGroup,
  ButtonGroupProps,
  Typography,
  CircularProgress,
} from '@mui/material';
import Button from '../Button';
import Tooltip from '../Tooltip';

// ButtonOption type must have either a label or an icon
export type ButtonOption<T> =
  | {
      value: T;
      icon?: ReactNode;
      label: string;
      tooltipText?: string;
      isLoading?: boolean;
      disabled?: boolean;
    }
  | {
      value: T;
      icon: ReactNode;
      label?: string;
      tooltipText?: string;
      isLoading?: boolean;
      disabled?: boolean;
    };
export interface ToggleButtonProps<T>
  extends Omit<ButtonGroupProps, 'variant'> {
  size?: 'small' | 'large';
  selected?: T;
  handleItemClick: (value: T) => void;
  options: ButtonOption<T>[];
  hasError?: boolean;
}

const ToggleButton = <T,>({
  size = 'large',
  selected,
  handleItemClick,
  options,
  hasError,
  ...rest
}: ToggleButtonProps<T>) => {
  const handleChange = useCallback(
    (newValue: T) => {
      if (newValue !== selected) {
        handleItemClick(newValue);
      }
    },
    [handleItemClick, selected],
  );

  const startIconMarginRight = size === 'small' ? '4px' : '8px';

  return (
    <ButtonGroup variant='outlined' {...rest}>
      {options.map((option) => {
        const isSelected = option.value === selected;
        const baseBorderColor = isSelected
          ? 'border.active'
          : 'border.actionable';
        const borderColor = hasError ? 'error.main' : baseBorderColor;
        return (
          <Tooltip
            key={option.value as string}
            title={option.tooltipText}
            arrow
            placement='top'
          >
            <Button
              variant={isSelected ? 'contained' : 'outlined'}
              onClick={() => handleChange(option.value)}
              disableElevation
              disableRipple
              startIcon={option.icon}
              disabled={option.disabled}
              sx={{
                borderRadius: '8px',
                padding: size === 'small' ? '4px 8px' : '8px 12px',
                '& .MuiButton-startIcon': {
                  // startIcon has a default margin-right and negative margin-left
                  // Use dynamic margin based on the size of the button
                  // Remove them when there is no label
                  marginRight: option.label ? startIconMarginRight : 0,
                  marginLeft: option.label ? -startIconMarginRight : 0,
                },
                // zIndex is used to make sure the borders for selected are visible
                zIndex: isSelected ? '2' : '0',
                // border and background colors
                borderColor,
                backgroundColor: isSelected
                  ? (theme) => theme.palette.primary.wash
                  : (theme) => theme.palette.background.paper,
                '&.MuiButtonGroup-grouped:not(:last-of-type)': {
                  borderRightColor: isSelected
                    ? (theme) => theme.palette.border.active
                    : 'transparent',
                },
                '&:hover': {
                  backgroundColor: (theme) => theme.palette.primary.wash,
                  borderColor: isSelected
                    ? (theme) => theme.palette.border.active
                    : (theme) => theme.palette.border.actionable,
                },
                '&:focus': {
                  backgroundColor: (theme) => theme.palette.primary.wash,
                  borderColor: (theme) => theme.palette.border.active,
                },
                '&:disabled': {
                  backgroundColor: (theme) => theme.palette.background.paper,
                  color: (theme) => theme.palette.text.disabled,
                },
              }}
            >
              {option.isLoading ? (
                <CircularProgress size={16} sx={{ marginRight: 1 }} />
              ) : null}
              <Typography variant={size === 'small' ? 'body2' : 'body1'}>
                {capitalize(option.label)}
              </Typography>
            </Button>
          </Tooltip>
        );
      })}
    </ButtonGroup>
  );
};

export default ToggleButton;
