import Autocomplete, {
  AutocompleteProps,
  AutocompleteRenderGetTagProps,
} from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import { FormControl } from '@mui/material';

interface TagProps extends ReturnType<AutocompleteRenderGetTagProps> {
  label: string;
}

const Tag = (props: TagProps) => {
  const { label, onDelete, disabled, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      {!disabled && (
        <IconButton
          onClick={onDelete}
          size='small'
          aria-label={`remove ${label}`}
        >
          <CloseIcon fontSize='inherit' />
        </IconButton>
      )}
    </div>
  );
};

const StyledTag = styled(Tag)<TagProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: theme.spacing(2.25),
  marginRight: 2,
  lineHeight: 1.5,
  border: `1px solid #bdbdbd`,
  borderRadius: 2,
  boxSizing: 'content-box',
  padding: `0 ${theme.spacing(0.5)} 0 ${theme.spacing(1)}`,
  outline: 0,
  overflow: 'hidden',
  [`& span`]: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

export type MultiValueAutocompleteProps = {
  label?: string;
  placeholder?: string;
  options: readonly string[];
  hasError?: boolean;
} & Omit<
  AutocompleteProps<string, true, false, boolean>,
  'multiple' | 'size' | 'renderTags' | 'renderInput' | 'options'
>;

const MultiValueAutocomplete = (props: MultiValueAutocompleteProps) => {
  const { label, placeholder, hasError, ...rest } = props;
  return (
    <FormControl fullWidth error={hasError}>
      <Autocomplete
        multiple
        size='small'
        renderTags={(tagValues, getTagProps) =>
          tagValues.map((value, index) => (
            // eslint-disable-next-line react/jsx-key
            <StyledTag
              aria-label={`tag for ${value}`}
              label={value}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            error={hasError}
          />
        )}
        {...rest}
      />
    </FormControl>
  );
};

export default MultiValueAutocomplete;
