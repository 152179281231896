import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ModalState {
  activeModal?: string;
}

const initialModalValue: ModalState = {
  activeModal: undefined,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState: { value: initialModalValue },
  reducers: {
    setActiveModalSlice: (state, action: PayloadAction<ModalState>) => {
      state.value = action.payload;
    },
    clearActiveModalSlice: (state) => {
      state.value = initialModalValue;
    },
  },
});

export const { setActiveModalSlice, clearActiveModalSlice } =
  modalSlice.actions;

export default modalSlice.reducer;
