import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Environment,
  EnvironmentLookerDataModelExplore,
} from '../../generated/types';
import { ResponseBias } from '@madeinventive/core-types';

interface EnvironmentState {
  environment?: Environment;
  envExplores?: EnvironmentLookerDataModelExplore[];
  agentPersonaConfig: AgentPersonaConfig;
  policies: Policy[];
  wsListUpdateCounter: number;
  isLoading?: boolean;
}

// This is app level AgentPersona type from core-types
// app level type has different field names and required fields for convenience
// compared to core-types AgentPersona type
export type AgentPersonaConfig = {
  analystName: string;
  profileIconUrl: string;
  welcomeMsgTitle: string;
  welcomeMsgSubTitle: string;
  disclaimer: string;
  responseBias: ResponseBias;
  analystHints?: string;
};

export type Policy = {
  id: string;
  createdBy: string;
  lastUpdated: string;
  context: string;
  response: string;
  constraint?: string;
};

const initialPersonaConfig: AgentPersonaConfig = {
  analystName: '',
  profileIconUrl: '',
  welcomeMsgTitle: '',
  welcomeMsgSubTitle: '',
  disclaimer: '',
  responseBias: ResponseBias.NEUTRAL,
  analystHints: '',
};

const initialState: EnvironmentState = {
  environment: undefined,
  envExplores: undefined,
  agentPersonaConfig: initialPersonaConfig,
  policies: [],
  wsListUpdateCounter: 0,
  isLoading: false,
};

const environmentSlice = createSlice({
  name: 'environment',
  initialState: {
    value: initialState,
  },
  reducers: {
    setEnvironment: (state, action: PayloadAction<Environment>) => {
      state.value.environment = action.payload;
    },
    setEnvironmentAPIKey: (state, action: PayloadAction<string>) => {
      if (state.value.environment) {
        state.value.environment.apiKey = action.payload;
      }
    },
    bumpWsListUpdateCounter: (state) => {
      state.value.wsListUpdateCounter =
        (state.value.wsListUpdateCounter ?? 0) + 1;
    },
    setEnvExplores: (
      state,
      action: PayloadAction<EnvironmentLookerDataModelExplore[]>,
    ) => {
      state.value.envExplores = action.payload;
    },
    addEnvExplore: (
      state,
      action: PayloadAction<EnvironmentLookerDataModelExplore>,
    ) => {
      state.value.envExplores?.push(action.payload);
    },
    updateEnvExplore: (
      state,
      action: PayloadAction<EnvironmentLookerDataModelExplore>,
    ) => {
      const index = state.value.envExplores?.findIndex(
        (explore) => explore.id === action.payload.id,
      );
      if (index !== undefined && index !== -1) {
        state.value.envExplores?.splice(index, 1, action.payload);
      }
    },
    removeEnvExplore: (state, action: PayloadAction<string>) => {
      state.value.envExplores = state.value.envExplores?.filter(
        (explore) => explore.id !== action.payload,
      );
    },
    setAgentPersonaConfig: (
      state,
      action: PayloadAction<AgentPersonaConfig>,
    ) => {
      state.value.agentPersonaConfig = action.payload;
    },
    setDefaultAgentPersonaConfig: (state) => {
      state.value.agentPersonaConfig = initialPersonaConfig;
    },
    setPoliciesAction: (state, action: PayloadAction<Policy[]>) => {
      state.value.policies = action.payload;
    },
    addPolicyAction: (state, action: PayloadAction<Policy>) => {
      state.value.policies.push(action.payload);
    },
    editPolicyAction: (state, action: PayloadAction<Policy>) => {
      const updatedPolicy = action.payload;
      const index = state.value.policies.findIndex(
        (policy) => policy.id === updatedPolicy.id,
      );
      if (index !== -1) {
        state.value.policies[index] = action.payload;
      }
    },
    deletePolicyAction: (state, action: PayloadAction<string>) => {
      state.value.policies = state.value.policies.filter(
        (policy) => policy.id !== action.payload,
      );
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.value.isLoading = action.payload;
    },
  },
});

export const {
  setEnvironment,
  setEnvironmentAPIKey,
  bumpWsListUpdateCounter,
  setEnvExplores,
  addEnvExplore,
  updateEnvExplore,
  removeEnvExplore,
  setAgentPersonaConfig,
  setDefaultAgentPersonaConfig,
  setPoliciesAction,
  addPolicyAction,
  editPolicyAction,
  deletePolicyAction,
  setIsLoading,
} = environmentSlice.actions;

export default environmentSlice.reducer;
