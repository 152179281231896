import { useContext, useState } from 'react';
import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

// components
import CustomerIdentifierInput from '../CustomerIdentifierInput';
import DialogActions from '../shared/Dialog/DialogActions';
import FormFieldLabel from '../shared/Form/FormFieldLabel';

// hooks
import { EnvironmentContext } from '../EnvironmentProvider';
import { CreateWorkspaceLookerDataModelExploreInput } from '../../generated/types';
import { BaseRegisteredDialogComponentProps } from './types';

export interface WorkspaceDataDialogProps
  extends BaseRegisteredDialogComponentProps {
  workspaceId: string;
  workspaceName: string;
  addExploreToWorkspace: (
    params: CreateWorkspaceLookerDataModelExploreInput,
  ) => Promise<void>;
}

interface FormDataProps {
  explore: string;
  customerIdentifiers: string[];
}

const FORM_INITIAL_STATE = {
  customerIdentifiers: [],
  explore: '',
};

const WorkspaceDataDialog = ({
  workspaceId,
  workspaceName,
  hideDialog,
  addExploreToWorkspace,
}: WorkspaceDataDialogProps) => {
  const [selectedExploreDetails, setSelectedExploreDetails] = useState({
    id: '',
    customerField: '',
    exploreName: '',
  });

  const { envExplores } = useContext(EnvironmentContext);

  const createDataModelExplore = async (
    formData: FormDataProps,
  ): Promise<void> => {
    addExploreToWorkspace({
      workspaceId,
      lookerDataModelExploreId: formData.explore,
      customerFilterValues: formData.customerIdentifiers,
    });
  };

  const handleExploreChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedRow = envExplores?.filter(
      (el) => el.id === e.target.value,
    )[0];
    if (selectedRow) {
      setSelectedExploreDetails({
        id: selectedRow.id,
        customerField: selectedRow.customerField || '',
        exploreName: selectedRow.exploreName,
      });
    }
  };

  const FORM_VALIDATION = Yup.object().shape({
    explore: Yup.string().required('Required information'),
    customerIdentifiers: selectedExploreDetails.customerField
      ? Yup.array().of(Yup.string()).required('Required information')
      : Yup.array().of(Yup.string()),
  });

  return (
    <Formik
      enableReinitialize={true}
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={FORM_INITIAL_STATE}
      validationSchema={FORM_VALIDATION}
      onSubmit={(values, { resetForm }) => {
        createDataModelExplore(values);
        resetForm();
      }}
    >
      {({
        values,
        errors,
        handleSubmit,
        handleChange,
        resetForm,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormFieldLabel text='Data' required />
                <Field
                  name='explore'
                  type='select'
                  size='small'
                  as={Select}
                  variant='outlined'
                  displayEmpty
                  fullWidth
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    handleChange(e);
                    handleExploreChange(e);
                    setFieldValue('customerIdentifier', '');
                  }}
                  sx={{ my: 1 }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: '310px',
                      },
                    },
                  }}
                >
                  <MenuItem value='' disabled>
                    <Typography color='text.secondary'>Select data</Typography>
                  </MenuItem>
                  {envExplores?.map((explore) => (
                    <MenuItem
                      value={explore.id}
                      key={explore.id}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        padding: '10px 15px',
                      }}
                    >
                      <Typography
                        variant='body2'
                        color='text.primary'
                        sx={{
                          fontWeight: 700,
                          lineHeight: '13px',
                        }}
                      >
                        {explore.exploreName}
                      </Typography>
                      <Typography
                        variant='body2'
                        color='text.primary'
                        sx={{
                          fontWeight: 400,
                          lineHeight: '12px',
                        }}
                      >
                        {explore.dataModelName}
                      </Typography>
                    </MenuItem>
                  ))}
                </Field>
                <FormHelperText sx={{ color: 'red' }}>
                  {errors.explore}
                </FormHelperText>
              </FormControl>
            </Grid>

            {selectedExploreDetails.customerField ? (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormFieldLabel
                    text='Customer identifiers'
                    required
                    subText={`Select the identifiers for ${workspaceName}`}
                  />
                  <Field
                    name='customerIdentifiers'
                    as={CustomerIdentifierInput}
                    fieldName={selectedExploreDetails.customerField}
                    envExploreId={selectedExploreDetails.id}
                    value={values.customerIdentifiers}
                    onChange={(_event: unknown, value: string | null) => {
                      setFieldValue('customerIdentifiers', value);
                    }}
                    autoSelect
                    fullWidth
                    size='small'
                    sx={{ my: 1 }}
                  />
                  <FormHelperText sx={{ color: 'red' }}>
                    {errors.customerIdentifiers}
                  </FormHelperText>
                </FormControl>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
          <DialogActions
            closeDialog={hideDialog}
            secondaryAction={{
              text: 'Cancel',
              action: () => {
                hideDialog();
                resetForm();
              },
              disabled: false,
            }}
            primaryAction={{
              text: 'Save changes',
              action: handleSubmit,
              disabled:
                !values.explore ||
                (!!selectedExploreDetails.customerField &&
                  !values.customerIdentifiers),
            }}
          />
        </Form>
      )}
    </Formik>
  );
};

export default WorkspaceDataDialog;
