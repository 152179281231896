import { useFormikContext } from 'formik';
import { Stack } from '@mui/material';

// components
import ClickableCard from '../../shared/ClickableCard';
import ScheduleSelector from '../../shared/ScheduleSelector';
import Conditions from '../Conditions';
import DataFieldSelector from '../../DataFieldSelector';
import AttachmentSelector from '../../AttachmentSelector';

// types
import { SettingsFormValues } from '../types';
import FormField from '../../shared/Inputs/FormField';

interface CreateAlertSettingsProps {
  workspaceId: string;
}
const CreateAlertSettings = ({ workspaceId }: CreateAlertSettingsProps) => {
  const { setFieldValue, values, errors, touched } =
    useFormikContext<SettingsFormValues>();

  return (
    <Stack id='settings-step' spacing={4} width='100%'>
      <FormField
        label='Alert type'
        errorMessage={errors.actionTriggerType}
        onChange={() => {}}
      >
        <Stack id='action-type-buttons' direction='row' spacing={1}>
          <ClickableCard
            label='schedule'
            selected={values.actionTriggerType === 'schedule'}
            onClick={() => setFieldValue('actionTriggerType', 'schedule')}
            icon='calendar'
            title='On a regular schedule'
            text='Create a recurring email to be delivered on a regular basis.'
            error={!!errors.actionTriggerType}
          />
          <ClickableCard
            label='condition'
            selected={values.actionTriggerType === 'condition'}
            onClick={() => setFieldValue('actionTriggerType', 'condition')}
            icon='bell-on'
            title='When conditions are met'
            text='Set criteria to determine when reports are automatically sent.'
            error={!!errors.actionTriggerType}
          />
        </Stack>
      </FormField>
      {values.attachment && (
        <FormField
          label='Attachment'
          subLabel='Choose from any chart or table saved to your workspace.'
        >
          <AttachmentSelector
            attachment={values.attachment}
            onChange={(attachment) => setFieldValue('attachment', attachment)}
          />
        </FormField>
      )}
      {values.actionTriggerType === 'schedule' && (
        <ScheduleSelector
          onChange={(cron) => setFieldValue('cron', cron)}
          initialCron={values.cron}
          hasError={touched.cron ? !!errors.cron : false}
        />
      )}
      {values.actionTriggerType === 'condition' && (
        <FormField
          label='Get alerts about'
          errorMessage={touched.alertsAbout ? errors.alertsAbout : undefined}
        >
          <DataFieldSelector
            field={values['alertsAbout']}
            handleFieldSelect={(dataField) => {
              setFieldValue('alertsAbout', dataField);
            }}
            hasError={touched.alertsAbout && !!errors.alertsAbout}
          />
        </FormField>
      )}
      {values.actionTriggerType === 'condition' && (
        <Conditions
          label='When these conditions are met'
          workspaceId={workspaceId}
        />
      )}
    </Stack>
  );
};

export default CreateAlertSettings;
