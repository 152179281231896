import { BaseEditor, Descendant, BaseText } from 'slate';
import { ReactEditor } from 'slate-react';
import { SchemaNode } from '../../store/slices/exploreExtracts';

export enum ElementType {
  FIELD = 'field',
  PARAGRAPH = 'paragraph',
}

export interface FieldElement {
  type: ElementType; // slate element type
  children: Descendant[]; // children of the slate element
  // field is a property of the SchemaNode to be stored in the FieldElement
  field: SchemaNode;
}

export interface ParagraphElement {
  type: ElementType;
  children: Descendant[];
}

export type CustomElement = FieldElement | ParagraphElement;

export interface CustomText extends BaseText {
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  code?: boolean;
}

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

export const DEFAULT_SLATE_VALUE: CustomElement[] = [
  {
    type: ElementType.PARAGRAPH,
    children: [{ text: '' }],
  },
];

export const DYNAMIC_FIELD_LAUNCH_KEY = '{';

export enum Mode {
  RICH_TEXT = 'rich-text',
  RAW_TEXT = 'raw-text',
}

export enum OutputFormat {
  HTML = 'html',
  MARKDOWN = 'markdown',
}
