import { useFormikContext } from 'formik';
import * as yup from 'yup';
import { Stack, Typography } from '@mui/material';
import TextField from '../Inputs/TextField';
import { BaseRegisteredDrawerComponentProps } from '../../registeredDrawers/types';

export interface LoginFormValues {
  email: string;
  password: string;
}

export interface LoginFormProps extends BaseRegisteredDrawerComponentProps {
  description?: string;
}

export const loginFormInitialValues: LoginFormValues = {
  email: '',
  password: '',
};

export const loginFormValidationSchema = yup.object({
  email: yup.string().email('Please enter a valid email address.').required(),
  password: yup.string().required('Please enter your password.'),
});

const LoginForm = ({ description }: LoginFormProps) => {
  const formik = useFormikContext<LoginFormValues>();
  const { setFieldValue, values, errors } = formik;

  return (
    <Stack spacing={1}>
      <Typography variant='body1'>{description}</Typography>
      <TextField
        label='email'
        value={values.email}
        errorMessage={errors.email}
        onChange={(e) => setFieldValue('email', e.target.value)}
      />
      <TextField
        label='password'
        value={formik.values['password']}
        onChange={(e) => setFieldValue('password', e.target.value)}
        errorMessage={errors.password}
        inputProps={{
          type: 'password',
        }}
      />
    </Stack>
  );
};

export default LoginForm;
