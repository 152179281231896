// material UI components
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Grid, Typography } from '@mui/material';

import moment from 'moment';

const LastUpdated = ({ updatedAt }: { updatedAt: string }) => {
  return (
    <Grid item display='flex' alignItems='center' gap={0.5}>
      <AccessTimeIcon sx={{ width: '9px', height: '9px' }} />
      <Typography variant='body2' color='text.secondary'>
        {moment(updatedAt).fromNow()}
      </Typography>
    </Grid>
  );
};

export default LastUpdated;
