import MuiTooltip, {
  TooltipProps,
  tooltipClasses,
} from '@mui/material/Tooltip';
import { styled } from '@mui/system';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: theme.spacing(0.3),
    padding: theme.spacing(1),
    whiteSpace: 'pre-line',
  },
})) as typeof MuiTooltip;

const Tooltip = ({ children, title, ...rest }: TooltipProps) => {
  if (!title) return children;
  return (
    <StyledTooltip title={title} {...rest}>
      {children}
    </StyledTooltip>
  );
};

export default Tooltip;
