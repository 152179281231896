import { Box, Typography } from '@mui/material';
import React from 'react';
import DynamicFieldInput from '../../DynamicFieldInput';
import InputBox from './InputBox';
import {
  FieldControlConfig,
  FieldControlType,
} from '../../../hooks/feature/ActionManifest';
import HtmlDynamicFieldInput from '../../HtmlDynamicFieldInput';

type ActionConfigPreviewProps = {
  sections: { [k: string]: string };
  controlConfig: FieldControlConfig;
};

const renderSection = (section: string, content: string | React.ReactNode) => (
  <Box key={section}>
    <Typography
      variant='body1'
      sx={{
        fontWeight: 500,
      }}
    >
      {section}
    </Typography>
    {content}
  </Box>
);

const ActionConfigPreview = ({
  sections,
  controlConfig,
}: ActionConfigPreviewProps) => {
  const nodes: React.ReactNode[] = [];
  controlConfig.fields.forEach((f) => {
    const fieldValue = sections[f.fieldName];
    if (fieldValue) {
      switch (f.controlType) {
        case FieldControlType.DynamicInput:
          nodes.push(
            renderSection(
              f.fieldLabel,
              <Box my={2}>
                <DynamicFieldInput
                  initialValue={fieldValue}
                  className='dynamicField'
                  readonly
                  mixed
                  disabled
                />
              </Box>,
            ),
          );
          break;
        case FieldControlType.DynamicInputArea:
          nodes.push(
            renderSection(
              f.fieldLabel,
              <Box my={2}>
                <HtmlDynamicFieldInput
                  initialValue={fieldValue}
                  className='htmlDynamicField'
                  InputMode='textarea'
                  mixed
                  disabled
                />
              </Box>,
            ),
          );
          break;
        case FieldControlType.RegularInput:
        default:
          nodes.push(
            renderSection(
              f.fieldLabel,
              <InputBox>
                <Typography>{sections[f.fieldName]}</Typography>
              </InputBox>,
            ),
          );
          break;
      }
    }
  });
  return <Box pl={2}>{nodes}</Box>;
};

export default ActionConfigPreview;
