import * as React from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListSubheader,
  Typography,
  ListItemIcon,
  Stack,
} from '@mui/material';

import { SidebarItemsGroup, SideBarItemId } from './getSidebarItems';
interface SettingsSidebarProps {
  setCurrentContent: (params: SideBarItemId) => void;
  currentContent: string;
  items: SidebarItemsGroup[];
}

const SettingsSidebar = ({
  setCurrentContent: setCurrentTab,
  currentContent: currentTab,
  items,
}: SettingsSidebarProps) => {
  const handleOptionClick = (id: SideBarItemId) => {
    setCurrentTab(id);
  };

  const visibleList = items.filter((group) => {
    return !group.hidden;
  });

  return (
    <Box display='flex' sx={{ background: '#C4C4C40F', height: '100%' }}>
      <Stack flexDirection='column' height='100%'>
        <Stack flexDirection='column' flex={1}>
          {visibleList.map((group, index) => (
            <List
              color='neutral'
              key={index}
              sx={{ p: 0, width: '240px', marginBottom: 2 }}
              subheader={
                <ListSubheader
                  sx={(theme) => ({
                    background: 'none',
                    fontSize: theme.typography.body2.fontSize,
                    pl: 4,
                  })}
                >
                  {group.header}
                </ListSubheader>
              }
            >
              {group.items.map((item, index) => (
                <ListItem
                  button
                  aria-label={item.desc}
                  onClick={() => handleOptionClick(item.id)}
                  key={index}
                  selected={currentTab === item.id}
                  disabled={item.disabled}
                  sx={{
                    p: 2,
                    pl: 3.5,
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    '&.Mui-selected': {
                      borderLeft: '3px solid',
                    },
                    '&:hover': {
                      borderLeft: '3px solid',
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color:
                        currentTab === item.id
                          ? 'text.primary'
                          : 'text.secondary',
                      '&.MuiListItemIcon-root': {
                        minWidth: '33px',
                      },
                    }}
                  >
                    <item.icon />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        sx={{
                          color:
                            currentTab === item.id
                              ? 'text.primary'
                              : 'text.secondary',
                          fontWeight: 500,
                          lineHeight: '20px',
                          letterSpacing: '0.1px',
                        }}
                      >
                        {item.desc}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          ))}
        </Stack>
      </Stack>
      <Divider orientation='vertical' flexItem />
    </Box>
  );
};

export default SettingsSidebar;
