import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';

import {
  Component,
  useDeleteComponentMutation,
  useEditComponentMutation,
  useEnvironmentComponentsLazyQuery,
  useCreateDataComponentFromChatResponseMutation,
  ComponentType,
} from '../generated/types';
import { useAppDispatch, useAppSelector } from './store';
import { useToast } from './useToast';
import {
  setEnvComponentsByType as setEnvComponentAction,
  addEnvComponentByType as addEnvComponentAction,
  removeEnvComponentByType as removeEnvComponentAction,
  updateEnvComponentByType as updateEnvComponentAction,
} from '../store/slices/environmentComponents';

export type QueryType = {
  id: string;
  title: string;
  query: Record<string, string>;
};

export const useQueries = (environmentId?: string) => {
  const storeDispatch = useAppDispatch();
  const router = useRouter();
  const { showErrorToast, showDefaultToast } = useToast();

  const { dataComponents } = useAppSelector(
    (state) => state.environmentComponents.value,
  );

  const [
    getEnvironmentComponents,
    { data: environmentComponents, loading: queryLoading },
  ] = useEnvironmentComponentsLazyQuery();
  const [deleteDataComponent] = useDeleteComponentMutation();
  const [editDataComponent] = useEditComponentMutation();
  const [createDataComponentFromChatResponse] =
    useCreateDataComponentFromChatResponseMutation();

  useEffect(() => {
    if (
      environmentComponents?.node?.__typename === 'Environment' &&
      environmentId
    ) {
      const queries = environmentComponents.node.components.edges
        .map((edge) => edge.node as Component)
        .filter(
          (component) =>
            !!component &&
            component.__typename === 'Component' &&
            component.type === ComponentType.DATA,
        );
      storeDispatch(
        setEnvComponentAction({
          environmentId,
          componentType: ComponentType.DATA,
          components: queries,
        }),
      );
    }
  }, [environmentComponents, environmentId, storeDispatch]);

  const loadQueries = useCallback(
    async (environmentId: string) => {
      await getEnvironmentComponents({
        variables: {
          environmentId,
          params: {
            type: ComponentType.DATA,
          },
        },
      });
    },
    [getEnvironmentComponents],
  );

  const createQuery = useCallback(
    async (chatResponseId: string, name: string) => {
      const res = await createDataComponentFromChatResponse({
        variables: {
          input: {
            chatResponseId,
            name,
          },
        },
      });
      if (res.data?.createDataComponentFromChatResponse) {
        const component =
          res.data.createDataComponentFromChatResponse.component;
        if (component && environmentId) {
          storeDispatch(
            addEnvComponentAction({
              environmentId: environmentId,
              componentType: ComponentType.DATA,
              component: component as Component,
            }),
          );
          showDefaultToast('Content saved to Create.', 'View', () => {
            router.push('/create/queries');
          });
        } else {
          showErrorToast('Content not saved. Please retry.');
        }
      } else {
        showErrorToast('Content not saved. Please retry.');
      }
    },
    [
      createDataComponentFromChatResponse,
      environmentId,
      router,
      showDefaultToast,
      showErrorToast,
      storeDispatch,
    ],
  );

  const renameQuery = useCallback(
    async (id: string, newTitle: string) => {
      const result = await editDataComponent({
        variables: {
          input: {
            componentId: id,
            name: newTitle,
          },
        },
      });

      if (result.data?.editComponent) {
        const component = result.data.editComponent.component as Component;
        if (component && environmentId) {
          storeDispatch(
            updateEnvComponentAction({
              environmentId,
              componentType: ComponentType.DATA,
              component,
            }),
          );
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    [editDataComponent, environmentId, storeDispatch],
  );

  const deleteQuery = useCallback(
    async (id: string) => {
      const result = await deleteDataComponent({
        variables: {
          input: {
            componentId: id,
          },
        },
      });

      if (result.data?.deleteComponent.success && environmentId) {
        storeDispatch(
          removeEnvComponentAction({
            environmentId,
            componentType: ComponentType.DATA,
            componentId: id,
          }),
        );
        return true;
      } else {
        showErrorToast('Content not deleted. Please retry.');
        return false;
      }
    },
    [deleteDataComponent, environmentId, showErrorToast, storeDispatch],
  );

  return {
    queries: dataComponents,
    queryLoading,
    loadQueries,
    renameQuery,
    deleteQuery,
    createQuery,
  };
};
