import { useState, useCallback, useRef, useMemo, useEffect } from 'react';

// mui
import {
  Box,
  FormControl,
  OutlinedInput,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  Typography,
  Paper,
  ClickAwayListener,
  InputAdornment,
  styled,
} from '@mui/material';
import Popper, { PopperProps } from '@mui/material/Popper';
import {
  SearchOutlined as SearchIcon,
  Info as InfoIcon,
} from '@mui/icons-material';
import { omit } from 'min-dash';

// components
import SchemaViewer, {
  OnSelectHandler,
  renderNodeTypeIcon,
} from './SchemaViewer';
import PreviewTable from './PreviewTable';

// hooks and more
import {
  PlaceholderLocation,
  usePlaceholder,
  useFeature,
  useAppSelector,
} from '../../hooks';
import { useVisualFieldPicker } from './useVisualFieldPicker';
import { getExploreInfoByEnvExploreId } from '../../store/slices/loadedWorkspaceExplores';

// types
import {
  isValid,
  validators,
  FieldType,
  ensureType,
  TagMetaData,
  PlaceholderV1MetaData,
  DynamicFieldV1MetaData,
} from '@madeinventive/core-types';

import { ConfigOptions } from './helpers';

interface HtmlTooltipProps extends TooltipProps {
  field?: boolean;
}

const HtmlTooltip = styled(({ className, ...props }: HtmlTooltipProps) => {
  const tooltipProps = omit(props, ['field']);
  return <Tooltip {...tooltipProps} classes={{ popper: className }} />;
})(({ theme, ...props }) => {
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      minWidth: props.field ? 100 : 220,
      maxWidth: 500,
      overflowWrap: 'break-word',
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      padding: '5 10',
      boxShadow: `1px 1px 5px ${theme.palette.neutrals.b2}`,
    },
  };
});

type BaseVFPInputFieldProps = {
  placeholder?: string;
  selectedFields?: string[];
  disabled?: boolean;
  placeholderAllowed?: boolean;
  options?: ConfigOptions;
  forceOpen?: boolean;
};

type DefaultVFPInputFieldProps =
  | {
      // input in schema viewer
      isSimpleVersion?: never;
      useInputInSchemaViewer: true;
      handleSelect: OnSelectHandler;
      extractId: string;
      workspaceId?: never;
      placeholderLocation?: PlaceholderLocation;
      fieldVariable?: never;
      setFieldVariable?: never;
      inputValue?: never;
      setInputValue?: never;
      hostEl:
        | HTMLInputElement
        | HTMLTextAreaElement
        | HTMLSpanElement
        | undefined;
      anchorEl: PopperProps['anchorEl'];
    }
  | {
      // separate input
      isSimpleVersion?: never;
      useInputInSchemaViewer?: never;
      handleSelect?: never;
      extractId?: never;
      workspaceId: string;
      placeholderLocation?: PlaceholderLocation;
      fieldVariable: TagMetaData | undefined;
      setFieldVariable: (value: TagMetaData | undefined) => void;
      inputValue?: never;
      setInputValue?: never;
      hostEl?: never;
      anchorEl?: never;
    };

type VFPInputFieldProps =
  | (DefaultVFPInputFieldProps & BaseVFPInputFieldProps)
  | ({
      // simple version
      isSimpleVersion: true;
      useInputInSchemaViewer?: never;
      handleSelect?: never; // only when useInputInSchemaViewer is true
      extractId: string; // simple version of VFPInputField
      workspaceId?: never;
      placeholderLocation?: never;
      fieldVariable?: never;
      setFieldVariable?: never;
      inputValue: string;
      setInputValue: (value: string, field?: DynamicFieldV1MetaData) => void;
      hostEl?: never;
      anchorEl?: never;
    } & BaseVFPInputFieldProps);

// Reasonining behind the temp fix:
//   Default MUI theme has crazy zIndex for modal set at 1300, and we have
//   use cases where picker is used with modal.
//
//   We want ours to be:
//    * higher than the modal (1300), but
//    * lower than SnackBar (1400) & Tooltip (1500)
//
// @TODO: calculate the zIndex to use based on theme.zIndex.modal instead
export const PICKER_ZINDEX = 1350;

const VFPInputField = ({
  // base
  placeholder,
  selectedFields,
  disabled,
  placeholderAllowed,
  options,
  forceOpen,
  // default
  isSimpleVersion,
  useInputInSchemaViewer,
  handleSelect,
  extractId,
  workspaceId,
  placeholderLocation,
  fieldVariable,
  setFieldVariable,
  inputValue,
  setInputValue,
  hostEl,
  anchorEl,
}: VFPInputFieldProps) => {
  const [openTooltip, setOpenTooltip] = useState(false);

  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement | undefined>(
    undefined,
  );
  const inputAnchorRef = useRef<HTMLDivElement | null>(null);

  const previewAnchorRef = useRef<HTMLDivElement | null>();
  const treeRef = useRef<HTMLDivElement | null>();

  const {
    schema,
    columnarSlices,
    isPickerOpen,
    openPicker,
    closePicker,
    clearInput,
    setSearchPhrase,
    updateSearchPhrase,
    activeNodes,
    preview,
    setPreview,
    expanded,
    setExpanded,
    selected,
    setSelected,
    searchPhrase,
  } = useVisualFieldPicker(
    isSimpleVersion || useInputInSchemaViewer ? { extractId } : { workspaceId },
  );

  useEffect(() => {
    if (forceOpen && !isPickerOpen) {
      openPicker();
    }
  }, [isPickerOpen, forceOpen, openPicker]);

  const [hoveredField, setHoveredField] = useState<string | undefined>(
    undefined,
  );

  const tooltipContent = useMemo(() => {
    if (
      !isSimpleVersion &&
      isValid<DynamicFieldV1MetaData>(
        validators.DynamicFieldV1MetaData,
        fieldVariable,
      )
    ) {
      const fieldPathParts = fieldVariable.field.split('.');
      return (
        <>
          {fieldPathParts.length > 1 ? (
            <Typography sx={{ display: 'inline-block' }}>
              {fieldPathParts[0]}.
            </Typography>
          ) : (
            ''
          )}
          <Typography sx={{ display: 'inline-block', fontWeight: 900 }}>
            {fieldPathParts[fieldPathParts.length - 1]}
          </Typography>{' '}
        </>
      );
    }
  }, [fieldVariable, isSimpleVersion]);

  const handleSchemaViewerSelect: OnSelectHandler = useCallback(
    (type, meta) => {
      if (isSimpleVersion) {
        if (type === FieldType.DYNAMIC_FIELD) {
          const fieldMetaData = ensureType<DynamicFieldV1MetaData>(
            validators.DynamicFieldV1MetaData,
            meta,
          );
          setInputValue(fieldMetaData.field, fieldMetaData);
        }
      } else {
        if (handleSelect) {
          handleSelect(type, meta);
        }
        if (setFieldVariable) {
          setFieldVariable(meta);
        }
      }
      setSearchPhrase('');
      closePicker();
    },
    [
      closePicker,
      handleSelect,
      isSimpleVersion,
      setFieldVariable,
      setInputValue,
      setSearchPhrase,
    ],
  );

  const handlePickerKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Escape') {
        closePicker();
        if (inputRef.current) inputRef.current.focus();
        if (hostEl) hostEl.focus();
        e.stopPropagation();
      }
    },
    [closePicker, hostEl],
  );

  // get disabled fields for schema viewer
  const loadedExplores = useAppSelector((state) => state.loadedExplores.value);
  const { featureEditData } = useFeature();
  const disabledFields = useMemo(() => {
    if (isSimpleVersion || useInputInSchemaViewer) return [];
    const exploreInfo = featureEditData.exploreId
      ? getExploreInfoByEnvExploreId(
          loadedExplores,
          workspaceId,
          featureEditData.exploreId,
        )
      : undefined;

    const { exploreConfig } = exploreInfo ?? {};
    const { customerFilterName } = exploreConfig ?? {};

    const disabledFields = [...(selectedFields ?? [])];
    if (customerFilterName) {
      disabledFields.push(customerFilterName);
    }
    return disabledFields;
  }, [
    isSimpleVersion,
    useInputInSchemaViewer,
    featureEditData.exploreId,
    loadedExplores,
    workspaceId,
    selectedFields,
  ]);

  // simple version doesn't use place holder, but hook cannot be called conditionally,
  // and the usePlaceholder requires a placeholderLocation
  // so we pass in FIELD_DATA as a placeholderLocation
  const { isPlaceholder, openPlaceholderComments, renderPlaceholderPopper } =
    usePlaceholder(placeholderLocation ?? PlaceholderLocation.FIELD_DATA);

  const placeholderMenuOptions = useMemo(() => {
    return isSimpleVersion || useInputInSchemaViewer
      ? []
      : [
          { label: 'Resolve', onClick: openPicker },
          {
            label: 'Clear',
            onClick: () => setFieldVariable(undefined),
          },
        ];
  }, [isSimpleVersion, openPicker, setFieldVariable, useInputInSchemaViewer]);

  const isValidField = isValid<DynamicFieldV1MetaData>(
    validators.DynamicFieldV1MetaData,
    fieldVariable,
  );

  const isValidPlaceholder = isPlaceholder(fieldVariable);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      updateSearchPhrase(e.target.value);
    },
    [updateSearchPhrase],
  );

  const handleKeyDownInSearch = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e.key === 'Backspace') {
        if (isSimpleVersion && inputValue !== '') {
          setInputValue('', undefined);
          clearInput();
        } else if (!isSimpleVersion && fieldVariable !== undefined) {
          if (setFieldVariable) {
            setFieldVariable(undefined);
          }
          clearInput();
        }
        e.stopPropagation();
      }

      if (e.key === 'ArrowDown') {
        treeRef.current?.focus();
        e.stopPropagation();
      }

      if (e.key === 'Escape') {
        closePicker();
        if (inputRef.current) inputRef.current.focus();
        e.stopPropagation();
      }
    },
    [
      clearInput,
      closePicker,
      fieldVariable,
      inputValue,
      isSimpleVersion,
      setFieldVariable,
      setInputValue,
    ],
  );

  const handleMouseUp = useCallback(() => {
    if (disabled) return;

    if (!isValidPlaceholder) {
      // open the picker if not placeholder, i.e. either empty or a dynamic field
      openPicker();
    }
  }, [disabled, openPicker, isValidPlaceholder]);

  const renderStartAdornment = () => {
    let icon: React.ReactNode | undefined;

    if (
      isValid<PlaceholderV1MetaData>(
        validators.PlaceholderV1MetaData,
        fieldVariable,
      )
    ) {
      icon = (
        <InfoIcon
          sx={{ color: (theme) => theme.palette.info.main }}
          fontSize='small'
        />
      );
    } else if (
      isValid<DynamicFieldV1MetaData>(
        validators.DynamicFieldV1MetaData,
        fieldVariable,
      ) &&
      schema
    ) {
      const node = schema?.lookup[fieldVariable.field];
      icon = renderNodeTypeIcon(schema.supportedTypes, node);
    }
    return icon ? (
      <InputAdornment position='start'>{icon}</InputAdornment>
    ) : (
      <InputAdornment position='start'>
        <SearchIcon fontSize='small' />
      </InputAdornment>
    );
  };

  const getFieldValue = useCallback(() => {
    // placeholder
    if (
      isValid<PlaceholderV1MetaData>(
        validators.PlaceholderV1MetaData,
        fieldVariable,
      )
    ) {
      return fieldVariable.name;
    }

    // dynamic field
    if (
      isValid<DynamicFieldV1MetaData>(
        validators.DynamicFieldV1MetaData,
        fieldVariable,
      ) &&
      fieldVariable.field.length > 0
    ) {
      return fieldVariable.field;
    }

    // simple input
    if (isSimpleVersion) {
      if (inputValue === '') {
        return searchPhrase;
      }

      const fieldPathParts = inputValue.split('.');
      return fieldPathParts[fieldPathParts.length - 1];
    }
    return searchPhrase;
  }, [fieldVariable, isSimpleVersion, searchPhrase, inputValue]);

  return (
    <FormControl fullWidth>
      {!useInputInSchemaViewer && (
        <HtmlTooltip
          title={tooltipContent}
          placement='bottom-start'
          open={openTooltip}
          onMouseOver={() =>
            setOpenTooltip(isValidField && !!fieldVariable.field)
          }
          onMouseOut={() => setOpenTooltip(false)}
          onClick={() => setOpenTooltip(false)}
          field={isValidField}
        >
          <OutlinedInput
            size='small'
            value={getFieldValue()}
            onClick={openPlaceholderComments}
            onKeyDown={handleKeyDownInSearch}
            onMouseUp={handleMouseUp}
            onChange={handleInputChange}
            disabled={disabled}
            placeholder={placeholder}
            startAdornment={renderStartAdornment()}
            inputRef={inputRef}
            ref={inputAnchorRef}
          />
        </HtmlTooltip>
      )}
      {!isSimpleVersion &&
        renderPlaceholderPopper(
          inputAnchorRef.current,
          fieldVariable,
          placeholderMenuOptions,
        )}
      {schema && (
        <Popper
          id='visual-field-picker-popper'
          open={isPickerOpen}
          anchorEl={anchorEl ?? inputAnchorRef.current}
          placement='bottom-start'
          onKeyDown={handlePickerKeyDown}
          sx={{ zIndex: PICKER_ZINDEX }}
        >
          <Paper elevation={4} sx={{ marginTop: 1 }}>
            <ClickAwayListener onClickAway={closePicker}>
              <Box>
                <SchemaViewer
                  // refs
                  ref={previewAnchorRef}
                  treeRef={treeRef}
                  inputRef={inputRef}
                  // data
                  schema={schema}
                  disabledFields={disabledFields}
                  onPreviewChange={setPreview}
                  activeNodes={activeNodes ?? []}
                  searchPhrase={searchPhrase}
                  handleInputChange={handleInputChange}
                  // options
                  placeholderAllowed={placeholderAllowed}
                  options={options}
                  showInput={!!useInputInSchemaViewer}
                  // hovered
                  hoveredField={hoveredField}
                  onFieldHovered={setHoveredField}
                  // expanded
                  expanded={expanded}
                  setExpanded={setExpanded}
                  // selected
                  selectedTreeItems={selected}
                  setSelectedTreeItems={setSelected}
                  selectedField={
                    isValidField ? fieldVariable?.field : undefined
                  }
                  onSelect={handleSchemaViewerSelect}
                />
                {previewAnchorRef.current && (
                  <Popper
                    id='visual-picker-preview'
                    open={preview.length > 0}
                    anchorEl={previewAnchorRef.current}
                    placement='right'
                    sx={{ zIndex: PICKER_ZINDEX }}
                  >
                    <Paper elevation={4} sx={{ ml: 0.1, mr: 0.1 }}>
                      <PreviewTable
                        preview={preview}
                        schema={schema}
                        disabledFields={disabledFields}
                        columnarSlices={columnarSlices}
                        height={
                          previewAnchorRef.current.getBoundingClientRect()
                            .height
                        }
                        hoveredField={hoveredField}
                        onColumnMouseOver={setHoveredField}
                        onColumnMouseLeave={() => setHoveredField(undefined)}
                        onSelect={handleSchemaViewerSelect}
                        options={options}
                      />
                    </Paper>
                  </Popper>
                )}
              </Box>
            </ClickAwayListener>
          </Paper>
        </Popper>
      )}
    </FormControl>
  );
};

export default VFPInputField;
