import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useState,
  MutableRefObject,
} from 'react';
import Image from 'next/legacy/image';
import clsx from 'clsx';

import TreeItem, {
  treeItemClasses,
  TreeItemContentProps,
  useTreeItem,
} from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import Tooltip from '@mui/material/Tooltip';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Search from '@mui/icons-material/Search';
import TableView from '@mui/icons-material/TableView';
import MuiIcon from '@mui/material/Icon';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import Typography from '@mui/material/Typography';

import Icon from '../../components/shared/Icon';
import { IconName } from '../../components/shared/Icon/types';

import {
  FieldType,
  TagMetaData,
  PlaceholderUtils,
} from '@madeinventive/core-types';

import {
  MaterialIcon,
  NormalizedType,
  TypeIcon,
  UrlIcon,
  InventiveIcon,
} from '../../generated/types';
import {
  createViewNodeId,
  getNormalizedTypeIcon,
  FieldInfo,
  isFieldInfo,
  Schema,
  SchemaNode,
  SchemaNodeKind,
} from '../../store/slices/exploreExtracts';
import useStyles from '../../styles/style';
import { ConfigOptions, isFieldDisabled } from './helpers';

const isMaterialIcon = (icon?: TypeIcon): icon is MaterialIcon => {
  if (icon && 'ligature' in icon) return true;
  return false;
};

const isUrlIcon = (icon?: TypeIcon): icon is UrlIcon => {
  if (icon && 'url' in icon) return true;
  return false;
};

const isInventiveIcon = (icon?: TypeIcon): icon is InventiveIcon => {
  if (icon && 'name' in icon) return true;
  return false;
};

export type OnSelectHandler = (type: FieldType, meta: TagMetaData) => void;

export const renderNodeTypeIcon = (
  supportedTypes: Record<string, NormalizedType>,
  node?: SchemaNode,
  className?: string,
): ReactNode | undefined => {
  if (!node || !isFieldInfo(node.metaData)) return;
  const icon = getNormalizedTypeIcon(
    supportedTypes,
    node.metaData.normalizedType,
  );
  if (isMaterialIcon(icon)) {
    return (
      <Tooltip title={node.metaData.normalizedType}>
        <MuiIcon className={className} sx={{ color: icon.color }}>
          {icon.ligature}
        </MuiIcon>
      </Tooltip>
    );
  } else if (isUrlIcon(icon)) {
    return (
      <Tooltip title={node.metaData.normalizedType}>
        <Image src={icon.url} alt='Icon' width={16} height={16} />
      </Tooltip>
    );
  } else if (isInventiveIcon(icon)) {
    return (
      <Tooltip title={node.metaData.normalizedType}>
        <Icon name={icon.name as IconName} size='small' />
      </Tooltip>
    );
  }
};

const renderNodeLabel = (node: SchemaNode): ReactNode => {
  const labelNodes: ReactNode[] =
    node.kind === SchemaNodeKind.VIEW
      ? [
          <Box
            key='view_icon'
            component={TableView}
            color='inherit'
            sx={{ mr: 1 }}
          />,
          <Typography
            key='view_label'
            variant='body1'
            sx={{ fontWeight: 'inherit', flexGrow: 1 }}
          >
            {node.name}
          </Typography>,
        ]
      : [
          <Typography
            key='field_label'
            variant='body1'
            sx={{ fontWeight: 'inherit', flexGrow: 1 }}
          >
            {node.name}
          </Typography>,
        ];
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0, mr: 1 }}>
      {labelNodes}
    </Box>
  );
};

// Custom component for the tree item content
// This takes hoveredField as a prop to highlight the hovered field
const CustomContent = forwardRef(function CustomContent(
  props: TreeItemContentProps,
  ref,
) {
  const { classes, className, label, nodeId, expansionIcon, displayIcon } =
    props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
  } = useTreeItem(nodeId);

  const handleMouseDown = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    handleSelection(event);
    handleExpansion(event);
  };

  return (
    <div
      role='button'
      tabIndex={0}
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={handleMouseDown}
      ref={ref as React.Ref<HTMLDivElement>}
      id={nodeId} // required to enable scrollIntoView
    >
      <Typography component='div' className={classes.label}>
        {label}
      </Typography>
      <div className={classes.iconContainer}>
        {expansionIcon ?? displayIcon}
      </div>
    </div>
  );
});

const StyledNoMatchItem = styled('div')(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: 12,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));

const StyledNoMatchItemButton = styled('div')(({ theme }) => ({
  color: '#4487F3',
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: 12,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  cursor: 'pointer',
}));

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderRadius: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
    '&.Mui-expanded  + ul': {
      '.MuiTreeItem-content ': {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
      color: 'inherit',
      textOverflow: 'ellipsis',
      overflow: 'clip',
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

export interface SchemaViewerProps {
  // refs
  treeRef: MutableRefObject<HTMLDivElement | null | undefined>;
  inputRef: MutableRefObject<
    HTMLInputElement | HTMLTextAreaElement | null | undefined
  >;
  // data
  schema: Schema;
  disabledFields?: Array<string>;
  onPreviewChange?: (previewNodes: SchemaNode[]) => void;
  activeNodes: SchemaNode[];
  searchPhrase: string;
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  // options
  placeholderAllowed?: boolean;
  options?: ConfigOptions;
  showInput: boolean;
  // hovered
  hoveredField?: string;
  onFieldHovered: (field: string | undefined) => void;
  // expanded
  expanded: string[];
  setExpanded: (expanded: string[]) => void;
  // selected
  selectedTreeItems: string[];
  setSelectedTreeItems: (selected: string[]) => void;
  selectedField?: string;
  onSelect: OnSelectHandler;
}

const SchemaViewer = forwardRef(function SchemaViewer(
  props: SchemaViewerProps,
  ref,
) {
  const { typeIcon } = useStyles();

  const {
    // refs
    treeRef,
    inputRef,
    // data
    schema,
    disabledFields,
    onPreviewChange,
    activeNodes,
    searchPhrase,
    handleInputChange,
    // options
    placeholderAllowed,
    options,
    showInput,
    // hovered
    hoveredField, // hovered from from the preview table
    onFieldHovered,
    // expanded
    expanded,
    setExpanded,
    // selected
    selectedTreeItems,
    setSelectedTreeItems,
    selectedField,
    onSelect,
  } = props;

  // track focused node id to handle keyboard navigation
  const [focusedNodeId, setFocusedNodeId] = useState<string | undefined>(
    hoveredField ?? selectedField,
  );

  const setInitialInteractiveSettings = useCallback(
    (selectedField: string | undefined) => {
      if (selectedField) {
        setFocusedNodeId(selectedField);
      }
      const isSelectedValidField = selectedField && selectedField.includes('.');
      if (isSelectedValidField) {
        const tableName = selectedField.split('.')[0];
        const nodeId = createViewNodeId(tableName);
        setExpanded([nodeId]);
        setSelectedTreeItems([selectedField, nodeId]);
        const selectedNode = schema.lookup[nodeId];
        if (onPreviewChange) {
          onPreviewChange([selectedNode]);
        }
      } else {
        // if no field is selected, reset the schema viewer selection and preview
        setExpanded([]);
        setSelectedTreeItems([]);
        if (onPreviewChange) {
          onPreviewChange([]);
        }
      }
    },
    [onPreviewChange, schema.lookup, setExpanded, setSelectedTreeItems],
  );

  // handles initial schema viewer settings
  useEffect(() => {
    setInitialInteractiveSettings(selectedField);
  }, [selectedField, setInitialInteractiveSettings]);

  const handleItemMouseOver = useCallback(
    (node: SchemaNode) => {
      if (node.kind === SchemaNodeKind.FIELD) {
        onFieldHovered(node.id);
      }
    },
    [onFieldHovered],
  );

  const handleItemMouseOut = useCallback(
    (_node: SchemaNode) => {
      onFieldHovered(undefined);
    },
    [onFieldHovered],
  );

  // handle hovered field change from PreviewTable
  useEffect(() => {
    const isHoveredValidField = hoveredField && hoveredField.includes('.');
    if (isHoveredValidField) {
      // scroll the item into view
      const hoveredNode = treeRef.current?.querySelector(
        `[id="${hoveredField}"]`,
      );
      if (hoveredNode) {
        hoveredNode.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'end',
        });
      }
    }
  }, [
    handleItemMouseOver,
    hoveredField,
    schema.lookup,
    setExpanded,
    setSelectedTreeItems,
    treeRef,
  ]);

  const renderNodeRecursive = useCallback(
    (node: SchemaNode) => (
      <StyledTreeItem
        key={node.id}
        nodeId={node.id}
        disabled={isFieldDisabled(
          node.metaData,
          schema.supportedTypes,
          options?.idFieldsOnly,
          disabledFields,
        )}
        label={renderNodeLabel(node)}
        ContentComponent={CustomContent}
        endIcon={renderNodeTypeIcon(schema.supportedTypes, node, typeIcon)}
        onMouseOver={() => handleItemMouseOver(node)}
        onMouseOut={() => handleItemMouseOut(node)}
        sx={(theme) => {
          const isHovered = hoveredField === node.id;
          return {
            ...(isHovered && {
              '& .MuiTreeItem-content': {
                backgroundColor: theme.palette.action.level1,
              },
            }),
            '& .MuiTreeItem-content.Mui-expanded': {
              backgroundColor: theme.palette.action.level2,
            },
            '& .MuiTreeItem-content.Mui-selected': {
              backgroundColor: theme.palette.primary.light,
            },
            '& .MuiTreeItem-content.Mui-selected.Mui-disabled': {
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.text.primary,
            },
            '& .MuiTreeItem-content.Mui-focused': {
              backgroundColor: theme.palette.action.level3,
            },
            '& .MuiTreeItem-label': {
              marginRight: theme.spacing(1),
            },
          };
        }}
      >
        {Array.isArray(node.children)
          ? node.children.map((child) => renderNodeRecursive(child))
          : null}
      </StyledTreeItem>
    ),
    [
      schema.supportedTypes,
      options?.idFieldsOnly,
      disabledFields,
      typeIcon,
      handleItemMouseOver,
      handleItemMouseOut,
      hoveredField,
    ],
  );

  const handleNodeSelect = useCallback(
    (event: React.SyntheticEvent<Element, Event>, nodeIds: string[]) => {
      // 2nd argument is an array of strings in multiselect mode. We need to use the first selected element (our case)
      const nodeId = nodeIds[0];
      setFocusedNodeId(nodeId);
      const selectedNode = schema.lookup[nodeId];
      switch (selectedNode.kind) {
        case SchemaNodeKind.VIEW:
          // selected is a view, preview all its data in preview table
          if (onPreviewChange) {
            onPreviewChange([selectedNode]);
          }
          break;
        case SchemaNodeKind.FIELD:
          // selected is a field, i.s user has spoken on the field of choice,
          // call onFieldSect prop to pass the decision the outside world
          // for whatever needs to be done
          onSelect(FieldType.DYNAMIC_FIELD, {
            field: selectedNode.id,
            normalizedType: (selectedNode.metaData as FieldInfo).normalizedType,
          });
          break;
      }
    },
    [schema, onPreviewChange, onSelect],
  );

  const handleNodeToggle = useCallback(
    (event: React.SyntheticEvent, nodeIds: string[]) => {
      if (onPreviewChange) {
        // handle preview if handler is provided
        const delta = nodeIds.length - expanded.length;
        if (delta === 1) {
          // single expansion
          const targetId = nodeIds.filter((n) => !expanded.includes(n))[0];
          const targetNode = schema.lookup[targetId];
          onPreviewChange([targetNode]);
        } else {
          // single collapse
          onPreviewChange([]);
        }
      }

      setExpanded(nodeIds);
    },
    [onPreviewChange, setExpanded, expanded, schema.lookup],
  );

  const handleNodeFocus = useCallback(
    (_event: React.SyntheticEvent, nodeId: string) => {
      setFocusedNodeId(nodeId);
    },
    [setFocusedNodeId],
  );

  const handleKeyDownInSearch = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'ArrowDown') {
        treeRef.current?.focus();
        event.stopPropagation();
      }
    },
    [treeRef],
  );

  const handleKeyUpInTree = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'ArrowUp') {
        // if the first node is in focus, focus the search input on up arrow
        if (activeNodes.length === 0 || activeNodes[0].id === focusedNodeId) {
          inputRef.current?.focus();
        }
      }
    },
    [activeNodes, focusedNodeId, inputRef],
  );

  const addPlaceholder = useCallback(() => {
    onSelect(FieldType.PLACEHOLDER, {
      name: searchPhrase,
      placeholderId: PlaceholderUtils.generateTempPlaceholderToken(), //this token will be replaced by core-api
    });
  }, [searchPhrase, onSelect]);

  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        // use fixed box height and width to prevent resizing and repositioning the box
        // as user types and the node tree changes
        height: 340, // same height as the preview table
        width: 240,
        padding: 1,
      }}
    >
      {showInput && (
        <>
          <Box py={1} mb={1} onKeyDown={handleKeyDownInSearch}>
            <TextField
              fullWidth
              size='small'
              variant='outlined'
              placeholder='Search'
              autoFocus
              onChange={handleInputChange}
              value={searchPhrase}
              inputRef={inputRef}
              InputProps={{
                type: 'search',
                startAdornment: (
                  <InputAdornment position='start'>
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </>
      )}
      {!searchPhrase && placeholderAllowed && (
        <>
          <Box
            py={1}
            mb={1}
            sx={{ display: 'flex' }}
            justifyContent='space-around'
            alignItems='center'
          >
            <StyledNoMatchItem>
              Can&apos;t find it? Type to add a placeholder
            </StyledNoMatchItem>
          </Box>
          <Divider variant='fullWidth' />
        </>
      )}

      {searchPhrase && placeholderAllowed && (
        <>
          <Box
            py={1}
            mb={1}
            sx={{ display: 'flex' }}
            justifyContent='space-between'
            alignItems='center'
          >
            <StyledNoMatchItem>Can&apos;t find it? </StyledNoMatchItem>
            <StyledNoMatchItemButton onClick={addPlaceholder}>
              + Add a placeholder
            </StyledNoMatchItemButton>
          </Box>
          <Divider variant='fullWidth' />
        </>
      )}
      <Box
        sx={{ py: 1, overflowY: 'auto' }}
        onKeyUp={handleKeyUpInTree}
        flex={1}
      >
        {!activeNodes.length ? (
          <Box
            my={1}
            sx={{ display: 'flex' }}
            justifyContent='flex-start'
            alignItems='center'
          >
            <StyledNoMatchItem>No matching field found</StyledNoMatchItem>
          </Box>
        ) : (
          <TreeView
            aria-label='schema viewer'
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            multiSelect
            expanded={expanded}
            selected={selectedTreeItems}
            onNodeSelect={handleNodeSelect}
            onNodeToggle={handleNodeToggle}
            onNodeFocus={handleNodeFocus}
            ref={treeRef}
            sx={{
              height: '100%',
              width: '100%',
              overflowY: 'auto',
            }}
          >
            {activeNodes.map((node) => renderNodeRecursive(node))}
          </TreeView>
        )}
      </Box>
    </Box>
  );
});

export default SchemaViewer;
