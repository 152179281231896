import {
  ChatResponseFieldsFragment,
  ChatRequestMessage,
  ChatRole,
} from '../../../generated/types';
import { MessageData, PickedChatResponse } from '../types';

import { USER_MESSAGE_ID, AI_MESSAGE_ID } from '../constants';
import {
  DataScientistThought,
  ThoughtClassification,
} from '@madeinventive/core-types';

export const convertChatResponsesToChatFeeds = (
  responses: ChatResponseFieldsFragment[],
): MessageData[] => {
  const chatFeedMessages: MessageData[] = [];
  // for each chat response, we need to create two messages
  // one for the user prompt and one for the AI response
  for (const response of responses) {
    // if response is fake, don't show it in the chat feed
    if (response.fake) continue;
    chatFeedMessages.push({
      id: USER_MESSAGE_ID,
      message: response.userPrompt,
      timestamp: response.createdAt,
      senderName: response.chatThread.user.firstname,
    });
    chatFeedMessages.push({
      id: AI_MESSAGE_ID,
      message: {
        id: response.id,
        message: response.message,
        classifiedThoughts: response.classifiedThoughts,
        warnings: response.warnings,
        quickFollow: response.quickFollow,
        hydratedViz: response.hydratedViz,
        completed: response.completed,
        canceled: response.canceled,
        rating: response.rating,
        dataSourceLabel: response.dataSourceLabel,
        dataSourceId: response.dataSourceId,
        createdAt: response.createdAt,
      },
      timestamp: response.updatedAt,
    });
  }

  return chatFeedMessages;
};

export const convertChatResponsesToOpenAiMessages = (
  responses: ChatResponseFieldsFragment[],
): ChatRequestMessage[] => {
  const openAiMessages: ChatRequestMessage[] = [];
  for (const response of responses) {
    openAiMessages.push({
      role: ChatRole.USER,
      content: response.userPrompt,
    });
  }

  return openAiMessages;
};

export enum ChatFeedMessageType {
  ERROR = 'error',
  CANCEL = 'cancel',
  RESPONSE = 'response',
  USER_MESSAGE = 'userMessage',
}

type getUpdateChatFeedMessagesForResponse = {
  type: ChatFeedMessageType.RESPONSE;
  chatFeedMessages: MessageData[];
  newMessage: PickedChatResponse;
  senderName?: string;
};

type getUpdateChatFeedMessagesForOtherType = {
  type: Exclude<ChatFeedMessageType, ChatFeedMessageType.RESPONSE>;
  chatFeedMessages: MessageData[];
  newMessage: string;
  senderName?: string;
};

type getUpdatedChatFeedMessagesByTypeProps =
  | getUpdateChatFeedMessagesForResponse
  | getUpdateChatFeedMessagesForOtherType;

export const getUpdatedChatFeedMessagesByType = ({
  type,
  chatFeedMessages,
  newMessage,
  senderName,
}: getUpdatedChatFeedMessagesByTypeProps): MessageData[] => {
  const newChatFeedMessages = [...chatFeedMessages];
  const lastMessageData = chatFeedMessages[chatFeedMessages.length - 1];

  switch (type) {
    case ChatFeedMessageType.ERROR: {
      const newClassifiedThought: DataScientistThought = {
        classification: ThoughtClassification.DataScientist,
        updatedAt: new Date(),
        message: newMessage,
      };

      const baseErrorMessage: MessageData = {
        id: AI_MESSAGE_ID,
        timestamp: new Date().toISOString(),
        message: {
          id: '',
          completed: true,
          canceled: false,
          classifiedThoughts: [newClassifiedThought],
          createdAt: new Date().toISOString(),
        },
      };

      if (!lastMessageData) {
        // if there is no last message, that means the chat hasn't started yet.
        // Create a new ai message with the error text.
        // and add to the chatFeed.
        newChatFeedMessages.push(baseErrorMessage);
      } else if (typeof lastMessageData.message === 'string') {
        // when the last message is a string, that means the chat hasn't started yet.
        // nothing to replace, but just add the new ai message to the chatFeed.
        newChatFeedMessages.push(baseErrorMessage);
      } else {
        // when the last message's message is ChatResponse, that includes the thoughts
        // keep the existing data and add the error message to thoughts
        baseErrorMessage.message = {
          ...lastMessageData.message,
          completed: true,
          classifiedThoughts: [
            ...(lastMessageData.message.classifiedThoughts ?? []),
            newClassifiedThought,
          ],
        };
        // replace the last ai message with the new one.
        newChatFeedMessages.pop();
        newChatFeedMessages.push(baseErrorMessage);
      }

      return newChatFeedMessages;
    }
    case ChatFeedMessageType.CANCEL: {
      const cancelMessageWithoutLast: MessageData = {
        id: AI_MESSAGE_ID,
        timestamp: new Date().toISOString(),
        message: {
          id: '',
          completed: true,
          canceled: true,
          message: {
            content: newMessage,
            role: ChatRole.SYSTEM,
          },
          createdAt: new Date().toISOString(),
        },
      };

      if (!lastMessageData) {
        // if there is no last message, that means the chat hasn't started yet.
        // Create a new ai message with the cancel text.
        // and add to the chatFeed.
        newChatFeedMessages.push(cancelMessageWithoutLast);
        return newChatFeedMessages;
      }

      const cancelMessageData: MessageData = {
        ...lastMessageData,
        message:
          typeof lastMessageData.message === 'string'
            ? newMessage
            : {
                ...lastMessageData.message,
                message: {
                  content: newMessage,
                  role: ChatRole.SYSTEM,
                },
              },
      };

      if (lastMessageData.id === AI_MESSAGE_ID) {
        newChatFeedMessages.pop();
      }
      newChatFeedMessages.push(cancelMessageData);
      return newChatFeedMessages;
    }
    case ChatFeedMessageType.RESPONSE: {
      const newResponseMessage: MessageData = {
        id: AI_MESSAGE_ID,
        timestamp: new Date().toISOString(),
        message: newMessage,
      };

      // when getting a response, at least a message should be there.
      if (!lastMessageData) {
        return newChatFeedMessages;
      }

      // if the last message is user message, add the new response to the end of the list.
      // if the last message is an AI message, replace it with the new response(polling updates).
      if (lastMessageData.id === AI_MESSAGE_ID) {
        newChatFeedMessages.pop();
      }
      newChatFeedMessages.push(newResponseMessage);
      return newChatFeedMessages;
    }
    case ChatFeedMessageType.USER_MESSAGE: {
      const newUserMessage: MessageData = {
        id: USER_MESSAGE_ID,
        senderName,
        timestamp: new Date().toISOString(),
        message: newMessage,
      };
      newChatFeedMessages.push(newUserMessage);
      return newChatFeedMessages;
    }
  }
};
