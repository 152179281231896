import { createSlice } from '@reduxjs/toolkit';

const initialStateValue = '';

export const ErrorSlice = createSlice({
  name: 'error',
  initialState: { value: initialStateValue },
  reducers: {
    setError: (state, action) => {
      state.value = action.payload;
    },

    dismissError: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setError, dismissError } = ErrorSlice.actions;

export default ErrorSlice.reducer;
