import { ReactNode, useCallback } from 'react';

// material UI components
import {
  MenuItem,
  FormControl,
  Box,
  InputLabel,
  Typography,
} from '@mui/material';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import Icon from '../Icon';

export interface OptionItem {
  value: string;
  label: string;
  icon?: ReactNode;
  disabled?: boolean;
}

type SimpleDropdownProps = SelectProps & {
  value?: string;
  setValue?: (value: string) => void;
  menuOptions?: OptionItem[];
  includeBlank?: boolean;
  blankLabel?: string;
  'data-testid'?: string;
  hasError?: boolean;
};

const SimpleDropdown = ({
  value,
  renderValue,
  setValue,
  label,
  placeholder,
  menuOptions = [],
  sx,
  disabled,
  includeBlank = false,
  blankLabel = '',
  variant,
  startAdornment,
  'data-testid': dataTestid,
  hasError,
}: SimpleDropdownProps) => {
  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      setValue && setValue(event.target.value);
    },
    [setValue],
  );

  if (includeBlank) {
    const blankOption: OptionItem = {
      value: '',
      label: blankLabel,
    };

    menuOptions = [blankOption, ...menuOptions];
  }

  const ArrowIcon = Icon;
  ArrowIcon.defaultProps = {
    name: 'carat-down',
    size: 'small',
  };

  return (
    <Box>
      <FormControl
        fullWidth
        disabled={disabled}
        size='small'
        variant={variant}
        error={hasError}
      >
        <InputLabel id='select-label'>{label}</InputLabel>
        <Select
          {...(startAdornment ? { startAdornment: startAdornment } : {})}
          labelId='select-label'
          label={label}
          id='simple-select'
          sx={{ ...sx, color: 'text.secondary' }}
          value={value}
          renderValue={renderValue}
          displayEmpty
          onChange={handleChange}
          disabled={disabled}
          placeholder={placeholder}
          inputProps={{ 'data-testid': dataTestid }} // This sets the testid on the <select> element itself instead of the wrapper <div> element.
          IconComponent={ArrowIcon}
        >
          {menuOptions.map((opt) => (
            <MenuItem
              color='neutral'
              key={opt.value}
              value={opt.value}
              disabled={opt.disabled}
            >
              <Box
                display='flex'
                gap='.5rem'
                alignItems='center'
                minHeight='1.25rem'
              >
                {opt.icon}
                {/* Flexbox bug workaround. See https://github.com/mui/material-ui/pull/13500/files */}
                <Typography variant='inherit' noWrap>
                  {opt.label}
                </Typography>
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SimpleDropdown;
