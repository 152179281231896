export type IconSize = 'large' | 'medium' | 'small';

export const ICON_DIMENSIONS = {
  large: {
    width: 36,
    height: 36,
  },
  medium: {
    width: 24,
    height: 24,
  },
  small: {
    width: 20,
    height: 20,
  },
};
