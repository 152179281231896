export enum DisplayTypes {
  HOME = 'home',
  CHAT = 'chat',
  DETAILS = 'details',
  CHAT_INTERMEDIATE = 'chat_intermediate',
}

export enum VizViewModes {
  RAW_DATA = 'raw_data',
  TRANSFORMED_DATA = 'transformed_data',
  VIZ_SPEC_DATA = 'viz_spec_data',
  VIZ = 'viz',
}

export interface VizViewModeIdentifier {
  mode: VizViewModes;
  id?: string;
  usedByViz?: boolean;
}

export const CARD_BORDER_RADIUS = 8; //24px

// viz heights
export const DEFAULT_HOME_VIZ_HEIGHT = 180;
export const MAX_VIZ_HEIGHT = 300;
export const MAX_VEGA_HEIGHT = 800;
