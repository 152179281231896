import { createSlice } from '@reduxjs/toolkit';
import { ChatState, initialChatStateValue } from './currentChat';
import { getUpdatedChatFeedMessagesByType } from '../../components/WorkspaceChat/utils/chatUtils';

interface LoadedChatsState {
  loadedChats: Record<string, ChatState>;
}

const ensureChatState = (value: LoadedChatsState, chatThreadId: string) => {
  if (!value.loadedChats[chatThreadId]) {
    if (value.loadedChats['new']) {
      const existingChatState = value.loadedChats['new'];
      delete value.loadedChats['new'];
      value.loadedChats[chatThreadId] = existingChatState;
    } else {
      value.loadedChats[chatThreadId] = initialChatStateValue;
    }
  }
};

const initialStateValue: LoadedChatsState = {
  loadedChats: {},
};

export const LoadedChatSlice = createSlice({
  name: 'loadedChats',
  initialState: { value: initialStateValue },
  reducers: {
    updateLoadedChatByThreadId: (state, action) => {
      const { chatThreadId, chatState } = action.payload;
      ensureChatState(state.value, chatThreadId);
      const states = {
        ...state.value.loadedChats[chatThreadId],
        ...chatState,
      };

      state.value.loadedChats[chatThreadId] = states;
    },
    handleChatMessageDataByThreadId: (state, action) => {
      const { type, chatThreadId, message, senderName } = action.payload;
      ensureChatState(state.value, chatThreadId);
      const matchingChatState = state.value.loadedChats[chatThreadId];
      const newChatFeedMessages = getUpdatedChatFeedMessagesByType({
        type,
        chatFeedMessages: matchingChatState.chatFeedMessages,
        newMessage: message,
        senderName,
      });
      state.value.loadedChats[chatThreadId] = {
        ...state.value.loadedChats[chatThreadId],
        chatFeedMessages: newChatFeedMessages,
      };
    },
  },
});

export const { updateLoadedChatByThreadId, handleChatMessageDataByThreadId } =
  LoadedChatSlice.actions;

export default LoadedChatSlice.reducer;
