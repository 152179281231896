import { useState } from 'react';
import { HuePicker } from 'react-color';
import { Stack, Box } from '@mui/material';
import TextField from '../Inputs/TextField';
import FormActionFooter from '../FormActionFooter';
import { BaseRegisteredDialogComponentProps } from '../../registeredDialogs/types';

const hexValidator = (value: string): boolean => {
  const hexRegex = /^#([A-Fa-f0-9]{3}){1,2}$/;
  return hexRegex.test(value);
};

export interface ColorPickerModalContentProps
  extends BaseRegisteredDialogComponentProps {
  initialColor: string; // hex
  onValidColorChange: (color: string) => void;
}

const ColorPickerModal = ({
  initialColor,
  onValidColorChange,
  hideDialog,
}: ColorPickerModalContentProps) => {
  const [color, setColor] = useState<string>(initialColor);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  const handleChange = (value: string) => {
    let newColor = value;
    if (value === '') {
      newColor = '#';
      setErrorMessage(undefined);
    } else if (!value.startsWith('#')) {
      newColor = '#' + value;
    } else {
      setColor(value);
    }

    setColor(newColor);
    const isValid = hexValidator(newColor);
    if (isValid) {
      setErrorMessage(undefined);
    } else {
      setErrorMessage('Invalid hex color');
    }
  };

  const handleSaveClick = () => {
    if (!errorMessage) {
      onValidColorChange(color);
      hideDialog();
    }
  };

  return (
    <Stack width='100%' spacing={2}>
      <Stack width='100%' direction='row' p={2} spacing={3} alignItems='center'>
        <Box
          width={36}
          height={36}
          bgcolor={errorMessage ? 'transparent' : color}
          borderRadius='50%'
          sx={{
            border:
              errorMessage || !color
                ? (theme) => `1px dashed ${theme.palette.neutrals.b2}`
                : 'none',
          }}
        />
        <Stack direction='column' spacing={2} flex={1}>
          <HuePicker
            width='100%'
            color={color}
            onChange={(colorResult) => {
              setColor(colorResult.hex);
              setErrorMessage(undefined);
            }}
          />
        </Stack>
      </Stack>
      <Stack spacing={0.5} minHeight={86}>
        <TextField
          label='Hex'
          value={color}
          errorMessage={errorMessage}
          onChange={(e) => handleChange(e.target.value)}
        />
      </Stack>
      <FormActionFooter
        submitAction={{
          label: 'Save changes',
          action: handleSaveClick,
          disabled: !!errorMessage,
        }}
        cancelAction={{
          label: 'Cancel',
          action: hideDialog,
        }}
      />
    </Stack>
  );
};

export default ColorPickerModal;
