import { useState, useEffect, useCallback, useMemo } from 'react';

function useElementWidth<T extends HTMLElement = HTMLDivElement>(): [
  (node: T | null) => void,
  number,
] {
  const [ref, setRef] = useState<T | null>(null);

  const [width, setWidth] = useState<number>(0);

  const getWidth = useCallback(() => {
    return ref?.getBoundingClientRect().width || 0;
  }, [ref]);

  const observer = useMemo(
    () =>
      new ResizeObserver((entries) => {
        if (ref) {
          if (entries?.[0]?.contentBoxSize?.[0]) {
            setWidth(entries[0].contentBoxSize[0].inlineSize);
          }
        }
      }),
    [ref],
  );

  // get initial width
  useEffect(() => {
    setWidth(getWidth());
  }, [getWidth, ref]);

  // add observer to the ref element.
  useEffect(() => {
    if (!ref) return;
    observer.observe(ref);
    return () => {
      if (!ref) return;
      observer.unobserve(ref);
    };
  }, [observer, ref]);

  return [setRef, width];
}

export { useElementWidth };
