import { useEffect, useState } from 'react';

import {
  default as EmojiPickerLib,
  EmojiStyle,
  EmojiClickData,
  Emoji,
} from 'emoji-picker-react';

import Button from '../Button';
import Menu from '@mui/material/Menu';
import BlockIcon from '@mui/icons-material/Block';
import { styled } from '@mui/system';
import { Box, Stack, Tab, Tabs } from '@mui/material';

const StyledButton = styled(Button)({
  minWidth: 'unset',
});

const StyledMenu = styled(Menu)({
  '& .MuiMenu-list': {
    padding: 0,
  },
});

interface EmojiPickerProps {
  selectedEmoji?: string; // unified string of EmojiClickData
  setSelectedEmoji: (emoji?: string) => void;
}

const EMOJI_STYLE = EmojiStyle.APPLE;

const EmojiPicker = ({
  selectedEmoji,
  setSelectedEmoji = () => {
    /* intentionally empty */
  },
}: EmojiPickerProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetEmoji = () => {
    setSelectedEmoji(undefined);
    handleClose();
  };

  const handleEmojiSelect = (emojiData: EmojiClickData) => {
    setSelectedEmoji(emojiData.unified);
    handleClose();
  };

  // Mui Menu listens to the keydown event and high-lights the menu that has the matching label.
  // This code below is required to prevent the keydown event of emoji-picker being bubbling up to the Menu.
  useEffect(() => {
    const emojiPickerWrapper =
      document.getElementsByClassName('customEmojiPicker')[0];
    if (emojiPickerWrapper) {
      emojiPickerWrapper.addEventListener('keydown', (e) => {
        e.stopPropagation();
      });
    }
  }, []);

  return (
    <>
      <StyledButton size='small' onClick={handleButtonClick}>
        {selectedEmoji ? (
          <Emoji size={20} unified={selectedEmoji} emojiStyle={EMOJI_STYLE} />
        ) : (
          <BlockIcon fontSize='small' color='neutral' />
        )}
      </StyledButton>
      <StyledMenu open={open} onClose={handleClose} anchorEl={anchorEl}>
        <Stack
          borderBottom={1}
          borderColor={(theme) => theme.palette.divider}
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Tabs value={0}>
            <Tab label='Emojis' />
          </Tabs>
          <Button size='small' sx={{ mr: 1 }} onClick={resetEmoji}>
            Remove
          </Button>
        </Stack>
        <Box className='customEmojiPicker' sx={{ overflowX: 'auto' }}>
          <EmojiPickerLib
            onEmojiClick={handleEmojiSelect}
            autoFocusSearch={false}
            emojiStyle={EMOJI_STYLE}
            previewConfig={{
              showPreview: false,
            }}
          />
        </Box>
      </StyledMenu>
    </>
  );
};

export default EmojiPicker;
