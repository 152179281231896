import { useState, useCallback } from 'react';
import { useRouter } from 'next/router';

// mui components
import { SxProps } from '@mui/material';

// components
import DialogHeader from '../Dialogs/DialogHeader';
import TemplateGalleryModal from './TemplateGalleryModal';
import SettingsModal from './SettingsModal';
import CustomDialog from '../Dialogs';
import AddTemplateCategory from './AddTemplateCategory';

// redux slice
import { useAppSelector, useAppDispatch } from '../../hooks/store';
import { clearActiveModalSlice } from '../../store/slices/modal';

// utils
import { removeSettingsUrlQuery } from '../../utils/removeSettingsUrlQuery';

interface ModalsGroup {
  [key: string]: ModalContent;
}

interface ModalContent {
  sx: SxProps;
  open: boolean;
  handleClose: () => void;
  header: React.ReactNode;
  content: React.ReactNode;
  sxContent: SxProps;
}

const Modals = () => {
  const router = useRouter();
  const [galleryPage, setGalleryPage] = useState<string>('firstPage');
  const [open, setOpen] = useState(false);
  const storeDispatch = useAppDispatch();
  const activeModalName = useAppSelector(
    (store) => store.modal.value.activeModal,
  );

  const closeModal = useCallback(() => {
    // remove url query params
    removeSettingsUrlQuery(router);
    storeDispatch(clearActiveModalSlice());
  }, [router, storeDispatch]);

  const closeTemplateGalleryModal = () => {
    closeModal();
    setTimeout(() => {
      setGalleryPage('firstPage');
    }, 500);
  };

  const dialogHeader = (title: string, itemTitle?: string) => {
    return (
      <DialogHeader title={title} itemTitle={itemTitle} onClose={closeModal} />
    );
  };
  const dialogHeaderGoBack = (title: string, closeFn: () => void) => {
    return (
      <DialogHeader
        title={title}
        onClose={closeFn}
        goBack={() => setGalleryPage('firstPage')}
      />
    );
  };

  const modalContent: ModalsGroup = {
    template_gallery: {
      sx: {
        '& .MuiDialog-paper': {
          width: '1060px',
          maxWidth: 'unset',
          maxHeight: '88%',
        },
      },
      open: !!activeModalName,
      handleClose: closeTemplateGalleryModal,
      header:
        galleryPage === 'firstPage'
          ? dialogHeader('Template gallery')
          : dialogHeaderGoBack('Template gallery', closeTemplateGalleryModal),
      content: (
        <TemplateGalleryModal
          closeModal={closeModal}
          setOpen={setOpen}
          galleryPage={galleryPage}
          setGalleryPage={setGalleryPage}
          closeTemplateGalleryModal={closeTemplateGalleryModal}
        />
      ),
      sxContent: { p: 0 },
    },
    settings_gallery: {
      sx: {
        '& .MuiDialog-paper': {
          width: '90%',
          maxWidth: 'unset',
          height: '90%',
          maxHeight: 'unset',
        },
      },
      open: !!activeModalName,
      handleClose: closeModal,
      header: dialogHeader('Settings'),
      content: <SettingsModal />,
      sxContent: { p: 0 },
    },
  };

  return (
    <>
      {activeModalName && (
        <>
          <CustomDialog {...modalContent[activeModalName]} />
          <AddTemplateCategory open={open} setOpen={setOpen} />
        </>
      )}
    </>
  );
};

export default Modals;
