import { Stack } from '@mui/material';
import Button from '../Button';
import Icon from '../Icon';
import { IconName } from '../Icon/types';

type ActionConfig = {
  label: string;
  action: () => void | Promise<void | boolean>;
  iconName?: IconName;
  isLoading?: boolean;
  disabled?: boolean;
  hidden?: boolean;
};

interface FormActionFooterProps {
  submitAction: ActionConfig;
  cancelAction: ActionConfig;
  tertiaryAction?: ActionConfig;
  noPadding?: boolean;
}

// styled action footer that contains a submit button and a cancel button, and occasionally a reset button
const FormActionFooter = ({
  submitAction,
  cancelAction,
  tertiaryAction,
  noPadding,
}: FormActionFooterProps) => {
  return (
    <Stack
      id='action-footer'
      direction='row'
      justifyContent={
        tertiaryAction && !tertiaryAction.hidden ? 'space-between' : 'flex-end'
      }
      spacing={1.5}
      p={noPadding ? 0 : 2}
    >
      {tertiaryAction && !tertiaryAction.hidden && (
        <Button
          variant='text'
          onClick={tertiaryAction.action}
          title={tertiaryAction.label}
          disabled={tertiaryAction.disabled}
          startIcon={
            tertiaryAction.iconName ? (
              <Icon name={tertiaryAction.iconName} />
            ) : undefined
          }
        />
      )}
      <Stack direction='row' spacing={1.5}>
        <Button
          variant='outlined'
          onClick={cancelAction.action}
          title={cancelAction.label}
          disabled={cancelAction.disabled}
        />
        <Button
          type='submit'
          variant='contained'
          onClick={submitAction.action}
          title={submitAction.label}
          disabled={submitAction.disabled}
          isLoading={submitAction.isLoading}
        />
      </Stack>
    </Stack>
  );
};

export default FormActionFooter;
