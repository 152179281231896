import { forwardRef, ForwardedRef } from 'react';
import Button, { ButtonProps } from '../Button';
import Icon from '../Icon';
import { IconName } from '../Icon/types';

export interface IconButtonProps extends ButtonProps {
  iconName: IconName;
}

const IconButton = forwardRef(
  (
    { iconName, ...rest }: IconButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const size = rest.size ?? 'small';
    const variant = rest.variant ?? 'text';
    return (
      <Button
        ref={ref}
        {...rest}
        size={size}
        variant={variant}
        sx={{
          ...rest.sx,
          minWidth: 'unset',
          lineHeight: 1,
          padding: size === 'small' ? '4px' : '8px',
          borderRadius: size === 'small' ? '60px' : '80px',
        }}
      >
        <Icon name={iconName} size={size} />
      </Button>
    );
  },
);

IconButton.displayName = 'IconButton';

export default IconButton;
