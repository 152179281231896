import { SxProps, Theme } from '@mui/material';
import MuiAlert, { AlertProps as MuiAlertProps } from '@mui/material/Alert';

interface AlertProps extends MuiAlertProps {
  appLevel?: boolean;
}

const Alert = ({ appLevel, ...rest }: AlertProps) => {
  const appLevelStyle: SxProps<Theme> = {
    opacity: 0.85,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: (theme) => theme.zIndex.snackbar,
    borderRadius: 0,
    justifyContent: 'center',
  };

  return (
    <MuiAlert
      {...rest}
      sx={{
        ...(appLevel ? appLevelStyle : {}),
        ...(rest.sx ?? {}),
      }}
    />
  );
};

export default Alert;
