import { useMemo, useCallback, useState, useRef, useEffect } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import TableCell, {
  TableCellProps,
  tableCellClasses,
} from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { omit } from 'min-dash';

import Spinner from '../shared/Spinner';
import Tooltip from '../shared/Tooltip';

import {
  ColumnarSlices,
  FieldInfo,
  FieldValue,
  isFieldInfo,
  Schema,
  SchemaNode,
  SchemaNodeKind,
} from '../../store/slices/exploreExtracts';
import { OnSelectHandler } from './SchemaViewer';
import { FieldType } from '@madeinventive/core-types';
import { ConfigOptions, isFieldDisabled } from './helpers';

const DEFAULT_COL_WIDTH = 80;
const EMPTY_CELL = '\u00a0';

interface StyledTableCellProps extends TableCellProps {
  hovered?: boolean;
  disabled?: boolean;
}
const StyledTableCell = styled(({ ...props }: StyledTableCellProps) => {
  const tableCellProps = omit(props, ['hovered', 'disabled']);
  return <TableCell {...tableCellProps} />;
})(({ theme, ...props }) => {
  const { disabled, hovered } = props;
  const hoveredBorderColor = disabled
    ? theme.palette.border.disabled
    : theme.palette.primary.main;
  const borderColor = hovered ? hoveredBorderColor : theme.palette.border.light;
  return {
    maxWidth: DEFAULT_COL_WIDTH,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    paddingX: theme.spacing(1),
    [`&.${tableCellClasses.head}`]: {
      fontWeight: 600,
      color: disabled ? theme.palette.text.disabled : undefined,
      borderWidth: '2px 2px 0px 2px',
      borderColor: borderColor,
      borderStyle: 'solid',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 10,
      color: disabled ? theme.palette.text.disabled : undefined,
      borderWidth: '0px 2px 0px 2px',
      borderColor: borderColor,
      borderStyle: 'solid',
    },
  };
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.level1,
  },
  '&:nth-of-type(even)': {
    backgroundColor: 'unset',
  },
  '&.MuiTableRow-hover:hover': {
    backgroundColor: theme.palette.action.level2,
  },
  [`&:last-of-type`]: {
    [`& .${tableCellClasses.body}`]: {
      borderWidth: '0px 2px 2px 2px',
    },
  },
}));

interface ColumnType extends SchemaNode {
  data: FieldValue[];
  columnAlign: TableCellProps['align'];
  columnWidth: number;
  name: string;
  label: string;
  metaData?: FieldInfo; // a column shouldn't ever correspond to a View, so override SchemaNode to remove ViewInfo
}

export interface PreviewTableProps {
  preview: SchemaNode[];
  schema: Schema;
  disabledFields?: Array<string>;
  columnarSlices?: ColumnarSlices;
  height: number;
  hoveredField?: string;
  onColumnMouseOver: (field: string | undefined) => void;
  onColumnMouseLeave: (field: string | undefined) => void;
  onSelect: OnSelectHandler;
  options?: ConfigOptions;
}

const PreviewTable = (props: PreviewTableProps) => {
  const {
    preview,
    schema,
    disabledFields,
    columnarSlices,
    hoveredField,
    onColumnMouseOver,
    onColumnMouseLeave,
    onSelect,
    options,
  } = props;

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLTableElement>(null);

  const [hideLeftScroll, setHideLeftScroll] = useState(true);
  const [hideRightScroll, setHideRightScroll] = useState(true);

  const viewHeaders = useMemo(() => {
    return preview.filter((node) => {
      return (
        node.kind === SchemaNodeKind.VIEW &&
        node.children &&
        node.children.length > 0
      );
    });
  }, [preview]);

  const columns = useMemo(() => {
    const results: ColumnType[] = [];
    if (viewHeaders.length) {
      viewHeaders.forEach((node) => {
        return node.children?.forEach((child) => {
          if (isFieldInfo(child.metaData)) {
            const { name, label } = child.metaData;
            results.push({
              ...child,
              metaData: child.metaData,
              data: columnarSlices?.slices[name] ?? [],
              columnAlign: 'justify' as TableCellProps['align'],
              columnWidth: DEFAULT_COL_WIDTH,
              name,
              label,
            });
          }
        });
      });
    }
    return results;
  }, [columnarSlices?.slices, viewHeaders]);

  const handleMouseDown = useCallback(
    (fieldDisabled: boolean, column: ColumnType) => {
      if (!fieldDisabled) {
        onSelect(FieldType.DYNAMIC_FIELD, {
          field: column.id,
          normalizedType: (column.metaData as FieldInfo).normalizedType,
        });
      }
    },
    [onSelect],
  );

  // This effect is needed to show/hide the scroll indicators
  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    // document check is added to prevent error when running storybook
    // runtime.js:4 Error rendering story 'components-previewtable--default':
    // Error: Cannot update an unmounted root.
    if (document && tableContainer) {
      const handleScroll = () => {
        const { scrollLeft, scrollWidth, clientWidth } = tableContainer;
        setHideLeftScroll(scrollLeft === 0);
        setHideRightScroll(scrollLeft + clientWidth === scrollWidth);
      };
      handleScroll();
      tableContainer.addEventListener('scroll', handleScroll);
      return () => {
        tableContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  // Scroll the hovered field in the view
  useEffect(() => {
    const hoveredCell = tableRef.current?.querySelector(
      `.${tableCellClasses.head}[id="${hoveredField}-column-header"]`,
    );

    if (hoveredCell) {
      hoveredCell.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [hoveredField]);

  return (
    <Paper
      sx={{
        width: 6 * DEFAULT_COL_WIDTH,
        height: props.height,
        overflow: 'auto',
      }}
    >
      <Box
        sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}
      >
        <TableContainer ref={tableContainerRef}>
          <Table
            ref={tableRef}
            stickyHeader
            aria-label='preview table'
            size='small'
          >
            <TableHead>
              <TableRow>
                {viewHeaders.map((header) => (
                  <StyledTableCell
                    key={header.id}
                    align='justify'
                    colSpan={header?.children?.length}
                  >
                    {header.name}
                  </StyledTableCell>
                ))}
              </TableRow>
              <TableRow>
                {columns.map((column) => {
                  const hovered = hoveredField === column.name;
                  const fieldDisabled = isFieldDisabled(
                    column.metaData,
                    schema.supportedTypes,
                    options?.idFieldsOnly,
                    disabledFields,
                  );
                  return (
                    <StyledTableCell
                      id={`${column.name}-column-header`}
                      key={column.id}
                      align='justify'
                      onMouseOver={() => {
                        onColumnMouseOver(column.name);
                      }}
                      onMouseLeave={() => {
                        // This is needed to prevent lingering hover state when
                        // the mouse leaves the table from the top or bottom
                        onColumnMouseLeave(column.name);
                      }}
                      onMouseDown={() => {
                        handleMouseDown(fieldDisabled, column);
                      }}
                      hovered={hovered}
                      disabled={fieldDisabled}
                    >
                      <Tooltip title={column.label} placement='top'>
                        <Box>{column.metaData?.shortName ?? EMPTY_CELL}</Box>
                      </Tooltip>
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {[...Array(columnarSlices?.rowCount)].map((_, rowIdx) => (
                <StyledTableRow hover tabIndex={-1} key={`row_${rowIdx}`}>
                  {columns.map((column, colIdx) => {
                    const hovered = hoveredField === column.name;
                    const fieldDisabled = isFieldDisabled(
                      column.metaData,
                      schema.supportedTypes,
                      options?.idFieldsOnly,
                      disabledFields,
                    );
                    return (
                      <StyledTableCell
                        key={`col_${colIdx + 1}`}
                        align={column.columnAlign}
                        onMouseOver={() => {
                          onColumnMouseOver(column.name);
                        }}
                        onMouseLeave={() => {
                          onColumnMouseLeave(column.name);
                        }}
                        onMouseDown={() => {
                          handleMouseDown(fieldDisabled, column);
                        }}
                        hovered={hovered}
                        disabled={fieldDisabled}
                      >
                        <Tooltip
                          title={column.data[rowIdx] ?? ''}
                          placement='top'
                        >
                          <Box>{column.data[rowIdx] ?? EMPTY_CELL}</Box>
                        </Tooltip>
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <ScrollIndicator position='left' hide={hideLeftScroll} />
          <ScrollIndicator position='right' hide={hideRightScroll} />
        </TableContainer>
        {!columnarSlices && <Spinner sx={{ my: 3 }} />}
      </Box>
    </Paper>
  );
};

export default PreviewTable;

interface ScrollIndicatorProps {
  position: 'left' | 'right';
  hide?: boolean;
}

const ScrollIndicator = ({ position, hide }: ScrollIndicatorProps) => {
  return (
    <Box
      sx={{
        visibility: hide ? 'hidden' : 'visible',
        zIndex: 3, // mui table cell has z-index 2
        position: 'absolute',
        top: 0,
        right: position === 'right' ? 0 : 'unset',
        left: position === 'left' ? 0 : 'unset',
        width: 16,
        height: '100%',
        backgroundImage: (theme) =>
          `linear-gradient(to ${position}, transparent, ${theme.palette.neutrals.b5} 80%)`,
        '&:hover': {
          opacity: 1,
        },
      }}
    />
  );
};
