import { useCallback, useState } from 'react';
import { Stack } from '@mui/material';
import DynamicFieldInput from './DynamicFieldInput';
import { convertDynamicFieldStringToChipValue } from './utils';
import Chip from '../shared/Chip';

interface MultiValueDynamicFieldInputProps {
  values: string[];
  onValueEntered: (value: string) => Promise<void>;
  onDelete: (value: string) => void;
  disableDynamicField?: boolean;
  showDebugger?: boolean;
}

const MultiValueDynamicFieldInput = ({
  values,
  onValueEntered,
  onDelete,
  disableDynamicField,
  showDebugger,
}: MultiValueDynamicFieldInputProps) => {
  const [inputValue, setInputValue] = useState('');

  const handleValueChange = useCallback((value: string) => {
    setInputValue(value);
  }, []);

  const handleValueEnter = useCallback(
    (value: string) => {
      return onValueEntered(value);
    },
    [onValueEntered],
  );

  return (
    <Stack width='100%' spacing={2}>
      <DynamicFieldInput
        defaultValue={inputValue}
        onChange={handleValueChange}
        onValueEntered={handleValueEnter}
        disableDynamicField={disableDynamicField}
        showDebugger={showDebugger}
      />

      <Stack direction='row' alignItems='flex-start' flexWrap='wrap'>
        {values.map((value) => {
          const convertedValue = convertDynamicFieldStringToChipValue(value);
          return (
            <Chip
              key={value}
              label={convertedValue}
              onDelete={() => onDelete(value)}
              sx={{
                marginRight: 1.5,
                marginBottom: 1.5,
              }}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};

export default MultiValueDynamicFieldInput;
