import * as React from 'react';
import inventiveLogo from '../../public/inventive_logo.svg';

// components
import { Typography, Grid } from '@mui/material';
import Image from 'next/legacy/image';

const PoweredByLogo = () => {
  return (
    <Grid container alignItems='center' flexWrap='nowrap'>
      <Typography mr={1} color='text.secondary'>
        Powered by
      </Typography>
      <Image src={inventiveLogo} alt='Inventive logo' width={120} height={15} />
    </Grid>
  );
};

export default PoweredByLogo;
