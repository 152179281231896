import { useEffect } from 'react';
import { usePickerData } from '../hooks/picker';
import { useSessionInfo } from '../hooks';
import { useEmbeddingContext } from '../contexts/EmbeddingProvider';

interface WorkspaceVFPPreFetcherProps {
  workspaceId: string;
}
const WorkspaceVFPPreFetcher = ({
  workspaceId,
}: WorkspaceVFPPreFetcherProps) => {
  const [loadPickerDataFor] = usePickerData();
  const { isWorkspaceViewer } = useSessionInfo();
  const { isEmbedded, authorizedEmbedding } = useEmbeddingContext();

  const isWebAppUserWithVFPAccess = !isEmbedded && !isWorkspaceViewer;
  const isEmbedUserWithVFPAccess = isEmbedded && authorizedEmbedding;

  // if it is web-app(not embedded), any user except for the C2 viewers can access the features editor and VFP data
  // it it is embedded, only user that has scoped access to the feature can access the features editor and VFP data
  useEffect(() => {
    if (isWebAppUserWithVFPAccess || isEmbedUserWithVFPAccess) {
      loadPickerDataFor(workspaceId);
    }
  }, [
    isEmbedded,
    isEmbedUserWithVFPAccess,
    isWebAppUserWithVFPAccess,
    isWorkspaceViewer,
    loadPickerDataFor,
    workspaceId,
  ]);
  return null;
};

export default WorkspaceVFPPreFetcher;
