import {
  isValid,
  MultiConditionValueV1,
  NormalizedConditionValueV1,
  RangeConditionValueV1,
  SingleConditionValueV1,
  RelativeDateV1,
  validators,
  SINGULAR_RELATIVE_DATE_UNIT_LABELS,
  RelativeDateUnit,
} from '@madeinventive/core-types';
import { FormControl } from '@mui/material';
import React from 'react';
import FormInput from '../shared/Form/FormInput';
import MultiConditionValueAutocomplete from './MultiConditionValueAutocomplete';
import RangeValueInput from '../shared/RangeValueInput';
import SimpleDropdown from '../shared/SimpleDropdown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RelativeDateInput from '../shared/RelativeDateInput';

interface ConditionValueProps {
  value?: NormalizedConditionValueV1;
  hasField: boolean; // when field is not set and had field placeholder, it is false
  disabled?: boolean;
  isMultiValue: boolean;
  isRangeValue: boolean;
  isSingularRelativeDateValue: boolean;
  isPluralRelativeDateValue: boolean;
  isDateType: boolean;
  suggestable: boolean;
  suggestions?: readonly string[];
  getSuggestions?: () => void;
  loading?: boolean;
  setValue: (value?: NormalizedConditionValueV1) => void;
}

export interface RelativeDateDropdownOption {
  value: RelativeDateV1['unit'];
  label: string;
}

const singularRelativeDateUnitOptions: RelativeDateDropdownOption[] =
  Object.entries(SINGULAR_RELATIVE_DATE_UNIT_LABELS).map(([value, label]) => ({
    value: value as RelativeDateUnit,
    label,
  }));

const ConditionValueInput = ({
  value,
  hasField,
  disabled,
  isMultiValue,
  isRangeValue,
  isSingularRelativeDateValue,
  isPluralRelativeDateValue,
  isDateType,
  suggestable,
  suggestions,
  getSuggestions,
  loading,
  setValue,
}: ConditionValueProps) => {
  const placeholder = disabled ? '' : 'Enter a value';
  const shouldUseMultiAutoComplete = hasField && (isMultiValue || suggestable);
  const shouldUseRangeValueInput = hasField && isRangeValue;
  const shouldUseSingleRelativeDateInput =
    hasField && isSingularRelativeDateValue;
  const shouldUsePluralRelativeDateInput =
    hasField && isPluralRelativeDateValue;

  if (
    shouldUseSingleRelativeDateInput &&
    (value == null || isValid<RelativeDateV1>(validators.RelativeDateV1, value))
  ) {
    const setSingleRelativeDateValue = (newValue: string) => {
      // The "as RelativeDateV1['unit']" type assertion is safe because we build the dropdown option values from the same type.
      const singleRelativeDate: RelativeDateV1 = {
        value: 1,
        unit: newValue as RelativeDateV1['unit'],
      };
      setValue(singleRelativeDate);
    };

    return (
      <FormControl size='small' fullWidth>
        <SimpleDropdown
          value={value?.unit}
          menuOptions={singularRelativeDateUnitOptions}
          IconComponent={KeyboardArrowDownIcon}
          placeholder='Unit'
          setValue={setSingleRelativeDateValue}
        />
      </FormControl>
    );
  } else if (
    shouldUsePluralRelativeDateInput &&
    (value == null || isValid<RelativeDateV1>(validators.RelativeDateV1, value))
  ) {
    return <RelativeDateInput value={value} onChange={setValue} />;
  } else if (
    shouldUseMultiAutoComplete &&
    (value == null ||
      isValid<MultiConditionValueV1>(validators.MultiConditionValueV1, value))
  ) {
    const propsForSuggestions = {
      loading,
      suggestions,
      getSuggestions,
    };

    return (
      <FormControl size='small' fullWidth>
        <MultiConditionValueAutocomplete
          label='Value'
          value={value}
          disabled={disabled}
          onChange={setValue}
          placeholder={placeholder}
          {...(suggestable ? { ...propsForSuggestions } : null)}
        />
      </FormControl>
    );
  } else if (
    shouldUseRangeValueInput &&
    (value == null ||
      isValid<RangeConditionValueV1>(validators.RangeConditionValueV1, value))
  ) {
    return (
      <FormControl size='small' fullWidth>
        <RangeValueInput
          value={value}
          disabled={disabled}
          onChange={setValue}
          placeholder={placeholder}
        />
      </FormControl>
    );
  } else {
    let singleConditionValue: SingleConditionValueV1 = { value: '' };
    const setSingleConditionValue = (newValue: string) => {
      const singleConditionValue: SingleConditionValueV1 = { value: newValue };
      setValue(singleConditionValue);
    };

    if (
      isValid<SingleConditionValueV1>(validators.SingleConditionValueV1, value)
    ) {
      singleConditionValue = value;
    }

    return (
      <FormControl size='small' fullWidth>
        <FormInput
          label='Value'
          type={isDateType ? 'date' : 'text'}
          inputValue={singleConditionValue.value}
          disabled={disabled}
          setValue={setSingleConditionValue}
          placeholder={placeholder}
        />
      </FormControl>
    );
  }
};

export default ConditionValueInput;
