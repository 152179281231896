import {
  FeatureConfig,
  FieldDatumV1,
  TableAppV1,
  ensureType,
  isValid,
  validators,
} from '@madeinventive/core-types';
import { EditDataAdapter } from './EditDataAdapter';
import { CustomBrandingState } from '../../../store/slices/session';
import { generateActionJSON, parseAction } from './helpers';
import { FeatureEditDataState } from '../../../store/slices/features/featureEditData';
import { FeatureType } from '../../../generated/types';

class TableAppDataAdapter implements EditDataAdapter {
  parseFromConfig(
    config: object,
  ): Omit<
    FeatureEditDataState,
    | 'id'
    | 'name'
    | 'description'
    | 'mode'
    | 'placeholderLookup'
    | 'hasUnsavedChanges'
  > {
    const typedConfig = ensureType<FeatureConfig>(
      validators.FeatureConfig,
      config,
    );
    if (
      typedConfig.kind === 'TableApp/v1.0' &&
      isValid<TableAppV1>(validators.TableAppV1, typedConfig.spec)
    ) {
      return {
        featureType: FeatureType.TABLE_APP,
        exploreId: typedConfig.spec.environmentExploreId,
        conditions: typedConfig.spec.conditions,
        actions: parseAction(typedConfig.spec.actions),
        fieldData: typedConfig.spec.columns.map((column) => column.spec),
      };
    }
    throw Error(`Unknown feature config kind: ${typedConfig.kind}`);
  }

  serializeToConfig(
    data: FeatureEditDataState,
    customBranding?: CustomBrandingState,
  ): object {
    return {
      kind: 'TableApp/v1.0',
      spec: {
        environmentExploreId: data.exploreId,
        conditions: data.conditions,
        actions: generateActionJSON(data.actions, customBranding),
        columns: data.fieldData.map((fieldDatum) => {
          const fieldDatumCopy: FieldDatumV1 = { ...fieldDatum };
          // keeps numberformat and picks one configuration to store
          if (fieldDatum.appearance && fieldDatum.appearanceConfig) {
            fieldDatumCopy.appearanceConfig = {
              numberFormat: fieldDatum.appearanceConfig?.numberFormat,
              [fieldDatum.appearance]:
                fieldDatum.appearanceConfig[fieldDatum.appearance],
            };
          }
          return {
            kind: 'TableColumn/v1.0',
            spec: fieldDatumCopy,
          };
        }),
      },
    };
  }
}

const tableAppDataAdapter = new TableAppDataAdapter();
export default tableAppDataAdapter;
