import { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import MenuButton, { MenuButtonProps, MenuItemOption } from '../../MenuButton';
import InlineEditableText from '../InlineEditableText';
import Comment, { CommentProps } from './Comment';
import CommentEditor from './CommentEditor';

export interface CommentThreadProps {
  comments: CommentProps[];
  title: string;
  description?: string;
  descriptionPlaceholderText?: string;

  addEnabled?: boolean;
  onNewComment?: (newComment: string) => void;
  editableTitleEnabled?: boolean;
  onNewTitle?: (newTitle: string) => void;
  onNewDescription?: (newDescription: string) => void;

  menuItemOptions?: MenuItemOption[];
  onMenuClose?: MenuButtonProps['onMenuClose'];
}

const CommentThread = (props: CommentThreadProps) => {
  const {
    title,
    description,
    descriptionPlaceholderText,
    comments,
    addEnabled,
    editableTitleEnabled,
    onNewComment,
    onNewTitle,
    onNewDescription,
    menuItemOptions,
    onMenuClose,
  } = props;

  const [newComment, setNewComment] = useState('');

  const handleNewComment = useCallback(
    (newComment: string) => {
      setNewComment(newComment);
      onNewComment && onNewComment(newComment);
    },
    [onNewComment],
  );

  return (
    <Stack
      alignItems='stretch'
      flexGrow={1}
      paddingY={0.5}
      height='fit-content'
    >
      <Box
        display='flex'
        flexDirection='row'
        flexGrow={1}
        mx={1}
        marginTop={0.5}
      >
        <InlineEditableText
          text={title}
          setText={editableTitleEnabled ? onNewTitle : undefined}
          tooltip={editableTitleEnabled ? 'Click to edit' : undefined}
          boldfaced
        />
        {menuItemOptions && menuItemOptions.length > 0 && (
          <MenuButton
            menuItemOptions={menuItemOptions}
            buttonProps={{ sx: { p: 0, minWidth: 0 } }}
            onMenuClose={onMenuClose}
          >
            <MoreVertIcon />
          </MenuButton>
        )}
      </Box>
      <Box
        display='flex'
        flexDirection='row'
        flexGrow={1}
        mx={1}
        marginBottom={0.5}
      >
        <InlineEditableText
          text={description ?? ''}
          setText={editableTitleEnabled ? onNewDescription : undefined}
          tooltip={editableTitleEnabled ? 'Click to edit' : undefined}
          typography='body2'
          placeholderText={
            descriptionPlaceholderText ??
            'Add context and @ mention your collaborators'
          }
        />
      </Box>
      {comments.map((c) => (
        <>
          <Divider key={`comment_divider_${c.data.id}`} />
          <Comment key={`comment_${c.data.id}`} {...c} />
        </>
      ))}
      {addEnabled && (
        <CommentEditor
          comment={newComment}
          setComment={handleNewComment}
          autoClear
        />
      )}
    </Stack>
  );
};
export default CommentThread;
