import { createContext, useCallback, useContext } from 'react';
import {
  useWorkspaceAssociatedExploresLazyQuery,
  usePickerSchemaLazyQuery,
  WorkspaceAssociatedExploresQuery,
} from '../generated/types';

// store
import { useAppSelector, useAppDispatch } from './store';
import {
  DataExploreConfig,
  toExploreExtract,
  updateExploreExtract,
  makeExtractId,
} from '../store/slices/exploreExtracts';
import {
  LoadState,
  updateExploreForWorkspace,
  updateWorkspaceExploresLoadState,
} from '../store/slices/loadedWorkspaceExplores';

// hook
import { useSessionInfo } from './session';

export interface PickerDataService {
  isDataLoaded: (workspaceId: string) => boolean;
  loadDataFor: (workspaceId: string, forced?: boolean) => void;
}

export const PickerDataContext = createContext({
  isDataLoaded: () => false,
  loadDataFor: () => {
    /**  intentionally empty */
  },
} as PickerDataService);

type PickerSchemaFetcher = (dataExploreCfg: DataExploreConfig) => Promise<void>;

const exploreDataUpdater =
  (
    workspaceId: string,
    storeDispatch: ReturnType<typeof useAppDispatch>,
    pickerSchemaFetcher: PickerSchemaFetcher,
    isExtractAvailable: (extractId: string) => boolean,
  ) =>
  (data: WorkspaceAssociatedExploresQuery) => {
    if (!workspaceId) return;
    if (
      data &&
      data?.node?.__typename === 'Workspace' &&
      data.node.lookerExplores
    ) {
      const updatedResult = data.node.lookerExplores.map((lookerExplore) => {
        const dataExploreCfg: DataExploreConfig = {
          providerId: lookerExplore.explore.externalProviderId,
          modelName: lookerExplore.explore.dataModelName,
          modelExploreName: lookerExplore.explore.exploreName,
          customerFilterName: lookerExplore.explore.customerField ?? undefined,
          customerFilterValues: lookerExplore.customerFilterValues ?? [],
        };

        const extractId = makeExtractId(dataExploreCfg);
        const alreadyAvailable = isExtractAvailable(extractId);
        const exploreInfo = {
          wsExploreId: lookerExplore.id,
          envExploreId: lookerExplore.explore.id,
          exploreConfig: { ...dataExploreCfg },
          extractId,
          loadState: alreadyAvailable ? LoadState.LOADED : LoadState.LOADING,
        };

        storeDispatch(
          updateExploreForWorkspace({
            workspaceId,
            exploreInfo,
          }),
        );

        return {
          isExtractAvailable: alreadyAvailable,
          dataExploreCfg,
        };
      });

      updatedResult.forEach(({ isExtractAvailable, dataExploreCfg }) => {
        if (!isExtractAvailable) {
          pickerSchemaFetcher(dataExploreCfg).then(() => {
            // mark explore data as 'LOADED`
            const extractId = makeExtractId(dataExploreCfg);
            storeDispatch(
              updateWorkspaceExploresLoadState({
                workspaceId,
                extractId,
                newState: LoadState.LOADED,
              }),
            );
          });
        }
      });
    }
  };

export const usePickerDataContext = (): PickerDataService => {
  const availableWorkspaceExplores = useAppSelector(
    (state) => state.workspace.value.workspaceExplores,
  );
  const loadedExplores = useAppSelector((state) => state.loadedExplores.value);

  const isExploresForWorkspaceFetched = useCallback(
    (workspaceId: string) => {
      return !!loadedExplores[workspaceId];
    },
    [loadedExplores],
  );

  const exploreExtracts = useAppSelector(
    (state) => state.exploreExtracts.value,
  );

  const { isSignedIn, isWorkspaceViewer } = useSessionInfo();

  const storeDispatch = useAppDispatch();

  const [fetchAssociatedExplores, { loading: exploresLoading }] =
    useWorkspaceAssociatedExploresLazyQuery();

  const [fetchPickerSchema] = usePickerSchemaLazyQuery();

  const pickerSchemaFetcher: PickerSchemaFetcher = useCallback(
    async (dataExploreCfg: DataExploreConfig) => {
      try {
        const result = await fetchPickerSchema({
          variables: {
            ...dataExploreCfg,
          },
        });

        const pickerSchema = result?.data?.pickerSchema;
        if (pickerSchema) {
          const extractId = makeExtractId(dataExploreCfg);
          storeDispatch(
            updateExploreExtract(toExploreExtract(extractId, pickerSchema)),
          );
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error(
            `Error loading picker schema for explore - ${error.message}`,
          );
        }
      }
    },
    [fetchPickerSchema, storeDispatch],
  );

  const isExtractAvailable = useCallback(
    (extractId: string): boolean => {
      return !!exploreExtracts[extractId];
    },
    [exploreExtracts],
  );

  const loadExplores = useCallback(
    (workspaceId: string) => {
      fetchAssociatedExplores({
        variables: { workspaceId },
      })
        .then(({ data }) => {
          if (data) {
            exploreDataUpdater(
              workspaceId,
              storeDispatch,
              pickerSchemaFetcher,
              isExtractAvailable,
            )(data);
          }
        })
        .catch((error: unknown) => {
          if (error instanceof Error) {
            console.error(
              `Error loading explores for workspace - ${error.message}`,
            );
          }
        });
    },
    [
      fetchAssociatedExplores,
      pickerSchemaFetcher,
      isExtractAvailable,
      storeDispatch,
    ],
  );

  const isDataLoaded = useCallback(
    (workspaceId?: string) => {
      if (!workspaceId) return false;

      const loadedForWorkspace = loadedExplores[workspaceId];
      if (!loadedForWorkspace) return false;

      const loadedExploreIds = Object.keys(loadedForWorkspace.byEnvExploreId);
      if (!loadedExploreIds.length) return false;

      // Checks if all workspace explores are loaded
      const availableExploreIds =
        availableWorkspaceExplores?.map((explore) => explore.exploreId) ?? [];
      const availableExploresNotLoaded = availableExploreIds.filter(
        (x) => !loadedExploreIds.includes(x),
      );
      if (availableExploresNotLoaded.length) return false;

      // for viewer, data is considered as loaded so long as schemas for the explores are loaded
      // as viewer doesn't care about the actual picker data
      if (isWorkspaceViewer) return true;

      for (const id of loadedExploreIds) {
        if (
          loadedForWorkspace.byEnvExploreId[id].loadState !== LoadState.LOADED
        )
          return false;
      }
      return true;
    },
    [loadedExplores, isWorkspaceViewer, availableWorkspaceExplores],
  );

  const loadDataFor = useCallback(
    (workspaceId: string, forced = false) => {
      if (
        isSignedIn &&
        !exploresLoading &&
        !isExploresForWorkspaceFetched(workspaceId) &&
        (!isDataLoaded(workspaceId) || forced)
      ) {
        loadExplores(workspaceId);
      }
    },
    [
      isSignedIn,
      loadExplores,
      isDataLoaded,
      exploresLoading,
      isExploresForWorkspaceFetched,
    ],
  );

  return {
    isDataLoaded,
    loadDataFor,
  };
};

export const usePickerData = () => {
  const { loadDataFor } = useContext(PickerDataContext);
  return [loadDataFor] as const;
};
