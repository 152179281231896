import { useState, useCallback } from 'react';
import { useFormikContext } from 'formik';
import { useFeatureFlags, FeatureEnum } from '../../../hooks/useFeatureFlags';

// components
import { Stack, AlertTitle } from '@mui/material';
import FormField from '../../shared/Inputs/FormField';
import TextField from '../../shared/Inputs/TextField';
import DynamicFieldInput from '../../DynamicFieldInputs/DynamicFieldInput';
import DynamicFieldEditor from '../../DynamicFieldInputs/DynamicFieldEditor';
import Alert from '../../shared/Alert';

// types
import { MessageFormValues, DYNAMIC_FIELD_SUB_LABEL } from '../types';
import { Mode, OutputFormat } from '../../DynamicFieldInputs/types';

interface AdvancedMessageProps {
  formik: ReturnType<typeof useFormikContext<MessageFormValues>>;
  disableDynamicField?: boolean;
}

const AdvancedMessage = ({
  formik,
  disableDynamicField,
}: AdvancedMessageProps) => {
  const { setFieldValue, values, errors, touched } = formik;
  const { isFeatureEnabled } = useFeatureFlags();
  const isDualModeEnabled = isFeatureEnabled(FeatureEnum.EmailBodyHtmlInput);

  const [emailBodyMode, setEmailBodyMode] = useState<Mode>(Mode.RICH_TEXT);
  const toggleEmailBodyMode = useCallback(() => {
    setFieldValue('emailBody', '');
    setEmailBodyMode(
      emailBodyMode === Mode.RAW_TEXT ? Mode.RICH_TEXT : Mode.RAW_TEXT,
    );
  }, [emailBodyMode, setFieldValue]);

  return (
    <Stack id='advanced-message-sub-step' spacing={4}>
      <FormField
        label='Email subject'
        errorMessage={touched.emailSubject ? errors.emailSubject : undefined}
      >
        <DynamicFieldInput
          defaultValue={values.emailSubject ?? ''}
          disableDynamicField={disableDynamicField}
          onChange={(value) => {
            setFieldValue('emailSubject', value);
          }}
        />
      </FormField>
      <FormField
        label='Message'
        subLabel={disableDynamicField ? undefined : DYNAMIC_FIELD_SUB_LABEL}
        labelOverflow
        errorMessage={touched.emailBody ? errors.emailBody : undefined}
        {...(isDualModeEnabled
          ? {
              action: {
                actionLabel:
                  emailBodyMode === Mode.RAW_TEXT
                    ? 'Switch to rich text mode'
                    : 'Switch to plain text mode(HTML)',
                onClick: toggleEmailBodyMode,
              },
            }
          : {})}
      >
        {emailBodyMode === Mode.RAW_TEXT && (
          <DynamicFieldEditor
            defaultValue={values.emailBody ?? ''}
            onChange={(value) => {
              const decodedValue = value
                .replaceAll('&lt;', '<')
                .replaceAll('&gt;', '>');
              setFieldValue('emailBody', decodedValue);
            }}
            mode={Mode.RAW_TEXT}
            disableDynamicField={disableDynamicField}
          />
        )}
        {emailBodyMode === Mode.RICH_TEXT && (
          <DynamicFieldEditor
            defaultValue={values.emailBody ?? ''}
            onChange={(value) => {
              setFieldValue('emailBody', value);
            }}
            mode={Mode.RICH_TEXT}
            outputFormat={OutputFormat.HTML}
            disableDynamicField={disableDynamicField}
          />
        )}
      </FormField>
      {/* Button text */}
      <TextField
        label='Button text'
        value={values.buttonText}
        onChange={(e) => {
          setFieldValue('buttonText', e.target.value);
        }}
        errorMessage={touched.buttonText ? errors.buttonText : undefined}
      />
      {/* Button link */}
      <Stack width='100%' spacing={2}>
        <FormField
          label='Button link'
          errorMessage={touched.buttonLink ? errors.buttonLink : undefined}
        >
          <DynamicFieldInput
            defaultValue={values.buttonLink ?? ''}
            disableDynamicField={disableDynamicField}
            onChange={(value) => {
              setFieldValue('buttonLink', value);
            }}
          />
        </FormField>
        <Alert severity='info'>
          <AlertTitle>Pro tip</AlertTitle>
          Button links to the live data of the attached chart or table by
          default. Customize the URL to guide message recipients to their next
          action.
        </Alert>
      </Stack>
    </Stack>
  );
};

export default AdvancedMessage;
