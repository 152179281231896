import { Box, Dialog, DialogContent } from '@mui/material';
import React, { useRef, useState } from 'react';
// @TODO: `makeStyles` is deprecated. Use `styled` from '@mui/material/styles' instead.
import { makeStyles } from '@mui/styles';
import { getFormattedRecipientString } from './utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface RecipientsDisplayProps {
  displayName: string;
  recipientListString: string;
}
const useStyles = makeStyles(() => ({
  dialog: {
    position: 'absolute',
    right: 200,
    top: 200,
    maxHeight: 400,
    borderRadius: 4,
    maxWidth: 300,
    boxShadow: '0px 5px 10px #111111',
  },
}));
const RecipientsDisplay = ({
  displayName,
  recipientListString,
}: RecipientsDisplayProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const classes = useStyles();
  const dialogParentRef = useRef();
  const { renderedRecipientString } = getFormattedRecipientString(
    recipientListString,
    4,
  );
  return (
    <>
      <Box
        display='flex'
        onClick={() => setExpanded((prev) => !prev)}
        sx={{
          cursor: 'pointer',
        }}
      >
        <span style={{ color: 'rgb(155, 155, 155)' }}>{displayName}:</span>{' '}
        <Box
          ref={dialogParentRef}
          sx={{
            position: 'relative',
            display: 'inline-block',
            ml: 2,
            mr: 'auto',
          }}
        >
          {renderedRecipientString}
        </Box>
        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Box>

      <Dialog
        open={expanded}
        onClose={() => setExpanded(false)}
        classes={{
          paper: classes.dialog,
        }}
        container={dialogParentRef.current}
        scroll='paper'
      >
        <DialogContent>
          <Box display='flex'>
            <Box mr={2} style={{ color: 'rgb(155, 155, 155)' }}>
              {displayName}:
            </Box>
            <Box>
              {recipientListString.split(',').map((recipient) => (
                <span key={recipient}>{recipient}</span>
              ))}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RecipientsDisplay;
