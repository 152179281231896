import { useCallback } from 'react';
import { useElementWidth } from '../../hooks/useElementWidth';

import {
  areTheSame,
  NormalizedConditionExpression,
  NormalizedConditionValueV1,
  TagMetaData,
} from '@madeinventive/core-types';

// material UI components
import Box from '@mui/material/Box';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useStyles from '../../styles/style';

// components
import VFPInputField from '../VFPInputField';
import SimpleDropdown from '../shared/SimpleDropdown';
import ConditionValueInput from './ConditionValueInput';

// store
import { PlaceholderLocation, useFeature } from '../../hooks';

import useFieldVariable from '../../hooks/useFieldVariable';
import { extractConditionExpressionFieldNames } from '../../store/slices/features';

interface ConditionRowProps {
  condition: NormalizedConditionExpression;
  index: number;
  inputsDisabled?: boolean;
  workspaceId: string;
}

const ROW_BREAK_POINT = 600;

const ConditionRow = ({
  condition,
  index,
  inputsDisabled,
  workspaceId,
}: ConditionRowProps) => {
  const { featureEditData, deleteConditionAt, updateConditionAt } =
    useFeature();

  const { fontWeightBold } = useStyles();

  const [fieldRef, width] = useElementWidth();

  const {
    // field
    isFieldDataValid,
    fieldName,
    // operators
    operators,
    // value
    fetchSuggestions,
    isDateType,
    isMultiValue,
    isRangeValue,
    isSingularRelativeDateValue,
    isPluralRelativeDateValue,
    isSuggestable,
    isSuggestionLoading,
    isValueDisabled,
    suggestions,
    // utils
    getIsMultiValue,
    getIsRangeValue,
    getNormalizedOperator,
  } = useFieldVariable(condition.variable, condition.operator);

  const operatorDisabled = inputsDisabled || !isFieldDataValid;
  const valueDisabled = inputsDisabled || isValueDisabled;

  const selectedFields = extractConditionExpressionFieldNames(
    featureEditData.conditions,
  );

  // event handlers
  const handleFieldChange = useCallback(
    (newVariable: TagMetaData | undefined) => {
      if (
        !condition.variable ||
        !newVariable ||
        !areTheSame(newVariable, condition.variable)
      ) {
        updateConditionAt(index, {
          chainingOperator: condition.chainingOperator,
          variable: !newVariable ? undefined : { ...newVariable },
          value: undefined,
        });
      }
    },
    [condition.chainingOperator, condition.variable, index, updateConditionAt],
  );

  const handleOperatorChange = useCallback(
    (opKey: string) => {
      const currentOperator = condition.operator?.key;
      const newOperator = opKey;
      const isCurrentMultiValue = isMultiValue;
      const isCurrentRangeValue = isRangeValue;
      const isNewMultiValue = getIsMultiValue(newOperator);
      const isNewRangeValue = getIsRangeValue(newOperator);

      // Clear the input in the following cases:
      // - The input is currently a multi value input and it changes
      // - The input type is a range value and it changes
      // - The input type changes to a range value from a different type of input type
      const shouldResetValue =
        (isCurrentMultiValue && !isNewMultiValue) ||
        (!isCurrentRangeValue && isNewRangeValue) ||
        (isCurrentRangeValue && !isNewRangeValue);
      const normalizedOperator = getNormalizedOperator(
        newOperator,
        true /* minimized */,
      );

      if (newOperator !== currentOperator && normalizedOperator) {
        updateConditionAt(index, {
          ...condition,
          operator: normalizedOperator,
          ...(shouldResetValue ? { value: undefined } : null),
        });
      }
    },
    [
      condition,
      getIsMultiValue,
      getIsRangeValue,
      getNormalizedOperator,
      index,
      isMultiValue,
      isRangeValue,
      updateConditionAt,
    ],
  );

  const handleValueChange = useCallback(
    (value?: NormalizedConditionValueV1) => {
      updateConditionAt(index, {
        ...condition,
        value,
      });
    },
    [condition, index, updateConditionAt],
  );

  const deleteRow = useCallback(() => {
    deleteConditionAt(index);
  }, [index, deleteConditionAt]);

  return (
    <Stack
      ref={fieldRef}
      direction={width > ROW_BREAK_POINT ? 'row' : 'column'}
      key={index}
      spacing={1}
      alignItems={width > ROW_BREAK_POINT ? 'center' : 'flex-start'}
    >
      <Box width={58}>
        <Typography className={!index ? fontWeightBold : ''} variant='h6'>
          {!index ? 'When' : 'AND'}
        </Typography>
      </Box>
      <Grid
        direction={width > ROW_BREAK_POINT ? 'row' : 'column'}
        container
        rowSpacing={width > ROW_BREAK_POINT ? 0 : 2}
        columnSpacing={width > ROW_BREAK_POINT ? 1 : 0}
      >
        <Grid item xs={5}>
          <VFPInputField
            fieldVariable={condition.variable}
            setFieldVariable={handleFieldChange}
            workspaceId={workspaceId}
            placeholder='Find a data field'
            selectedFields={selectedFields}
            placeholderLocation={PlaceholderLocation.CONDITION}
            disabled={inputsDisabled}
            placeholderAllowed={true}
          />
        </Grid>
        <Grid item xs={3}>
          <SimpleDropdown
            label='Operator'
            value={condition.operator?.key ?? ''}
            menuOptions={operators}
            IconComponent={KeyboardArrowDownIcon}
            placeholder='Pick operator'
            disabled={operatorDisabled}
            setValue={handleOperatorChange}
            sx={{}}
          />
        </Grid>
        <Grid item xs={4}>
          <ConditionValueInput
            value={condition.value}
            hasField={!!fieldName}
            disabled={valueDisabled}
            isMultiValue={isMultiValue}
            isRangeValue={isRangeValue}
            isSingularRelativeDateValue={isSingularRelativeDateValue}
            isPluralRelativeDateValue={isPluralRelativeDateValue}
            isDateType={isDateType}
            suggestable={isSuggestable}
            suggestions={suggestions}
            getSuggestions={fetchSuggestions}
            loading={isSuggestionLoading}
            setValue={handleValueChange}
          />
        </Grid>
      </Grid>
      <IconButton
        aria-label='delete'
        onClick={deleteRow}
        disabled={inputsDisabled}
      >
        <CloseOutlinedIcon />
      </IconButton>
    </Stack>
  );
};

export default ConditionRow;
