import {
  useContext,
  createContext,
  useEffect,
  ReactNode,
  useState,
} from 'react';
import { isObject } from 'lodash';
import { useSession } from '../hooks';
import { EmbedAuthScope } from '@madeinventive/core-types';
import AccessDenied from '../components/shared/AccessDenied';

export enum EmbedScopeEnum {
  Chat = 'Chat',
}

export class AuthorizedEmbedding {
  private scope: EmbedAuthScope;
  private hostUrl?: string;

  constructor(scope: EmbedAuthScope, hostUrl?: string) {
    this.scope = scope;
    this.hostUrl = hostUrl;
  }

  getScope(): EmbedAuthScope {
    return this.scope;
  }

  getHostUrl(): string | undefined {
    return this.hostUrl;
  }
}

interface EmbeddingContextType {
  isEmbedded: boolean;
  authorizedEmbedding?: AuthorizedEmbedding;
  hostContext?: object;
}

const initialValue: EmbeddingContextType = {
  isEmbedded: false,
  authorizedEmbedding: undefined,
  hostContext: undefined,
};

const EmbeddingContext = createContext(initialValue);

export const useEmbeddingContext = () => useContext(EmbeddingContext);

interface EmbeddingProviderProps {
  children: ReactNode;
}

const EmbeddingProvider = ({ children }: EmbeddingProviderProps) => {
  const devOverride =
    process.env.NEXT_PUBLIC_IS_EMBEDDED_OVERRIDE === 'true' ||
    !!Number(process.env.NEXT_PUBLIC_IS_EMBEDDED_OVERRIDE);

  const isEmbedded = devOverride || window !== window.parent; // the reliable way to check if the app is embedded in an iframe

  const { cleanupSession, signInWithEmbedTokens } = useSession();
  const [authorizedEmbedding, setAuthorizedEmbedding] = useState<
    AuthorizedEmbedding | undefined
  >(
    devOverride
      ? new AuthorizedEmbedding({
          chat: true,
          workflows: true,
          tableApps: true,
        })
      : undefined,
  );
  const [is403, setIs403] = useState<boolean>(false);
  const [hostContext, setHostContext] = useState<object | undefined>(undefined);

  useEffect(() => {
    // no op if not embedded
    if (!isEmbedded) return;

    const handleParentMessage = (event: MessageEvent) => {
      // Check the type of message
      switch (event.data.type) {
        case 'embed_tokens':
          {
            const { tokens, scopeToken, hostUrl, hostContext } = event.data;
            // @TODO: validate the incoming tokens are of the expected type, i.e. the `TokensData` that `core-api` returns
            const {
              accessToken,
              accessTokenExpiresAt,
              refreshToken,
              refreshTokenExpiresAt,
            } = tokens;
            if (
              !accessToken ||
              !accessTokenExpiresAt ||
              !refreshToken ||
              !refreshTokenExpiresAt
            ) {
              setIs403(true);
              cleanupSession();
              return;
            }

            // not need to wait as we don't want to block the message event loop
            signInWithEmbedTokens(tokens, scopeToken).then((authScope) => {
              if (authScope) {
                // existence of the AuthorizedEmbedding object means not only we are in an
                // embedded session but also that the inter-frame handshake has been completed
                setAuthorizedEmbedding(
                  new AuthorizedEmbedding(authScope, hostUrl),
                );
              }
            });

            if (isObject(hostContext)) {
              setHostContext(hostContext);
            }
          }
          break;
        case 'set_host_context':
          {
            const { hostContext } = event.data;
            if (isObject(hostContext)) {
              setHostContext(hostContext);
            }
          }
          break;
        // no default
      }
    };

    // Listen for messages from the parent window
    window.addEventListener('message', handleParentMessage);
    // Announce that the app is ready to receive messages
    window.parent.postMessage({ type: 'embed_content_ready' }, '*');

    // Clean up the event listener
    return () => {
      window.removeEventListener('message', handleParentMessage);
    };
  }, [
    isEmbedded,
    setAuthorizedEmbedding,
    signInWithEmbedTokens,
    cleanupSession,
  ]);

  return is403 ? (
    <AccessDenied />
  ) : (
    <EmbeddingContext.Provider
      value={{
        isEmbedded,
        authorizedEmbedding,
        hostContext,
      }}
    >
      {children}
    </EmbeddingContext.Provider>
  );
};

export default EmbeddingProvider;
