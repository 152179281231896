import {
  SyntheticEvent,
  useState,
  ReactNode,
  useRef,
  useMemo,
  ChangeEvent,
} from 'react';
import { debounce } from 'min-dash';

// mui
import { Popover, Stack, styled, TextField, Typography } from '@mui/material';
import MuiAccordion, {
  AccordionProps as MuiAccordionProps,
} from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FilterListIcon from '@mui/icons-material/FilterList';

// components
import Button from '../../shared/Button';
import FormFieldLabel from '../../shared/Form/FormFieldLabel';
import SimpleDropdown from '../../shared/SimpleDropdown';
import ConditionForm from '../../ConditionForm';
import ActionPanels from '../../Workflow/ActionPanels';

// hooks and types
import { useFeature } from '../../../hooks';
import { DRAWER_PADDING_UNITS } from '../../shared/Drawer';
import {
  actionManifest,
  ActionManifestEntry,
  ACTION_MANIFEST_LOOKUP,
} from '../../../hooks/feature/ActionManifest';
import { DEFAULT_ACTION_INDEX } from './utils/constants';
import useActiveExplore from '../../../hooks/useActiveExplore';
import { BaseRegisteredDrawerComponentProps } from '../../registeredDrawers/types';

export interface EditTableFormProps extends BaseRegisteredDrawerComponentProps {
  inputsDisabled?: boolean;
  openedAccordionId?: string;
}

const EditTableForm = ({
  inputsDisabled,
  openedAccordionId,
}: EditTableFormProps) => {
  const {
    featureEditData,
    addCondition,
    setExploreId,
    deleteActionAt,
    updateActionAt,
    setActionLabelAt,
  } = useFeature();
  const { exploreOptions, workspaceId } = useActiveExplore();

  const [expandedAccordion, setExpandedAccordion] = useState<string | false>(
    openedAccordionId || false,
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const filterButtonRef = useRef<HTMLButtonElement | null>(null);

  const action = featureEditData.actions[DEFAULT_ACTION_INDEX];
  const actionManifestEntry: ActionManifestEntry =
    ACTION_MANIFEST_LOOKUP[action?.kind];

  const handleAccordionChange =
    (accordionId: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpandedAccordion(isExpanded ? accordionId : false);
      if (accordionId === 'filters-accordion' && isExpanded) {
        openConditionsPopover();
      }
    };

  const openConditionsPopover = () => {
    if (!featureEditData.conditions.length) {
      addCondition();
    }
    setAnchorEl(filterButtonRef.current);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const onActionLabelChange = debounce(
    (event: ChangeEvent<HTMLInputElement>) => {
      setActionLabelAt(DEFAULT_ACTION_INDEX, event.target.value);
    },
    300,
  );

  const handleActionKindSelection = (selectedActionKind: string) => {
    if (selectedActionKind === '') {
      deleteActionAt(DEFAULT_ACTION_INDEX);
      return;
    }

    const defaultSpecForAction =
      ACTION_MANIFEST_LOOKUP[selectedActionKind].defaultSpec;
    if (defaultSpecForAction) {
      const existingAction = featureEditData.actions[DEFAULT_ACTION_INDEX];
      if (!existingAction || existingAction.kind !== selectedActionKind) {
        updateActionAt(DEFAULT_ACTION_INDEX, defaultSpecForAction);
      }
    } else {
      console.error(
        `Couldn't find default spec for action ${selectedActionKind}`,
      );
    }
  };

  const popoverOpen = Boolean(anchorEl);
  const popoverId = popoverOpen ? 'filters-popover' : undefined;

  const actionOptions = useMemo(() => {
    const options = actionManifest.map((a) => ({
      value: a.defaultSpec?.kind || a.cardProps.title,
      label: a.cardProps.title,
      icon: a.cardProps.icon('small'),
      disabled: a.disabled,
    }));

    return options;
  }, []);

  if (!workspaceId) return null;

  return (
    <>
      {/* Data Accordion */}
      <Accordion
        id='data-accordion'
        header='Data'
        expandedAccordion={expandedAccordion}
        handleAccordionChange={handleAccordionChange}
      >
        <FormFieldLabel
          text='Source'
          tooltipText='Select which data you want to use for this feature'
          required
        />
        <SimpleDropdown
          disabled={exploreOptions.length === 0 || inputsDisabled}
          setValue={setExploreId}
          menuOptions={exploreOptions}
          value={featureEditData.exploreId ?? ''}
          sx={{ width: '100%' }}
          IconComponent={KeyboardArrowDownIcon}
        />
      </Accordion>
      {/* Actions Accordion */}
      <Accordion
        id='actions-accordion'
        header='Actions'
        subtitle='Configure the action button that will be clickable on each row in the table.'
        expandedAccordion={expandedAccordion}
        handleAccordionChange={handleAccordionChange}
      >
        <FormFieldLabel text='Select action' required />
        <SimpleDropdown
          value={
            !featureEditData.actions.length
              ? ''
              : featureEditData.actions[DEFAULT_ACTION_INDEX].kind
          }
          setValue={handleActionKindSelection}
          menuOptions={actionOptions}
          includeBlank={true}
          blankLabel={'No action'}
          disabled={inputsDisabled}
        />
        <FormFieldLabel text='Button text' required />
        <TextField
          size='small'
          defaultValue={action?.actionLabel}
          onChange={onActionLabelChange}
          placeholder={
            actionManifestEntry?.controlConfig?.buttonTextPlaceholder
          }
          disabled={!action || inputsDisabled}
        />
        <ActionPanels
          featureEditData={featureEditData}
          workspaceId={workspaceId}
          actionIndex={DEFAULT_ACTION_INDEX}
          enableDataPreview={false}
        />
      </Accordion>
      {/* Filter Accordion */}
      <Accordion
        id='filters-accordion'
        header='Preset Filters'
        expandedAccordion={expandedAccordion}
        handleAccordionChange={handleAccordionChange}
      >
        <Button
          variant='contained'
          startIcon={<FilterListIcon />}
          endIcon={<KeyboardArrowDownIcon />}
          onClick={openConditionsPopover}
          ref={filterButtonRef}
          disabled={inputsDisabled}
          sx={{
            width: 125,
            borderRadius: '24px',
          }}
        >
          {featureEditData.conditions.length}{' '}
          {featureEditData.conditions.length === 1 ? 'rule' : 'rules'}
        </Button>

        <Popover
          id={popoverId}
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={closePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          PaperProps={{
            sx: {
              width: 700,
              p: 2,
            },
          }}
        >
          <ConditionForm
            workspaceId={workspaceId}
            inputsDisabled={inputsDisabled}
            showFilterText={true}
          />
        </Popover>
      </Accordion>
    </>
  );
};

const StyledAccordion = styled((props: MuiAccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  '&:before': {
    display: 'none',
  },
  marginLeft: theme.spacing(-DRAWER_PADDING_UNITS),
  marginRight: theme.spacing(-DRAWER_PADDING_UNITS),
}));

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<KeyboardArrowRightIcon />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  paddingLeft: theme.spacing(DRAWER_PADDING_UNITS),
  paddingRight: theme.spacing(DRAWER_PADDING_UNITS),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));

const StyledAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingLeft: theme.spacing(DRAWER_PADDING_UNITS),
  paddingRight: theme.spacing(DRAWER_PADDING_UNITS),
}));

interface AccordionProps {
  children?: ReactNode;
  id: string;
  header: string;
  subtitle?: string;
  expandedAccordion: string | false;
  handleAccordionChange: (
    id: string,
  ) => (event: SyntheticEvent, isExpanded: boolean) => void;
}

const Accordion = ({
  children,
  id,
  header,
  subtitle,
  expandedAccordion,
  handleAccordionChange,
}: AccordionProps) => {
  const currentAccordionExpanded = expandedAccordion === id;
  return (
    <StyledAccordion
      id={id}
      expanded={currentAccordionExpanded}
      onChange={handleAccordionChange(id)}
      elevation={0}
    >
      <StyledAccordionSummary>
        <Stack direction='column' spacing={0}>
          <Typography
            variant='h6'
            sx={{
              color: 'text.secondary',
              textTransform: 'uppercase',
              fontWeight: 'medium',
            }}
          >
            {header}
          </Typography>
        </Stack>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <Stack direction='column' spacing={2}>
          {subtitle && (
            <Typography variant='body1' color='text.secondary'>
              {subtitle}
            </Typography>
          )}
          {children}
        </Stack>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default EditTableForm;
