import Image from 'next/image';
import { Stack, Typography } from '@mui/material';
import Button from '../shared/Button';
import slackEmpty from '../../public/slack_empty.svg';
import { FormikProps } from 'formik';
import { ScheduleVizFormValues } from './types';

interface ScheduleVizSlackProps {
  formik: FormikProps<ScheduleVizFormValues>;
}
const ScheduleVizSlack = ({ formik }: ScheduleVizSlackProps) => {
  return (
    <Stack spacing={3} alignItems='center' pt={3}>
      {/* this image alt value is used temporarily to place the formik value while this section is empty */}
      <Image src={slackEmpty} alt={formik.values.actionType} />
      <Stack spacing={1} alignItems='center'>
        <Typography variant='h3'>Send via Slack</Typography>
        <Typography variant='body1' color='text.secondary'>
          Set up a recurring Slack post for your most important reports.
        </Typography>
      </Stack>
      <Button variant='outlined' disabled>
        Coming soon
      </Button>
    </Stack>
  );
};

export default ScheduleVizSlack;
