import { useState, MouseEvent, BaseSyntheticEvent } from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/system';
import { darken } from '@mui/material';
import Button from '../Button';

type ColorType = {
  name: string;
  hex: string;
};

export enum AvailableColors {
  LIGHT_GRAY = '#E7E6E5',
  GRAY = '#DFDEDB',
  BROWN = '#EBDBD5',
  ORANGE = '#F9D9C2',
  YELLOW = '#FDE9C1',
  GREEN = '#D6EAD6',
  BLUE = '#CDE1ED',
  PURPLE = '#E5D9EB',
  PINK = '#F3DBE6',
  RED = '#FFDED8',
}

export const colorOptions: ColorType[] = [
  { name: 'Light gray', hex: AvailableColors.LIGHT_GRAY },
  { name: 'Gray', hex: AvailableColors.GRAY },
  { name: 'Brown', hex: AvailableColors.BROWN },
  { name: 'Orange', hex: AvailableColors.ORANGE },
  { name: 'Yellow', hex: AvailableColors.YELLOW },
  { name: 'Green', hex: AvailableColors.GREEN },
  { name: 'Blue', hex: AvailableColors.BLUE },
  { name: 'Purple', hex: AvailableColors.PURPLE },
  { name: 'Pink', hex: AvailableColors.PINK },
  { name: 'Red', hex: AvailableColors.RED },
];

const StyledColorBox = styled(Box)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  marginRight: theme.spacing(1),
}));

export interface ColorSelectorProps {
  color: string;
  setColor: (color: string) => void;
  text?: string;
}

const ColorSelector = ({
  color: selectedColor,
  setColor,
  text,
}: ColorSelectorProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (_: BaseSyntheticEvent, hex: string) => {
    setColor(hex);
    handleClose();
  };

  return (
    <div>
      <Button
        size='small'
        id='color-selector-button'
        aria-controls={open ? 'color-selector-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          color: 'text.primary',
          bgcolor: selectedColor,
          lineHeight: 1,
          '&:hover': {
            bgcolor: darken(selectedColor, 0.05),
          },
        }}
      >
        {text}
      </Button>
      <Menu
        id='color-selector-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {colorOptions.map((color) => {
          return (
            <MenuItem
              key={color.name}
              onClick={(e) => handleSelect(e, color.hex)}
              selected={color.hex === selectedColor}
            >
              <StyledColorBox bgcolor={color.hex} />
              <ListItemText>{color.name}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default ColorSelector;
