import { createSlice } from '@reduxjs/toolkit';
import { UserNotificationType } from '../../generated/types';

export type UserNotificationPreferenceEntry = {
  workspaceId: string;
  featureId: string;
  name?: string;
  schedule?: string;
  optedOut: boolean;
  type: UserNotificationType;
};

export interface UserNotificationPreferencesState {
  userNotificationPreferences: UserNotificationPreferenceEntry[] | null;
}

const initialState: UserNotificationPreferencesState = {
  userNotificationPreferences: null,
};

export const userNotificationPreferencesSlice = createSlice({
  name: 'userNotificationPreferences',
  initialState,
  reducers: {
    setUserNotificationPreferences: (state, action) => {
      state.userNotificationPreferences = action.payload;
    },
    updateUserNotificationPreference: (state, action) => {
      const { featureId, optedOut } = action.payload;
      const found = state.userNotificationPreferences?.find(
        (notification) => notification.featureId === featureId,
      );
      if (found) {
        found.optedOut = !!optedOut;
      }
    },
  },
});

export const {
  setUserNotificationPreferences,
  updateUserNotificationPreference,
} = userNotificationPreferencesSlice.actions;

export default userNotificationPreferencesSlice.reducer;
