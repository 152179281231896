import React, { useContext, useMemo } from 'react';
import { View } from 'react-vega';
import { VisualizationConfigV2 } from '@madeinventive/core-types';
import { DisplayTypes } from './constants';

const VisualizationContext = React.createContext({
  workspaceId: '',
  entityId: '',
  hydratedViz: {} as VisualizationConfigV2,
  vizTitle: '',
  vegaView: null,
  displayType: DisplayTypes.CHAT,
  exploreId: '',
} as Omit<VisualizationProviderProps, 'children'>);

export const useVisualizationContext = () => useContext(VisualizationContext);

interface VisualizationProviderProps {
  workspaceId: string;
  entityId: string; // chatResponseId or componentId
  hydratedViz: VisualizationConfigV2;
  vizTitle: string;
  vegaView: View | null;
  children: React.ReactNode;
  displayType: DisplayTypes;
  wsExploreId?: string;
}

export const VisualizationProvider = ({
  workspaceId,
  entityId,
  hydratedViz,
  vizTitle,
  vegaView,
  children,
  displayType,
  wsExploreId,
}: VisualizationProviderProps) => {
  const value = useMemo(() => {
    return {
      workspaceId,
      entityId,
      hydratedViz,
      vizTitle,
      vegaView,
      displayType,
      wsExploreId,
    };
  }, [
    workspaceId,
    entityId,
    hydratedViz,
    vizTitle,
    vegaView,
    displayType,
    wsExploreId,
  ]);

  return (
    <VisualizationContext.Provider value={value}>
      {children}
    </VisualizationContext.Provider>
  );
};
