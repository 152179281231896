import * as React from 'react';

// components
import DialogHeader from '../../Dialogs/DialogHeader';
import CustomDialog from '../../Dialogs';
import SuccessDialogContent from '../../SuccessDialogContent';
import { DeleteDialogBody, DeleteDialogButtons } from '../ModalToDelete';

interface DeleteTemplateProps {
  open: boolean;
  setOpen: (params: boolean) => void;
  boldTitle: string;
  success: boolean;
  setSuccess: (params: boolean) => void;
  templateID: string;
  deleteTemplate: (params: string) => void;
  fetchTemplates: () => void;
}

const DeleteTemplate = ({
  open,
  setOpen,
  success,
  setSuccess,
  boldTitle,
  templateID,
  deleteTemplate,
  fetchTemplates,
}: DeleteTemplateProps) => {
  const title = 'Are you sure you want to delete the template';
  const subTitle = `Deleting this template won't delete the features created from it, but will delete the template for everyone.`;
  const confirmationTitle = 'Delete template';
  const successMessage = 'was successfully deleted.';
  const successTitle = 'Delete template';

  const closeDialog = () => {
    setOpen(false);
    setSuccess(false);
    fetchTemplates();
  };

  const onCancel = () => {
    setOpen(false);
    setSuccess(false);
  };

  const dialogHeader = () => {
    return (
      <DialogHeader
        title={success ? confirmationTitle : successTitle}
        onClose={closeDialog}
      />
    );
  };

  const dialogContent = success ? (
    <SuccessDialogContent boldText={boldTitle} regularText={successMessage} />
  ) : (
    <DeleteDialogBody
      helperText={title}
      selectedItemName={`${boldTitle}?`}
      subHelperText={subTitle}
    />
  );

  return (
    <CustomDialog
      sx={{
        '& .MuiDialog-paper': {
          width: '600px',
          maxWidth: 'unset',
        },
      }}
      open={open}
      noActionsMargin={true}
      handleClose={closeDialog}
      header={dialogHeader()}
      content={dialogContent}
      actions={
        !success && (
          <DeleteDialogButtons
            deleteOperation={() => deleteTemplate(templateID)}
            setSuccess={setSuccess}
            closeDialog={onCancel}
            deleteButtonName='Yes, delete'
          />
        )
      }
    />
  );
};

export default DeleteTemplate;
