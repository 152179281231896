import React, { ReactNode } from 'react';
import { Stack, Typography, Link } from '@mui/material';
import Image from 'next/image';
import error_boundary from '../../public/error_boundary.svg';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // Log error to an external service
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI
      return (
        <Stack alignItems='center' py={8} spacing={1} width='100%'>
          <Image src={error_boundary} alt='error' width={80} height={80} />
          <Typography
            variant='h3'
            color='text.tertiary'
            textAlign='center'
            maxWidth={320}
          >
            Something went wrong
          </Typography>
          <Typography
            variant='body1'
            color='text.tertiary'
            textAlign='center'
            maxWidth={320}
          >
            Please refresh the page or{' '}
            <Link href={`mailto:support@madeinventive.com`} color='inherit'>
              contact support
            </Link>{' '}
            if the problem persists.
          </Typography>
        </Stack>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
