import {
  Grid,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from '@mui/material';

import { useCallback, useState } from 'react';
import {
  NormalizedQueryConditionInput,
  NormalizedQueryInput,
  NormalizedQueryResultRow,
  useWorkspaceQueryDataLazyQuery,
} from '../../generated/types';
import { useAppSelector, parseForActionDynamicInput } from '../../hooks';
import Button from '../shared/Button';
import Spinner from '../shared/Spinner';
import { convertValidConditionExpressions } from '../../store/slices/features';

interface DataPreviewProp {
  selectedExploreId: string | undefined;
  workspaceId: string;
  ctaText: string;
  title: string;
  includeActionFields?: boolean;
  isDisabled: boolean;
}

const MAX_DATA_TO_DISPLAY = 50;
const DATA_PREVIEW_FETCH_LIMIT = 201;
const LEFT_OVER_DATA_THRESHOLD =
  DATA_PREVIEW_FETCH_LIMIT - MAX_DATA_TO_DISPLAY - 1;

const DataPreview = ({
  selectedExploreId, // this is an environment expolore id
  workspaceId,
  ctaText,
  title,
  includeActionFields,
  isDisabled,
}: DataPreviewProp) => {
  const [loading, setLoading] = useState(false);
  const [noRowsMessage, setNoRowsMessage] = useState('');

  const [previewData, setPreviewData] = useState<Record<string, string>[]>([]);

  const [fetchPreviewData] = useWorkspaceQueryDataLazyQuery();

  const featureEditData = useAppSelector(
    (store) => store.featureEditData.value,
  );

  const formatStr = useCallback(
    (str: string) => str.charAt(0).toUpperCase() + str.substr(1).toLowerCase(),
    [],
  );

  const totalDataLeft = previewData.length - MAX_DATA_TO_DISPLAY;

  const fetchDataPreview = useCallback(async () => {
    setNoRowsMessage('');
    if (!selectedExploreId) return;

    setLoading(true);

    const preview: Record<string, string>[] = [];
    try {
      const conditionInputs: NormalizedQueryConditionInput[] =
        convertValidConditionExpressions(featureEditData.conditions);

      const queryParams: NormalizedQueryInput = {
        conditions: conditionInputs,
        extraFields: includeActionFields
          ? parseForActionDynamicInput(featureEditData) || []
          : [],
        limit: DATA_PREVIEW_FETCH_LIMIT,
      };

      const res = await fetchPreviewData({
        variables: {
          workspaceId,
          envExploreId: selectedExploreId,
          queryParams,
        },
      });

      if (res?.data?.node?.__typename !== 'Workspace') return;
      res?.data.node.queryData.rows.forEach((row: NormalizedQueryResultRow) => {
        preview.push(
          row.fields?.reduce((acc: Record<string, string>, field) => {
            if (field.name) {
              acc[field.name ?? ''] = field.value ?? '';
            }
            return acc;
          }, {}),
        );
      });
    } finally {
      setLoading(false);
    }

    if (!preview.length) {
      setNoRowsMessage(
        'There are currently no rows associated with these conditions',
      );
    }

    setPreviewData(preview);
    setLoading(false);
  }, [
    workspaceId,
    fetchPreviewData,
    selectedExploreId,
    includeActionFields,
    featureEditData,
  ]);

  const renderTableHeader = (data: Record<string, string>[]) => {
    const header = Object.keys(data[0]);
    return header.map((tableHeader, index) => {
      const tableHeaderComponents = tableHeader.split('.');
      const computedFieldName = formatStr(
        tableHeaderComponents[tableHeaderComponents.length - 1].replace(
          /_/g,
          ' ',
        ),
      );

      const computedTableName = formatStr(
        tableHeaderComponents?.[0]?.replace(/_/g, ' '),
      );

      return (
        <TableCell key={tableHeader + index} align='left'>
          <Typography sx={{ fontWeight: 900, display: 'inline-block' }}>
            {computedFieldName}
          </Typography>{' '}
          <Typography sx={{ display: 'inline-block' }}>in</Typography>{' '}
          <Typography sx={{ fontStyle: 'italic', display: 'inline-block' }}>
            {computedTableName}
          </Typography>
        </TableCell>
      );
    });
  };

  function renderTableRows(data: Record<string, string>[]) {
    const keys = Object.keys(data[0]);
    const table = data.map((preview, rowIndex) => {
      return (
        <TableRow
          key={rowIndex}
          sx={{
            border: (theme) => `1px solid ${theme.palette.border.actionable}`,
          }}
        >
          {keys.map((key, colIndex) => (
            <TableCell key={key + colIndex}>{preview[key]}</TableCell>
          ))}
        </TableRow>
      );
    });
    return table;
  }

  const generateTypography = () => {
    if (totalDataLeft < 1) return '';
    if (totalDataLeft > LEFT_OVER_DATA_THRESHOLD) {
      return (
        <Typography color='text.secondary'>
          There are {DATA_PREVIEW_FETCH_LIMIT - 1}+ rows in total.
        </Typography>
      );
    } else {
      return (
        <Typography color='text.secondary'>
          + {totalDataLeft} more rows.
        </Typography>
      );
    }
  };
  return (
    <Grid mb={5}>
      <Grid display='flex' justifyContent='flex-end'>
        <Button
          variant='contained'
          disabled={isDisabled || loading}
          onClick={fetchDataPreview}
        >
          {ctaText}
        </Button>
      </Grid>
      {loading && (
        <Box mt={7}>
          <Spinner />
        </Box>
      )}
      {!loading && (
        <Grid>
          {previewData.length > 0 ? (
            <Grid>
              {' '}
              <Typography sx={{ fontSize: 21 }}>{title}</Typography>
              <Typography color='text.secondary'>
                Below are a sample of the current data results. Adjust the
                conditions as needed if these results don&apos;t look right
              </Typography>
              <TableContainer
                component={Paper}
                style={{
                  maxHeight: 400,
                  maxWidth: 750,
                  marginTop: 30,
                  marginBottom: 30,
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>{renderTableHeader(previewData)}</TableRow>
                  </TableHead>
                  <TableBody>
                    {renderTableRows(previewData.slice(0, MAX_DATA_TO_DISPLAY))}
                  </TableBody>
                </Table>
              </TableContainer>
              {generateTypography()}
              <Typography color='text.secondary'>
                {' '}
                This count does not consider placeholder conditions.
              </Typography>
            </Grid>
          ) : (
            <Box mt={5}>
              <Typography>{noRowsMessage}</Typography>
            </Box>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default DataPreview;
