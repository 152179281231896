import { ApolloLink } from '@apollo/client';
import { getOperationLogging } from '../localStorage';

export const logLink = new ApolloLink((operation, forward) => {
  if (getOperationLogging()) {
    console.log(
      `[GQL Operation]: operation=${
        operation.operationName
      }, variables=${JSON.stringify(operation.variables)}`,
    );
  }
  return forward(operation);
});
