import { useCallback } from 'react';
import { Element, Transforms } from 'slate';
import { RenderElementProps, useSlate, ReactEditor } from 'slate-react';
import Chip from '../shared/Chip';
import { isFieldInfo } from '../../store/slices/exploreExtracts';
import { CustomElement, ElementType, FieldElement } from './types';

const SlateElement = ({
  attributes,
  children,
  element,
}: RenderElementProps) => {
  const editor = useSlate();
  const deleteField = useCallback(
    (element: CustomElement) => {
      const path = ReactEditor.findPath(editor, element);
      Transforms.removeNodes(editor, { at: path });
    },
    [editor],
  );

  if (Element.isElementType(element, ElementType.FIELD)) {
    const verifiedElement = element as FieldElement;
    // display field chip only when the field data is valid
    const validFieldMetaData =
      verifiedElement.field && isFieldInfo(verifiedElement.field.metaData)
        ? verifiedElement.field.metaData
        : null;
    if (!validFieldMetaData) {
      return <p {...attributes}>{children}</p>;
    }
    return (
      // span allows the Chip component to be treated as an inline elements
      // and takes the slate required attributes such as data-slate-node, data-slate-inline
      <span {...attributes} contentEditable={false}>
        <Chip
          label={validFieldMetaData.label}
          size='small'
          onDelete={() => deleteField(element)}
        />
        {/* Slate requires all custom elements to render their children */}
        {children}
      </span>
    );
  }

  return <p {...attributes}>{children}</p>;
};

export default SlateElement;
