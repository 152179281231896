const getWsUri = (url: string): string => {
  if (url.startsWith('http://')) {
    return url.replace('http://', 'ws://');
  } else if (url.startsWith('https://')) {
    return url.replace('https://', 'wss://');
  } else {
    return url; // Return the original URL if it doesn't start with 'http://' or 'https://'
  }
};

export const API_URI =
  process.env.NEXT_PUBLIC_API_ENDPOINT || 'http://localhost:4000/graphql';

export const WS_URI = getWsUri(API_URI);
