export type RecurrenceType = 'Daily' | 'Weekly' | 'Monthly' | 'Conditional';

export enum DaysOfWeek {
  Sun = 'SUN',
  Mon = 'MON',
  Tue = 'TUE',
  Wed = 'WED',
  Thu = 'THU',
  Fri = 'FRI',
  Sat = 'SAT',
}

export const daysOfWeek = [
  { label: 'Sun', value: DaysOfWeek.Sun },
  { label: 'Mon', value: DaysOfWeek.Mon },
  { label: 'Tue', value: DaysOfWeek.Tue },
  { label: 'Wed', value: DaysOfWeek.Wed },
  { label: 'Thu', value: DaysOfWeek.Thu },
  { label: 'Fri', value: DaysOfWeek.Fri },
  { label: 'Sat', value: DaysOfWeek.Sat },
];

export enum DaysOfMonth {
  First = '1',
  Fifteenth = '15',
  Last = 'L',
}

export const daysOfMonth = [
  { label: 'Day 1', value: DaysOfMonth.First },
  { label: 'Day 15', value: DaysOfMonth.Fifteenth },
  { label: 'Last day', value: DaysOfMonth.Last },
];

export interface ScheduleState {
  recurrence: RecurrenceType;
  min?: string;
  hour?: string;
  daysOfWeek?: DaysOfWeek[]; // Only relevant when `recurrence` is 'Weekly'
  daysOfMonth?: DaysOfMonth[]; // Only relevant when `recurrence` is 'Monthly'
}

// Patterns: * * * * * *
// first *: (optional) second (0 - 59) allowed special characters: * , - / ?
// second *: minute (0 - 59) allowed special characters: * , - / ?
// third *: hour (0 - 23) allowed special characters: * , - / ?
// fourth *: day of month (1 - 31) allowed special characters: * , - / ? L
// fifth *: month (1 - 12, JAN-DEC) allowed special characters: * , - / ?
// sixth *: day of week (0 - 6, SUN-Mon) (0 to 6 are Sunday to Saturday; 7 is Sunday, the same as 0) allowed special characters: * , - / ? L #

// ?: The question mark is substituted with the time of initialization. For example, ? ? * * * * would be substituted with 25 8 * * * * if the time is :08:25 at the time of new Cron('? ? * * * *', <...>). The question mark can be used in any field.
// L: The letter 'L' can be used in the day of the month field to indicate the last day of the month. When used in the day of the week field in conjunction with the # character, it denotes the last specific weekday of the month. For example, 5#L represents the last Friday of the month.
// #: The # character specifies the "nth" occurrence of a particular day within a month. For example, supplying 5#2 in the day of week field signifies the second Friday of the month. This can be combined with ranges and supports day names. For instance, MON-FRI#2 would match the Monday through Friday of the second week of the month.

export function parseCronToState(
  cron: string | undefined,
): ScheduleState | undefined {
  if (!cron) {
    return undefined;
  }
  const parts = cron.split(' ');

  if (parts.length !== 6) {
    return undefined;
  }

  const [, min, hour, dayOfMonth, , dayOfWeek] = parts;
  let recurrence: RecurrenceType;

  if (dayOfWeek !== '*') {
    recurrence = 'Weekly';
  } else if (dayOfMonth !== '*') {
    recurrence = 'Monthly';
  } else {
    recurrence = 'Daily';
  }

  const state: ScheduleState = {
    recurrence,
    min,
    hour,
  };

  if (recurrence === 'Weekly') {
    state.daysOfWeek = dayOfWeek.split(',').map((day) => {
      return day as DaysOfWeek;
    });
  }

  if (recurrence === 'Monthly') {
    state.daysOfMonth = dayOfMonth.split(',').map((day) => day as DaysOfMonth);
  }

  if (recurrence === 'Daily') {
    state.daysOfWeek = [
      DaysOfWeek.Sun,
      DaysOfWeek.Mon,
      DaysOfWeek.Tue,
      DaysOfWeek.Wed,
      DaysOfWeek.Thu,
      DaysOfWeek.Fri,
      DaysOfWeek.Sat,
    ];
  }

  return state;
}

export function validateCronState(state?: ScheduleState): boolean {
  if (!state) {
    return false;
  }

  if (!state.min || !state.hour) {
    return false;
  }

  if (state.recurrence === 'Weekly' && !state.daysOfWeek) {
    return false;
  }

  if (state.recurrence === 'Monthly' && !state.daysOfMonth) {
    return false;
  }

  return true;
}

export function generateCronFromState(state?: ScheduleState): string {
  if (!state || !validateCronState(state)) {
    return '';
  }
  const second = '0';
  const minute = state.min;
  const hour = state.hour;
  const dayOfMonth = state.daysOfMonth?.join(',') ?? '*';
  const month = '*';
  const dayOfWeek =
    state.daysOfWeek?.length === 7 || !state.daysOfWeek
      ? '*'
      : state.daysOfWeek?.join(',');

  const cron = `${second} ${minute} ${hour} ${dayOfMonth} ${month} ${dayOfWeek}`;
  return cron;
}

export function numberToTimeString(hour: number): string {
  if (hour < 0 || hour > 23) {
    throw new Error('Invalid hour. Must be between 0 and 23.');
  }

  const isPM = hour >= 12;
  const convertedHour = hour % 12 === 0 ? 12 : hour % 12;

  return `${convertedHour} ${isPM ? 'PM' : 'AM'}`;
}

export const convertDaysToText = (
  days?: DaysOfMonth[] | DaysOfWeek[],
): string => {
  if (!days) return 'Not defined';
  return days
    .map((day) => {
      const found =
        daysOfMonth.find((d) => d.value === day) ||
        daysOfWeek.find((d) => d.value === day);
      return found ? found.label : '';
    })
    .join(', ');
};
