import { useFormikContext } from 'formik';
import { FormLabel, Stack, StackProps, Typography } from '@mui/material';

// components
import TextField from '../../shared/Inputs/TextField';
import Button from '../../shared/Button';

// types
import { SummaryFormValues } from './types';
import { GLOBAL_CONTENT_WIDTH } from '../../Layout/constants';
import Dot from '../../shared/Dot';

const GrayCard = (props: StackProps) => (
  <Stack
    {...props}
    width='100%'
    padding={4}
    spacing={1}
    borderRadius={1.5}
    bgcolor='background.default'
  />
);

const PreviewCard = (props: StackProps) => (
  <Stack
    {...props}
    width='100%'
    direction='row'
    alignItems='center'
    padding={2}
    spacing={2}
    borderRadius={1.5}
    bgcolor='background.paper'
  />
);

const SummaryForm = () => {
  const { values, setFieldValue, errors } =
    useFormikContext<SummaryFormValues>();

  return (
    <Stack
      id='summary-step'
      width='100%'
      maxWidth={GLOBAL_CONTENT_WIDTH}
      height='100%'
      spacing={4}
    >
      <GrayCard>
        <FormLabel>Preview</FormLabel>
        <Typography variant='body2' color='text.secondary'>
          Style how your Stack appears on the workspace homepage. ## will
          automatically update to show the number of insights waiting for
          review.
        </Typography>
        <PreviewCard>
          <Dot type='error' />
          <Stack flex={1}>
            <Typography variant='body1' color='text.secondary'>
              {values.stackTitle || 'title'}
            </Typography>
            <Typography variant='h2' color='text.primary'>
              ##
            </Typography>
          </Stack>
          <Button>{values.stackButtonText || 'Button text'}</Button>
        </PreviewCard>
      </GrayCard>
      <TextField
        label='Title'
        subLabel='Describe your stack. Max 50 characters.'
        value={values.stackTitle}
        onChange={(e) => setFieldValue('stackTitle', e.target.value)}
        errorMessage={errors.stackTitle}
      />
      <TextField
        label='Button text'
        subLabel='Should be clear, concise, and action-oriented.'
        value={values.stackButtonText}
        onChange={(e) => setFieldValue('stackButtonText', e.target.value)}
        errorMessage={errors.stackButtonText}
      />
    </Stack>
  );
};

export default SummaryForm;
