import {
  FeatureConfig,
  WorkflowConfigConditionAndActionV2,
  WorkflowConfigScheduleAndActionV1,
  ensureType,
  isValid,
  validators,
} from '@madeinventive/core-types';
import { EditDataAdapter } from './EditDataAdapter';
import { FeatureEditDataState } from '../../../store/slices/features/featureEditData';
import { CustomBrandingState } from '../../../store/slices/session';
import { generateActionJSON, parseAction } from './helpers';
import { FeatureType } from '../../../generated/types';

// serialization
const generateConditionJSON = (
  data: FeatureEditDataState,
): WorkflowConfigConditionAndActionV2['condition'] => {
  if (!data.exploreId) {
    throw new Error('Missing exploreId');
  }
  return {
    kind: 'NormalizedCondition/v1.0',
    spec: {
      environmentExploreId: data.exploreId,
      expressions: data.conditions,
    },
  };
};

class WorkflowDataAdapter implements EditDataAdapter {
  parseFromConfig(
    config: object,
  ): Omit<
    FeatureEditDataState,
    | 'id'
    | 'name'
    | 'description'
    | 'mode'
    | 'placeholderLookup'
    | 'hasUnsavedChanges'
  > {
    const typedConfig = ensureType<FeatureConfig>(
      validators.FeatureConfig,
      config,
    );
    if (
      typedConfig.kind === 'ConditionAndAction/v2.0' &&
      isValid<WorkflowConfigConditionAndActionV2>(
        validators.WorkflowConfigConditionAndActionV2,
        typedConfig.spec,
      )
    ) {
      return {
        featureType: FeatureType.WORKFLOW,
        actionTriggerType: 'condition',
        exploreId: typedConfig.spec.condition.spec.environmentExploreId,
        conditions: typedConfig.spec.condition.spec.expressions,
        extraFields: typedConfig.spec.extraFields,
        actions: parseAction(typedConfig.spec.action),
        fieldData: [],
      };
    }

    if (
      typedConfig.kind === 'ScheduleAndAction/v1.0' &&
      isValid<WorkflowConfigScheduleAndActionV1>(
        validators.WorkflowConfigScheduleAndActionV1,
        typedConfig.spec,
      )
    ) {
      return {
        featureType: FeatureType.WORKFLOW,
        actionTriggerType: 'schedule',
        cronSchedule: typedConfig.spec.cronSchedule,
        actions: parseAction(typedConfig.spec.actions),
        fieldData: [],
        conditions: [],
      };
    }
    throw Error(`Unknown feature config kind: ${typedConfig.kind}`);
  }

  serializeToConfig(
    data: FeatureEditDataState,
    customBranding?: CustomBrandingState,
  ): object {
    const isSchedule = data.actionTriggerType === 'schedule';

    if (isSchedule) {
      return {
        kind: 'ScheduleAndAction/v1.0',
        spec: {
          cronSchedule: data.cronSchedule,
          actions: generateActionJSON(data.actions, customBranding),
        },
      };
    }
    return {
      kind: 'ConditionAndAction/v2.0',
      spec: {
        condition: generateConditionJSON(data),
        action: generateActionJSON(data.actions, customBranding),
      },
    };
  }
}
const workflowDataAdapter = new WorkflowDataAdapter();
export default workflowDataAdapter;
