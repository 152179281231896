import { forwardRef } from 'react';
import { Typography, TypographyProps } from '@mui/material';

interface TruncatedTypographyProps extends TypographyProps {
  truncatedWidth?: string | number;
}

const TruncatedTypography = forwardRef<
  HTMLSpanElement,
  TruncatedTypographyProps
>((props, ref) => {
  const { truncatedWidth = undefined, ...otherProps } = props;
  return (
    <Typography
      {...otherProps}
      ref={ref}
      whiteSpace='nowrap'
      overflow='hidden'
      textOverflow='ellipsis'
      maxWidth={truncatedWidth}
    />
  );
});

TruncatedTypography.displayName = 'TruncatedTypography';

export default TruncatedTypography;
