import { useCallback, useMemo, useState } from 'react';
import { BaseTagData } from '@yaireo/tagify';
import {
  DynamicFieldV1MetaData,
  FieldType,
  isValid,
  PlaceholderV1MetaData,
  TagMetaData,
  validators,
} from '@madeinventive/core-types';

import RichTagsInput, {
  BaseHandlerArgs,
  LaunchKeyHandler,
  RichTagsInputProps,
  TagClickHandler,
} from '../RichTagsInput';
import VFPInputField from '../VFPInputField';
import { OnSelectHandler } from '../VFPInputField/SchemaViewer';

import { PlaceholderLocation, usePlaceholder, usePopupMenu } from '../../hooks';
import { MenuItemOption } from '../MenuButton';

const PICKER_LAUNCH_KEY = '{';
const launchKeys = new Set<string>();
launchKeys.add(PICKER_LAUNCH_KEY);

// 'value' from BaseTagData holds the display text for the tag
// ExtendedTagData accounts for other optional Tagify fields
export type ExtendedTagData = BaseTagData & {
  title?: string; // what people see in tooltip
  prefix?: string; // launch key that triggered the creation of the tag
};

// fields that our BE are interested in
export interface FieldTagData extends ExtendedTagData {
  type: FieldType;
  metaData: TagMetaData;
}

export interface DynamicFieldInputProps {
  disabled?: boolean;
  extractId?: string;
  initialValue: string;
  className?: RichTagsInputProps['className'];
  InputMode?: RichTagsInputProps['InputMode'];
  mixed?: boolean;
  readonly?: boolean;
  onValueChange?: RichTagsInputProps['onValueChange'];
  placeholder?: string;
  disableDynamicField?: boolean;
}

const DynamicFieldInput = (props: DynamicFieldInputProps) => {
  const {
    disabled,
    className,
    extractId,
    initialValue,
    InputMode,
    onValueChange,
    readonly,
    placeholder,
    disableDynamicField,
  } = props;

  const [launchPicker, setLaunchPicker] = useState<boolean>(false);
  const [popupContext, setPopupContext] = useState<
    BaseHandlerArgs<FieldTagData> | undefined
  >(undefined);
  const [tagMetaData, setTagMetaData] = useState<TagMetaData | undefined>(
    undefined,
  );

  const {
    isPlaceholder,
    placeholderOpen,
    setPlaceholderOpen,
    renderPlaceholderPopper,
  } = usePlaceholder(PlaceholderLocation.ACTION);

  const openVisualFieldPicker = useCallback(() => {
    setLaunchPicker(true);
    // we don't keep the closed state from the VFPInputField
    // We should set this local state to false so setting it to true can reopen the picker
    setTimeout(() => {
      setLaunchPicker(false);
    }, 100);
  }, []);

  const cachedValue = useMemo(
    () => initialValue,
    // the dependency on 'placeholderOpen' is intentional:
    // we want to keep the value stable while placeholder is open
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [placeholderOpen, initialValue],
  );

  const tagMenuOptions: MenuItemOption[] = useMemo(() => {
    const menuOptions: MenuItemOption[] = [
      {
        label: isPlaceholder(tagMetaData) ? 'Resolve' : 'Edit',
        onClick: openVisualFieldPicker,
      },
    ];
    if (popupContext?.removeTag) {
      menuOptions.push({ label: 'Delete', onClick: popupContext.removeTag });
    }
    return menuOptions;
  }, [
    tagMetaData,
    popupContext?.removeTag,
    isPlaceholder,
    openVisualFieldPicker,
  ]);

  const {
    open: tagMenuOpen,
    renderMenu,
    openMenuFor,
  } = usePopupMenu({
    hostAriaLabel: 'mixed-tag',
    menuItemOptions: tagMenuOptions,
  });

  const closePicker = useCallback(() => setLaunchPicker(false), []);

  const onTagClicked: TagClickHandler<FieldTagData> = useCallback(
    ({ anchorEl, hostEl, tag, tagData, removeTag, onNewTagData }) => {
      setPopupContext({
        anchorEl,
        hostEl,
        onNewTagData,
        removeTag,
      });
      setTagMetaData(tagData.metaData);

      if (isPlaceholder(tagData.metaData)) {
        setPlaceholderOpen(true);
      } else {
        openMenuFor(tag);
      }
    },
    [isPlaceholder, setPlaceholderOpen, openMenuFor],
  );

  const onKeydown: RichTagsInputProps<FieldTagData>['onKeydown'] = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any): void => {
      if (event.detail.originalEvent.key === 'Escape') {
        closePicker();
      }
    },
    [closePicker],
  );

  const onLaunchKeyActivated: LaunchKeyHandler<FieldTagData> = useCallback(
    ({ key, anchorEl, hostEl, onNewTagData }) => {
      if (key === PICKER_LAUNCH_KEY && !launchPicker) {
        setPopupContext({
          anchorEl,
          hostEl,
          onNewTagData,
        });
        setTagMetaData(undefined);
        openVisualFieldPicker();
      }
    },
    [launchPicker, openVisualFieldPicker],
  );

  const onSelect: OnSelectHandler = useCallback(
    (type, meta) => {
      if (!popupContext) return;

      if (
        isValid<DynamicFieldV1MetaData>(validators.DynamicFieldV1MetaData, meta)
      ) {
        popupContext.onNewTagData({
          value: meta.field,
          type,
          metaData: meta,
        });
      } else if (
        isValid<PlaceholderV1MetaData>(validators.PlaceholderV1MetaData, meta)
      ) {
        popupContext.onNewTagData({
          value: meta.name,
          type,
          metaData: meta,
        });
      }
    },
    [popupContext],
  );

  const selectedField = useMemo(() => {
    if (
      isValid<DynamicFieldV1MetaData>(
        validators.DynamicFieldV1MetaData,
        tagMetaData,
      )
    ) {
      return tagMetaData.field;
    }
  }, [tagMetaData]);

  // Tagify is NOT a controlled component:
  //   https://github.com/yairEO/tagify/issues/489#issuecomment-628390621
  //
  // Meaning that blindly using it as one, such as supplying a different
  // 'value` prop after the initial render can seriously mess up it's
  // internal state and event firing too - a lesson we learned the hard way.
  //
  // Try not to repeat the mistake. Use the 'defaultValue' prop instead!
  return (
    <>
      <RichTagsInput<FieldTagData>
        disabled={disabled}
        defaultValue={placeholderOpen ? cachedValue : initialValue}
        placeholder={placeholder}
        onValueChange={onValueChange}
        onKeydown={onKeydown}
        onTagClicked={onTagClicked}
        settings={{
          mode: props.mixed ? 'mix' : undefined,
          readonly,
        }}
        InputMode={InputMode}
        className={className}
        launchKeys={launchKeys}
        onLaunchKeyActivated={onLaunchKeyActivated}
      />
      {!readonly &&
        !disabled &&
        !!extractId &&
        !disableDynamicField &&
        popupContext && (
          <VFPInputField
            useInputInSchemaViewer={true}
            extractId={extractId}
            hostEl={popupContext.hostEl}
            anchorEl={popupContext.anchorEl}
            handleSelect={onSelect}
            selectedFields={selectedField ? [selectedField] : []}
            forceOpen={launchPicker}
            placeholderAllowed={true}
          />
        )}
      {!readonly &&
        !disableDynamicField &&
        renderPlaceholderPopper(
          popupContext?.anchorEl,
          tagMetaData,
          tagMenuOptions,
        )}
      {!readonly && tagMenuOpen && renderMenu()}
    </>
  );
};

export default DynamicFieldInput;
