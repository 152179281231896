import { GridColDef } from '@mui/x-data-grid';

export const DEFAULT_ACTION_INDEX = 0;
export const DETAIL_VIEW_WIDTH = 400;
export const FLOATING_BUTTON_SPACE = 24;
export const FAKE_COLUMN_PROPERTIES: Partial<GridColDef> = {
  filterable: false,
  hideable: false,
  sortable: false,
  disableColumnMenu: true,
};
