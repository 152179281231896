import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';

import {
  CreateStackFromDataComponentInput,
  useCreateStackFromDataComponentMutation,
  Component,
  useEnvironmentComponentsLazyQuery,
  ComponentType,
  useEditComponentMutation,
  EditComponentInput,
  useDeleteComponentMutation,
} from '../generated/types';
import { useToast, useDialogV2, useAppDispatch } from '.';
import { DIALOG_IDS } from '../components/registeredDialogs/dialogRegistry';
import {
  addEnvComponentByType as addComponentAction,
  setEnvComponentsByType as setComponentsAction,
  updateEnvComponentByType as updateComponentAction,
  removeEnvComponentByType as removeComponentAction,
} from '../store/slices/environmentComponents';

export const useStack = (environmentId?: string) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { showErrorToast, showDefaultToast } = useToast();
  const { showDialog } = useDialogV2();

  const [createStackFromDataComponent, { loading: createStackLoading }] =
    useCreateStackFromDataComponentMutation();

  const [editStackComponent, { loading: editStackLoading }] =
    useEditComponentMutation();

  const [deleteStackComponent, { loading: deleteStackLoading }] =
    useDeleteComponentMutation();

  const [
    getEnvironmentComponents,
    { data: environmentComponents, loading: stacksLoading },
  ] = useEnvironmentComponentsLazyQuery();

  useEffect(() => {
    if (
      environmentComponents?.node?.__typename === 'Environment' &&
      environmentId
    ) {
      const stacks = environmentComponents.node.components.edges
        .map((edge) => edge.node as Component)
        .filter((component) => component.type === ComponentType.STACK);
      dispatch(
        setComponentsAction({
          environmentId,
          componentType: ComponentType.STACK,
          components: stacks,
        }),
      );
    }
  }, [environmentComponents, environmentId, dispatch]);

  const loadStackComponents = useCallback(
    async (environmentId: string) => {
      await getEnvironmentComponents({
        variables: { environmentId, params: { type: ComponentType.STACK } },
      });
    },
    [getEnvironmentComponents],
  );

  const showCreateStackDialog = useCallback(
    (dataComponentId: string) => {
      if (!environmentId) {
        return;
      }
      showDialog({
        id: DIALOG_IDS.CREATE_STACK,
        title: 'Create stack',
        fullDisplay: true,
        contentProps: {
          environmentId,
          dataComponentId,
        },
      });
    },
    [environmentId, showDialog],
  );

  const createStack = useCallback(
    async (input: CreateStackFromDataComponentInput) => {
      const res = await createStackFromDataComponent({
        variables: {
          input,
        },
      });

      if (res.data?.createStackFromDataComponent) {
        const component = res.data.createStackFromDataComponent.component;
        if (component && environmentId) {
          dispatch(
            addComponentAction({
              environmentId,
              componentType: ComponentType.STACK,
              component: component as Component,
            }),
          );
          showDefaultToast('Stack created.', 'View', () => {
            router.push(`/create/stacks`);
          });
        } else {
          showErrorToast('Stack not created. Please retry.');
        }
      } else {
        showErrorToast('Stack not created. Please retry.');
      }
    },
    [
      createStackFromDataComponent,
      dispatch,
      environmentId,
      router,
      showDefaultToast,
      showErrorToast,
    ],
  );

  const editStack = useCallback(
    async (input: EditComponentInput) => {
      const res = await editStackComponent({
        variables: { input },
      });

      if (res.data?.editComponent) {
        const component = res.data.editComponent.component as Component;
        if (component && environmentId) {
          dispatch(
            updateComponentAction({
              environmentId,
              componentType: ComponentType.STACK,
              component,
            }),
          );
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    [editStackComponent, dispatch, environmentId],
  );

  const deleteStack = useCallback(
    async (id: string) => {
      const res = await deleteStackComponent({
        variables: { input: { componentId: id } },
      });

      if (res.data?.deleteComponent.success && environmentId) {
        dispatch(
          removeComponentAction({
            environmentId,
            componentType: ComponentType.STACK,
            componentId: id,
          }),
        );
        return true;
      } else {
        showErrorToast('Stack not deleted. Please retry.');
        return false;
      }
    },
    [deleteStackComponent, dispatch, environmentId, showErrorToast],
  );

  return {
    createStack,
    createStackLoading,
    editStack,
    editStackLoading,
    showCreateStackDialog,
    deleteStack,
    deleteStackLoading,
    loadStackComponents,
    stacksLoading,
  };
};
