import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useCallback,
  useRef,
} from 'react';
import isEqual from 'lodash/isEqual';
import Drawer, {
  DrawerProps,
  FormikDrawerConfig,
  NonFormikDrawerConfig,
} from '../components/shared/Drawer';
import { DRAWER_IDS } from '../components/registeredDrawers/drawerRegistry';

type Action<ID extends DRAWER_IDS> =
  | { type: 'SHOW_DRAWER'; drawerConfig: DrawerProps<ID>['drawerConfig'] }
  | {
      type: 'UPDATE_DRAWER_CONTENT';
      contentProps: DrawerProps<ID>['drawerConfig']['contentProps'];
    }
  | { type: 'UPDATE_DRAWER_TITLE'; title: string }
  | { type: 'HIDE_DRAWER' };

interface DrawerContextStore<ID extends DRAWER_IDS> {
  drawerConfig: FormikDrawerConfig<ID> | NonFormikDrawerConfig<ID>;
  dispatch: Dispatch<Action<ID>>;
  isDrawerOpen: boolean;
}

const initialDrawerConfig = {
  id: DRAWER_IDS.NO_DRAWER,
  title: '',
  isFormikDrawer: false,
  contentProps: {},
  instanceId: Symbol(),
} as NonFormikDrawerConfig<DRAWER_IDS>;

const initialState: DrawerContextStore<DRAWER_IDS> = {
  drawerConfig: initialDrawerConfig,
  dispatch: () => {
    /* intentionally empty */
  },
  isDrawerOpen: false,
};

export const DrawerContext = createContext(initialState);

type UseDrawerProps<ID extends DRAWER_IDS> =
  | Omit<FormikDrawerConfig<ID>, 'instanceId'>
  | Omit<NonFormikDrawerConfig<ID>, 'instanceId'>;

export const useDrawer = <ID extends DRAWER_IDS>(props: UseDrawerProps<ID>) => {
  const { id, title, isFormikDrawer, contentProps } = props;
  const { drawerConfig: activeDrawerConfig, dispatch } =
    useContext(DrawerContext);

  // creates a unique id for each drawer instance
  const instanceId = useRef(Symbol());

  const showDrawer = useCallback(() => {
    if (isFormikDrawer && props.formikProps) {
      const formikDrawerConfig = {
        id,
        title,
        contentProps,
        isFormikDrawer,
        formikProps: props.formikProps,
        instanceId: instanceId.current,
      } as FormikDrawerConfig<ID>;

      dispatch({
        type: 'SHOW_DRAWER',
        drawerConfig: formikDrawerConfig,
      });
    } else {
      const nonFormikDrawerConfig = {
        id,
        title,
        contentProps,
        isFormikDrawer,
        formikProps: null,
        instanceId: instanceId.current,
      } as NonFormikDrawerConfig<ID>;

      dispatch({
        type: 'SHOW_DRAWER',
        drawerConfig: nonFormikDrawerConfig,
      });
    }
  }, [isFormikDrawer, props, id, title, contentProps, dispatch]);

  // Update the drawer if contentProps changes
  useEffect(() => {
    // only if the current active drawer is not the empty drawer
    // and the side effect is not triggered by the same drawer
    if (
      (id === DRAWER_IDS.NO_DRAWER && id !== activeDrawerConfig?.id) ||
      instanceId.current !== activeDrawerConfig?.instanceId
    )
      return;

    if (!isEqual(contentProps, activeDrawerConfig.contentProps)) {
      dispatch({
        type: 'UPDATE_DRAWER_CONTENT',
        contentProps,
      });
    }
  }, [contentProps, dispatch, id, activeDrawerConfig]);

  // Update the drawer if title changes
  useEffect(() => {
    // only if the current active drawer is not the empty drawer
    // and the side effect is not triggered by the same drawer
    if (
      (id === DRAWER_IDS.NO_DRAWER && id !== activeDrawerConfig?.id) ||
      instanceId.current !== activeDrawerConfig?.instanceId
    )
      return;

    if (!isEqual(title, activeDrawerConfig.title)) {
      dispatch({
        type: 'UPDATE_DRAWER_TITLE',
        title,
      });
    }
  }, [title, dispatch, id, activeDrawerConfig]);

  return { showDrawer };
};

const drawerReducer = <ID extends DRAWER_IDS>(
  drawerConfig: DrawerProps<ID>['drawerConfig'],
  action: Action<ID>,
) => {
  switch (action.type) {
    case 'SHOW_DRAWER': {
      const { drawerConfig } = action;
      return drawerConfig;
    }
    case 'UPDATE_DRAWER_CONTENT': {
      const { contentProps } = action;
      return {
        ...drawerConfig,
        contentProps,
      };
    }
    case 'UPDATE_DRAWER_TITLE': {
      const { title } = action;
      return {
        ...drawerConfig,
        title,
      };
    }
    case 'HIDE_DRAWER':
      return initialState.drawerConfig;
    default:
      throw new Error(`Provided action type ${action} is not valid.`);
  }
};

interface DrawerProviderProps {
  children?: ReactNode;
}

export const DrawerProvider = ({ children }: DrawerProviderProps) => {
  const [drawerConfig, dispatch] = useReducer(
    drawerReducer,
    initialState.drawerConfig,
  );

  const value = useMemo(
    () => ({
      drawerConfig,
      dispatch,
      isDrawerOpen: drawerConfig.id !== DRAWER_IDS.NO_DRAWER,
    }),
    [drawerConfig, dispatch],
  );

  useEffect(() => {
    // close drawer on window history change (back button)
    const handleRouteChange = () => {
      if (drawerConfig) {
        dispatch({ type: 'HIDE_DRAWER' });
      }
    };
    window.addEventListener('popstate', handleRouteChange);
    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, [drawerConfig]);

  const hideDrawer = useCallback(() => {
    dispatch({ type: 'HIDE_DRAWER' });
  }, [dispatch]);

  return (
    <DrawerContext.Provider value={value}>
      {children}
      <DrawerPlaceholder hideDrawer={hideDrawer} />
    </DrawerContext.Provider>
  );
};

const DrawerPlaceholder = ({ hideDrawer }: { hideDrawer: () => void }) => {
  const { drawerConfig } = useContext(DrawerContext);

  if (!drawerConfig) return <></>;

  return <Drawer drawerConfig={drawerConfig} hideDrawer={hideDrawer} />;
};
