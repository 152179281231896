import * as React from 'react';

// material UI components
import {
  Box,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  Select as MuiSelect,
  FormHelperText,
} from '@mui/material';

// icon
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 160,
      width: 250,
    },
  },
};
interface SelectProps {
  options: Array<string>;
  className: string;
  inputClassName: string;
  value: string;
  setValue: (params: string) => void;
  label?: string;
  error?: boolean;
  helperText?: string | boolean;
  disabled?: boolean;
  size?: 'small' | 'medium';
}

const Select = ({
  className,
  inputClassName,
  options,
  value,
  setValue,
  label = 'Select Option',
  error,
  helperText,
  disabled,
  size,
}: SelectProps) => {
  return (
    <Box className={className}>
      <FormControl sx={{ width: '100%' }} error={error}>
        <InputLabel id='custom-select'>{label}</InputLabel>
        <MuiSelect
          id='custom-select'
          value={value}
          IconComponent={KeyboardArrowDownIcon}
          onChange={(e) => setValue(e.target.value)}
          input={<OutlinedInput className={inputClassName} label={label} />}
          MenuProps={MenuProps}
          disabled={disabled}
          size={size}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </MuiSelect>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
};

export default Select;
