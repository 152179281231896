import { useCallback, useEffect, useMemo } from 'react';
import { useFeature } from './feature';
import { useRouter } from 'next/router';
import { useAppSelector } from './store';
import { OptionItem } from '../components/shared/SimpleDropdown';
import { useWorkspace } from './useWorkspace';
import { convertToExploreLabel } from '../utils/Strings';

const useActiveExplore = () => {
  // Explore ID
  const { featureEditData, setExploreId } = useFeature();
  const { fetchWorkspaceExplores } = useWorkspace();
  const envExploreId = featureEditData.exploreId;
  const wsExploreId = featureEditData.wsExploreId;

  // WorkSpace ID
  const router = useRouter();
  // workspaceId may or may not be found from router
  // so it should consider the case when it is undefined
  const workspaceId = router.query.workspaceId as string | undefined;

  // Explores
  useEffect(() => {
    if (workspaceId) {
      fetchWorkspaceExplores(workspaceId);
    }
  }, [fetchWorkspaceExplores, workspaceId]);
  const availableExplores = useAppSelector(
    (state) => state.workspace.value.workspaceExplores,
  );

  // explores where we have loaded picker data
  const loadedExplores = useAppSelector((state) => state.loadedExplores.value);

  const exploreInfo = useMemo(() => {
    if (workspaceId && wsExploreId) {
      return loadedExplores[workspaceId]?.byWsExploreId[wsExploreId];
    } else if (workspaceId && envExploreId) {
      return loadedExplores[workspaceId]?.byEnvExploreId[envExploreId];
    }
  }, [envExploreId, loadedExplores, workspaceId, wsExploreId]);

  const exploreOptions = useMemo(() => {
    const options: OptionItem[] = [];
    if (availableExplores) {
      for (const explore of availableExplores) {
        options.push({
          value: explore.exploreId,
          label: convertToExploreLabel(explore.explore.exploreName),
        });
      }
    }
    return options;
  }, [availableExplores]);

  // exploreExtract
  const exploreExtracts = useAppSelector(
    (state) => state.exploreExtracts.value,
  );
  const exploreExtract = useMemo(
    () => (exploreInfo ? exploreExtracts[exploreInfo.extractId] : undefined),
    [exploreExtracts, exploreInfo],
  );

  const selectExploreByOption = useCallback(
    (exploreId: string) => {
      const optItem = exploreOptions.find((opt) => opt.value === exploreId);
      if (optItem) {
        setExploreId(optItem.value /* env explore id */);
      }
    },
    [exploreOptions, setExploreId],
  );

  return {
    workspaceId,
    exploreInfo,
    exploreExtract,
    exploreOptions,
    selectExploreByOption,
  };
};

export default useActiveExplore;
