// custom material UI components
import TemplateBox from './TemplateBox';
import WrapperForSection from '../shared/WrapperForSection';

// material UI components
import { Grid } from '@mui/material';
import { FeatureTemplate } from '../../generated/types';

interface TemplatesProps {
  templatesData: FeatureTemplate[];
  setGalleryPage: (value: string) => void;
  fetchTemplates: () => void;
  setSelectedTemplate: (value: FeatureTemplate) => void;
}

const Templates = ({
  templatesData,
  setGalleryPage,
  setSelectedTemplate,
  fetchTemplates,
}: TemplatesProps) => {
  return (
    <WrapperForSection>
      <Grid
        container
        justifyContent='flex-start'
        flexWrap='wrap'
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        sx={{
          pb: 3,
        }}
      >
        {templatesData.map((template: FeatureTemplate) => (
          <TemplateBox
            fetchTemplates={fetchTemplates}
            key={template.id}
            title={template.name}
            description={template.description || ''}
            // category={template.category}
            templateID={template.id}
            updatedAt={template.updatedAt || ''}
            actions={{
              setPage: setGalleryPage,
              setTemplate: () => setSelectedTemplate(template),
            }}
          />
        ))}
      </Grid>
    </WrapperForSection>
  );
};

export default Templates;
