import {
  EmailV1,
  EmailV2,
  GenericActionV1,
  isValid,
  SlackV1,
  validators,
} from '@madeinventive/core-types';
import { ActionArray } from '@madeinventive/core-types/dist/types/ActionArray';
import {
  ActionSpec,
  FeatureEditDataState,
  isEmailInMemConfig,
  isSlackInMemConfig,
} from '../../../store/slices/features';
import { CustomBrandingState } from '../../../store/slices/session';

// TODO: Remove this function once we get rid of all EmailV1 actions
// DEPRECATED: EmailV1 used to require parsing of the body to extract button and
// body text. EmailV2 now has fields for these values.
export const getBodyParts = (body: string) => {
  let buttonText = '',
    buttonUrl = '',
    bodyText = body;

  if (body.includes('<div id="button-break" />')) {
    const parts = body.split('<div id="button-break" />');

    bodyText = parts[0];
    if (bodyText.includes('<tr align="center">')) {
      bodyText = bodyText.split('<tr align="center">')[0];
    }
    const firstPart = parts[1].split('<a href="')[1];
    if (firstPart.includes('style')) {
      buttonUrl = firstPart?.split('" style')[0];
    } else {
      buttonUrl = parts[1].split('<a href="')[1]?.split('">')[0];
    }
    buttonText = firstPart.split('">')[1].split('</a>')[0];
  }

  return {
    buttonText,
    buttonUrl,
    bodyText,
  };
};

export const parseAction = (
  actionSection: ActionArray,
): FeatureEditDataState['actions'] => {
  const actions: FeatureEditDataState['actions'] = [];
  actionSection.forEach((actionSpec) => {
    if (isValid<GenericActionV1>(validators.GenericActionV1, actionSpec)) {
      if (isValid<EmailV1>(validators.EmailV1, actionSpec.spec)) {
        const emailBodyParts = getBodyParts(actionSpec.spec.bodyHTML);
        actions.push({
          kind: actionSpec.kind,
          spec: {
            to: actionSpec.spec.to,
            subject: actionSpec.spec.subject,
            body: emailBodyParts.bodyText,
            buttonUrl: emailBodyParts.buttonUrl,
            buttonText: emailBodyParts.buttonText,
            attachments: actionSpec.spec.attachments,
          },
          integrationId: actionSpec.integrationId,
          actionLabel: actionSpec.actionLabel,
        });
      } else if (isValid<EmailV2>(validators.EmailV2, actionSpec.spec)) {
        actions.push({
          kind: actionSpec.kind,
          spec: {
            ...actionSpec.spec,
            buttonText: actionSpec.spec.buttonText ?? '',
            buttonUrl: actionSpec.spec.buttonUrl ?? '',
          },
          integrationId: actionSpec.integrationId,
          actionLabel: actionSpec.actionLabel,
        });
      } else if (isValid<SlackV1>(validators.SlackV1, actionSpec.spec)) {
        actions.push({
          kind: actionSpec.kind,
          spec: actionSpec.spec,
          integrationId: actionSpec.integrationId,
          actionLabel: actionSpec.actionLabel,
        });
      }
    }
  });
  return actions;
};

export const generateSingleActionJSON = (
  action: ActionSpec,
  customBranding: CustomBrandingState | undefined,
): GenericActionV1 | undefined => {
  if (isEmailInMemConfig(action.spec)) {
    return {
      kind: 'Email/v2.0',
      spec: {
        to: action.spec.to,
        subject: action.spec.subject,
        buttonUrl: action.spec.buttonUrl,
        buttonText: action.spec.buttonText,
        body: action.spec.body,
        themeColor: customBranding?.themeColor,
        logoUrl: customBranding?.logoUrl,
        attachments: action.spec.attachments,
      },
      integrationId: action.integrationId ?? '',
      actionLabel: action.actionLabel ?? '',
    };
  } else if (isSlackInMemConfig(action.spec)) {
    // undefined integrationId possible in the UI
    // for generic actions (not just for slack)
    // in the case when user saves half-done features
    // before picking the integration to use
    return {
      kind: 'Slack/v1.0',
      spec: action.spec,
      integrationId: action.integrationId ?? '',
      actionLabel: action.actionLabel ?? '',
    };
  }
};

export const generateActionJSON = (
  actions: FeatureEditDataState['actions'],
  customBranding: CustomBrandingState | undefined,
): ActionArray => {
  const actionJSONs: ActionArray = [];
  for (const a of actions) {
    const actionJSON = generateSingleActionJSON(a, customBranding);
    if (actionJSON) {
      actionJSONs.push(actionJSON);
    }
  }
  return actionJSONs;
};
