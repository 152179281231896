import { Typography, Divider, Box } from '@mui/material';
import moment from 'moment';
import { FeatureActionLogEntry } from '../../generated/types';
import { sanitize } from 'dompurify';
import RecipientsDisplay from '../ActivityTab/RecipientsDisplay';
import { isEmail, isSlackMessage } from '../ActivityTab/utils';
import { BaseRegisteredDialogComponentProps } from './types';

export interface ActionLogEntryDataDialogProps
  extends BaseRegisteredDialogComponentProps {
  logEntry: FeatureActionLogEntry;
}

const ActionLogEntryDataDialog = ({
  logEntry,
}: ActionLogEntryDataDialogProps) => {
  const renderLogDetailView = () => {
    if (isEmail(logEntry.actionPayload)) {
      const { to, subject, bodyHTML } = logEntry.actionPayload;
      return (
        <>
          <Typography variant='h5' component='p'>
            <span style={{ color: 'rgb(155, 155, 155)' }}>Subject:</span>{' '}
            {subject}
          </Typography>
          <Typography variant='h5' component='p' sx={{ mb: 3 }}>
            <RecipientsDisplay displayName='To' recipientListString={to} />
          </Typography>
          <Divider />
          <Box
            sx={{
              mt: 4,
              p: 4,
              border: `1px solid rgba(155, 155, 155, 0.2)`,
              borderRadius: 2.5,
            }}
          >
            <Box dangerouslySetInnerHTML={{ __html: sanitize(bodyHTML) }} />
          </Box>
        </>
      );
    } else if (isSlackMessage(logEntry.actionPayload)) {
      const { channelId, message } = logEntry.actionPayload;
      return (
        <>
          <Typography variant='h5' component='p' sx={{ mb: 3 }}>
            <RecipientsDisplay
              displayName='Channel ID'
              recipientListString={channelId}
            />
          </Typography>
          <Divider />
          <Box
            sx={{
              mt: 4,
              p: 4,
              border: `1px solid rgba(155, 155, 155, 0.2)`,
              borderRadius: 2.5,
            }}
          >
            {message}
          </Box>
        </>
      );
    }
  };

  return (
    <>
      <Box display='flex'>
        <Typography
          variant='h5'
          component='p'
          sx={{ color: 'rgb(155, 155, 155)', mb: 3, mr: 3 }}
          gutterBottom
        >
          {moment(logEntry.createdAt).calendar()}
        </Typography>
        {logEntry.status === 'SUCCESS' ? (
          <Typography
            color='success'
            sx={{
              color: (theme) => theme.palette.success.dark,
            }}
          >
            Completed
          </Typography>
        ) : (
          <Typography
            sx={{
              color: (theme) => theme.palette.error.main,
            }}
          >
            Failed
          </Typography>
        )}
      </Box>
      {renderLogDetailView()}
    </>
  );
};

export default ActionLogEntryDataDialog;
