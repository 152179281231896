import React from 'react';

// mui components
import { Typography, IconButton, Box } from '@mui/material';
// icon
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
interface DialogHeaderProps {
  title: string;
  itemTitle?: string;
  onClose: () => void;
  width?: string;
  goBack?: () => void;
}

const DialogHeader = ({
  title,
  itemTitle,
  onClose,
  width,
  goBack,
}: DialogHeaderProps) => {
  return (
    <Box
      width={width}
      display='flex'
      alignItems='center'
      justifyContent='space-between'
    >
      <Box display='flex' alignItems='center' gap={1}>
        {goBack && (
          <IconButton onClick={goBack} size='small'>
            <ArrowBackIosNewIcon
              fontSize='inherit'
              sx={{
                fontWeight: 700,
              }}
            />
          </IconButton>
        )}
        <Typography
          variant='h5'
          width={260}
          sx={{
            fontWeight: 700,
            lineHeight: '28px',
          }}
        >
          {title}
        </Typography>
        <Typography
          variant='h5'
          sx={{
            fontWeight: 700,
            lineHeight: '28px',
          }}
        >
          {itemTitle}
        </Typography>
      </Box>

      <IconButton aria-label='close' onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export default DialogHeader;
