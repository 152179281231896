import * as React from 'react';
import { useContext, useEffect } from 'react';
import { useFormik } from 'formik';

// mui components
import { TextField, Box, Typography, Stack } from '@mui/material';

// components
import Button from '../../shared/Button';
import DialogHeader from '../../Dialogs/DialogHeader';
import CustomDialog from '../../Dialogs';
import SuccessDialogContent from '../../SuccessDialogContent';
import { DeleteDialogBody, DeleteDialogButtons } from '../ModalToDelete';

import { OptionalObjectSchema } from 'yup/lib/object';
import { RequiredStringSchema } from 'yup/lib/string';
import { EnvironmentContext } from '../../EnvironmentProvider';

interface ElementUpdateDialogProps {
  open: boolean;
  setOpen: (params: boolean) => void;
  id?: string;
  selectedItemName?: string;
  title: string;
  subTitle?: string;
  submitButtonName: string;
  deleteButtonName?: string;
  dialogTitle: string;
  successMessage: string;
  deleteSuccessMessage?: string;
  isDeleteFlow?: boolean;
  setIsDeleteFlow?: (params: boolean) => void;
  success: boolean;
  setSuccess: (params: boolean) => void;
  updateItem: (id: string, name: string) => void;
  closeButtonName?: string;
  closeButtonFunction?: () => void;
  placeholder?: string;
  validationSchema?: OptionalObjectSchema<{
    name: RequiredStringSchema<string | undefined>;
  }>;
  deleteHelperText?: string;
  deleteSubHelperText?: string;
  deleteOperation?: () => void;
  showTextField?: boolean;
  customSubmitFunction?: () => void;
}

const ElementUpdateDialog = ({
  selectedItemName,
  open,
  setOpen,
  id,
  title,
  subTitle,
  submitButtonName,
  deleteButtonName,
  dialogTitle,
  successMessage,
  deleteSuccessMessage,
  isDeleteFlow,
  setIsDeleteFlow,
  deleteHelperText,
  deleteSubHelperText,
  success,
  setSuccess,
  updateItem,
  closeButtonName,
  closeButtonFunction,
  placeholder,
  validationSchema,
  deleteOperation,
  showTextField = true,
  customSubmitFunction,
}: ElementUpdateDialogProps) => {
  const closeDialog = () => {
    setOpen(false);
    setSuccess(false);
    setIsDeleteFlow && setIsDeleteFlow(false);
    formik.values.name = '';
    success && closeButtonFunction && closeButtonFunction();
  };
  const { environment } = useContext(EnvironmentContext);

  const [deletedItem, setdeletedItem] = React.useState('');

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    onSubmit: onSubmit,
    validationSchema: validationSchema,
  });

  useEffect(() => {
    formik.values.name = selectedItemName || '';
  }, [selectedItemName, open]); // eslint-disable-line

  async function onSubmit() {
    if (typeof environment?.id === 'string' && formik.isValid) {
      try {
        updateItem(id || environment.id, formik.values.name);
        setSuccess(true);
      } catch (error) {
        console.log(error);
      }
    }
  }

  const openDeleteFlow = () => {
    setIsDeleteFlow && setIsDeleteFlow(true);
    !isDeleteFlow && setdeletedItem(selectedItemName || '');
  };

  const getEditDialogBody = () => {
    return (
      <form onSubmit={formik.handleSubmit}>
        <Box pt={3} pb={1.5} pl={9} pr={9}>
          <Typography variant='h5' mb={0.5} color='text.primary'>
            {title}
          </Typography>
          {subTitle && (
            <Typography variant='body1' color='text.secondary' mb={1.5}>
              {subTitle}
            </Typography>
          )}
          {showTextField && (
            <TextField
              id='name'
              type='text'
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              placeholder={placeholder}
              fullWidth
              sx={{
                '& .MuiOutlinedInput-input': {
                  height: 6,
                },
              }}
            />
          )}
        </Box>
      </form>
    );
  };

  const getEditDialogButtons = () => {
    return (
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2} direction='row'>
          {deleteButtonName && (
            <Button variant='outlined' onClick={openDeleteFlow}>
              {deleteButtonName}
            </Button>
          )}
          <Button
            variant='outlined'
            onClick={() => {
              formik.resetForm();
              closeDialog();
              closeButtonFunction && closeButtonFunction();
            }}
          >
            {closeButtonName ?? 'Close'}
          </Button>
          <Button
            variant='outlined'
            type={customSubmitFunction ? 'button' : 'submit'}
            onClick={customSubmitFunction}
          >
            {submitButtonName}
          </Button>
        </Stack>
      </form>
    );
  };

  const dialogHeader = () => {
    return (
      <DialogHeader
        title={dialogTitle}
        onClose={() => {
          formik.resetForm();
          closeDialog();
        }}
      />
    );
  };

  const successText = id ? selectedItemName || deletedItem : formik.values.name;
  const dialogContent = !success ? (
    isDeleteFlow ? (
      <DeleteDialogBody
        selectedItemName={`${selectedItemName}?`}
        helperText={deleteHelperText || ''}
        subHelperText={deleteSubHelperText || ''}
      />
    ) : (
      getEditDialogBody()
    )
  ) : (
    <SuccessDialogContent
      boldText={successText}
      regularText={isDeleteFlow ? deleteSuccessMessage : successMessage}
    />
  );

  return (
    <CustomDialog
      sx={{
        '& .MuiDialog-paper': {
          width: '600px',
          maxWidth: 'unset',
        },
      }}
      open={open}
      handleClose={closeDialog}
      header={dialogHeader()}
      content={dialogContent}
      actions={
        isDeleteFlow && !success && deleteOperation ? (
          <DeleteDialogButtons
            deleteOperation={deleteOperation}
            setSuccess={setSuccess}
            setIsDeleteFlow={setIsDeleteFlow}
            deleteButtonName={'Yes, delete'}
          />
        ) : (
          !success && getEditDialogButtons()
        )
      }
    />
  );
};

export default ElementUpdateDialog;
