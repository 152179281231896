import { ApolloLink } from '@apollo/client';
import { getSentrySessionID } from '../../utils/monitoring/initSentry';

export const sentryLink = new ApolloLink((operation, forward) => {
  console.debug('Setting Sentry Session ID' + getSentrySessionID());

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'x-sentry-session-id': getSentrySessionID(),
    },
  }));

  return forward(operation);
});
