import { useCallback, useState, useEffect, forwardRef } from 'react';

// slate
import { Transforms } from 'slate';
import {
  Editable,
  RenderElementProps,
  RenderLeafProps,
  useSlate,
  ReactEditor,
} from 'slate-react';
import { EditableProps } from 'slate-react/dist/components/editable';

// components
import { styled } from '@mui/material/styles';
import { Popper, Card, ClickAwayListener, Box } from '@mui/material';
import SlateElement from './SlateElement';
import SlateLeaf from './SlateLeaf';
import DataFieldSelector from '../DataFieldSelector';

// types and utils
import { DYNAMIC_FIELD_LAUNCH_KEY, ElementType, FieldElement } from './types';
import { SchemaNode } from '../../store/slices/exploreExtracts';

const StyledEditable = styled(Editable, {
  shouldForwardProp: (prop) => prop !== 'isSingleLine',
})<SlateEditableProps>(({ isSingleLine, theme }) => {
  const singleLineStyle = {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    overflow: 'auto',
    [`& > p`]: {
      minWidth: 1, // to prevent the cursor from disappearing
      lineHeight: 2, // match the height of the chip
    },
    [`& > *`]: {
      margin: 0,
      flexShrink: 0,
      [`&::-webkit-scrollbar`]: {
        display: 'none',
      },
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
    },
  };

  const multiLineStyle = {
    [`& > p`]: {
      margin: '4px 0',
      fontSize: theme.typography.body1.fontSize,
    },
  };

  return {
    width: '100%',
    padding: '8px 14px',
    [`&:focus-visible`]: {
      outline: 'none',
    },
    ...(isSingleLine ? singleLineStyle : multiLineStyle),
  };
});

export interface SlateEditableProps extends EditableProps {
  isSingleLine?: boolean;
  popperAnchorRef?: React.RefObject<HTMLElement>;
  popperWidth?: number;
  externalIsPopperOpen?: boolean;
  onPopperOpen?: () => void;
  onPopperClose?: () => void;
  disableDynamicField?: boolean;
}

// Drop ref (second argument) intentionally
// because adding ref overwrites the input style
const SlateEditable = forwardRef(
  (
    {
      isSingleLine,
      popperAnchorRef,
      popperWidth,
      externalIsPopperOpen,
      onPopperOpen,
      onPopperClose,
      onKeyDown,
      disableDynamicField,
    }: SlateEditableProps,
    _,
  ) => {
    const editor = useSlate();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [isPopperOpen, setIsPopperOpen] = useState(
      Boolean(externalIsPopperOpen),
    );

    const openPopper = useCallback(() => {
      popperAnchorRef?.current && setAnchorEl(popperAnchorRef.current);
      setIsPopperOpen(true);
      onPopperOpen?.();
    }, [popperAnchorRef, onPopperOpen]);

    const closePopper = useCallback(() => {
      setAnchorEl(null);
      setIsPopperOpen(false);
      onPopperClose?.();
    }, [onPopperClose]);

    useEffect(() => {
      if (externalIsPopperOpen) {
        openPopper();
      } else {
        closePopper();
      }
    }, [externalIsPopperOpen, openPopper, closePopper]);

    const renderElement = useCallback(
      (props: RenderElementProps) => <SlateElement {...props} />,
      [],
    );

    const renderLeaf = useCallback(
      (props: RenderLeafProps) => <SlateLeaf {...props} />,
      [],
    );

    const handleFieldSelect = (field?: SchemaNode) => {
      if (!field) {
        return;
      }

      const fieldElement: FieldElement = {
        type: ElementType.FIELD,
        field: field,
        children: [{ text: '' }],
      };

      // insert field chip
      Transforms.insertNodes(editor, fieldElement);

      // Move the selection to after the inserted chip
      // and insert a space
      if (editor.selection) {
        Transforms.move(editor, { distance: 1, unit: 'offset' });
        Transforms.insertText(editor, ' ', { at: editor.selection.anchor });
      }

      // Focus the editor
      ReactEditor.focus(editor);
      closePopper();
    };

    return (
      <Box width='100%' flex={1} id='slate-wrapper' overflow='hidden'>
        <StyledEditable
          autoComplete='off'
          isSingleLine={isSingleLine}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
            // default key down handler for all editable
            if (event.key === DYNAMIC_FIELD_LAUNCH_KEY) {
              if (disableDynamicField) {
                return;
              }
              event.preventDefault();
              openPopper();
            }
            // custom key down handler
            if (onKeyDown) {
              onKeyDown(event);
            }
          }}
        />
        {!disableDynamicField && (
          <Popper
            open={isPopperOpen}
            anchorEl={anchorEl}
            placement='auto'
            sx={{
              zIndex: (theme) => theme.zIndex.tooltip + 1,
              width: popperWidth,
            }}
          >
            <ClickAwayListener onClickAway={closePopper}>
              <Card
                sx={{
                  backgroundColor: (theme) => theme.palette.background.paper,
                  borderRadius: 2, // input radius is 2(8px) and accordion radius is 1(4px)
                }}
              >
                <DataFieldSelector
                  handleFieldSelect={handleFieldSelect}
                  allInOne={true}
                />
              </Card>
            </ClickAwayListener>
          </Popper>
        )}
      </Box>
    );
  },
);

SlateEditable.displayName = 'SlateEditable';

export default SlateEditable;
