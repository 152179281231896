import { Box } from '@mui/material';
import React from 'react';

type DisabledBoxProps = {
  children?: React.ReactNode;
};

const DisabledBox = ({ children }: DisabledBoxProps) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      gap={1}
      p={1}
      my={2}
      sx={{
        border: '1px solid',
        borderColor: 'border.disabled',
        borderRadius: '3px',
        background: (theme) => theme.palette.background.disabled,
      }}
    >
      {children}
    </Box>
  );
};

export default DisabledBox;
