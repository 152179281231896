import { Stack, Typography } from '@mui/material';
import TextField, { TextFieldProps } from '../TextField';
import Chip from '../../Chip';
import FormField from '../FormField';

interface MultiValueTextField extends TextFieldProps {
  label?: string;
  subLabel?: string;
  values: string[];
  errorMessage?: string;
  clearError?: () => void;
  onValueEntered: (value: string) => void;
  valueValidator?: (value: string) => boolean;
  onValidationError?: () => void;
  onDelete: (value: string) => void;
  readOnly?: boolean;
}

const MultiValueTextField = ({
  label,
  subLabel,
  values,
  errorMessage,
  clearError,
  onValueEntered,
  valueValidator,
  onValidationError,
  onDelete,
  readOnly,
  ...rest
}: MultiValueTextField) => {
  const handleTextFieldKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      const target = event.target as HTMLInputElement;
      const value = target.value;

      // prevent form submission
      if (event.key === 'Enter') {
        event.preventDefault();
      }

      // ignore empty values
      if (value.trim() === '') {
        clearError && clearError();
        return;
      }

      // check if value is already in values
      if (values.includes(value)) {
        clearError && clearError();
        target.value = '';
        return;
      }

      // validate value
      if (valueValidator && !valueValidator(value)) {
        onValidationError && onValidationError();
        return;
      }

      // add value and clear input
      onValueEntered(value);
      clearError && clearError();
      target.value = '';
    }
  };

  return (
    <Stack width='100%' spacing={1.5}>
      {readOnly && (
        <FormField label={label}>
          <Typography variant='body1'>{values.join(', ')}</Typography>
        </FormField>
      )}
      {!readOnly && (
        <>
          <TextField
            onKeyDown={handleTextFieldKeyDown}
            label={label}
            subLabel={subLabel}
            errorMessage={errorMessage}
            {...rest}
          />
          <Stack direction='row' alignItems='flex-start' flexWrap='wrap'>
            {values.map((value, index) => (
              <Chip
                key={index}
                label={value}
                onDelete={() => onDelete(value)}
                sx={{
                  marginRight: 1.5,
                  marginBottom: 1.5,
                }}
              />
            ))}
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default MultiValueTextField;
