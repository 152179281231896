import { useCallback } from 'react';
import {
  FeatureState,
  useRefreshComponentSnapshotMutation,
  useWorkspaceComponentLazyQuery,
  useWorkspaceComponentReferencesLazyQuery,
} from '../generated/types';

export const useWorkspaceComponent = () => {
  const [fetchWorkspaceComponent, { loading, error }] =
    useWorkspaceComponentLazyQuery();
  const [fetchWorkspaceComponentReferences, { data: componentReferencesData }] =
    useWorkspaceComponentReferencesLazyQuery();

  const [
    refreshWorkspaceComponent,
    { loading: refreshing, error: refreshError },
  ] = useRefreshComponentSnapshotMutation();

  const fetchComponent = useCallback(
    async ({
      workspaceId,
      componentId,
    }: {
      workspaceId: string;
      componentId: string;
    }) => {
      return fetchWorkspaceComponent({
        variables: {
          workspaceId,
          componentId,
        },
      });
    },
    [fetchWorkspaceComponent],
  );

  const fetchComponentReferences = useCallback(
    async ({
      workspaceId,
      componentId,
      state,
    }: {
      workspaceId: string;
      componentId: string;
      state?: FeatureState;
    }) => {
      return fetchWorkspaceComponentReferences({
        variables: {
          workspaceId,
          componentId,
          state,
        },
      });
    },
    [fetchWorkspaceComponentReferences],
  );

  const refreshComponent = useCallback(
    async ({ componentId }: { componentId: string }) => {
      return refreshWorkspaceComponent({
        variables: {
          input: {
            componentId,
          },
        },
      });
    },
    [refreshWorkspaceComponent],
  );

  return {
    loading,
    error,
    fetchComponent,
    componentReferencesData,
    fetchComponentReferences,
    refreshing,
    refreshError,
    refreshComponent,
  };
};
