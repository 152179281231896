export enum VisualizationType {
  VEGA = 'vega',
  TABLE = 'table',
  TILE = 'tile',
  NONE = 'none',
}

export enum VizViewMode {
  VIZ = 'VIZ',
  DATA = 'DATA',
}

export enum ACTION_TYPE {
  EMAIL = 'email',
  SLACK = 'slack',
}

export interface ScheduleVizFormValues {
  actionType: ACTION_TYPE;
  cron: string;
  recipients: string[];
}
