import {
  WorkflowConfigScheduleAndActionV1,
  WorkflowConfigConditionAndActionV2,
  ActionConfigV1,
  FeatureConfig,
  EmailV2,
  SlackV1,
  NormalizedConditionV1,
  NormalizedConditionExpression,
  DynamicFieldV1,
  isValid,
  validators,
} from '@madeinventive/core-types';
import {
  FeatureEditDataState,
  isEmailInMemConfig,
  isSlackInMemConfig,
} from '../../store/slices/features';
import {
  Schema,
  SchemaNode,
  isFieldInfo,
} from '../../store/slices/exploreExtracts';
import { MultiStepFormValues, ActionType, ConditionFormValues } from './types';

const getActionConfig = (
  formValues: MultiStepFormValues,
  integrationId?: string,
): ActionConfigV1 => {
  if (formValues.message.actionType === 'Email') {
    const emailSpec: EmailV2 = {
      to: (formValues.message.recipients ?? []).join(','),
      subject: formValues.message.emailSubject ?? '',
      body: formValues.message.emailBody ?? '',
      buttonText: formValues.message.buttonText ?? '',
      buttonUrl: formValues.message.buttonLink ?? '',
      attachments: formValues.settings.attachment
        ? [formValues.settings.attachment]
        : [],
    };
    return {
      integrationId: '',
      kind: 'Email/v2.0',
      spec: emailSpec,
    };
  } else {
    const slackSpec: SlackV1 = {
      actionType: 'Slack/PostMessage/v1.0',
      channelId: formValues.message.slackChannelIds?.join(',') ?? '',
      message: formValues.message.slackMessage ?? '',
    };
    return {
      // when integrationId is not provided, it will fail sending the alert
      integrationId: integrationId ?? '',
      kind: 'Slack/v1.0',
      spec: slackSpec,
    };
  }
};

export const formValuesToWorkflowConfig = (
  formValues: MultiStepFormValues,
  envExploreId: string,
  integrationId?: string,
): FeatureConfig => {
  const actionConfig = getActionConfig(formValues, integrationId);

  if (formValues.settings.actionTriggerType === 'schedule') {
    const scheduleWorkflowSpec: WorkflowConfigScheduleAndActionV1 = {
      cronSchedule: formValues.settings.cron ?? '',
      actions: [actionConfig],
    };

    return {
      kind: 'ScheduleAndAction/v1.0',
      spec: scheduleWorkflowSpec,
    };
  } else {
    const expressions = formValues.settings.conditions?.map((condition) => {
      const expression: NormalizedConditionExpression = {
        variable: condition.variable,
        operator: condition.operator,
        value: condition.value,
        chainingOperator: condition.chainingOperator,
      };
      return expression;
    });

    const conditionSpec: NormalizedConditionV1 = {
      environmentExploreId: envExploreId,
      expressions: expressions ?? [],
    };

    const conditionWorkflowSpec: WorkflowConfigConditionAndActionV2 = {
      condition: {
        kind: 'NormalizedCondition/v1.0',
        spec: conditionSpec,
      },
      action: [actionConfig],
      extraFields: formValues.settings.alertsAbout
        ? [convertSchemaNodeToDynamicField(formValues.settings.alertsAbout)]
        : [],
    };

    return {
      kind: 'ConditionAndAction/v2.0',
      spec: conditionWorkflowSpec,
    };
  }
};

export const convertSchemaNodeToDynamicField = (
  schemaNode: SchemaNode,
): DynamicFieldV1 => {
  const fieldInfo = isFieldInfo(schemaNode.metaData)
    ? schemaNode.metaData
    : null;
  if (!fieldInfo) {
    throw new Error('Schema node does not contain field info');
  }
  return {
    field: fieldInfo.name,
    normalizedType: fieldInfo.normalizedType,
  };
};

const getSchemaNodeFromDynamicField = (
  dynamicField: DynamicFieldV1,
  schema: Schema,
): SchemaNode => {
  const field = schema.lookup[dynamicField.field];
  return field;
};

export const featureEditDataToMultiStepFormValues = (
  featureEditData: FeatureEditDataState,
  schema?: Schema, // used to look up field
): MultiStepFormValues => {
  // action can be missing for draft alerts
  const action = featureEditData.actions[0];
  let isEmail = false;
  let isSlack = false;
  let actionType = undefined;

  if (action) {
    isEmail = action.kind === 'Email/v2.0' || action.kind === 'Email/v1.0';
    isSlack = action.kind === 'Slack/v1.0';
    actionType = isEmail
      ? ActionType.Email
      : isSlack
      ? ActionType.Slack
      : undefined;
  }

  const emailSpec =
    isEmail && isEmailInMemConfig(action.spec) ? action.spec : null;
  const slackSpec =
    isSlack && isSlackInMemConfig(action.spec) ? action.spec : null;

  // alertsAbout takes only one field
  const alertsAboutSchemaNode =
    schema &&
    featureEditData.extraFields &&
    featureEditData.extraFields.length > 0 &&
    isValid<DynamicFieldV1>(
      validators.DynamicFieldV1,
      featureEditData.extraFields[0],
    )
      ? getSchemaNodeFromDynamicField(featureEditData.extraFields?.[0], schema)
      : undefined;

  const attachment =
    isEmail && isEmailInMemConfig(action.spec)
      ? action.spec.attachments?.[0]
      : undefined;

  return {
    settings: {
      actionTriggerType: featureEditData.actionTriggerType,
      cron: featureEditData.cronSchedule,
      conditions: featureEditData.conditions.map(
        (condition) =>
          ({
            variable: condition.variable,
            operator: condition.operator,
            value: condition.value,
            chainingOperator: condition.chainingOperator,
            isEditing: false,
          } as ConditionFormValues),
      ),
      alertsAbout: alertsAboutSchemaNode,
      attachment: attachment,
    },
    message: {
      actionType: actionType,
      recipients: emailSpec?.to.split(',') ?? [],
      emailSubject: emailSpec?.subject ?? '',
      emailBody: emailSpec?.body ?? '',
      buttonText: emailSpec?.buttonText ?? '',
      buttonLink: emailSpec?.buttonUrl ?? '',
      slackChannelIds: slackSpec?.channelId.split(',') ?? [],
      slackMessage: slackSpec?.message ?? '',
    },
  };
};
