import { Box } from '@mui/material';

interface DotProps {
  ariaLabel?: string;
  type:
    | 'default'
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
    | 'draft'
    | 'empty';
}

const getColor = (type: DotProps['type']) => {
  switch (type) {
    case 'default':
      return 'primary.main';
    case 'info':
      return 'info.main';
    case 'success':
      return 'success.main';
    case 'warning':
      return 'warning.main';
    case 'error':
      return 'error.main';
    case 'draft':
      return 'neutrals.b4';
    case 'empty':
      return 'transparent';
  }
};

const Dot = ({ type, ariaLabel }: DotProps) => {
  const color = getColor(type);
  return (
    <Box
      width={6}
      height={6}
      borderRadius='50%'
      bgcolor={color}
      border={type === 'empty' ? '1px solid' : undefined}
      borderColor={type === 'empty' ? 'neutrals.b3' : undefined}
      role='status'
      aria-label={ariaLabel}
    />
  );
};

export default Dot;
