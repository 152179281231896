import { TokenRefreshLink } from 'apollo-link-token-refresh';
import {
  deleteTokens,
  getTokenState,
  getTokens,
  TokenState,
  Tokens,
  setTokens,
  deleteCurrentUserDetails,
} from '../localStorage';
import { API_URI } from '../endpoints';
import { getCustomHeaders } from '../authHelpers';

const REFRESH_SESSION_QUERY = `
  mutation RefreshSession($input: RefreshSessionInput!) {
    refreshSession(input: $input) {
      accessToken
      accessTokenExpiresAt
      refreshToken
      refreshTokenExpiresAt
    }
  }
`;

export const tokenRefreshLink = new TokenRefreshLink<Tokens>({
  accessTokenField: 'refreshSession',
  isTokenValidOrUndefined: () => getTokenState() !== TokenState.NEED_REFRESH,
  fetchAccessToken: () => {
    // call 'freshSession' mutation without the auth header as the access token
    // is expired by now and useless
    const headers = {
      'content-type': 'application/json',
      ...getCustomHeaders(false /* includeAuth */),
    };
    const tokens = getTokens();
    return fetch(API_URI, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        operationName: 'RefreshSession',
        query: REFRESH_SESSION_QUERY,
        variables: {
          input: {
            refreshToken: tokens.refreshToken,
          },
        },
      }),
    });
  },
  handleFetch: (tokens: Tokens) => {
    setTokens(tokens);
  },
  handleResponse: () => async (response: Response) => {
    return response.json();
  },
  handleError: (err) => {
    // full control over handling token fetch Error
    console.warn(`session refresh failed: ${err.message}. Try to relogin`);
    deleteTokens();
    deleteCurrentUserDetails();
    window.location.href = '/';
  },
});
