import { Box } from '@mui/material';
import React from 'react';

type Props = {
  width?: string;
  children?: React.ReactNode;
};

const InputBox = ({ width, children }: Props) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      gap={1}
      px={1.25}
      py={1}
      my={2}
      sx={{
        border: '1px solid',
        borderColor: 'border.light',
        borderRadius: '3px',
        width: `${width ? width : 'auto'}`,
        minHeight: '35px',
      }}
    >
      {children}
    </Box>
  );
};

export default InputBox;
