import { useCallback, useRef } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import SendIcon from '@mui/icons-material/Send';
import { TextareaAutosize, useTheme } from '@mui/material';
import Button from '../Button';

export interface CommentEditorProps {
  comment: string;
  setComment: (comment: string) => void;
  placeholderText?: string;
  minRows?: number;
  maxRows?: number;
  width?: number;
  autoClear?: boolean;
}

const CommentEditor = (props: CommentEditorProps) => {
  const theme = useTheme();
  const { autoClear, setComment } = props;
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const handleClick = useCallback(() => {
    // do nothing if ref isn't valid yet
    if (!textAreaRef.current) {
      return;
    }
    if (setComment) {
      setComment(textAreaRef.current.value);
      if (autoClear) {
        // auto-clear the text area after the submission if `autoClear` prop is set
        textAreaRef.current.value = '';
      }
    }
  }, [autoClear, setComment]);

  const { body1, fontFamilyComfortaa } = theme.typography;

  return (
    <Stack
      flexDirection='column'
      alignItems='stretch'
      spacing={0.5}
      px={1}
      paddingBottom={1}
    >
      <TextareaAutosize
        ref={textAreaRef}
        aria-label='comment editor'
        defaultValue={props.comment ?? ''}
        minRows={props.minRows ?? 3}
        maxRows={props.maxRows ?? 6}
        placeholder={props.placeholderText ?? 'Add comment here...'}
        style={{
          width: props.width,
          fontFamily: fontFamilyComfortaa,
          ...body1,
        }}
      />
      <Box display='flex' justifyContent='flex-end'>
        <Button
          variant='contained'
          endIcon={<SendIcon />}
          size='small'
          onClick={handleClick}
        >
          Submit
        </Button>
      </Box>
    </Stack>
  );
};

export default CommentEditor;
