import { ChangeEvent, useCallback, useState } from 'react';
import {
  FormControl,
  Stack,
  TextField,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { ProgressBarConfig } from '@madeinventive/core-types';
import ProgressBar from './ProgressBar';

interface ProgressBarConfigPanelProps {
  config?: ProgressBarConfig;
  setConfig: (config: ProgressBarConfig) => void;
}

const ProgressBarConfigPanel = ({
  config,
  setConfig,
}: ProgressBarConfigPanelProps) => {
  const [showNumber, setShowNumber] = useState<boolean | undefined>(
    config?.showNumber,
  );

  const handleShowNumberChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setShowNumber(e.target.checked);
      setConfig({
        ...config,
        showNumber: e.target.checked,
      });
    },
    [config, setConfig],
  );

  const handleDenominatorChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const denominator = parseFloat(e.target.value);
      if (!isNaN(denominator)) {
        setConfig({
          ...config,
          denominator,
        });
      }
    },
    [config, setConfig],
  );

  return (
    <Stack spacing={2}>
      <FormControl fullWidth>
        <Stack direction='row' spacing={2}>
          <Stack flex={1} height={'36px'} justifyContent='center'>
            <ProgressBar
              number={65.555} // This number is a random number to show an example
              formatted={undefined}
              divideBy={100}
              showNumber={showNumber}
            />
          </Stack>
          <Stack flex={1}>
            <TextField
              size='small'
              label='Divide by'
              defaultValue={config?.denominator}
              onChange={handleDenominatorChange}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={showNumber}
                  onChange={handleShowNumberChange}
                />
              }
              label='Show number'
            />
          </Stack>
        </Stack>
      </FormControl>
    </Stack>
  );
};

export default ProgressBarConfigPanel;
