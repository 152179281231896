import { useMemo } from 'react';
import { useAppSelector } from './store';
import { getExploreInfoByEnvExploreId } from '../store/slices/loadedWorkspaceExplores';
import { DynamicFieldV1 } from '@madeinventive/core-types';

const useExploreSchema = ({ envExploreId }: { envExploreId?: string }) => {
  const loadedExplores = useAppSelector((state) => state.loadedExplores.value);
  const exploreExtracts = useAppSelector(
    (store) => store.exploreExtracts.value,
  );

  const { workspace } = useAppSelector((state) => state.workspace.value);
  const workspaceId = workspace?.id;

  const exploreInfo = useMemo(() => {
    if (envExploreId && workspaceId) {
      return getExploreInfoByEnvExploreId(
        loadedExplores,
        workspaceId,
        envExploreId,
      );
    }
  }, [envExploreId, loadedExplores, workspaceId]);

  const extractId = exploreInfo?.extractId;

  const schema = useMemo(() => {
    return extractId ? exploreExtracts[extractId]?.schema : undefined;
  }, [exploreExtracts, extractId]);

  const getSchemaNodeForDynamicField = (dynamicField: DynamicFieldV1) => {
    if (!schema) {
      return;
    }
    const field = schema.lookup[dynamicField.field];
    return field;
  };

  return {
    schema,
    getSchemaNodeForDynamicField,
  };
};

export { useExploreSchema };
