import { useMemo, useState, useRef, useCallback, useEffect } from 'react';
import isHotkey from 'is-hotkey';
import { Slate, useSlate } from 'slate-react';
import { Descendant, Transforms } from 'slate';
import {
  toggleMark,
  isMarkActive,
  createSlateEditor,
  // for html
  convertHtmlStringToSlateValue,
  convertSlateValueToHtmlString,
  // for markdown
  convertMarkdownStringToSlateValue,
  convertSlateValueToMarkdownString,
  // for plain text (use only for html)
  convertRawStringToSlateValue,
  convertSlateValueToRawString,
} from './utils';
import { useElementWidth } from '../../hooks/useElementWidth';
import { DEFAULT_SLATE_VALUE, Mode, OutputFormat } from './types';

// components
import { Box, Divider, Stack, Theme, Typography } from '@mui/material';
import Icon from '../shared/Icon';
import { IconName } from '../shared/Icon/types';
import SlateEditable from './SlateEditable';
import DynamicFieldButton from './DynamicFieldButton';

const MIN_HEIGHT = 120;

const HOTKEYS: { [key: string]: string } = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

const borderStyle = (theme: Theme) => {
  const borderColor = theme.palette.border.actionable;
  const hoverColor = theme.palette.border.active;
  const focusColor = theme.palette.primary.main;
  return {
    border: `1px solid ${borderColor}`,
    '.toolbar': {
      borderBottom: `1px solid ${borderColor}`,
    },
    '&:hover': {
      border: `1px solid ${hoverColor}`,
      '.toolbar': {
        borderBottom: `1px solid ${hoverColor}`,
      },
    },
    '&:focus-within': {
      border: `2px solid ${focusColor}`,
      '.toolbar': {
        borderBottom: `2px solid ${focusColor}`,
      },
    },
  };
};

interface BaseDynamicFieldEditorProps {
  defaultValue: string;
  slateEditorResetKey?: number;
  onChange: (value: string) => void;
  showDebugger?: boolean;
  disableDynamicField?: boolean;
}

interface DynamicFieldRawEditorProps extends BaseDynamicFieldEditorProps {
  mode: Mode.RAW_TEXT;
  outputFormat?: never;
}

interface DynamicFieldRichTextEditorProps extends BaseDynamicFieldEditorProps {
  mode: Mode.RICH_TEXT;
  outputFormat: OutputFormat;
}

export type DynamicFieldEditorProps =
  | DynamicFieldRawEditorProps
  | DynamicFieldRichTextEditorProps;

const DynamicFieldEditor = ({
  slateEditorResetKey,
  defaultValue,
  onChange,
  mode,
  showDebugger = false,
  outputFormat,
  disableDynamicField,
}: DynamicFieldEditorProps) => {
  const outputFormatByMode = mode === Mode.RICH_TEXT ? outputFormat : undefined;
  const isRichText = useMemo(() => mode === Mode.RICH_TEXT, [mode]);
  const [convertedString, setConvertedString] = useState<string>('');

  const stringConverter = useMemo(() => {
    switch (mode) {
      case Mode.RAW_TEXT:
        return convertRawStringToSlateValue;
      case Mode.RICH_TEXT:
        return outputFormatByMode === OutputFormat.HTML
          ? convertHtmlStringToSlateValue
          : convertMarkdownStringToSlateValue;
    }
  }, [mode, outputFormatByMode]);

  const slateValueConverter = useMemo(() => {
    switch (mode) {
      case Mode.RAW_TEXT:
        return convertSlateValueToRawString;
      case Mode.RICH_TEXT:
        return outputFormatByMode === OutputFormat.HTML
          ? convertSlateValueToHtmlString
          : convertSlateValueToMarkdownString;
    }
  }, [mode, outputFormatByMode]);

  // the initial slate value impact the editor state only on the first render
  // unless the slateEditorResetKey changes
  const initialSlateValue = useMemo(() => {
    return defaultValue ? stringConverter(defaultValue) : DEFAULT_SLATE_VALUE;
  }, [defaultValue, stringConverter]);

  // state for DataFieldSelector
  const [isDFSOpen, setIsDFSOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [containerRef, containerWidth] = useElementWidth();

  // create editor
  const editor = useMemo(() => {
    return createSlateEditor();
  }, []);

  const handleChange = useCallback(
    (slateValue: Descendant[]) => {
      const convertedString = slateValueConverter(slateValue);
      setConvertedString(convertedString);
      onChange(convertedString);
    },
    [onChange, slateValueConverter],
  );

  useEffect(() => {
    // when the editor values change discarded,
    // the editor node selection also needs to be reset
    // to prevent the editor from crashing with the error:
    // Cannot resolve a DOM point from Slate point error
    Transforms.deselect(editor);
  }, [editor, slateEditorResetKey]);

  return (
    <>
      <Stack
        borderRadius={2}
        minHeight={MIN_HEIGHT}
        sx={borderStyle}
        direction='column'
      >
        <Slate
          key={slateEditorResetKey}
          editor={editor}
          initialValue={initialSlateValue}
          onChange={handleChange}
        >
          {/* Rich text toolbar */}
          {isRichText && (
            <Stack
              className='toolbar'
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              paddingX={1}
              paddingY={0.5}
            >
              {/* left */}
              <Stack direction='row' alignItems='center' spacing={1.5}>
                <MarkButton format='bold' icon='text-bold' />
                <MarkButton format='italic' icon='text-italic' />
              </Stack>
              {/* right */}
              <Stack direction='row' alignItems='center' spacing={1.5}>
                {!disableDynamicField && (
                  <DynamicFieldButton
                    isOpen={isDFSOpen}
                    onClick={() => {
                      setIsDFSOpen((prev) => !prev);
                    }}
                  />
                )}
              </Stack>
            </Stack>
          )}
          <Stack flex={1}>
            <SlateEditable
              disableDynamicField={disableDynamicField}
              popperAnchorRef={anchorRef}
              popperWidth={containerWidth}
              externalIsPopperOpen={isDFSOpen}
              onPopperOpen={() => {
                setIsDFSOpen(true);
              }}
              onPopperClose={() => {
                setIsDFSOpen(false);
              }}
              {...(isRichText
                ? {
                    onKeyDown: (event: React.KeyboardEvent) => {
                      for (const hotkey in HOTKEYS) {
                        if (isHotkey(hotkey, event)) {
                          event.preventDefault();
                          const mark = HOTKEYS[hotkey];
                          toggleMark(editor, mark);
                        }
                      }
                    },
                  }
                : {})}
            />
            {/* Space for popper placement */}
            <Box paddingX={2}>
              <div ref={containerRef}>
                <div ref={anchorRef} />
              </div>
            </Box>
          </Stack>
        </Slate>
      </Stack>
      {/* Use this for debugging */}
      {showDebugger && (
        <Stack p={2} spacing={2} bgcolor='background.default'>
          <Typography variant='h5'>** Debugger **</Typography>
          <Typography variant='h6'>String Value</Typography>
          <Typography
            variant='body2'
            sx={{
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
            }}
          >
            {convertedString}
          </Typography>
          <Divider />
          <Typography variant='h6'>Slate Value</Typography>
          <Typography
            variant='body2'
            sx={{
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
            }}
          >
            <code>{JSON.stringify(initialSlateValue, null, 2)}</code>
          </Typography>
        </Stack>
      )}
    </>
  );
};

export default DynamicFieldEditor;

const MarkButton = ({ format, icon }: { format: string; icon: IconName }) => {
  const editor = useSlate();
  const isActive = isMarkActive(editor, format);
  return (
    <Icon
      name={icon}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
      sx={{
        cursor: 'pointer',
        fontWeight: isActive ? 'bold' : 'normal',
        color: (theme) =>
          isActive ? theme.palette.text.primary : theme.palette.text.tertiary,
      }}
    />
  );
};
