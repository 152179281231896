import { useCallback } from 'react';
import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import Icon from '../shared/Icon';
import { IconName } from '../shared/Icon/types';
import { AttachmentType, EmailAttachmentV1 } from '@madeinventive/core-types';
import { useAppSelector } from '../../hooks/store';
import { ComponentIcon } from '../../generated/types';

interface AttachmentSelectorProps {
  attachment: EmailAttachmentV1;
  onChange: (attachment: EmailAttachmentV1) => void;
  hasError?: boolean;
}

const AttachmentSelector = ({
  attachment,
  onChange,
  hasError,
}: AttachmentSelectorProps) => {
  const { components } = useAppSelector(
    (state) => state.workspaceComponents.value,
  );

  const component = components?.find(
    (c) => c.id === attachment.spec.componentId,
  );

  const handleChange = useCallback(
    (e: SelectChangeEvent<string>) => {
      const value = e.target.value;
      const component = components?.find((c) => c.name === value);
      if (!component) return;

      const attachment: EmailAttachmentV1 = {
        type: AttachmentType.COMPONENT,
        spec: {
          componentId: component?.id,
        },
      };
      onChange(attachment);
    },
    [components, onChange],
  );

  const IconMap: Record<ComponentIcon, IconName> = {
    [ComponentIcon.BAR]: 'chart-bar',
    [ComponentIcon.LINE]: 'chart-line',
    [ComponentIcon.NONE]: 'text',
    [ComponentIcon.PIE]: 'chart-bar',
    [ComponentIcon.TABLE]: 'table',
    [ComponentIcon.TILE]: 'text',
  };

  return (
    <FormControl fullWidth size='small' error={hasError}>
      <Select
        size='small'
        value={component?.name ?? ''}
        onChange={handleChange}
      >
        {components?.map((c) => (
          <MenuItem key={c.id} value={c.name}>
            <Stack spacing={1} direction='row' width='100%' alignItems='center'>
              <Icon name={IconMap[c.icon]} size='small' color='icon.disabled' />
              <Typography variant='body1'>{c.name}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default AttachmentSelector;
