import { useCallback } from 'react';
import { noop } from 'lodash';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

// components
import DialogActions from '../shared/Dialog/DialogActions';
import FormFieldLabel from '../shared/Form/FormFieldLabel';
import SimpleDropdown, { OptionItem } from '../shared/SimpleDropdown';

// types
import {
  ENVIRONMENT_USER_ROLE_MAP,
  WORKSPACE_USER_ROLE_MAP,
} from '../../utils/Strings';
import {
  EnvironmentMemberRole,
  WorkspaceMemberRole,
} from '../../generated/types';
import { BaseRegisteredDialogComponentProps } from './types';

// hooks
import { useMembers } from '../../hooks/useMembers';

export type Variant = 'environment' | 'workspace';

const environmentRoleOptions: OptionItem[] = Object.entries(
  EnvironmentMemberRole,
)
  .filter(([key]) => key !== EnvironmentMemberRole.ENVIRONMENT_OWNER)
  .map(([key, value]) => ({
    value,
    label: ENVIRONMENT_USER_ROLE_MAP[key],
    testid: `option-${ENVIRONMENT_USER_ROLE_MAP[key]}`,
  }));

const workspaceRoleOptions: OptionItem[] = Object.entries(WorkspaceMemberRole)
  .filter(([key]) => key !== WorkspaceMemberRole.WORKSPACE_OWNER)
  .map(([key, value]) => ({
    value,
    label: WORKSPACE_USER_ROLE_MAP[key],
    testid: `option-${WORKSPACE_USER_ROLE_MAP[key]}`,
  }));

export interface UserDataProps {
  userId?: string;
  firstname: string;
  lastname: string;
  email: string;
  role: EnvironmentMemberRole | WorkspaceMemberRole;
}

export interface CreateOrEditUserDialogProps
  extends BaseRegisteredDialogComponentProps {
  existing?: UserDataProps; // if defined edit, otherwise create
  variant: Variant;
  entityId: string;
}

const CreateOrEditUserDialog = ({
  existing,
  variant,
  entityId,
  hideDialog,
}: CreateOrEditUserDialogProps) => {
  const {
    addEnvironmentMember,
    editEnvironmentMember,
    addWorkspaceMember,
    editWorkspaceMember,
  } = useMembers();

  const FORM_VALIDATION = Yup.object().shape({
    firstname: Yup.string().required('Required information'),
    lastname: Yup.string(),
    email: Yup.string()
      .email('Enter a valid email')
      .required('Email is required'),
  });

  const createOrEditUserOnSubmit = useCallback(
    async (values: UserDataProps) => {
      let result;
      if (variant === 'environment') {
        if (values.userId) {
          // edit
          result = await editEnvironmentMember({
            userId: values.userId,
            firstName: values.firstname,
            lastName: values.lastname,
            email: values.email,
            role: values.role as EnvironmentMemberRole,
          });
        } else {
          result = await addEnvironmentMember({
            environmentId: entityId,
            email: values.email,
            firstName: values.firstname,
            lastName: values.lastname,
            role: values.role as EnvironmentMemberRole,
            sendInvite: true,
          });
        }
      } else if (variant === 'workspace') {
        if (values.userId) {
          result = await editWorkspaceMember({
            userId: values.userId,
            firstName: values.firstname,
            lastName: values.lastname,
            email: values.email,
            role: values.role as WorkspaceMemberRole,
          });
        } else {
          result = await addWorkspaceMember({
            workspaceId: entityId,
            email: values.email,
            firstName: values.firstname,
            lastName: values.lastname,
            role: values.role as WorkspaceMemberRole,
            sendInvite: true,
          });
        }
      } else {
        throw new Error(`Unknown user dialog variant`);
      }

      return result;
    },
    [
      addEnvironmentMember,
      addWorkspaceMember,
      editEnvironmentMember,
      editWorkspaceMember,
      entityId,
      variant,
    ],
  );

  const handleSubmit = useCallback(
    (values: UserDataProps) => {
      return new Promise<boolean>((resolve) => {
        createOrEditUserOnSubmit(values)
          .then((result) => {
            if (result?.errors) {
              resolve(false);
            } else {
              resolve(true);
            }
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
    [createOrEditUserOnSubmit],
  );

  return (
    <Formik
      enableReinitialize={true}
      validateOnBlur={true}
      validateOnChange={true}
      initialValues={
        existing
          ? existing
          : {
              firstname: '',
              lastname: '',
              email: '',
              // default to the least privileged role for the variant
              role:
                variant === 'environment'
                  ? EnvironmentMemberRole.ENVIRONMENT_ADMIN
                  : WorkspaceMemberRole.WORKSPACE_VIEWER,
            }
      }
      validationSchema={FORM_VALIDATION}
      onSubmit={noop}
    >
      {({ values, isValid, dirty, setFieldValue }) => (
        <Form>
          <Stack direction='row' spacing={2}>
            <FormControl sx={{ flexGrow: 1 }}>
              <Stack spacing={1}>
                <FormFieldLabel text='First name' required />
                <Field
                  name='firstname'
                  as={TextField}
                  placeholder='First name'
                  variant='outlined'
                  size='small'
                />
              </Stack>
            </FormControl>
            <FormControl sx={{ flexGrow: 1 }}>
              <Stack spacing={1}>
                <FormFieldLabel text='Last name' />
                <Field
                  name='lastname'
                  as={TextField}
                  placeholder={`Last name`}
                  variant='outlined'
                  size='small'
                />
              </Stack>
            </FormControl>
          </Stack>
          <FormControl fullWidth margin='normal'>
            <Stack spacing={1}>
              <FormFieldLabel text='Email' required />
              <Field
                name='email'
                as={TextField}
                placeholder={`Email`}
                variant='outlined'
                size='small'
              />
            </Stack>
          </FormControl>

          <FormControl fullWidth margin='normal'>
            <Stack mb={1} spacing={1}>
              <FormFieldLabel
                text='Role'
                subText='Select the role for the user. You can always come back and edit later.'
                required
              />
              <Field
                dataTestid='user-role'
                size='small'
                as={SimpleDropdown}
                fullWidth
                value={values.role}
                setValue={(value: string) => setFieldValue('role', value)}
                menuOptions={
                  variant === 'environment'
                    ? environmentRoleOptions
                    : workspaceRoleOptions
                }
              />
            </Stack>
          </FormControl>
          <DialogActions
            closeDialog={hideDialog}
            secondaryAction={{
              text: 'Cancel',
              action: hideDialog,
              disabled: false,
            }}
            primaryAction={{
              text: existing
                ? 'Save changes'
                : `Invite ${variant === 'environment' ? 'user' : 'member'}`,
              asyncAction: () => handleSubmit(values),
              disabled: !isValid || !dirty,
            }}
          />
        </Form>
      )}
    </Formik>
  );
};

export default CreateOrEditUserDialog;
