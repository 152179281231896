import ErrorIcon from '@mui/icons-material/Error';
import { SnackbarAction, SnackbarKey, SnackbarMessage } from 'notistack';
import { forwardRef } from 'react';
import SnackbarContent from './SnackbarContent';

interface SnackbarErrorProps {
  id: SnackbarKey;
  message: SnackbarMessage;
  action: SnackbarAction;
}

const SnackbarError = forwardRef<HTMLDivElement, SnackbarErrorProps>(
  (props, ref) => {
    const { id, message, action } = props;

    return (
      <SnackbarContent
        id={id}
        message={message}
        ref={ref}
        severity='error'
        icon={<ErrorIcon sx={{ '&&': { color: 'error.dark' } }} />}
        textColor='text.primary'
        backgroundColor='error.light'
        action={action}
      />
    );
  },
);

SnackbarError.displayName = 'SnackbarError';

export default SnackbarError;
