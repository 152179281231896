import { ReactNode } from 'react';
import { DialogContent, DialogContentText } from '@mui/material';
import { BaseRegisteredDialogComponentProps } from './types';

interface TextContent {
  heading: string;
  body?: string;
}

export interface InformationProps extends BaseRegisteredDialogComponentProps {
  content: ReactNode | TextContent;
}

const isTextContent = (
  content: ReactNode | TextContent,
): content is TextContent => {
  return (content as TextContent).heading !== undefined;
};

const Information = ({ content }: InformationProps) => {
  if (isTextContent(content)) {
    const { heading, body } = content;
    return (
      <>
        <DialogContentText variant='h4' mb={2}>
          {heading}
        </DialogContentText>
        {body ? (
          <DialogContentText variant='body1'>{body}</DialogContentText>
        ) : (
          ''
        )}
      </>
    );
  }

  return <DialogContent sx={{ padding: 0 }}>{content}</DialogContent>;
};

export default Information;
