import { API_URI } from '../endpoints';
import { setContext } from '@apollo/client/link/context';

export const operationNameLink = setContext((operation, previousContext) => {
  const newUri = `${API_URI}/${operation.operationName}`;
  return {
    headers: {
      ...previousContext.headers,
    },
    uri: newUri,
  };
});
