import { useState, useCallback, useContext } from 'react';
import { Field, Form, Formik } from 'formik';
import FormFieldLabel from '../shared/Form/FormFieldLabel';
import { Box, Stack, TextField, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Button from '../shared/Button';
import {
  useCreateEmbedAuthorizationMutation,
  EnvironmentEmbedAuthorizationsDocument,
} from '../../generated/types';
import { BaseRegisteredDialogComponentProps } from './types';
import { EnvironmentContext } from '../EnvironmentProvider';

export interface CreateEmbedTokenDialogProps
  extends BaseRegisteredDialogComponentProps {}

const initialFormValues = {
  name: '',
  domain: '',
  scope: ['chat'], // The UI for this is removed, but the backend still expects it with a default value
};

const CreateEmbedTokenDialog = () => {
  const [createTokenMutation] = useCreateEmbedAuthorizationMutation({
    refetchQueries: [EnvironmentEmbedAuthorizationsDocument],
  });
  const [generatedToken, setGeneratedToken] = useState<string>('');
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const { environment } = useContext(EnvironmentContext);
  const environmentId = environment?.id ?? '';

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(generatedToken);
    setIsCopied(true);
  }, [generatedToken]);

  const handleFormSubmit = useCallback(
    async (values: typeof initialFormValues) => {
      const convertedScopes = values.scope.reduce(
        (acc, scope) => ({ ...acc, [scope]: true }),
        {},
      );
      const result = await createTokenMutation({
        variables: {
          input: {
            name: values.name,
            environmentId: environmentId,
            authorizedDomain: values.domain,
            authorizedScopes: convertedScopes,
          },
        },
      });

      const created = result.data?.createEmbedAuthorization;
      if (created) {
        setGeneratedToken(created.authToken);
      }
    },
    [createTokenMutation, environmentId],
  );

  return generatedToken ? (
    <Stack>
      <Stack
        minHeight={400}
        justifyContent='center'
        alignItems='center'
        spacing={2}
        pb={6}
      >
        <Box
          sx={{
            width: '90%',
            borderRadius: '4px',
            backgroundColor: 'background.default',
            p: 2,
          }}
        >
          <Typography
            fontWeight='bold'
            width='100%'
            sx={{
              wordWrap: 'break-word',
            }}
          >
            {generatedToken}
          </Typography>
        </Box>

        <Button
          variant='text'
          startIcon={isCopied ? <CheckIcon /> : <ContentCopyIcon />}
          onClick={copyToClipboard}
        >
          {isCopied ? 'Copied to clipboard' : 'Copy token to clipboard'}
        </Button>
      </Stack>
    </Stack>
  ) : (
    <Formik initialValues={initialFormValues} onSubmit={handleFormSubmit}>
      {() => (
        <Form>
          <Stack spacing={1} mb={3}>
            <FormFieldLabel text='Token name' required />
            <Field as={TextField} name='name' size='small' fullWidth required />
          </Stack>
          <Stack spacing={1} mb={3}>
            <FormFieldLabel text='Domain' required />
            <Field
              as={TextField}
              name='domain'
              size='small'
              fullWidth
              required
            />
          </Stack>
          <Stack direction='row' justifyContent='flex-end' py={3}>
            <Button variant='contained' type='submit'>
              Generate token
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default CreateEmbedTokenDialog;
