import { useFormikContext } from 'formik';
import { capitalize } from 'lodash';
import { Stack, Typography } from '@mui/material';

//components
import Button from '../../shared/Button';
import Icon from '../../shared/Icon';
import ReadOnlyCondition from './ReadOnlyCondition';
import ConditionEditor from './ConditionEditor';

import { ConditionFormValues, SettingsFormValues } from '../types';

interface ConditionProps {
  condition: ConditionFormValues;
  index: number;
  selectedFields: string[];
  deleteCondition: (index: number) => void;
}

const Condition = ({
  condition,
  index,
  selectedFields,
  deleteCondition,
}: ConditionProps) => {
  const formik = useFormikContext<SettingsFormValues>();
  const setIsEditing = (isEditing: boolean) => {
    formik.setFieldValue(`conditions.${index}.isEditing`, isEditing);
  };
  const { isEditing } = condition;

  return (
    <Stack>
      {index > 0 && (
        <Stack
          id='chaining-operator'
          width={40}
          height={60}
          borderRight={(theme) =>
            `1px solid ${theme.palette.border.actionable}`
          }
          position='relative'
        >
          <Stack
            bgcolor={(theme) => theme.palette.success.wash}
            borderRadius={6}
            position='absolute'
            left='100%'
            top='50%'
            alignItems='center'
            justifyContent='center'
            padding={0.5}
            sx={{
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Typography
              variant='h6'
              color={(theme) => theme.palette.success.dark}
            >
              {capitalize(condition.chainingOperator)}
            </Typography>
          </Stack>
        </Stack>
      )}
      <Stack
        aria-label='condition'
        padding={2}
        spacing={2}
        width='100%'
        border={(theme) => `1px solid ${theme.palette.border.light}`}
        borderRadius={5}
      >
        {!isEditing && (
          <Stack
            aria-label='read-only-condition-row'
            direction={'row'}
            spacing={2}
            justifyContent='space-between'
            overflow='hidden'
            width='100%'
          >
            <ReadOnlyCondition condition={condition} />
            <Button
              variant='text'
              size='small'
              startIcon={<Icon name='pencil' />}
              title='Edit'
              onClick={() => setIsEditing(true)}
            />
          </Stack>
        )}
        {isEditing && (
          <ConditionEditor
            condition={condition}
            index={index}
            selectedFields={selectedFields}
            deleteCondition={deleteCondition}
            closeEditing={() => setIsEditing(false)}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default Condition;
