import { useState, useCallback, useEffect, useMemo } from 'react';
import { Stack } from '@mui/material';

// components
import Stepper from '../shared/SteppedForm/Stepper';
import SteppedFormLayout, {
  PrimaryButtonConfig,
  SecondaryButtonConfig,
} from '../shared/SteppedForm/SteppedFormLayout';
import Settings from './StepForms/Settings';
import Message from './StepForms/Message';
import Preview from './StepForms/Preview';

// types and form values
import { BaseRegisteredDrawerComponentProps } from '../registeredDrawers/types';
import {
  SettingsFormValues,
  settingsInitialValues,
  settingsValidationSchema,
  MessageFormValues,
  messageInitialValues,
  messageValidationSchema,
  MultiStepFormValues,
} from './types';

// hooks and utils
import { useAppSelector, useFeature } from '../../hooks';
import { formValuesToWorkflowConfig } from './utils';
import { useRouter } from 'next/router';
import { Routes } from '@madeinventive/core-types';
import Alert from '../shared/Alert';
import { FeatureState, IntegrationType } from '../../generated/types';
import { useAlert } from '../../hooks/useAlert';

const steps = ['Settings', 'Message', 'Preview'];

type StepActionsType = {
  settings: {
    primary: PrimaryButtonConfig;
    secondary: SecondaryButtonConfig;
  };
  message: {
    primary: PrimaryButtonConfig;
    secondary: SecondaryButtonConfig;
  };
  preview: {
    primary: PrimaryButtonConfig;
    secondary: SecondaryButtonConfig;
  };
};

export interface AlertSteppedFormDrawerProps
  extends BaseRegisteredDrawerComponentProps {
  isDrawer: true;
  workspaceId: string;
  envExploreId: string;
  vizTitle: string;
  entityId: string;
  // In-page specific properties set to never
  featureId?: never;
  featureValues?: never;
  isLive?: never;
}

export interface AlertSteppedFormInPageProps {
  isDrawer: false;
  workspaceId: string;
  envExploreId: string;
  featureId: string;
  featureValues: MultiStepFormValues;
  isLive: boolean;
  // Drawer-specific properties set to never
  vizTitle?: never;
  entityId?: never;
  hideDrawer?: never;
}

const AlertSteppedForm = ({
  workspaceId,
  envExploreId,
  vizTitle,
  entityId,
  hideDrawer,
  featureId,
  featureValues,
  isLive,
  isDrawer,
}: AlertSteppedFormDrawerProps | AlertSteppedFormInPageProps) => {
  const router = useRouter();
  const [slateEditorResetKey, setSlateEditorResetKey] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const isCreated = !!featureId;
  const [isFormDirty, setIsFormDirty] = useState({
    settings: false,
    message: false,
  }); // true when any of the form fields are dirty

  const { integrations } = useAppSelector((state) => state.workspace.value);

  // our app supports multiple slack integrations, but for now
  // the form assumes we have only one slack integration
  const slackIntegrationId = useMemo(() => {
    // make sure it handles the undefined integrations
    // even though the redux default value is an empty array
    // some subsequent update to the workspace slice makes integrations undefined.
    return integrations?.find((i) => i.type === IntegrationType.SLACK_V1)?.id;
  }, [integrations]);

  const {
    getDefaultAlertEmailContent,
    createLiveAlert,
    updateLiveOrDraftAlert,
    saveAsDraft,
    createAlertError,
  } = useAlert(workspaceId);

  const { recipients, emailSubject, buttonText, buttonLink } = isDrawer
    ? getDefaultAlertEmailContent(vizTitle, entityId)
    : {
        recipients: [],
        emailSubject: '',
        buttonText: '',
        buttonLink: '',
      };

  // updated only when user goes to next step
  const [savedFormValues, setSavedFormValues] = useState<MultiStepFormValues>(
    featureValues ?? {
      settings: settingsInitialValues,
      message: {
        ...messageInitialValues,
        recipients,
        emailSubject,
        buttonText,
        buttonLink,
      },
    },
  );

  // update feature values when featureId changes
  useEffect(() => {
    if (featureValues) {
      setSavedFormValues(featureValues);
    }
  }, [featureValues]);

  const { setExploreId, setFeatureEditMode } = useFeature();

  const goNext = useCallback(() => {
    setActiveStep((prev) => prev + 1);
  }, []);

  const goBack = useCallback(() => {
    setActiveStep((prev) => prev - 1);
  }, []);

  const saveSettingsAndContinue = useCallback(
    (values: SettingsFormValues) => {
      setSavedFormValues((prev) => ({ ...prev, settings: values }));
      goNext();
    },
    [goNext],
  );

  const saveMessageAndContinue = useCallback(
    (values: MessageFormValues) => {
      setSavedFormValues((prev) => ({ ...prev, message: values }));
      goNext();
    },
    [goNext],
  );

  // only for non-drawer mode
  const updateAlert = useCallback(
    async (updateValues?: MultiStepFormValues) => {
      if (!isCreated) return;
      const config = formValuesToWorkflowConfig(
        updateValues ?? savedFormValues,
        envExploreId,
        slackIntegrationId,
      );

      await updateLiveOrDraftAlert({
        featureId,
        workflowConfig: config,
      });
    },
    [
      isCreated,
      savedFormValues,
      envExploreId,
      slackIntegrationId,
      updateLiveOrDraftAlert,
      featureId,
    ],
  );

  const saveAsLive = useCallback(async () => {
    const config = formValuesToWorkflowConfig(savedFormValues, envExploreId);

    if (!isDrawer) {
      // if it is already created, update the alert and set the feature to actives
      await updateAlert(savedFormValues);
      await setFeatureEditMode(featureId, FeatureState.ACTIVE);
    } else {
      // if it is a new alert, create a live alert
      const result = await createLiveAlert({
        workflowConfig: config,
        entityId,
      });

      if (hideDrawer) {
        hideDrawer();
      }

      const componentId = result.data?.createWorkflowFromViz?.component?.id;
      if (componentId) {
        router.push(
          Routes.chat(workspaceId, undefined, undefined, componentId),
        );
      }
    }
  }, [
    savedFormValues,
    envExploreId,
    isDrawer,
    updateAlert,
    setFeatureEditMode,
    featureId,
    createLiveAlert,
    entityId,
    hideDrawer,
    router,
    workspaceId,
  ]);

  // only for drawer mode
  const createDraftAlert = useCallback(
    async (updatedValues?: MultiStepFormValues) => {
      if (!isDrawer) return;
      const config = formValuesToWorkflowConfig(
        updatedValues ?? savedFormValues,
        envExploreId,
      );
      const result = await saveAsDraft({
        workflowConfig: config,
        entityId,
      });
      if (hideDrawer) hideDrawer();
      const featureId = result.data?.createWorkflowFromViz?.feature?.id;
      if (featureId) {
        await router.push(Routes.feature(workspaceId, featureId));
      }
    },
    [
      isDrawer,
      entityId,
      hideDrawer,
      router,
      saveAsDraft,
      savedFormValues,
      workspaceId,
      envExploreId,
    ],
  );

  const saveSettingsAsDraft = useCallback(
    async (values: SettingsFormValues) => {
      const updatedValues = {
        ...savedFormValues,
        settings: {
          ...values,
        },
      };
      setSavedFormValues(updatedValues);
      if (isCreated) {
        updateAlert(updatedValues);
      } else {
        createDraftAlert(updatedValues);
      }
    },
    [createDraftAlert, updateAlert, isCreated, savedFormValues],
  );

  const saveMessageAsDraft = useCallback(
    async (values: MessageFormValues) => {
      const updatedValues = {
        ...savedFormValues,
        message: {
          ...values,
        },
      };
      setSavedFormValues(updatedValues);
      if (isCreated) {
        updateAlert(updatedValues);
      } else {
        createDraftAlert(updatedValues);
      }
    },
    [createDraftAlert, updateAlert, isCreated, savedFormValues],
  );

  const savePreviewAsDraft = useCallback(async () => {
    createDraftAlert();
  }, [createDraftAlert]);

  // env exploreId is required to get explore data schema
  useEffect(() => {
    if (envExploreId) {
      setExploreId(envExploreId);
    }
  }, [envExploreId, setExploreId]);

  // unlike the discardStepChange prop that resets the single step form values,
  // this function resets the all step form values
  const discardAllStepChanges = useCallback(() => {
    setSavedFormValues(
      featureValues ?? {
        settings: settingsInitialValues,
        message: {
          ...messageInitialValues,
          recipients,
          emailSubject,
          buttonText,
          buttonLink,
        },
      },
    );
    setActiveStep(0);
  }, [featureValues, recipients, emailSubject, buttonText, buttonLink]);

  const stepActions = useMemo<StepActionsType>(
    () => ({
      settings: {
        primary: {
          text: isLive ? 'Continue to message' : 'Continue',
          action: saveSettingsAndContinue,
        },
        secondary: {
          text: isLive ? 'Discard changes' : 'Save as draft',
          action: saveSettingsAsDraft,
          shouldDiscardStepChange: isLive,
          disabledWhenStepClean: isLive,
        },
      },
      message: {
        primary: {
          text: isLive ? 'Continue to preview' : 'Continue',
          action: saveMessageAndContinue,
        },
        secondary: {
          text: isLive ? 'Discard changes' : 'Save as draft',
          action: saveMessageAsDraft,
          shouldDiscardStepChange: isLive,
          disabledWhenStepClean: isLive,
        },
      },
      preview: {
        primary: {
          text: isLive ? 'Update alert' : 'Create live alert',
          action: isLive ? () => updateAlert() : saveAsLive,
          disabledWhenAllStepsClean: isLive,
        },
        secondary: {
          text: isLive ? 'Discard changes' : 'Save as draft',
          action: isLive ? discardAllStepChanges : savePreviewAsDraft,
          disabledWhenAllStepsClean: isLive,
        },
      },
    }),
    [
      isLive,
      saveAsLive,
      updateAlert,
      saveMessageAndContinue,
      saveMessageAsDraft,
      savePreviewAsDraft,
      saveSettingsAndContinue,
      saveSettingsAsDraft,
      discardAllStepChanges,
    ],
  );

  return (
    <Stack
      id='create-alert-form'
      height='100%'
      flex={1}
      margin={isDrawer ? -2 : 0}
    >
      <Stepper activeStep={activeStep} steps={steps} />
      {createAlertError && (
        <Alert severity='error'>
          <strong>Alert creation failed</strong> Please retry or contact support
          if the issue persists.
        </Alert>
      )}
      <SteppedFormLayout
        index={0}
        activeIndex={activeStep}
        id='step-one-container'
        goBack={goBack}
        initialFormValues={savedFormValues.settings}
        validationSchema={settingsValidationSchema}
        primaryButtonConfig={stepActions.settings.primary}
        secondaryButtonConfig={stepActions.settings.secondary}
        onDirtyChange={(isDirty) => {
          setIsFormDirty((prev) => ({ ...prev, settings: isDirty }));
        }}
      >
        <Settings workspaceId={workspaceId} />
      </SteppedFormLayout>
      <SteppedFormLayout
        index={1}
        activeIndex={activeStep}
        id='step-two-container'
        goBack={goBack}
        initialFormValues={savedFormValues.message}
        validationSchema={messageValidationSchema}
        primaryButtonConfig={stepActions.message.primary}
        secondaryButtonConfig={stepActions.message.secondary}
        onDirtyChange={(isDirty) =>
          setIsFormDirty((prev) => ({ ...prev, message: isDirty }))
        }
        onResetForm={() => {
          setSlateEditorResetKey((prev) => prev + 1);
        }}
      >
        <Message
          workspaceId={workspaceId}
          slateEditorResetKey={slateEditorResetKey}
          slackIntegrationId={slackIntegrationId}
          disableDynamicField={
            savedFormValues.settings.actionTriggerType === 'schedule'
          }
        />
      </SteppedFormLayout>
      <SteppedFormLayout
        index={2}
        activeIndex={activeStep}
        id='step-three-container'
        goBack={goBack}
        initialFormValues={{}}
        primaryButtonConfig={stepActions.preview.primary}
        secondaryButtonConfig={stepActions.preview.secondary}
        isFormDirty={isFormDirty.settings || isFormDirty.message}
      >
        <Preview formValues={savedFormValues} vizTitle={vizTitle ?? ''} />
      </SteppedFormLayout>
    </Stack>
  );
};

export default AlertSteppedForm;
