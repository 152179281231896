import { PaletteOptions } from '@mui/material';
import { alpha, lighten, darken } from '@mui/system';
import { ColorMode } from '../contexts/ColorModeContext';

type NeutralColors = {
  shadow: string;
  white: string;
  black: string;
  b1: string;
  b2: string;
  b3: string;
  b4: string;
  b5: string;
  b6: string;
};

// Define light mode neutrals
const lightNeutrals: NeutralColors = {
  shadow: '#00046A',
  white: '#FFFFFF',
  black: '#1C1C1C',
  b1: '#313131',
  b2: '#565656',
  b3: '#CFCFCF',
  b4: '#DFDFDF',
  b5: '#EEEEEE',
  b6: '#F9F9F9',
};

// Define dark mode neutrals (reversed)
const darkNeutrals: NeutralColors = {
  shadow: '#C4C6FF',
  white: '#1C1C1C',
  black: '#FEFEFE',
  b6: '#313131', // Reversed order of b1-b6
  b5: '#565656',
  b4: '#727272',
  b3: '#A9A9A9',
  b2: '#C2C2C2',
  b1: '#DCDCDC',
};

const getColors = (neutrals: NeutralColors) => {
  const { white, black, b1, b2, b3, b4, b5, b6 } = neutrals;

  return {
    // primary
    primary: '#D4D6EC',
    primaryLight: '#DCDEEF',
    primaryDark: '#9495A5',
    // status colors
    errorDark: '#993A2C',
    errorMain: '#D05340',
    errorWash: '#FFEBED',
    warningDark: '#CE8308',
    warningMain: '#EFD263',
    warningWash: '#FFF9E2',
    successDark: '#385B4A',
    successMain: '#5B8A72',
    successWash: '#EDFCEF',
    infoDark: '#1F305E',
    infoMain: '#6082B6',
    infoWash: '#E8F3FF',

    // neutral-dependent colors
    text: {
      primary: black,
      secondary: b1,
      tertiary: b2,
      disabled: b2,
      inverted: white,
    },
    border: {
      actionable: b3,
      disabled: b4,
      light: b5,
      active: black,
    },
    icon: {
      main: b1,
      disabled: b3,
      active: black,
      inverted: white,
    },
    background: {
      paper: white,
      default: b6,
      disabled: b6,
      inverted: black,
      overlay: alpha(black, 0.4),
      gradient:
        'linear-gradient(90deg, rgba(212, 214, 236, 0.08) 0%, rgba(79, 82, 234, 0.08) 56%, rgba(131, 103, 199, 0.08) 100%)',
    },
    neutrals,
  };
};

export const getPalette = (
  mode: ColorMode,
  customBrandingColor?: string,
): PaletteOptions => {
  const colors = getColors(mode === 'light' ? lightNeutrals : darkNeutrals);
  const modifier = mode === 'light' ? lighten : darken;

  const primaryColors = customBrandingColor
    ? {
        dark: modifier(customBrandingColor, 0.2),
        main: customBrandingColor,
        light: modifier(customBrandingColor, 0.4),
        wash: modifier(customBrandingColor, 0.8),
        contrastText: colors.neutrals.black,
      }
    : {
        dark: colors.primaryDark,
        main: colors.primary,
        light: colors.primaryLight,
        wash: modifier(colors.primaryLight, 0.8),
        contrastText: colors.neutrals.black,
      };

  return {
    primary: primaryColors,
    // same as neutral
    secondary: {
      dark: colors.neutrals.b2,
      main: colors.neutrals.b3,
      light: colors.neutrals.b4,
      wash: colors.neutrals.b5,
      contrastText: colors.neutrals.black,
    },
    // same as secondary
    neutral: {
      dark: colors.neutrals.b2,
      main: colors.neutrals.b3,
      light: colors.neutrals.b4,
      wash: colors.neutrals.b5,
      contrastText: colors.neutrals.black,
    },
    error: {
      dark: colors.errorDark,
      main: colors.errorMain,
      light: modifier(colors.errorMain, 0.4),
      wash: colors.errorWash,
      contrastText: colors.neutrals.white,
    },
    warning: {
      dark: colors.warningDark,
      main: colors.warningMain,
      light: modifier(colors.warningMain, 0.4),
      wash: colors.warningWash,
      contrastText: colors.neutrals.white,
    },
    success: {
      dark: colors.successDark,
      main: colors.successMain,
      light: modifier(colors.successMain, 0.4),
      wash: colors.successWash,
      contrastText: colors.neutrals.white,
    },
    info: {
      dark: colors.infoDark,
      main: colors.infoMain,
      light: modifier(colors.infoMain, 0.4),
      wash: colors.infoWash,
      contrastText: colors.neutrals.white,
    },
    text: colors.text,
    border: colors.border,
    icon: colors.icon,
    background: colors.background,
    neutrals: colors.neutrals,
    accents: {
      accent1: '#8C8FBE',
      accent2: '#4B4F6F',
      accent3: '#61A1A2',
      accent4: '#65997F',
      accent5: '#53706F',
      accent6: '#6587A6',
      accent7: '#6874A3',
      accent8: '#807198',
      accent9: '#63537E',
      accent10: '#9D6995',
      accent11: '#BB7E8C',
      accent12: '#673A3F',
    },
    heatmap: {
      heatmap1: '#E3FFFF',
      heatmap2: '#ACD5D6',
      heatmap3: '#61A1A2',
      heatmap4: '#006D6F',
      heatmap5: '#006D6F',
    },
    diverging: {
      diverging1: '#3E0336',
      diverging2: '#9D6995',
      diverging3: '#DFC6E7',
      diverging4: '#C4E9EA',
      diverging5: '#61A1A2',
      diverging6: '#006D6F',
    },
    action: {
      level1: alpha(colors.neutrals.black, 0.04), // table odd row
      level2: alpha(colors.neutrals.black, 0.08), // table row hover
      level3: alpha(colors.neutrals.black, 0.12), // table row selected
    },
  };
};
