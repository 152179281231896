import {
  PeopleOutline as PeopleOutlineIcon,
  ShareOutlined as ShareOutlinedIcon,
} from '@mui/icons-material';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import DatabaseIcon from '../../shared/icons/DatabaseIcon';
import StyledAvatar from './StyledAvatar';

// types
import { UseSessionInfo } from '../../../hooks/session';

export enum SideBarItemId {
  USER_SETTINGS = 'USER_SETTINGS',
  WORKSPACE_GENERAL = 'WORKSPACE_GENERAL',
  WORKSPACE_USERS = 'WORKSPACE_USERS',
  WORKSPACE_DATA = 'WORKSPACE_DATA',
  WORKSPACE_INTEGRATIONS = 'WORKSPACE_INTEGRATIONS',
  WORKSPACE_ANALYTICS = 'WORKSPACE_ANALYTICS',
}

export interface SidebarItem {
  id: SideBarItemId;
  disabled: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
  desc: string;
  hidden?: boolean;
}

export interface SidebarItemsGroup {
  header: string;
  hidden?: boolean;
  items: SidebarItem[];
}

export const getSidebarItems = (
  isWorkspace: boolean,
  sessionUser: UseSessionInfo,
  isDataManagementEnabled: boolean,
): SidebarItemsGroup[] => {
  return [
    {
      header: 'USER SETTINGS',
      items: [
        {
          id: SideBarItemId.USER_SETTINGS,
          disabled: false,
          icon: StyledAvatar,
          desc: `${sessionUser.firstName} ${sessionUser.lastName}`,
        },
      ],
    },
    {
      header: 'WORKSPACE SETTINGS',
      hidden: !isWorkspace || sessionUser.isWorkspaceViewer,
      items: [
        {
          id: SideBarItemId.WORKSPACE_GENERAL,
          disabled: false,
          icon: BusinessOutlinedIcon,
          desc: 'General',
        },
        {
          id: SideBarItemId.WORKSPACE_USERS,
          disabled: false,
          icon: PeopleOutlineIcon,
          desc: 'Users',
        },
        ...(isDataManagementEnabled
          ? [
              {
                id: SideBarItemId.WORKSPACE_DATA,
                disabled: false,
                icon: DatabaseIcon,
                desc: 'Data',
              },
            ]
          : []),
        {
          id: SideBarItemId.WORKSPACE_INTEGRATIONS,
          disabled: false,
          icon: ShareOutlinedIcon,
          desc: 'Integrations',
        },
        {
          id: SideBarItemId.WORKSPACE_ANALYTICS,
          disabled: false,
          icon: AssessmentOutlinedIcon,
          desc: 'Analytics',
        },
      ],
    },
  ];
};
