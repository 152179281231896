import { useCallback, useState, useMemo } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Button from '../components/shared/Button';
import { useDialog } from './useDialog';

import {
  UserDataProps,
  Variant,
} from '../components/registeredDialogs/CreateOrEditUserDialog';
import { useMembers } from './useMembers';
import { DIALOG_IDS } from '../components/registeredDialogs/dialogRegistry';

const getFullUserName = (firstname: string, lastname?: string) =>
  lastname ? `${firstname} ${lastname}` : `${firstname}`;

export const useUserManagement = (
  variant: Variant,
  entityId: string, // the environment/workspace id depending on the 'variant'
) => {
  const [selectedUser, setSelectedUser] = useState<UserDataProps | undefined>(
    undefined,
  );

  // this sets up the deactivate user dialog.
  // The details of the dialog are defined when the dialog is called
  // based on the selected user
  const deactivateDialogHeading = useMemo(() => {
    if (!selectedUser) {
      return '';
    }
    const { firstname, lastname } = selectedUser;
    return `Are you sure you want to deactivate ${getFullUserName(
      firstname,
      lastname,
    )}`;
  }, [selectedUser]);

  const { showDialog: showDeactivateUserConfirmationDialog } = useDialog({
    id: DIALOG_IDS.INFORMATION,
    title: 'Deactivate user',
    contentProps: {
      content: {
        heading: deactivateDialogHeading,
        body: `Deactivated users will not be able to long in or access the system`,
      },
    },
  });

  const { deactivateMember } = useMembers();

  // this sets up the create/edit user dialog.
  // The details of the dialog are defined when the dialog is called
  // based on the trigger (create or edit)
  const { showDialog: showUserManagementDialog } = useDialog({
    id: DIALOG_IDS.CREATE_OR_EDIT_USER,
    title: '',
    contentProps: {
      existing: selectedUser,
      variant,
      entityId,
    },
  });

  const launchCreateUser = useCallback(() => {
    showUserManagementDialog({
      title: `Invite ${
        variant === 'environment' ? 'global user' : 'workspace member'
      }`,
    });
  }, [showUserManagementDialog, variant]);

  const launchEditUser = useCallback(
    (user: UserDataProps) => {
      setSelectedUser(user);
      showUserManagementDialog({
        title: `Edit ${
          variant === 'environment' ? 'global user' : 'workspace member'
        }`,
      });
    },
    [showUserManagementDialog, variant],
  );

  const launchDeactivateUser = useCallback(
    (user: UserDataProps) => {
      const { userId } = user;
      if (!userId) {
        return;
      }
      setSelectedUser(user);
      showDeactivateUserConfirmationDialog({
        primaryAction: {
          text: 'Yes, Deactivate',
          asyncAction: async () => {
            const result = await deactivateMember(
              {
                userId,
              },
              variant,
            );
            return !!result;
          },
        },
        secondaryAction: {
          text: 'Cancel',
        },
      });
    },
    [showDeactivateUserConfirmationDialog, deactivateMember, variant],
  );

  const renderCreateButton = useCallback(() => {
    return (
      <Button
        variant='outlined'
        onClick={launchCreateUser}
        startIcon={<AddIcon />}
      >
        {variant === 'environment' ? 'Invite user' : 'Invite member'}
      </Button>
    );
  }, [launchCreateUser, variant]);

  return {
    launchCreateUser,
    launchEditUser,
    launchDeactivateUser,
    renderCreateButton,
  };
};
