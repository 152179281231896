import { ApolloLink } from '@apollo/client';
import { getCustomHeaders } from '../authHelpers';

export const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...getCustomHeaders(),
    },
  }));

  return forward(operation);
});
