import { styled } from '@mui/material/styles';
import { Typography, Stack } from '@mui/material';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';

const PulsingStopIcon = styled(DoNotDisturbAltIcon)(() => ({
  animation: 'pulsing 3s ease-in infinite',
  '@keyframes pulsing': {
    '0%': {
      transform: 'translate(-50%, -50%) scale(0.95)',
    },
    '10%': {
      transform: 'translate(-50%, -50%) scale(0.8)',
    },
    '15%': {
      transform: 'translate(-50%, -50%) scale(0.95)',
    },
    '20%': {
      transform: 'translate(-50%, -50%) scale(0.9)',
    },
    '30%': {
      transform: 'translate(-50%, -50%) scale(1)',
    },
    '100%': {
      transform: 'translate(-50%, -50%) scale(1)',
    },
  },
}));

export interface AccessDeniedProps {
  actionButton?: React.ReactNode;
}

const AccessDenied = (props: AccessDeniedProps) => {
  const { actionButton } = props;
  return (
    <Stack alignItems='center' justifyContent='center' height='100vh'>
      <Stack
        direction='row'
        spacing={2}
        mx='auto'
        width='600px'
        height='auto'
        justifyContent='center'
        alignItems='center'
      >
        <Stack flex={1} spacing={3} alignItems='start'>
          <Typography variant='h1'>Uh-oh! Access denied.</Typography>
          <Typography variant='body1'>
            {`You don't have permission to access this page.`}
          </Typography>
          {actionButton && actionButton}
        </Stack>
        <Stack sx={{ position: 'relative' }}>
          <MeetingRoomIcon
            sx={{
              fontSize: '160px',
            }}
            color='neutral'
          />
          <PulsingStopIcon
            sx={{
              position: 'absolute',
              top: '75%',
              left: '75%',
              fontSize: '120px',
            }}
            color='error'
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AccessDenied;
