import { useCallback, useMemo, useState } from 'react';
import { WatchQueryFetchPolicy } from '@apollo/client';
import { useFeatureTemplateCategoriesQuery } from '../generated/types';

export interface CategoryItem {
  id: string;
  name: string;
}

export const ALL_TEMPLATES: CategoryItem = {
  id: '__ALL_TEMPLATES__',
  name: 'All templates',
};

export const UNCATEGORIZED: CategoryItem = {
  id: '__UNCATEGORIZED__',
  name: 'Uncategorized',
};

export const useTemplateCategories = (fetchPolicy?: WatchQueryFetchPolicy) => {
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined);
  const { data: categoryData } = useFeatureTemplateCategoriesQuery({
    fetchPolicy,
  });

  const categoryLookups = useMemo(() => {
    const lookupById: Record<string, CategoryItem> = {};
    const lookupByName: Record<string, CategoryItem> = {};
    if (categoryData?.viewer.user?.__typename === 'EnvironmentMember') {
      categoryData.viewer.user.environment.featureTemplateCategories.forEach(
        (category) => {
          const item: CategoryItem = { id: category.id, name: category.name };
          lookupById[item.id] = item;
          lookupByName[item.name] = item;
        },
      );
    } else if (categoryData?.viewer.user?.__typename === 'WorkspaceMember') {
      categoryData.viewer.user.workspace.featureTemplateCategories.forEach(
        (category) => {
          const item: CategoryItem = { id: category.id, name: category.name };
          lookupById[item.id] = item;
          lookupByName[item.name] = item;
        },
      );
    }
    return {
      byId: lookupById,
      byName: lookupByName,
    };
  }, [categoryData]);

  const categoryNames = useMemo(
    () => Object.keys(categoryLookups.byName).sort(),
    [categoryLookups],
  );

  const categoryGroupings = useMemo(
    () => [
      ALL_TEMPLATES,
      ...categoryNames.map((n) => categoryLookups.byName[n]),
      UNCATEGORIZED,
    ],
    [categoryNames, categoryLookups],
  );

  const getCategoryGroupingById = useCallback(
    // returns ALL_TEMPLATES if id not found
    (groupingId: string) =>
      categoryGroupings.find((grouping) => grouping.id === groupingId) ??
      ALL_TEMPLATES,
    [categoryGroupings],
  );

  const isCategoryGroupingEditable = useCallback(
    (groupingId: string) => {
      const resolvedId = getCategoryGroupingById(groupingId).id;
      return resolvedId !== ALL_TEMPLATES.id && resolvedId !== UNCATEGORIZED.id;
    },
    [getCategoryGroupingById],
  );

  const getNameById = useCallback(
    (id: string): string | undefined => categoryLookups.byId[id]?.name,
    [categoryLookups],
  );

  const getIdByName = useCallback(
    (name: string): string | undefined => categoryLookups.byName[name]?.id,
    [categoryLookups],
  );

  const selectByName = useCallback(
    (name: string | undefined) => {
      setSelectedId(name ? getIdByName(name) : undefined);
    },
    [getIdByName, setSelectedId],
  );

  const selectedName = useMemo(
    () => (selectedId ? getNameById(selectedId) : undefined),
    [selectedId, getNameById],
  );

  return {
    selectedId,
    selectedName,
    categoryNames,

    // sorted CategoryItems, including ALL_TEMPLATES & UNCATEGORIZED
    // representing all possible ways to group the templates by categories
    categoryGroupings,
    getCategoryGroupingById,
    isCategoryGroupingEditable,

    selectByName,
    selectById: setSelectedId,

    getNameById,
    getIdByName,
  };
};
