import { useEffect, useState, useCallback } from 'react';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import DynamicFieldInput, {
  DynamicFieldInputProps,
} from '../DynamicFieldInput';
import CreateIcon from '@mui/icons-material/Create';
import PreviewIcon from '@mui/icons-material/Preview';

enum Mode {
  WRITE = 'WRITE',
  PREVIEW = 'PREVIEW',
}

const HtmlDynamicFieldInput = (props: DynamicFieldInputProps) => {
  const {
    onValueChange,
    initialValue,
    className,
    disableDynamicField,
    ...rest
  } = props;

  // decodedValue is rendered as html
  const [decodedValue, setDecodedValue] = useState(initialValue);
  const [mode, setMode] = useState(Mode.WRITE);

  // ensure decodedValue is up-to-date when `initialValue` prop changes
  useEffect(() => {
    const decodedValue = initialValue
      .replaceAll('&lt;', '<')
      .replaceAll('&gt;', '>');
    setDecodedValue(decodedValue);
  }, [initialValue]);

  const handleValueChange = useCallback(
    (value: string) => {
      const decodedValue = value
        .replaceAll('&lt;', '<')
        .replaceAll('&gt;', '>');
      setDecodedValue(decodedValue);
      onValueChange && onValueChange(decodedValue);
    },
    [onValueChange],
  );

  const togglePreview = useCallback(
    (_: React.MouseEvent<HTMLElement>, newMode: Mode | null) => {
      if (newMode !== null) {
        setMode(newMode);
      }
    },
    [],
  );

  // encodedValue escapes html tags
  // and the htmlDynamicInput will show tags as it is.
  const encodedValue = decodedValue
    .replaceAll('<', '&lt;')
    .replaceAll('>', '&gt;');

  return (
    <>
      <ToggleButtonGroup
        value={mode}
        exclusive
        onChange={togglePreview}
        aria-label='Platform'
        size='small'
        color='primary'
        sx={{
          borderBottom: 0,
          mb: 1,
        }}
      >
        <ToggleButton value={Mode.WRITE}>
          <CreateIcon />
        </ToggleButton>
        <ToggleButton value={Mode.PREVIEW}>
          <PreviewIcon />
        </ToggleButton>
      </ToggleButtonGroup>
      {mode === Mode.WRITE ? (
        <DynamicFieldInput
          initialValue={encodedValue}
          onValueChange={handleValueChange}
          className={`${className}`}
          disableDynamicField={disableDynamicField}
          {...rest}
        />
      ) : (
        <>
          {/* If tags are not required in the preview mode, we don't need to use DynamicFieldInput */}
          <DynamicFieldInput
            disabled={true}
            initialValue={decodedValue || ''}
            onValueChange={handleValueChange}
            className={`${className}`}
            disableDynamicField={disableDynamicField}
            {...rest}
          />
        </>
      )}
    </>
  );
};

export default HtmlDynamicFieldInput;
