import { Shadows } from '@mui/material/styles/shadows';

const shadow1 =
  '0px 0px 6px -1px rgba(0, 4, 106, 0.06), 0px 0px 3px -1px rgba(0, 4, 106, 0.03)';
const shadow2 =
  '0px 0px 12px -3px rgba(0, 4, 106, 0.08), 0px 0px 4px -2px rgba(0, 4, 106, 0.04)';
const shadow3 =
  '0px 0px 30px -12px rgba(0, 4, 106, 0.08), 0px 0px 4px -1px rgba(0, 4, 106, 0.12)';

export const customShadows = ['none', shadow1, shadow2, shadow3];

export const shadows: Shadows = [
  'none', // shadows[0]
  shadow1, // shadows[1], Paper
  shadow1,
  shadow1,
  shadow2, // shadows[4], AppBar
  shadow2,
  shadow2,
  shadow2,
  shadow2,
  shadow2,
  shadow2,
  shadow2,
  shadow2,
  shadow2,
  shadow2,
  shadow2,
  shadow3, // shadows[16], Drawer
  shadow3,
  shadow3,
  shadow3,
  shadow3,
  shadow3,
  shadow3,
  shadow3, // shadows[24], Modal/Popover/Dialog
  shadow3,
];
