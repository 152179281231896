import { useCallback } from 'react';
import Button, { ButtonProps } from '../Button';
import Icon from '../Icon';
import { IconName } from '../Icon/types';
import { Stack, Typography, Theme } from '@mui/material';

export interface ClickableCardProps extends ButtonProps {
  label: string;
  selected: boolean;
  onClick: () => void;
  error?: boolean;
  icon: IconName;
  title: string;
  text?: string;
}

const ClickableCard = ({
  label,
  selected,
  onClick,
  error,
  icon,
  title,
  text,
  ...rest
}: ClickableCardProps) => {
  const getBorderColor = useCallback(
    (theme: Theme) => {
      if (error) {
        return theme.palette.error.main;
      }
      return selected
        ? theme.palette.border.active
        : theme.palette.border.actionable;
    },
    [error, selected],
  );

  return (
    <Button
      size='large'
      variant='outlined'
      onClick={onClick}
      key={`${label}${selected ? '-selected' : ''}`}
      sx={{
        flex: 1,
        borderRadius: '8px',
        p: 2,
        borderColor: (theme) => getBorderColor(theme),
        backgroundColor: selected
          ? (theme) => theme.palette.primary.wash
          : (theme) => theme.palette.background.paper,
        // unset the focus color
        '&:focus': {
          borderColor: (theme) => getBorderColor(theme),
          backgroundColor: selected
            ? (theme) => theme.palette.primary.wash
            : (theme) => theme.palette.background.paper,
        },
      }}
      {...rest}
    >
      <Stack
        spacing={1.5}
        alignItems='flex-start'
        sx={{ textAlign: 'left' }}
        width='100%'
      >
        <Icon name={icon} />
        <Typography variant='h6'>{title}</Typography>
        {text && (
          <Typography variant='body2' color='text.secondary'>
            {text}
          </Typography>
        )}
      </Stack>
    </Button>
  );
};

export default ClickableCard;
