import { Stack, Typography } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';
import GridOnIcon from '@mui/icons-material/GridOn';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import { EmailAttachmentType } from '../../generated/types';

interface EmailAttachmentProps {
  name: string;
  type: EmailAttachmentType;
  disabled?: boolean;
}

const EmailAttachment = ({ name, type, disabled }: EmailAttachmentProps) => {
  return (
    <Stack
      direction='row'
      border={(theme) => `1px solid ${theme.palette.border.light}`}
      padding={0.5}
      alignItems='center'
      spacing={1}
      borderRadius={1}
      color={(theme) =>
        disabled ? theme.palette.text.disabled : theme.palette.text.primary
      }
    >
      <Stack
        sx={{
          fontSize: '3rem',
          alignItems: 'center',
        }}
      >
        <IconComponent type={type} color='inherit' fontSize='inherit' />
      </Stack>
      <Typography variant='body1' color='inherit'>
        {name}
      </Typography>
    </Stack>
  );
};

export default EmailAttachment;

interface IconComponentProps extends SvgIconProps {
  type: EmailAttachmentType;
}

const IconComponent = ({ type, ...rest }: IconComponentProps) => {
  if (type === EmailAttachmentType.IMAGE) {
    return <ImageOutlinedIcon {...rest} />;
  }
  if (type === EmailAttachmentType.PDF) {
    return <ArticleOutlinedIcon {...rest} />;
  }
  if (type === EmailAttachmentType.CSV) {
    return <GridOnIcon {...rest} />;
  }
  return <AttachFileOutlinedIcon {...rest} />;
};
