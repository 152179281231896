import { Stack } from '@mui/material';
import PoweredByLogo from './Logo';

const PoweredByRibbon = () => {
  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='center'
      spacing={1}
      sx={(theme) => ({
        position: 'absolute',
        bottom: 0,
        right: '1rem',
        height: 40,
        backgroundColor: theme.palette.background.default,
        borderRadius: '4px 4px 0 0',
        paddingLeft: '1rem',
        paddingRight: '0.5rem',
      })}
    >
      <PoweredByLogo />
    </Stack>
  );
};

export default PoweredByRibbon;
