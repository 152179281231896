import React, { useCallback, useState } from 'react';

import { FormControl, Grid } from '@mui/material';
import FormInput from '../Form/FormInput';
import {
  RELATIVE_DATE_UNIT_LABELS,
  RelativeDateUnit,
  RelativeDateV1,
} from '@madeinventive/core-types';
import SimpleDropdown from '../SimpleDropdown';
import { RelativeDateDropdownOption } from '../../ConditionForm/ConditionValueInput';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

type RelativeDateInputProps = {
  value?: RelativeDateV1;
  disabled?: boolean;
  onChange: (values: RelativeDateV1) => void;
  hideLabel?: boolean;
  hasError?: boolean;
};

const relativeDateUnitOptions: RelativeDateDropdownOption[] = Object.entries(
  RELATIVE_DATE_UNIT_LABELS,
).map(([value, label]) => ({ value: value as RelativeDateUnit, label }));

const RelativeDateInput = ({
  value,
  disabled,
  onChange,
  hideLabel,
  hasError,
}: RelativeDateInputProps) => {
  const [relativeDateUnit, setRelativeDateUnit] = useState<
    RelativeDateV1['unit'] | undefined
  >(value?.unit);
  const [relativeDateValue, setRelativeDateValue] = useState<
    number | undefined
  >(value?.value);

  const onValueChange = useCallback(
    (inputValue: string) => {
      const parsedValue = Number(inputValue);
      if (isNaN(parsedValue)) {
        return;
      } else {
        setRelativeDateValue(Number(inputValue));
      }

      if (relativeDateUnit) {
        onChange({
          value: parsedValue,
          unit: relativeDateUnit,
        });
      }
    },
    [onChange, relativeDateUnit],
  );

  const onUnitChange = useCallback(
    (inputValue: string) => {
      // The "as RelativeDateV1['unit']" type assertion is safe because we build the dropdown option values from the same type.
      const typedInputValue = inputValue as RelativeDateV1['unit'];
      setRelativeDateUnit(typedInputValue);

      if (relativeDateValue) {
        onChange({
          value: relativeDateValue,
          unit: typedInputValue,
        });
      }
    },
    [onChange, relativeDateValue],
  );

  return (
    <FormControl size='small' fullWidth error={hasError}>
      <Grid container>
        <Grid xs={3}>
          <FormInput
            label={hideLabel ? undefined : 'Number'}
            type='text'
            inputValue={relativeDateValue?.toString() ?? ''}
            disabled={disabled}
            setValue={onValueChange}
            data-testid='value-input-wrapper'
          />
        </Grid>
        <Grid xs={9} pl={1}>
          <SimpleDropdown
            value={relativeDateUnit}
            disabled={disabled}
            menuOptions={relativeDateUnitOptions}
            IconComponent={KeyboardArrowDownIcon}
            label={hideLabel ? undefined : 'Unit'}
            placeholder='Unit'
            setValue={onUnitChange}
            data-testid='unit-input-wrapper'
          />
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default RelativeDateInput;
