import { useCallback } from 'react';
import {
  setEnvironmentMembers as setEnvironmentMembersReducer,
  addEnvironmentMember as addEnvironmentMemberReducer,
  updateEnvironmentMember as updateEnvironmentMemberReducer,
  deactivateEnvironmentMember as deactivateEnvironmentMemberReducer,
} from '../store/slices/environmentMembers';

import {
  addMember as addWorkspaceMemberReducer,
  updateMember as updateWorkspaceMemberReducer,
  deactivateMember as deactivateWorkspaceMemberReducer,
} from '../store/slices/workspace';

import { useAppDispatch, useAppSelector } from './store';
import {
  useEnvironmentMembersLazyQuery,
  useCreateEnvironmentUserMutation,
  useEditEnvironmentUserMutation,
  useDeactivateUserMutation,
  useCreateWorkspaceUserMutation,
  useEditWorkspaceUserMutation,
  CreateEnvironmentUserInput,
  CreateWorkspaceUserInput,
  EditEnvironmentUserInput,
  EditWorkspaceUserInput,
  DeactivateUserInput,
  PaginationArgs,
  EnvironmentMember,
  WorkspaceMember,
  useEnvironmentMembersByIdLazyQuery,
} from '../generated/types';

import { Variant } from '../components/registeredDialogs/CreateOrEditUserDialog';
import { useSessionInfo } from './session';

// this hook is to manage both environment members and workspace members
// using the store and the graphql api
// workspace members are set when a workspace is loaded
export const useMembers = () => {
  const storeDispatch = useAppDispatch();
  const { userType } = useSessionInfo();
  const { environment } = useAppSelector((state) => state.environment.value);
  const environmentId = environment?.id ?? '';

  const [fetchMembersByUser, { data, error, loading }] =
    useEnvironmentMembersLazyQuery({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    });

  const [
    fetchMembersByEnvironmentId,
    { data: envMembersData, loading: systemLoading },
  ] = useEnvironmentMembersByIdLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const [createEnvironmentUser] = useCreateEnvironmentUserMutation();
  const [editEnvironmentUser] = useEditEnvironmentUserMutation();
  const [deactivateUser] = useDeactivateUserMutation();

  const [createWorkspaceUser] = useCreateWorkspaceUserMutation();
  const [editWorkspaceUser] = useEditWorkspaceUserMutation();

  const fetchEnvironmentMembers = useCallback(
    async (pagination: PaginationArgs) => {
      if (userType === 'SystemUser') {
        const result = await fetchMembersByEnvironmentId({
          variables: {
            environmentId: environmentId,
            params: {
              pagination,
            },
          },
        });
        if (result?.data?.node?.__typename === 'Environment') {
          storeDispatch(
            setEnvironmentMembersReducer(
              result.data.node.members.edges.map(
                (edge) => edge.node,
              ) as EnvironmentMember[],
            ),
          );
        }
        return result;
      } else if (userType === 'EnvironmentMember') {
        const result = await fetchMembersByUser({
          variables: {
            params: {
              pagination,
            },
          },
        });
        if (result?.data?.viewer.user?.__typename === 'EnvironmentMember') {
          storeDispatch(
            setEnvironmentMembersReducer(
              result.data.viewer.user.environment.members.edges.map(
                (edge) => edge.node,
              ) as EnvironmentMember[],
            ),
          );
        }
        return result;
      }
    },
    [
      fetchMembersByUser,
      fetchMembersByEnvironmentId,
      environmentId,
      storeDispatch,
      userType,
    ],
  );

  const addEnvironmentMember = useCallback(
    async (input: CreateEnvironmentUserInput) => {
      try {
        const result = await createEnvironmentUser({ variables: { input } });
        if (result?.data?.createEnvironmentUser) {
          storeDispatch(
            addEnvironmentMemberReducer(
              result.data.createEnvironmentUser.user as EnvironmentMember,
            ),
          );
        }
        return result;
      } catch {
        // Nothing to do here just make sure any ApolloError is handled.
        // Showing the error to user is already handled by the error link.
      }
    },
    [createEnvironmentUser, storeDispatch],
  );

  const editEnvironmentMember = useCallback(
    async (input: EditEnvironmentUserInput) => {
      try {
        const result = await editEnvironmentUser({ variables: { input } });
        if (result?.data?.editEnvironmentUser) {
          storeDispatch(
            updateEnvironmentMemberReducer(
              result.data.editEnvironmentUser.updated as EnvironmentMember,
            ),
          );
        }
        return result;
      } catch {
        // Nothing to do here just make sure any ApolloError is handled.
      }
    },
    [editEnvironmentUser, storeDispatch],
  );

  const addWorkspaceMember = useCallback(
    async (input: CreateWorkspaceUserInput) => {
      try {
        const result = await createWorkspaceUser({ variables: { input } });
        if (result?.data?.createWorkspaceUser) {
          storeDispatch(
            addWorkspaceMemberReducer(
              result.data.createWorkspaceUser?.user as WorkspaceMember,
            ),
          );
        }
        return result;
      } catch {
        // Nothing to do here just make sure any ApolloError is handled.
      }
    },
    [createWorkspaceUser, storeDispatch],
  );

  const editWorkspaceMember = useCallback(
    async (input: EditWorkspaceUserInput) => {
      try {
        const result = await editWorkspaceUser({ variables: { input } });
        if (result?.data?.editWorkspaceUser) {
          storeDispatch(
            updateWorkspaceMemberReducer(
              result.data.editWorkspaceUser?.updated as WorkspaceMember,
            ),
          );
        }
        return result;
      } catch {
        // Nothing to do here just make sure any ApolloError is handled.
      }
    },
    [editWorkspaceUser, storeDispatch],
  );

  const deactivateMember = useCallback(
    async (input: DeactivateUserInput, variant: Variant) => {
      try {
        const result = await deactivateUser({ variables: { input } });
        if (result.data?.deactivateUser) {
          if (variant === 'workspace') {
            storeDispatch(
              deactivateWorkspaceMemberReducer(
                result.data.deactivateUser.deactivated.id,
              ),
            );
          } else if (variant === 'environment') {
            storeDispatch(
              deactivateEnvironmentMemberReducer(
                result.data.deactivateUser.deactivated.id,
              ),
            );
          }
        }
        return result;
      } catch {
        // Nothing to do here just make sure any ApolloError is handled.
      }
    },
    [deactivateUser, storeDispatch],
  );

  return {
    // environment members
    fetchEnvironmentMembers,
    envMembersData,
    data,
    loading: loading || systemLoading,
    error,
    addEnvironmentMember,
    editEnvironmentMember,
    // workspace members
    addWorkspaceMember,
    editWorkspaceMember,
    // shared
    deactivateMember,
  };
};
