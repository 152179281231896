import {
  useState,
  useEffect,
  useCallback,
  useMemo,
  forwardRef,
  FocusEvent,
} from 'react';

// components
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  MenuList,
  MenuItem,
  Stack,
} from '@mui/material';
import DataFieldTypeIcon from './DataFieldTypeIcon';
import Icon from '../shared/Icon';

// Types and utils
import { SchemaNode, isFieldInfo } from '../../store/slices/exploreExtracts';
import { isFieldDisabled, focusDelayed } from './utils';

const ACCORDION_MAX_HEIGHT = 300;

interface DataFieldAccordionProps {
  filteredNodes?: SchemaNode[];
  fullNodes: SchemaNode[];
  selectedFieldValue?: SchemaNode;
  selectedFields?: string[];
  handleFieldItemClick: (field: SchemaNode) => void;
  inputRef: React.RefObject<HTMLDivElement>;
  tableNodeRefs: React.RefObject<(HTMLDivElement | null)[]>;
  fieldNodeRefs: React.RefObject<(HTMLLIElement | null)[][]>;
  allInOne?: boolean;
}

const DataFieldAccordion = forwardRef<HTMLDivElement, DataFieldAccordionProps>(
  (
    {
      filteredNodes,
      fullNodes,
      selectedFieldValue,
      selectedFields,
      handleFieldItemClick,
      inputRef,
      tableNodeRefs,
      fieldNodeRefs,
      allInOne,
    },
    ref,
  ) => {
    // 0 - n index of the accordion that is open
    // -1 it means all accordions are open
    const [openAccordionIndex, setOpenAccordionIndex] = useState<number | null>(
      null,
    );

    const toggleAccordion = useCallback(
      (index: number) => {
        if (openAccordionIndex === index) {
          setOpenAccordionIndex(null);
        } else {
          setOpenAccordionIndex(index);
        }
      },
      [openAccordionIndex],
    );

    const handleKeyDownInAccordion = useCallback(
      (e: React.KeyboardEvent<HTMLDivElement>, index: number) => {
        if (!fieldNodeRefs.current) return;
        // arrow or tab navigation down
        if (e.key === 'ArrowDown' || e.key === 'Tab') {
          // select first list item of the sibling accordion details
          const firstListItem = fieldNodeRefs.current[index][0];
          focusDelayed(firstListItem);
        }

        // arrow or tab navigation up
        if (e.key === 'ArrowUp' || e.key === 'Shift + Tab') {
          // close the current accordion and open the previous one
          if (index > 0) {
            toggleAccordion(index - 1);
            // focus the previous accordion's last item
            const prevAccordionLength = fieldNodeRefs.current[index - 1].length;
            const prevAccordionLastItem =
              fieldNodeRefs.current[index - 1][prevAccordionLength - 1];
            focusDelayed(prevAccordionLastItem);
          }

          // if the current accordion is the first one, focus the search input
          if (index === 0) {
            // focus the search input
            setOpenAccordionIndex(null);
            const inputElement =
              inputRef.current?.getElementsByTagName('input')[0];
            if (inputElement) {
              focusDelayed(inputElement);
            }
          }
        }
      },
      [fieldNodeRefs, inputRef, toggleAccordion],
    );

    const handleKeyDownInListItem = useCallback(
      (
        e: React.KeyboardEvent<HTMLLIElement>,
        field: SchemaNode,
        fieldIndex: number,
        accordionIndex: number,
      ) => {
        e.stopPropagation();
        e.preventDefault();
        if (!tableNodeRefs.current || !fieldNodeRefs.current) return;

        // arrow or tab navigation down
        const accordionLength = fieldNodeRefs.current[accordionIndex].length;
        if (e.key === 'ArrowDown' || e.key === 'Tab') {
          // if the fieldIndex is the last one, focus the next accordion and open it
          if (fieldIndex === accordionLength - 1) {
            setOpenAccordionIndex(accordionIndex + 1);
            // use timeout to make sure the accordion is in display before focusing the next accordions
            focusDelayed(tableNodeRefs.current[accordionIndex + 1]);
          } else {
            // next list item
            const nextListItem =
              fieldNodeRefs.current[accordionIndex][fieldIndex + 1];
            focusDelayed(nextListItem);
          }
        }

        // arrow or tab navigation up
        if (e.key === 'ArrowUp' || e.key === 'Shift + Tab') {
          // if the fieldIndex is the first one, focus on the accordion summary
          if (fieldIndex === 0) {
            const accordionSummary = tableNodeRefs.current[accordionIndex];
            focusDelayed(accordionSummary);
          } else {
            // previous list item
            const prevListItem =
              fieldNodeRefs.current[accordionIndex][fieldIndex - 1];
            focusDelayed(prevListItem);
          }
        }
      },
      [fieldNodeRefs, tableNodeRefs],
    );

    // when the accordion is focused from the parent, focus the first accordion
    const handleFocus = (_: FocusEvent<HTMLDivElement, Element>) => {
      if (!tableNodeRefs.current) return;
      setOpenAccordionIndex(0);
      if (tableNodeRefs.current[0]) {
        focusDelayed(tableNodeRefs.current[0]);
      }
    };

    const nodesToUse = useMemo(
      () => (filteredNodes && filteredNodes.length ? filteredNodes : fullNodes),
      [filteredNodes, fullNodes],
    );

    useEffect(() => {
      // expand all accordion only when the filtered node has more than 1 node
      if (filteredNodes && filteredNodes?.length > 0) {
        setOpenAccordionIndex(-1);
      } else {
        setOpenAccordionIndex(null);
      }
    }, [filteredNodes, inputRef]);

    return (
      <div>
        {/* Element to hijack focus event from the parent without getting it from the other children elements */}
        <span ref={ref} onFocus={handleFocus} tabIndex={0} />
        {nodesToUse?.map((view, viewIndex) => {
          const isCurrentAccordionExpanded =
            openAccordionIndex === viewIndex || openAccordionIndex === -1;
          return (
            <Accordion
              TransitionProps={{ timeout: 100 }}
              expanded={isCurrentAccordionExpanded}
              key={view.id}
              sx={{
                '&.Mui-expanded': {
                  margin: 0,
                  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                },
                ...(allInOne && {
                  '&.MuiAccordion-root:first-of-type': {
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                  },
                }),
              }}
              onChange={() => setOpenAccordionIndex(viewIndex)}
            >
              <AccordionSummary
                tabIndex={0} // make a div focusable
                ref={(el) => {
                  if (tableNodeRefs.current) {
                    tableNodeRefs.current[viewIndex] = el;
                  }
                }}
                onClick={() => {
                  toggleAccordion(viewIndex);
                }}
                onKeyDown={(e) => handleKeyDownInAccordion(e, viewIndex)}
                expandIcon={<Icon name='carat-down' />}
                sx={{
                  paddingX: 1,
                  // discard the default padding for expanded state
                  '&.Mui-expanded': {
                    minHeight: 'unset',
                  },
                  '& > .MuiAccordionSummary-content.Mui-expanded': {
                    margin: '12px 0',
                  },
                }}
              >
                <Typography variant='h5'>{view.name}</Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: 0,
                  maxHeight: ACCORDION_MAX_HEIGHT,
                  overflowX: 'auto',
                }}
              >
                <MenuList>
                  {view.children?.map((field, fieldIndex) => {
                    const fieldInfo = isFieldInfo(field.metaData)
                      ? field.metaData
                      : undefined;
                    if (!fieldInfo) return null;
                    return (
                      <MenuItem
                        tabIndex={0}
                        ref={(el) => {
                          if (fieldNodeRefs.current) {
                            fieldNodeRefs.current[viewIndex][fieldIndex] = el;
                          }
                        }}
                        key={field.id}
                        sx={{ padding: 0 }}
                        onClick={() => {
                          handleFieldItemClick(field);
                        }}
                        onKeyDown={(e) =>
                          handleKeyDownInListItem(
                            e,
                            field,
                            fieldIndex,
                            viewIndex,
                          )
                        }
                        selected={selectedFieldValue?.name === field.name}
                        disabled={isFieldDisabled(fieldInfo, selectedFields)}
                      >
                        <Stack direction='column' p={1} spacing={0.5}>
                          <Stack
                            direction='row'
                            spacing={0.5}
                            alignItems='center'
                          >
                            <DataFieldTypeIcon
                              normalizedType={fieldInfo.normalizedType}
                            />
                            <Typography variant='h6' color='text.secondary'>
                              {fieldInfo.label}
                            </Typography>
                          </Stack>
                          <Typography variant='caption' color='text.secondary'>
                            {view.name}{' '}
                            {fieldInfo.example
                              ? ` · Ex: ${fieldInfo.example}`
                              : ''}
                          </Typography>
                        </Stack>
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    );
  },
);

DataFieldAccordion.displayName = 'DataFieldAccordion';

export default DataFieldAccordion;
