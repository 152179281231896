import { useState, useCallback, useMemo, useEffect } from 'react';
import debounce from 'lodash/debounce';

import { useAppSelector } from '../../hooks/store';

import { SchemaNode } from '../../store/slices/exploreExtracts';
import {
  getExploreInfoByEnvExploreId,
  getExploreInfoByWsExploreId,
} from '../../store/slices/loadedWorkspaceExplores';
import { useFeature } from '../../hooks/feature';

type useVisualFieldPickerProps =
  | { extractId: string; workspaceId?: never }
  | { extractId?: never; workspaceId: string };

export const useVisualFieldPicker = ({
  extractId,
  workspaceId,
}: useVisualFieldPickerProps) => {
  const [searchPhrase, setSearchPhrase] = useState('');
  const [isPickerOpen, setIsPickerOpen] = useState<boolean>(false);
  const [preview, setPreview] = useState<SchemaNode[]>([]);

  const [expanded, setExpanded] = useState<string[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  // when workspaceId is provided, we need to find the extractId.
  // for simple vfp, extractId is provided directly, we don't need to find it

  const loadedExplores = useAppSelector((state) => state.loadedExplores.value);
  const { featureEditData } = useFeature();

  const extractIdToUse = useMemo(() => {
    if (extractId) return extractId;
    if (workspaceId && featureEditData.wsExploreId) {
      return getExploreInfoByWsExploreId(
        loadedExplores,
        workspaceId,
        featureEditData.wsExploreId,
      )?.extractId;
    }
    if (workspaceId) {
      return getExploreInfoByEnvExploreId(
        loadedExplores,
        workspaceId,
        featureEditData.exploreId ?? '',
      )?.extractId;
    }
  }, [
    extractId,
    featureEditData.exploreId,
    featureEditData.wsExploreId,
    loadedExplores,
    workspaceId,
  ]);

  const exploreExtracts = useAppSelector(
    (state) => state.exploreExtracts.value,
  );

  const { schema, columnarSlices } =
    (!!extractIdToUse && exploreExtracts[extractIdToUse]) || {};

  const fullNodeList = useMemo(() => schema?.nodes, [schema?.nodes]);

  const [activeNodes, setActiveNodes] = useState<SchemaNode[] | undefined>(
    fullNodeList,
  );

  useEffect(() => {
    fullNodeList && setActiveNodes(fullNodeList);
  }, [fullNodeList]);

  const openPicker = useCallback(() => {
    setIsPickerOpen(true);
  }, [setIsPickerOpen]);

  const closePicker = useCallback(() => {
    setIsPickerOpen(false);
  }, [setIsPickerOpen]);

  const clearInput = useCallback(() => {
    setSearchPhrase('');
    setActiveNodes(fullNodeList);
    setPreview([]);
  }, [fullNodeList]);

  const getNodesWithMatchingChildren = useCallback(
    (matchStr: string) => {
      if (matchStr === '') return fullNodeList;
      return fullNodeList
        ?.map((node) => {
          if (node.name.toLocaleLowerCase().includes(matchStr)) {
            return node;
          } else {
            const children = node.children?.filter((fieldNode) =>
              fieldNode.name.toLocaleLowerCase().includes(matchStr),
            );
            return { ...node, children };
          }
        })
        .filter((node) => node.children && node.children.length > 0);
    },
    [fullNodeList],
  );

  const filterNodes = useMemo(
    () =>
      debounce((matchStr) => {
        const filteredNodes = getNodesWithMatchingChildren(matchStr);
        const filtering = filteredNodes !== fullNodeList;

        setActiveNodes(filteredNodes);
        setExpanded(
          filtering && filteredNodes ? filteredNodes.map((n) => n.id) : [],
        );
      }, 100),
    [fullNodeList, getNodesWithMatchingChildren],
  );

  const updateSearchPhrase = useCallback(
    (inputText: string) => {
      const matchStr = inputText.toLocaleLowerCase();
      setSearchPhrase(matchStr);
      filterNodes(matchStr);
    },
    [filterNodes],
  );

  return {
    searchPhrase,
    setSearchPhrase,
    isPickerOpen,
    openPicker,
    closePicker,
    clearInput,
    updateSearchPhrase,
    // schema viewer
    schema,
    columnarSlices,
    fullNodeList,
    activeNodes,
    setActiveNodes,
    preview,
    setPreview,
    expanded,
    setExpanded,
    selected,
    setSelected,
  };
};
