import { noop } from 'lodash';
import { useCallback } from 'react';
import {
  useWorkspaceChatThreadsLazyQuery,
  useWorkspaceChatThreadLazyQuery,
  useEditWorkspaceChatThreadMutation,
  useDeleteWorkspaceChatThreadMutation,
  ChatThread,
} from '../generated/types';
import { useAppDispatch } from '.';
import {
  setChatThreads,
  updateChatThreadTitle,
  deleteChatThread,
  setIsSelectedChatThreadLoaded,
  setSelectedChatThreadId as setSelectedChatThreadIdAction,
} from '../store/slices/chatThreads';
import { getCurrentUserDetails } from '../lib/localStorage';

const DEFAULT_LOOKBACK_DAYS = 90;

export const useChatThreads = (workspaceId: string) => {
  const storeDispatch = useAppDispatch();
  const userId = getCurrentUserDetails()?.id;

  const [fetch] = useWorkspaceChatThreadsLazyQuery({
    variables: {
      id: workspaceId,
      params: {
        lookbackDays: DEFAULT_LOOKBACK_DAYS,
        userId,
      },
    },
  });

  const [fetchChatThread] = useWorkspaceChatThreadLazyQuery();
  const [editWSChatThread] = useEditWorkspaceChatThreadMutation();
  const [deleteWSChatThread] = useDeleteWorkspaceChatThreadMutation();

  const fetchChatThreads = useCallback(() => {
    fetch()
      .then((res) => {
        if (res.data?.node?.__typename === 'Workspace') {
          const threads = res.data?.node?.chatThreads.edges.map(
            (edge) => edge.node,
          );
          if (threads) {
            storeDispatch(setChatThreads(threads));
          }
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, [fetch, storeDispatch]);

  const editChatThreadTitle = useCallback(
    async (threadId: string, title: string) => {
      editWSChatThread({
        variables: {
          input: {
            id: threadId,
            title,
          },
        },
      }).catch(noop);
      storeDispatch(updateChatThreadTitle({ id: threadId, title }));
    },
    [editWSChatThread, storeDispatch],
  );

  const removeChatThread = useCallback(
    (threadId: string) => {
      deleteWSChatThread({
        variables: {
          input: {
            id: threadId,
          },
        },
      }).catch(noop);
      storeDispatch(deleteChatThread({ id: threadId }));
    },
    [deleteWSChatThread, storeDispatch],
  );

  // please do not depend on the chatThreads in the store
  // this will regenerate the fetchSelectedChatThread when a new chatThread is added
  // and this will reinitialize the chat thread in the useChat hook.
  const fetchSelectedChatThread = useCallback(
    async (chatThreadId: string) => {
      storeDispatch(setIsSelectedChatThreadLoaded(false));
      const response = await fetchChatThread({
        variables: {
          workspaceId,
          chatThreadId,
        },
      });

      if (response.data?.node?.__typename === 'Workspace') {
        if (response.data.node.chatThread.__typename === 'ChatThread') {
          storeDispatch(setIsSelectedChatThreadLoaded(true));
          return response.data.node.chatThread as ChatThread;
        }
      }
    },
    [fetchChatThread, storeDispatch, workspaceId],
  );

  const setSelectedChatThreadId = useCallback(
    (chatThreadId?: string) => {
      storeDispatch(setSelectedChatThreadIdAction(chatThreadId));
    },
    [storeDispatch],
  );

  return {
    fetchChatThreads,
    editChatThreadTitle,
    removeChatThread,
    fetchSelectedChatThread,
    setSelectedChatThreadId,
  };
};
