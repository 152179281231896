import { Formik, Form, FormikConfig } from 'formik';
import {
  Drawer as MuiDrawer,
  Typography,
  IconButton,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Button from '../Button';
import {
  DRAWER_IDS,
  DRAWER_REGISTRY,
  DrawerComponentTypeMap,
} from '../../registeredDrawers/drawerRegistry';
import { DRAWER_WIDTH } from '../../Layout/constants';
export const DRAWER_PADDING_UNITS = 3;

type FormikProps<FORMIK_VALUE_TYPE> = {
  submitButtonText: string;
  initialFormValues: FORMIK_VALUE_TYPE;
  submitForm: FormikConfig<FORMIK_VALUE_TYPE>['onSubmit'];
  validationSchema: FormikConfig<FORMIK_VALUE_TYPE>['validationSchema'];
  validateOnChange: FormikConfig<FORMIK_VALUE_TYPE>['validateOnChange'];
  validateOnBlur: FormikConfig<FORMIK_VALUE_TYPE>['validateOnBlur'];
};

export type FormikDrawerConfig<ID extends DRAWER_IDS> = {
  id: ID;
  title: string;
  isFormikDrawer: true;
  // formik props are needed for formik drawers
  formikProps: FormikProps<
    NonNullable<DrawerComponentTypeMap[ID]['formValueType']>
  >;
  contentProps?: Omit<
    DrawerComponentTypeMap[ID]['contentPropType'],
    'hideDrawer'
  >;
  instanceId: symbol;
};

export type NonFormikDrawerConfig<ID extends DRAWER_IDS> = {
  id: ID;
  title: string;
  isFormikDrawer?: false;
  // formik props are not needed for non-formik drawers
  contentProps?: Omit<
    DrawerComponentTypeMap[ID]['contentPropType'],
    'hideDrawer'
  >;
  instanceId: symbol;
};

export interface DrawerProps<ID extends DRAWER_IDS> {
  drawerConfig: FormikDrawerConfig<ID> | NonFormikDrawerConfig<ID>;
  hideDrawer: () => void;
}

const Drawer = <ID extends DRAWER_IDS>({
  drawerConfig,
  hideDrawer,
}: DrawerProps<ID>) => {
  const { id, title, isFormikDrawer, contentProps } = drawerConfig;
  const DrawerComponent = DRAWER_REGISTRY[id].component;

  return (
    <MuiDrawer
      key='global-drawer'
      anchor={'right'}
      open={id !== DRAWER_IDS.NO_DRAWER}
      onClose={hideDrawer}
      PaperProps={{
        sx: {
          width: DRAWER_WIDTH,
        },
      }}
    >
      <Stack
        id='drawer-header'
        height='76px'
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        sx={{
          height: '76x',
          p: 2,
        }}
      >
        <Typography variant='h4'>{title}</Typography>
        <IconButton aria-label='close' onClick={hideDrawer} size='small'>
          <CloseIcon fontSize='small' />
        </IconButton>
      </Stack>
      {isFormikDrawer && (
        <FormikDrawerContent
          drawerConfig={drawerConfig}
          hideDrawer={hideDrawer}
        />
      )}
      {!isFormikDrawer && (
        <Stack
          id='drawer-component-container'
          flex={1}
          pt={3}
          px={2}
          pb={2}
          sx={{
            overflowY: 'auto',
          }}
        >
          <DrawerComponent {...contentProps} hideDrawer={hideDrawer} />
        </Stack>
      )}
    </MuiDrawer>
  );
};

export default Drawer;

interface FormikDrawerContentProps<ID extends DRAWER_IDS> {
  drawerConfig: FormikDrawerConfig<ID>;
  hideDrawer: () => void;
}

const FormikDrawerContent = <ID extends DRAWER_IDS>({
  drawerConfig,
  hideDrawer,
}: FormikDrawerContentProps<ID>) => {
  const { id, formikProps, contentProps } = drawerConfig;
  const DrawerComponent = DRAWER_REGISTRY[id].component;
  type Values = NonNullable<DrawerComponentTypeMap[ID]['formValueType']>;

  const {
    initialFormValues,
    submitForm,
    validationSchema,
    submitButtonText,
    validateOnChange,
    validateOnBlur,
  } = formikProps;

  const handleSubmit: FormikConfig<Values>['onSubmit'] = async (
    values,
    helpers,
  ) => {
    if (values === null || initialFormValues === null) {
      return;
    }

    await submitForm(values, helpers);
    hideDrawer();
  };

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange={validateOnChange}
      validateOnBlur={validateOnBlur}
      submitOnEnter={false}
    >
      {(formik) => (
        <Form
          style={{
            display: 'flex',
            flex: 1,
          }}
        >
          <Stack id='formik-drawer-content' width='100%'>
            <Stack
              id='formik-drawer-component-container'
              flex={1}
              pt={3}
              px={2}
              sx={{
                overflowY: 'auto',
              }}
            >
              <DrawerComponent {...contentProps} hideDrawer={hideDrawer} />
            </Stack>
            {/* Footer */}
            <Stack
              id='formik-drawer-footer'
              direction='row'
              justifyContent='flex-end'
              spacing={1.5}
              pt={3}
              px={2}
              pb={2}
            >
              <Button variant='outlined' onClick={hideDrawer}>
                Cancel
              </Button>
              <Button
                type='submit'
                variant='contained'
                disabled={!formik.isValid}
                isLoading={formik.isSubmitting}
              >
                {submitButtonText}
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
