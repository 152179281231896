import { useCallback } from 'react';
import { IconButton, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import EmojiPicker from '../shared/EmojiPicker';
import ColorSelector from '../shared/ColorSelector';
import SimpleDropdown from '../shared/SimpleDropdown';
import ConditionValueInput from '../ConditionForm/ConditionValueInput';
import useFieldVariable from '../../hooks/useFieldVariable';
import {
  BadgeSetting,
  FieldDatumV1,
  NormalizedConditionValueV1,
} from '@madeinventive/core-types';

interface BadgeSettingRowProps {
  selectedFieldDatum?: FieldDatumV1;
  defaultSetting: BadgeSetting;
  setSetting: (setting: BadgeSetting) => void;
  deleteSetting: () => void;
}

const BadgeSettingRow = ({
  selectedFieldDatum,
  defaultSetting,
  setSetting,
  deleteSetting,
}: BadgeSettingRowProps) => {
  const {
    // operators
    operators,
    // value
    fetchSuggestions,
    isDateType,
    isMultiValue,
    isRangeValue,
    isSingularRelativeDateValue,
    isPluralRelativeDateValue,
    isSuggestable,
    isSuggestionLoading,
    isValueDisabled,
    suggestions,
    // utils
    getIsMultiValue,
    getIsRangeValue,
    getNormalizedOperator,
  } = useFieldVariable(selectedFieldDatum?.variable, defaultSetting.operator);

  const handleSetEmoji = useCallback(
    (emoji?: string) => {
      // it can be undefined when it is removed
      setSetting({
        ...defaultSetting,
        emoji,
      });
    },
    [defaultSetting, setSetting],
  );

  const handleSetColor = useCallback(
    (color: string) => {
      setSetting({
        ...defaultSetting,
        color,
      });
    },
    [defaultSetting, setSetting],
  );

  const handleSetOperator = useCallback(
    (newOperator: string) => {
      const currentOperator = defaultSetting.operator?.key;
      const isCurrentMultiValue = isMultiValue;
      const isCurrentRangeValue = isRangeValue;
      const isNewMultiValue = getIsMultiValue(newOperator);
      const isNewRangeValue = getIsRangeValue(newOperator);
      // Clear the input in the following cases:
      // - The input is currently a multi value input and it changes
      // - The input type is a range value and it changes
      // - The input type changes to a range value from a different type of input type
      const shouldResetValue =
        (isCurrentMultiValue && !isNewMultiValue) ||
        (!isCurrentRangeValue && isNewRangeValue) ||
        (isCurrentRangeValue && !isNewRangeValue);
      const normalizedOperator = getNormalizedOperator(
        newOperator,
        true /* minimized */,
      );

      if (newOperator !== currentOperator && normalizedOperator) {
        setSetting({
          ...defaultSetting,
          operator: normalizedOperator,
          ...(shouldResetValue ? { value: undefined } : null),
        });
      }
    },
    [
      defaultSetting,
      getIsMultiValue,
      getIsRangeValue,
      getNormalizedOperator,
      isMultiValue,
      isRangeValue,
      setSetting,
    ],
  );

  const handleSetValue = useCallback(
    (value?: NormalizedConditionValueV1) => {
      setSetting({
        ...defaultSetting,
        value,
      });
    },
    [defaultSetting, setSetting],
  );

  // TODO: Remove this once we implement the custom logic for badge conditions in the table app.
  const unsupportedOperators = [
    'IS_THIS',
    'IS_NEXT',
    'IS_LAST',
    'IS_IN_THE_LAST',
  ];
  const supportedOperators = operators.filter(
    (operator) => !unsupportedOperators.includes(operator.value),
  );

  return (
    <Stack direction='row' spacing={2} alignItems='flex-start'>
      <IconButton onClick={deleteSetting} color='error' size='small'>
        <CloseIcon fontSize='small' />
      </IconButton>

      <Stack direction='column' flex={1} spacing={2}>
        <SimpleDropdown
          menuOptions={supportedOperators}
          value={defaultSetting.operator?.key}
          setValue={handleSetOperator}
          label='Operator'
          sx={{}}
        />
        <ConditionValueInput
          value={defaultSetting.value}
          hasField={!!selectedFieldDatum}
          disabled={isValueDisabled}
          isMultiValue={isMultiValue}
          isRangeValue={isRangeValue}
          isDateType={isDateType}
          isSingularRelativeDateValue={isSingularRelativeDateValue}
          isPluralRelativeDateValue={isPluralRelativeDateValue}
          suggestable={isSuggestable}
          suggestions={suggestions}
          getSuggestions={fetchSuggestions}
          loading={isSuggestionLoading}
          setValue={handleSetValue}
        />
      </Stack>
      <Stack direction='row' alignItems='center'>
        <EmojiPicker
          selectedEmoji={defaultSetting.emoji}
          setSelectedEmoji={handleSetEmoji}
        />
        <ColorSelector
          color={defaultSetting.color}
          setColor={handleSetColor}
          text='[value]'
        />
      </Stack>
    </Stack>
  );
};

export default BadgeSettingRow;
