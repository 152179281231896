import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

export const useToast = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showErrorToast = useCallback(
    (message: string, actionText?: string, onActionClick?: () => void) => {
      enqueueSnackbar(message, {
        variant: 'error',
        action:
          actionText && onActionClick
            ? (key) => (
                <button
                  onClick={() => {
                    closeSnackbar(key);
                    onActionClick();
                  }}
                  style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  {actionText}
                </button>
              )
            : undefined,
      });
    },
    [enqueueSnackbar, closeSnackbar],
  );

  const showInfoToast = useCallback(
    (message: string) => {
      enqueueSnackbar(message, { variant: 'info' });
    },
    [enqueueSnackbar],
  );

  const showSuccessToast = useCallback(
    (message: string) => {
      enqueueSnackbar(message, { variant: 'success' });
    },
    [enqueueSnackbar],
  );

  // success toast hides action button
  // Use default toast to display action button
  const showDefaultToast = useCallback(
    (message: string, actionText?: string, onActionClick?: () => void) => {
      enqueueSnackbar(message, {
        action:
          actionText && onActionClick
            ? (key) => (
                <button
                  onClick={() => {
                    closeSnackbar(key);
                    onActionClick();
                  }}
                  style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    color: 'white',
                  }}
                >
                  {actionText}
                </button>
              )
            : undefined,
      });
    },
    [closeSnackbar, enqueueSnackbar],
  );

  return {
    showInfoToast,
    showErrorToast,
    showSuccessToast,
    showDefaultToast,
  };
};
