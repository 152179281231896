import { useRouter } from 'next/router';

import { Box, Stack } from '@mui/material';
import {
  LibraryBooksOutlined as LibraryBooksOutlinedIcon,
  OfflineBoltOutlined as OfflineBoltOutlinedIcon,
  ViewListOutlined as ViewListOutlinedIcon,
  SvgIconComponent,
} from '@mui/icons-material';
import Button from '../shared/Button';

import { setActiveModalSlice } from '../../store/slices/modal';
import { useAppDispatch, useAppSelector } from '../../hooks/store';

import NavCard from '../Workflow/NavPane/NavCard';
import { IconSize, ICON_DIMENSIONS } from '../shared/dimensions';
import { BaseRegisteredDialogComponentProps } from './types';

export interface CreateFeatureDialogProps
  extends BaseRegisteredDialogComponentProps {}

const CreateFeatureDialog = ({ hideDialog }: CreateFeatureDialogProps) => {
  const storeDispatch = useAppDispatch();
  const { workspace } = useAppSelector((store) => store.workspace.value);
  const workspaceId = workspace?.id;

  const router = useRouter();

  const handleCreateWorkflow = () => {
    router.push(`/workspace/${workspaceId}/features/new`);
    hideDialog();
  };

  const handleCreateApp = () => {
    router.push(`/workspace/${workspaceId}/apps/new`);
    hideDialog();
  };

  const handleCreateFromTemplate = () => {
    storeDispatch(setActiveModalSlice({ activeModal: 'template_gallery' }));
    hideDialog();
  };

  const renderNavCard = (
    title: string,
    subTitle: string,
    onClick: () => void,
    Icon: SvgIconComponent,
  ) => {
    return (
      <NavCard
        title={title}
        subTitle={subTitle}
        icon={(iconSize: IconSize) => {
          return (
            <Box
              sx={{
                m: 1,
                p: 2,
                border: `1px solid`,
                borderColor: 'border.light',
                borderRadius: 1,
              }}
            >
              <Icon sx={{ ...ICON_DIMENSIONS[iconSize] }} />
            </Box>
          );
        }}
        onClick={onClick}
        highlightBackgroundOnHover
      />
    );
  };

  return (
    <Box>
      <Stack
        direction='column'
        alignItems='center'
        justifyContent='center'
        py={0}
        px={8}
        spacing={2}
        mb={2}
      >
        {renderNavCard(
          'Table App',
          'An app experience to track the status of items.',
          handleCreateApp,
          ViewListOutlinedIcon,
        )}
        {renderNavCard(
          'Workflow',
          'Dynamic alerts and automations to any system.',
          handleCreateWorkflow,
          OfflineBoltOutlinedIcon,
        )}
      </Stack>
      {
        <Button
          variant='text'
          startIcon={
            <LibraryBooksOutlinedIcon sx={{ ...ICON_DIMENSIONS['small'] }} />
          }
          onClick={handleCreateFromTemplate}
        >
          Templates
        </Button>
      }
    </Box>
  );
};

export default CreateFeatureDialog;
