import { ChangeEvent, FocusEvent, useCallback, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography, { TypographyProps } from '@mui/material/Typography';

import Tooltip from '../Tooltip';

export interface InlineEditableTextProps {
  text: string;
  setText?: (newText: string) => void; // supplying 'setText' makes the text editable
  placeholderText?: string;
  boldfaced?: boolean;
  tooltip?: string;
  typography?: TypographyProps['variant'];
}

const InlineEditableText = (props: InlineEditableTextProps) => {
  const { text, setText, placeholderText, boldfaced, tooltip, typography } =
    props;

  const [textHovered, setTextHovered] = useState(false);
  const [editingText, setEditingText] = useState(false);

  const modifiedTextRef = useRef<string>('');

  const handleEnterText = useCallback(
    () => !!setText && setTextHovered(true),
    [setText, setTextHovered],
  );
  const handleLeaveText = useCallback(
    () => !!setText && setTextHovered(false),
    [setText, setTextHovered],
  );
  const handleOnTextClick = useCallback(
    () => !!setText && setEditingText(true),
    [setText, setEditingText],
  );

  const updateText = useCallback(() => {
    if (editingText) {
      // do nothing to do if `modified` is empty
      if (!modifiedTextRef.current) {
        return;
      }
      setText && setText(modifiedTextRef.current);
    }
  }, [editingText, setText]);

  const onTextInputBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      // auto commit value change on loss of focus (e.g. user clicks away)
      updateText();
      setEditingText(false);
      event.stopPropagation();
    },
    [setEditingText, updateText],
  );

  const onTextInputKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      switch (event.key) {
        case 'Escape':
        case 'Enter':
          {
            // commit the change if user hits 'Enter'
            // do nothing if it's 'ESC`
            if (event.key === 'Enter') {
              updateText();
            }
            setEditingText(false);
            event.stopPropagation();
          }
          break;
      }
    },
    [setEditingText, updateText],
  );

  const onTextInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      modifiedTextRef.current = event.target.value;
    },
    [],
  );

  return (
    <Box
      onMouseEnter={handleEnterText}
      onMouseLeave={handleLeaveText}
      onClick={handleOnTextClick}
      display='flex'
      flexDirection='column'
      flexGrow={1}
      alignItems='stretch'
      sx={{
        border: 1,
        borderRadius: 1,
        borderColor:
          textHovered && !editingText ? 'info.main' : 'rgba(255, 255, 255, 0)',
      }}
    >
      {editingText ? (
        <TextField
          autoFocus
          variant='outlined'
          defaultValue={text || ''}
          placeholder={placeholderText}
          size='small'
          sx={{
            typography: typography ?? 'body1',
          }}
          onBlur={onTextInputBlur}
          onKeyDown={onTextInputKeyDown}
          onChange={onTextInputChange}
          inputProps={{
            'aria-label': 'text-input',
          }}
        />
      ) : (
        <Tooltip title={tooltip ?? ''}>
          <Typography
            variant={typography ?? 'body1'}
            padding={1}
            fontWeight={boldfaced ? 'bold' : undefined}
          >
            {text.length > 0 ? text : placeholderText ?? ''}
          </Typography>
        </Tooltip>
      )}
    </Box>
  );
};

export default InlineEditableText;
