import { Ref, forwardRef } from 'react';
import { Typography, TypographyProps, Stack, alpha } from '@mui/material';
import selection from '../../../public/fontIcons/selection.json';
import Badge, { BadgeProps } from '../Badge';
import { IconName } from './types';

export const iconNames = selection.icons.map((icon) => icon.properties.name);

export const isIconName = (name: string): name is IconName =>
  iconNames.includes(name);

const sizeMap = {
  xsmall: 10,
  small: 16,
  large: 20,
  xlarge: 24,
};

const BOXED_ICON_SIZE = 16;

interface IconBaseProps extends TypographyProps {
  name: IconName;
  color?: TypographyProps['color'];
  className?: string;
}

interface DefaultIconProps extends IconBaseProps {
  isBoxed?: never;
  size?: keyof typeof sizeMap | number;
  badgeName?: never;
  badgeColor?: never;
}

interface BoxedIconProps extends IconBaseProps {
  isBoxed: boolean;
  badgeName?: IconName;
  badgeColor?: BadgeProps['color'];
  size?: never;
}

export type IconProps = DefaultIconProps | BoxedIconProps;

const Icon = forwardRef(
  (
    {
      name,
      size = 'large',
      color,
      className,
      isBoxed,
      badgeName,
      badgeColor,
      ...rest
    }: IconProps,
    ref: Ref<HTMLSpanElement>,
  ) => {
    if (isBoxed === true) {
      return (
        <Stack
          sx={{
            width: 32,
            height: 32,
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            borderRadius: 2,
            backgroundColor: 'primary.wash',
            // shadowy border
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              borderRadius: 2,
              border: (theme) =>
                `1px solid  ${alpha(theme.palette.neutrals.shadow, 0.08)}`,
              pointerEvents: 'none', // Ensures it doesn’t interfere with content clicks
            },
          }}
        >
          <Typography
            component='span'
            ref={ref}
            tabIndex={0}
            className={`icon-${name} ${className}`}
            sx={{
              lineHeight: 1,
              letterSpacing: 'unset',
              fontSize: BOXED_ICON_SIZE, // boxed icon uses a fixed size.
            }}
            color={color}
            {...rest}
          />
          {badgeName && (
            <div
              style={{
                position: 'absolute',
                top: '-5px',
                right: '-5px',
              }}
            >
              <Badge iconName={badgeName} color={badgeColor} />
            </div>
          )}
        </Stack>
      );
    }
    return (
      <Typography
        component='span'
        ref={ref}
        tabIndex={0}
        className={`icon-${name} ${className}`}
        sx={{
          lineHeight: 1,
          letterSpacing: 'unset',
          fontSize: typeof size === 'string' ? sizeMap[size] : size,
        }}
        color={color}
        {...rest}
      />
    );
  },
);

Icon.displayName = 'Icon';

export default Icon;
