import { ApolloLink } from '@apollo/client';
import * as uuid from 'uuid';

const generateTraceId = () => uuid.v4();

export const traceIdLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'inventive-trace-id': generateTraceId(),
    },
  }));

  return forward(operation);
});
