import { useState, useMemo, useCallback, SyntheticEvent } from 'react';
import { throttle } from 'lodash';
import { ApolloError } from '@apollo/client';

import { AutocompleteInputChangeReason } from '@mui/material';
import {
  useEnvironmentLookerDataModelExploreSuggestionsLazyQuery,
  useWorkspaceLookerDataModelExploreFieldSuggestionsLazyQuery,
} from '../../generated/types';
import MultiValueAutocomplete, {
  MultiValueAutocompleteProps,
} from '../shared/MultiValueAutocomplete';

type CustomerIdentifierInputProps = MultiValueAutocompleteProps & {
  fieldName: string;
  wsExploreId?: string;
  envExploreId?: string;
  onChange: (values: string[]) => void;
};

const CustomerIdentifierInput = (props: CustomerIdentifierInputProps) => {
  const { envExploreId, fieldName, wsExploreId, value, onChange } = props;

  const [inputValues, setInputValues] = useState<string[]>(value ?? []);
  const [options, setOptions] = useState<readonly string[]>([]);
  const [loading, setLoading] = useState(false);

  const [fetchEnvFieldSuggestions] =
    useEnvironmentLookerDataModelExploreSuggestionsLazyQuery();
  const [fetchWsFieldSuggestions] =
    useWorkspaceLookerDataModelExploreFieldSuggestionsLazyQuery();

  const throttledFetch = useMemo(
    () =>
      throttle(
        (
          matchPattern?: string, // use current input value as match term
        ) => {
          if ((!envExploreId && !wsExploreId) || !fieldName) return;

          setLoading(true);
          if (wsExploreId) {
            fetchWsFieldSuggestions({
              variables: {
                id: wsExploreId,
                suggestionInputArgs: {
                  field: fieldName,
                  term: matchPattern,
                },
              },
            })
              .then(({ data }) => {
                if (
                  data?.node?.__typename === 'WorkspaceLookerDataModelExplore'
                ) {
                  const { suggestions, error } = data.node.getSuggestions;
                  if (error) {
                    console.error(
                      `Error '${error}' encountered while getting suggestion for field ${fieldName}`,
                    );
                  }
                  setOptions(suggestions ?? []);
                }
              })
              .catch((error: ApolloError) => console.error(error.message))
              .finally(() => setLoading(false));
          } else if (envExploreId) {
            fetchEnvFieldSuggestions({
              variables: {
                id: envExploreId,
                suggestionInputArgs: {
                  field: fieldName,
                  term: matchPattern,
                },
              },
            })
              .then(({ data }) => {
                if (
                  data?.node?.__typename === 'EnvironmentLookerDataModelExplore'
                ) {
                  const { suggestions, error } = data.node.getSuggestions;
                  if (error) {
                    console.error(
                      `Error '${error}' encountered while getting suggestion for field ${fieldName}`,
                    );
                  }
                  setOptions(suggestions ?? []);
                }
              })
              .catch((error: ApolloError) => console.error(error.message))
              .finally(() => setLoading(false));
          }
        },
        200,
      ),
    [
      envExploreId,
      wsExploreId,
      fieldName,
      fetchEnvFieldSuggestions,
      fetchWsFieldSuggestions,
      setLoading,
      setOptions,
    ],
  );

  const handleOnFocus = useCallback(() => {
    const lastInputValue =
      inputValues.length > 0 ? inputValues[inputValues.length - 1] : '';
    return !options.length && throttledFetch(lastInputValue);
  }, [inputValues, options, throttledFetch]);

  const handleOnInputChange = useCallback(
    (
      _event: SyntheticEvent,
      newInputValue: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      // change reason `reset` means the input is being programmatically changed,
      // such as user making a selection by hitting 'Enter' or selecting from
      // the dropdown. Not really time to attempt another suggestion retrieval.
      if (reason !== 'reset') {
        throttledFetch(newInputValue);
      }
      setInputValues((prev) => [...prev, newInputValue]);
    },
    [throttledFetch],
  );

  return (
    <MultiValueAutocomplete
      loading={loading}
      options={options}
      onFocus={handleOnFocus}
      onChange={onChange}
      onInputChange={handleOnInputChange}
      placeholder='Customer value'
    />
  );
};

export default CustomerIdentifierInput;
