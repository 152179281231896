import { useState, useCallback } from 'react';

// mui
import { IconButton, Stack, Typography } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

// hooks
import { useFeature } from '../../../hooks';
import {
  DynamicFieldV1MetaData,
  FieldDatumV1,
  isValid,
  validators,
} from '@madeinventive/core-types';
import { BaseRegisteredDrawerComponentProps } from '../../registeredDrawers/types';

export interface EditDetailViewFormProps
  extends BaseRegisteredDrawerComponentProps {
  fields: FieldDatumV1[];
}

const EditDetailViewForm = ({ fields }: EditDetailViewFormProps) => {
  return (
    <Stack pt={4}>
      <Typography variant='h6' mb={2}>
        Show / Hide Fields
      </Typography>
      {fields.map((field) => (
        <FieldRow key={field.label} fieldDatum={field} />
      ))}
    </Stack>
  );
};

export default EditDetailViewForm;

interface FieldRowProps {
  fieldDatum: FieldDatumV1;
}

const FieldRow = ({ fieldDatum }: FieldRowProps) => {
  const { updateFieldVisibilityInDetailView } = useFeature();
  const [isVisible, setIsVisible] = useState(!fieldDatum.isHiddenInDetailView);

  const handleVisibility = useCallback(
    (fieldDatum: FieldDatumV1, visibility: boolean) => {
      if (
        isValid<DynamicFieldV1MetaData>(
          validators.DynamicFieldV1,
          fieldDatum.variable,
        )
      ) {
        setIsVisible(visibility);
        updateFieldVisibilityInDetailView(
          fieldDatum.variable.field,
          visibility,
        );
      }
    },
    [updateFieldVisibilityInDetailView],
  );

  return (
    <Stack
      direction='row'
      alignItems='center'
      spacing={2}
      key={fieldDatum.label}
    >
      <IconButton onClick={() => handleVisibility(fieldDatum, !isVisible)}>
        {isVisible && <VisibilityOutlinedIcon />}
        {!isVisible && <VisibilityOffOutlinedIcon />}
      </IconButton>
      <Typography>{fieldDatum.label}</Typography>
    </Stack>
  );
};
