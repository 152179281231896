// mui components
import { Box, Typography, lighten } from '@mui/material';

// icon
import DoneIcon from '@mui/icons-material/Done';
import { BaseRegisteredDialogComponentProps } from './types';

export interface SuccessDialogProps extends BaseRegisteredDialogComponentProps {
  bodyText: string;
  boldText?: string;
}

const SuccessDialog = ({ bodyText, boldText }: SuccessDialogProps) => {
  return (
    <Box
      display='flex'
      justifyContent='center'
      flexDirection='column'
      pt={2}
      pb={2}
    >
      <Box display='flex' justifyContent='center' mb={2.5}>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          sx={{
            height: '35px',
            width: '35px',
            borderRadius: '50%',
            background: (theme) => lighten(theme.palette.success.light, 0.5),
          }}
        >
          <DoneIcon color='success' />
        </Box>
      </Box>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        textAlign='center'
      >
        <Typography variant='body1'>
          <strong>{boldText}</strong>
          {bodyText}
        </Typography>
      </Box>
    </Box>
  );
};

export default SuccessDialog;
