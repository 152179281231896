import { useMemo } from 'react';
import { OutlinedInput, OutlinedInputProps } from '@mui/material';
import FormField, { FormFieldProps } from '../FormField';

export interface TextFieldProps extends Omit<FormFieldProps, 'onChange'> {
  value?: string;
  placeholder?: string;
  inputProps?: OutlinedInputProps;
  multiline?: boolean;
  fullHeight?: boolean;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}

const TextField = (props: TextFieldProps) => {
  const {
    value,
    sx,
    placeholder,
    inputProps,
    multiline,
    fullHeight,
    onChange,
    ...rest
  } = props;

  const combinedSX = useMemo(
    () => ({
      ...sx,
      ...(fullHeight
        ? {
            height: '100%',
            '& textarea': {
              height: 'inherit !important',
              overflowY: 'auto !important',
            },
          }
        : {}),
    }),
    [sx, fullHeight],
  );

  // If fullHeight is true, then multiline should be true
  const multilineValue = useMemo(
    () => multiline ?? fullHeight,
    [multiline, fullHeight],
  );

  return (
    <FormField {...rest} fullHeight={fullHeight}>
      <OutlinedInput
        sx={combinedSX}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        multiline={multilineValue}
        {...inputProps}
      />
    </FormField>
  );
};

export default TextField;
