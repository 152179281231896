import { useMemo } from 'react';
import EmailActionConfiguration from '../../ActionConfiguration/EmailActionConfiguration';
import SlackActionConfiguration from '../../ActionConfiguration/SlackActionConfiguration';
import { useAppSelector } from '../../../hooks';
import { getExploreInfoByEnvExploreId } from '../../../store/slices/loadedWorkspaceExplores';
import {
  FeatureEditDataState,
  isEmailInMemConfig,
  isSlackInMemConfig,
} from '../../../store/slices/features';
import { Box } from '@mui/material';

interface ActionPanelsProps {
  workspaceId: string;
  actionIndex: number;
  enableDataPreview?: boolean;
  featureEditData: FeatureEditDataState;
}
const ActionPanels = ({
  workspaceId,
  actionIndex,
  enableDataPreview,
  featureEditData,
}: ActionPanelsProps) => {
  const { id: featureId, exploreId, actions } = featureEditData;
  const loadedExplores = useAppSelector((state) => state.loadedExplores.value);

  const exploreInfo = useMemo(() => {
    if (!exploreId) return undefined;
    return getExploreInfoByEnvExploreId(loadedExplores, workspaceId, exploreId);
  }, [exploreId, loadedExplores, workspaceId]);

  const selectedAction = actions[actionIndex];

  if (!selectedAction) return null;

  return (
    <Box mx={6}>
      {['Email/v1.0', 'Email/v2.0'].includes(selectedAction.kind) &&
        isEmailInMemConfig(selectedAction.spec) && (
          <EmailActionConfiguration
            key={`email-config-${actionIndex}`}
            workspaceId={workspaceId}
            emailConfig={selectedAction.spec}
            actionIndex={actionIndex}
            enableDataPreview={enableDataPreview}
            exploreInfo={exploreInfo}
            featureId={featureId}
          />
        )}
      {selectedAction.kind === 'Slack/v1.0' &&
        isSlackInMemConfig(selectedAction.spec) && (
          <SlackActionConfiguration
            key={`slack-config-${actionIndex}`}
            workspaceId={workspaceId}
            slackConfig={selectedAction.spec}
            actionIndex={actionIndex}
            enableDataPreview={enableDataPreview}
            exploreInfo={exploreInfo}
            featureId={featureId}
          />
        )}
    </Box>
  );
};

export default ActionPanels;
