import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormFieldLabel from '../shared/Form/FormFieldLabel';
import DialogActions from '../shared/Dialog/DialogActions';
import useActiveExplore from '../../hooks/useActiveExplore';
import { BaseRegisteredDialogComponentProps } from './types';

export interface FeatureDataDialogProps
  extends BaseRegisteredDialogComponentProps {}

const FeatureDataDialog = ({ hideDialog }: FeatureDataDialogProps) => {
  const FORM_VALIDATION = Yup.object().shape({
    explore: Yup.string().required('Required information'),
  });

  const { exploreOptions, selectExploreByOption } = useActiveExplore();

  return (
    <Formik
      enableReinitialize={true}
      validateOnBlur={true}
      validateOnChange={true}
      initialValues={{ explore: '' }}
      validationSchema={FORM_VALIDATION}
      onSubmit={(values, { resetForm }) => {
        resetForm();
        selectExploreByOption(values.explore);
        hideDialog();
      }}
    >
      {({ errors, handleSubmit, handleChange, isValid, dirty }) => (
        <Form onSubmit={handleSubmit}>
          <FormControl fullWidth>
            <FormFieldLabel text='Data' required />
            <Field
              name='explore'
              type='select'
              size='small'
              as={Select}
              variant='outlined'
              displayEmpty
              fullWidth
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                handleChange(e);
              }}
              sx={{
                my: 1,
              }}
            >
              <MenuItem value='' disabled>
                <Typography>Select data</Typography>
              </MenuItem>
              {exploreOptions.map((explore) => (
                <MenuItem
                  value={explore.value}
                  key={explore.value}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    padding: '10px 15px',
                  }}
                >
                  <Typography variant='body2' fontWeight='bold'>
                    {explore.label}
                  </Typography>
                </MenuItem>
              ))}
            </Field>
            <FormHelperText sx={{ color: 'error.main' }}>
              {errors.explore}
            </FormHelperText>
            <DialogActions
              closeDialog={hideDialog}
              secondaryAction={{
                text: 'Skip for now',
                action: hideDialog,
              }}
              primaryAction={{
                text: 'Done',
                action: handleSubmit,
                disabled: !isValid || !dirty,
              }}
            />
          </FormControl>
        </Form>
      )}
    </Formik>
  );
};

export default FeatureDataDialog;
