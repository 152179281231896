import { ChipOptionType } from '../components/shared/ToggleChips';
import { ChatConfig, GPT4_TURBO } from '../store/slices/workspace';

// temperature and vizGenerationMode are missing from the UI
export const CHAT_SETTING_VALUES = {
  GPT4_TURBO: 'gpt-4-turbo',
  QUICK_FOLLOW: 'quick-follow',
  ANSWERABILITY_CHECK: 'answerability-check',
  COMMON_QUESTION_HINTS: 'common-question-hints',
  DATA_SCIENTIST_SUMMARY: 'data-scientist-summary',
  FIELD_REDUCTION: 'field-reduction',
  DATA_TRANSFORMATION: 'data-transformation',
  DATA_EMBEDDINGS: 'data-embeddings',
};

type ChatSettingOption = ChipOptionType & {
  configKey?: keyof ChatConfig;
};

export const chatSettingOptions: ChatSettingOption[] = [
  {
    label: 'GPT-4 Turbo',
    value: CHAT_SETTING_VALUES.GPT4_TURBO,
    configKey: 'model',
  },
  {
    label: 'Quick-follow',
    value: CHAT_SETTING_VALUES.QUICK_FOLLOW,
    configKey: 'enableQuickFollow',
  },
  {
    label: 'Common question hints',
    value: CHAT_SETTING_VALUES.COMMON_QUESTION_HINTS,
    configKey: 'enableCommonQuestionHints',
  },
  {
    label: 'Embeddings',
    value: CHAT_SETTING_VALUES.DATA_EMBEDDINGS,
    configKey: 'enableEmbeddings',
  },
];

export const getSelectedChatSetting = (chatConfig: ChatConfig) => {
  return chatSettingOptions
    .filter((option) => {
      if (option.configKey === 'model') {
        return chatConfig.model === GPT4_TURBO;
      }
      return chatConfig[option.configKey as keyof ChatConfig];
    })
    .map((option) => option.value);
};
