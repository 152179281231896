import { Stack } from '@mui/material';
import Icon from '../shared/Icon';

interface DynamicFieldButtonProps {
  onClick: () => void;
  isOpen: boolean;
}

const DynamicFieldButton = ({ onClick, isOpen }: DynamicFieldButtonProps) => {
  return (
    <Stack
      direction='row'
      spacing={0.5}
      paddingLeft={1}
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        borderLeft: (theme) => `1px solid ${theme.palette.border.light}`,
      }}
    >
      <Icon name='bracket' size='small' />
      <Icon name={isOpen ? 'carat-up' : 'carat-down'} size='small' />
    </Stack>
  );
};

export default DynamicFieldButton;
