import { useEffect, useContext } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  Grid,
  Typography,
  DialogActions,
  Box,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';

//icons
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

// types
import {
  LookerModel,
  useEnvironmentLookerProviderQuery,
  usePickerSchemaLazyQuery,
  usePickerColumnDataLazyQuery,
  CreateEnvironmentLookerDataModelExploreInput,
  UpdateEnvironmentLookerDataModelExploreInput,
} from '../../generated/types';
import { BaseRegisteredDialogComponentProps } from './types';

// context, slice, hooks
import {
  setColumnarSlices,
  toColumnarSlices,
  updateExploreExtract,
  toExploreExtract,
  makeExtractId,
  DataExploreConfig,
} from '../../store/slices/exploreExtracts';
import { useAppDispatch, useAppSelector, useToast } from '../../hooks';
import { EnvironmentContext } from '../EnvironmentProvider';

// components
import Button from '../shared/Button';
import VFPInputField from '../VFPInputField';
import FormFieldLabel from '../shared/Form/FormFieldLabel';

interface ExploreDetailsProps {
  id: string;
  dataModel: string;
  explore: string;
  customerField: string;
  noCustomField: boolean;
}

interface FormDataProps {
  dataModel: string;
  explore: string;
  customerField: string;
  noCustomField: boolean;
}

export interface EnvDataDialogProps extends BaseRegisteredDialogComponentProps {
  providerId: string;
  environmentId: string;
  exploreDetails: ExploreDetailsProps;
}

const EnvDataDialog = ({
  providerId,
  environmentId,
  exploreDetails,
  hideDialog,
}: EnvDataDialogProps) => {
  let dataModels: Array<LookerModel> | [];
  let externalProviderId: string | null;

  const storeDispatch = useAppDispatch();
  const exploreExtracts = useAppSelector(
    (state) => state.exploreExtracts.value,
  );
  const { showErrorToast } = useToast();

  const { createEnvExplore, updateEnvExplore } = useContext(EnvironmentContext);

  const [fetchPickerSchema] = usePickerSchemaLazyQuery();
  const [fetchPickerColumnData] = usePickerColumnDataLazyQuery();

  const { data: externalProviderData } = useEnvironmentLookerProviderQuery({
    variables: {
      environmentId: environmentId,
    },
  });

  if (externalProviderData && externalProviderData.node) {
    if (
      externalProviderData.node?.__typename === 'Environment' &&
      externalProviderData?.node.lookerProvider
    ) {
      if (externalProviderData.node.lookerProvider.models) {
        dataModels = externalProviderData.node.lookerProvider.models;
      }

      externalProviderId = externalProviderData.node.lookerProvider.id;
    }
  }

  const exploreChanged = async (
    values: ExploreDetailsProps,
    exploreName: string,
  ) => {
    const { dataModel } = values;

    const dataExploreCfg: DataExploreConfig = {
      providerId,
      modelName: dataModel,
      modelExploreName: exploreName,
    };

    const extractId = makeExtractId(dataExploreCfg);
    const { schema } = exploreExtracts[extractId] || {};
    if (!schema) {
      // explore extract not yet loaded, load it
      try {
        const result = await fetchPickerSchema({
          variables: {
            ...dataExploreCfg,
          },
        });
        const pickerSchema = result?.data?.pickerSchema;
        if (pickerSchema) {
          storeDispatch(
            updateExploreExtract(toExploreExtract(extractId, pickerSchema)),
          );
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          // picker schema fetch don't normally fail unless it's something low level such as
          // network error or looker time out whose error message is less useful to the user
          // show another toast here to give user more context
          showErrorToast(
            `A Looker error, ${e.message}, occurred while fetching picker schema for explore ${exploreName}`,
          );
        }
      }

      fetchPickerColumnData({
        variables: {
          ...dataExploreCfg,
        },
      })
        .then((res) => {
          if (res?.data) {
            const columnarSlices = toColumnarSlices(res.data);
            storeDispatch(
              setColumnarSlices({
                id: extractId,
                sliceInfo: columnarSlices,
              }),
            );
          }
        })
        .catch((e: unknown) => {
          if (e instanceof Error) {
            // picker data fetch don't normally fail unless it's something low level such as
            // network error or looker time out whose error message is less useful to the user
            // show another toast here to give user more context
            showErrorToast(
              `A Looker error, ${e.message}, occurred while fetching picker data for explore ${exploreName}.`,
            );
          }
        });
    }
  };

  const createDataModelExplore = async (formData: FormDataProps) => {
    if (
      typeof environmentId === 'string' &&
      typeof externalProviderId === 'string'
    ) {
      try {
        if (exploreDetails.id) {
          const input: UpdateEnvironmentLookerDataModelExploreInput = {
            id: exploreDetails.id,
            data: {
              dataModelName: formData.dataModel,
              exploreName: formData.explore,
              customerField: formData.customerField,
            },
          };

          await updateEnvExplore(input);
        } else {
          const input: CreateEnvironmentLookerDataModelExploreInput = {
            dataModelName: formData.dataModel,
            exploreName: formData.explore,
            externalProviderId: externalProviderId,
            customerField: formData.customerField,
          };

          await createEnvExplore(input);
        }
      } catch {
        // Nothing to do here just make sure any ApolloError is handled.
        // Showing the error to user is already handled by the error link.
      }
    }
    hideDialog();
  };

  useEffect(() => {
    if (exploreDetails.id !== '') {
      exploreChanged(exploreDetails, exploreDetails.explore);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exploreDetails]);

  const FORM_VALIDATION = Yup.object().shape({
    dataModel: Yup.string().required('Required information'),
    explore: Yup.string().required('Required information'),
    noCustomField: Yup.boolean(),
    customerField: Yup.string().when('noCustomField', {
      is: (noCustomField: boolean) => noCustomField === false,
      then: Yup.string().required('Required information'),
      otherwise: Yup.string(),
    }),
  });

  return (
    <Formik
      enableReinitialize={true}
      validateOnBlur={true}
      validateOnChange={true}
      initialValues={exploreDetails}
      validationSchema={FORM_VALIDATION}
      onSubmit={(values) => {
        createDataModelExplore(values);
      }}
    >
      {({ values, handleChange, errors, touched, setFieldValue, isValid }) => {
        const selectedDataModel = dataModels.find(
          (model: LookerModel) => model.name === values.dataModel,
        );
        const exploreDataOptions = selectedDataModel?.explores
          ? selectedDataModel.explores.reduce((acc: string[], element) => {
              if (element.name) {
                acc.push(element.name);
              }

              return acc;
            }, [])
          : [];

        const extractId = makeExtractId({
          providerId,
          modelName: values.dataModel,
          modelExploreName: values.explore,
        });
        return (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormFieldLabel text='Data model' required />
                  <Field
                    name='dataModel'
                    type='select'
                    size='small'
                    as={Select}
                    variant='outlined'
                    displayEmpty
                    fullWidth
                    sx={{ my: 1 }}
                  >
                    <MenuItem value='' disabled>
                      <Typography>Select a data model</Typography>
                    </MenuItem>

                    {dataModels &&
                      dataModels.map((model: LookerModel) => {
                        return (
                          model.name && (
                            <MenuItem value={model.name} key={model.name}>
                              <Typography>{model.name}</Typography>
                            </MenuItem>
                          )
                        );
                      })}
                  </Field>
                  <FormHelperText sx={{ color: 'red' }}>
                    {touched.dataModel && errors.dataModel}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormFieldLabel
                    text='Data'
                    subText='You will be able to assign these to specific customer workspaces.'
                    required
                  />
                  <Box sx={{ my: 1 }}>
                    <Autocomplete
                      disablePortal
                      options={exploreDataOptions}
                      onChange={(_, value) => {
                        if (value) {
                          setFieldValue('explore', value);
                          exploreChanged(values, value);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label='Select data' />
                      )}
                      disabled={!values.dataModel}
                      size='small'
                      value={values.explore}
                    />
                  </Box>

                  <FormHelperText sx={{ color: 'red' }}>
                    {touched.explore && errors.explore}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormFieldLabel
                  text='Customer field'
                  subText='Select which Field specifies which customer to filter for'
                  required
                />
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Field
                        name='noCustomField'
                        as={Checkbox}
                        checked={values.noCustomField}
                        color='primary'
                        onChange={(e: React.ChangeEvent) => {
                          setFieldValue('customerField', '');
                          //reset customer field
                          handleChange(e);
                        }}
                        size='small'
                        disabled={!values.explore}
                      />
                    }
                    label='No customer field'
                  />

                  <Field
                    name='customerField'
                    as={VFPInputField}
                    inputValue={values.customerField}
                    setInputValue={(inputValue: string) => {
                      //reset no customer field checkbox
                      values.noCustomField &&
                        setFieldValue('noCustomField', false);
                      setFieldValue('customerField', inputValue);
                    }}
                    isSimpleVersion={true}
                    extractId={extractId}
                    placeholder='Select customer field'
                    options={{ idFieldsOnly: true }}
                    disabled={!values.explore || values.noCustomField}
                  />

                  <FormHelperText sx={{ color: 'red' }}>
                    {touched.customerField && errors.customerField}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <DialogActions sx={{ mt: 4 }}>
              <Button variant='outlined' sx={{ mr: 1 }} onClick={hideDialog}>
                Cancel
              </Button>

              <Button
                variant='contained'
                type='submit'
                disabled={
                  !isValid ||
                  values.dataModel === '' ||
                  values.explore === '' ||
                  (values.customerField === '' &&
                    values.noCustomField === false)
                }
              >
                Save
              </Button>
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EnvDataDialog;
