import { TypographyOptions } from '@mui/material/styles/createTypography';

export const remToPx = (rem: string | number | undefined) => {
  if (rem === undefined) {
    return 14; //just choose some default # i.e. body1
  }
  let remNum: number;
  const remStr = rem.toString();
  if (remStr.endsWith('rem')) {
    remNum = parseFloat(remStr.substring(0, remStr.length - 3));
  } else {
    remNum = parseFloat(remStr);
  }
  return remNum * 16;
};

export type FontWeightTypes = 'light' | 'regular' | 'medium' | 'bold';

export const FontWeightMap: Record<FontWeightTypes, number> = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
};

export const typography: TypographyOptions = {
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'sans-serif',
  ].join(', '),
  fontFamilyComfortaa: 'Comfortaa',
  h1: {
    fontSize: '2rem', //32px
    lineHeight: '2.5rem', //40px
    letterSpacing: -0.1,
    fontWeight: FontWeightMap.light,
    textTransform: 'none',
  },
  h2: {
    fontSize: '1.5rem', //24px
    lineHeight: '2rem', //32px
    letterSpacing: -0.1,
    fontWeight: FontWeightMap.regular,
    textTransform: 'none',
  },
  h3: {
    fontSize: '1.25rem', //20px
    lineHeight: '1.75rem', //28px
    letterSpacing: -0.25,
    fontWeight: FontWeightMap.medium,
    textTransform: 'none',
  },
  h4: {
    fontSize: '1rem', //16px
    lineHeight: '1.5rem', //24px
    letterSpacing: -0.25,
    fontWeight: FontWeightMap.medium,
    textTransform: 'none',
  },
  h5: {
    fontSize: '0.875rem', //14px
    lineHeight: '1.5rem', //24px
    letterSpacing: -0.25,
    fontWeight: FontWeightMap.medium,
    textTransform: 'none',
  },
  body1: {
    fontSize: '0.875rem', //14px
    lineHeight: '1.5rem', //24px
    letterSpacing: -0.25,
    fontWeight: FontWeightMap.regular,
  },
  body1Link: {
    fontSize: '0.875rem', //14px
    lineHeight: '1.5rem', //24px
    letterSpacing: -0.25,
    fontWeight: FontWeightMap.regular,
    textDecoration: 'underline',
  },
  h6: {
    fontSize: '0.75rem', //12px
    lineHeight: '1.25rem', // 20px
    letterSpacing: -0.25,
    fontWeight: FontWeightMap.medium,
    textTransform: 'none',
  },
  body2: {
    fontSize: '0.75rem', //12px
    lineHeight: '1.25rem', // 20px
    letterSpacing: -0.25,
    fontWeight: FontWeightMap.regular,
  },
  body2Link: {
    fontSize: '0.75rem', //12px
    lineHeight: '1.25rem', // 20px
    letterSpacing: -0.25,
    fontWeight: FontWeightMap.regular,
    textDecoration: 'underline',
  },
  subtitle1: {
    // deprecated, use body2
    fontSize: '0.815rem', //13px
    lineHeight: '1.125rem', // 18px
    letterSpacing: '-0.00625rem', // -0.1px
    fontWeight: FontWeightMap.medium,
  },
  subtitle2: {
    // deprecated, use caption
    fontSize: '0.6875rem', //11px
    lineHeight: '1rem', // 16px
    letterSpacing: '-0.00625rem', // -0.1px
    fontWeight: FontWeightMap.medium,
  },
  button: {
    fontSize: '0.875rem', //14px
    lineHeight: '1.5rem', //24px
    letterSpacing: -0.25,
    fontWeight: FontWeightMap.medium,
  },
  caption: {
    fontSize: '0.625rem', //10px
    lineHeight: '1rem', // 16px
    letterSpacing: -0.25,
  },
  overline: {
    fontSize: '0.6875rem', //11px
  },
  code: {
    fontSize: '0.6875rem', //11px
    lineHeight: '1rem', //16px
    fontFamily: 'roboto-mono, monospace',
  },
};
