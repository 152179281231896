import { useCallback, useState, useContext } from 'react';
import { noop } from 'lodash';
import { useRouter } from 'next/router';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

// components
import {
  FormControl,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  FormHelperText,
} from '@mui/material';
import FormFieldLabel from '../shared/Form/FormFieldLabel';
import DialogActions from '../shared/Dialog/DialogActions';
import CustomerIdentifierInput from '../CustomerIdentifierInput';

// hooks and context
import { useAppDispatch } from '../../hooks/store';
import { setWorkspace } from '../../store/slices/workspace';
import { EnvironmentContext } from '../EnvironmentProvider';
import { BaseRegisteredDialogComponentProps } from './types';

export interface CreateWorkspaceDialogProps
  extends BaseRegisteredDialogComponentProps {}

interface FormDataProps {
  workspaceName: string;
  description?: string;
  externalRefId?: string;
  explore: string;
  customerIdentifiers: string[];
}

const CreateWorkspaceDialog = ({ hideDialog }: CreateWorkspaceDialogProps) => {
  const router = useRouter();
  const storeDispatch = useAppDispatch();
  const { createWorkspaceAddExplore, envExplores, environment } =
    useContext(EnvironmentContext);

  const [selectedExploreDetails, setSelectedExploreDetails] = useState({
    id: '',
    customerField: '',
    exploreName: '',
  });

  const createWorkspaceOnSubmit = async (values: FormDataProps) => {
    const workspace = await createWorkspaceAddExplore(
      {
        name: values.workspaceName,
        description: values.description,
        externalRefId: values.externalRefId,
        environmentId: environment?.id ?? '',
      },
      values.explore,
      values.customerIdentifiers,
    );

    // set the workspace to the store before redirecting
    if (workspace) {
      storeDispatch(setWorkspace(workspace));
      await router.push(`/workspace-settings/manage-data`);
    }
  };

  const handleExploreChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedRow = envExplores?.filter(
        (el) => el.id === e.target.value,
      )[0];
      if (selectedRow) {
        setSelectedExploreDetails({
          id: selectedRow.id,
          customerField: selectedRow.customerField || '',
          exploreName: selectedRow.exploreName,
        });
      }
    },
    [envExplores],
  );

  const FORM_VALIDATION = Yup.object().shape({
    workspaceName: Yup.string().required('Required information'),
    explore: Yup.string().required('Required information'),
    customerIdentifiers: selectedExploreDetails.customerField
      ? Yup.array().of(Yup.string()).required('Required information')
      : Yup.array(),
  });

  return (
    <Formik
      enableReinitialize={true}
      validateOnBlur={true}
      validateOnChange={true}
      initialValues={{
        workspaceName: '',
        description: '',
        externalRefId: '',
        explore: '',
        customerIdentifiers: [],
      }}
      validationSchema={FORM_VALIDATION}
      onSubmit={noop}
    >
      {({ values, errors, isValid, dirty, handleChange, setFieldValue }) => (
        <Form>
          <FormControl fullWidth>
            <Stack mb={1} spacing={1}>
              <FormFieldLabel text='Workspace name' required />
              <Field
                name='workspaceName'
                as={TextField}
                placeholder='Acme, Inc.'
                variant='outlined'
                size='small'
              />
            </Stack>
          </FormControl>
          <FormControl fullWidth margin='normal'>
            <Stack mb={1} spacing={1}>
              <FormFieldLabel text='Description' />
              <Field
                name='description'
                as={TextField}
                placeholder={`This workspace is for the Acme sales team's data apps.`}
                variant='outlined'
                size='small'
              />
            </Stack>
          </FormControl>
          <FormControl fullWidth margin='normal'>
            <Stack mb={1} spacing={1}>
              <FormFieldLabel text='External Ref ID' />
              <Field
                name='externalRefId'
                as={TextField}
                placeholder='ABCDE12345'
                variant='outlined'
                size='small'
              />
            </Stack>
          </FormControl>
          <FormControl fullWidth margin='normal'>
            <Stack mb={1} spacing={1}>
              <FormFieldLabel
                text='Data'
                subText='Select data this workspace can access. You can add more later.'
                required
              />
              <Field
                name='explore'
                type='select'
                size='small'
                as={Select}
                variant='outlined'
                displayEmpty
                fullWidth
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  handleChange(e);
                  handleExploreChange(e);
                }}
              >
                <MenuItem value='' disabled>
                  <Typography
                    sx={{
                      margin: 0,
                    }}
                  >
                    Select data
                  </Typography>
                </MenuItem>
                {envExplores?.map((explore) => (
                  <MenuItem
                    value={explore.id}
                    key={explore.id}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      padding: '10px 15px',
                    }}
                  >
                    <Typography
                      variant='body2'
                      sx={{
                        fontWeight: 700,
                        lineHeight: '13px',
                      }}
                    >
                      {explore.exploreName}
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{
                        fontWeight: 400,
                        lineHeight: '12px',
                      }}
                    >
                      {explore.dataModelName}
                    </Typography>
                  </MenuItem>
                ))}
              </Field>
            </Stack>
          </FormControl>
          {values.explore && !!selectedExploreDetails.customerField ? (
            <FormControl fullWidth margin='normal'>
              <Stack mb={1} spacing={1}>
                <FormFieldLabel
                  text='Customer identifier'
                  subText={`Select this customer's identifier from the list`}
                  required
                />
                <Field
                  name='customerIdentifiers'
                  as={CustomerIdentifierInput}
                  fieldName={selectedExploreDetails.customerField}
                  envExploreId={selectedExploreDetails.id}
                  value={values.customerIdentifiers}
                  onChange={(_event: unknown, value: string[] | null) => {
                    if (value !== values.customerIdentifiers) {
                      setFieldValue('customerIdentifiers', value);
                    }
                  }}
                  freeSolo
                  autoSelect
                  fullWidth
                  size='small'
                />
                <FormHelperText sx={{ color: 'red' }}>
                  {errors.customerIdentifiers}
                </FormHelperText>
              </Stack>
            </FormControl>
          ) : (
            ''
          )}
          <DialogActions
            closeDialog={hideDialog}
            primaryAction={{
              text: 'Create workspace',
              asyncAction: async () => {
                await createWorkspaceOnSubmit(values);
                return true;
              },
              disabled: !isValid || !dirty,
            }}
          />
        </Form>
      )}
    </Formik>
  );
};

export default CreateWorkspaceDialog;
