import { useState, useMemo } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';

// mui components
import { Box, Divider, Stack } from '@mui/material';

// components
import SettingsSidebar from './SettingsSidebar';

// hooks and utils
import { SideBarItemId, getSidebarItems } from './getSidebarItems';
import { useSessionInfo, useFeatureFlags, FeatureEnum } from '../../../hooks';

// lazy load Environment Settings content components
const UserSettings = dynamic(() => import('./UserSettings'));

// lazy load Workspace Settings content components
const WorkspaceGeneral = dynamic(() => import('../../WorkspaceGeneral'));
const WorkspaceMembers = dynamic(() => import('../../WorkspaceMembers'));
const WorkspaceData = dynamic(() => import('../../WorkspaceData'));
const WorkspaceIntegrations = dynamic(
  () => import('../../WorkspaceIntegrations'),
);
const WorkspaceAnalytics = dynamic(() => import('../../WorkspaceAnalytics'));

const SettingsComponentMap = {
  [SideBarItemId.USER_SETTINGS]: UserSettings,
  [SideBarItemId.WORKSPACE_GENERAL]: WorkspaceGeneral,
  [SideBarItemId.WORKSPACE_USERS]: WorkspaceMembers,
  [SideBarItemId.WORKSPACE_DATA]: WorkspaceData,
  [SideBarItemId.WORKSPACE_INTEGRATIONS]: WorkspaceIntegrations,
  [SideBarItemId.WORKSPACE_ANALYTICS]: WorkspaceAnalytics,
};

const SettingsModal = () => {
  const sessionUser = useSessionInfo();
  const router = useRouter();
  const isWorkspaceSelected = router.query.workspaceId !== undefined;
  const settingsTabId = router.query.settingsTabId as SideBarItemId;

  const [currentContentId, setCurrentContentId] = useState<SideBarItemId>(
    settingsTabId in SideBarItemId
      ? settingsTabId
      : SideBarItemId.USER_SETTINGS,
  );

  const { isFeatureEnabled } = useFeatureFlags();
  const isDataManagementEnabled = isFeatureEnabled(FeatureEnum.DataManagement);

  const allSidebarItems = getSidebarItems(
    isWorkspaceSelected,
    sessionUser,
    isDataManagementEnabled,
  );

  const CurrentSettingComponent = useMemo(
    () => SettingsComponentMap[currentContentId],
    [currentContentId],
  );

  return (
    <Stack direction='row' height='100%'>
      <Stack direction='column' width='240px'>
        <SettingsSidebar
          setCurrentContent={setCurrentContentId}
          currentContent={currentContentId}
          items={allSidebarItems}
        />
      </Stack>
      <Divider orientation='vertical' />
      <Box flex='1' overflow='auto' padding={4}>
        <CurrentSettingComponent />
      </Box>
    </Stack>
  );
};

export default SettingsModal;
