import Image from 'next/image';
import Markdown from 'markdown-to-jsx';

import { Stack, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

import { useSessionInfo } from '../../../hooks/session';
import useDynamicField from '../../DynamicFieldInputs/useDynamicField';
import { useContext } from 'react';
import { EnvironmentContext } from '../../EnvironmentProvider';
export interface SlackPreviewProps {
  message: string;
  vizTitle: string;
}

const SlackPreview = ({ message, vizTitle }: SlackPreviewProps) => {
  const { customBranding } = useSessionInfo();
  const { environment } = useContext(EnvironmentContext);
  const { convertStringToPreviewString } = useDynamicField();

  const SlackTypography = styled(Typography)({
    fontFamily: 'Lato',
    letterSpacing: '0px',
    lineHeight: 1,
  });

  return (
    <Stack spacing={2}>
      <Typography variant='h6'>Message</Typography>
      <Stack
        data-testid='slack-message-preview'
        direction='row'
        spacing={2}
        sx={{
          border: (theme) => `1px solid ${theme.palette.border.light}`,
          borderRadius: 4,
          padding: 2,
        }}
      >
        <Stack data-testid='slack-message-icon'>
          <Image
            src={customBranding?.iconUrl ?? ''}
            alt='icon'
            width={32}
            height={32}
          />
        </Stack>
        <Stack data-testid='slack-message-content'>
          <Stack direction='row' spacing={1} alignItems='center' mb={0.5}>
            <SlackTypography fontWeight={800} fontSize='13px'>
              {environment?.name}
            </SlackTypography>
            <Box
              sx={{
                bgcolor: '#DFDFDF',
                color: '#2E2E2E',
                borderRadius: '3px',
                padding: '2px 4px',
              }}
            >
              <SlackTypography fontSize='9px'>APP</SlackTypography>
            </Box>
            <SlackTypography fontSize='11px'>12:45</SlackTypography>
          </Stack>
          <SlackTypography mb={1} fontWeight={400}>
            {vizTitle}
          </SlackTypography>
          <Stack
            direction='row'
            spacing={1.5}
            alignItems='center'
            sx={{
              a: {
                color: '#2392A2',
              },
            }}
          >
            <Box
              sx={{
                width: 3,
                height: '100%',
                borderRadius: 1,
                bgcolor: 'border.actionable',
                marginRight: 1.5,
              }}
            />
            <Markdown>
              {convertStringToPreviewString(message) + ' [Review](#)'}
            </Markdown>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SlackPreview;
