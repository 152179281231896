import { capitalize } from 'lodash';
import {
  isValid,
  validators,
  NormalizedConditionExpression,
  DynamicFieldV1,
  SingleConditionValueV1,
  MultiConditionValueV1,
  RangeConditionValueV1,
  RelativeDateV1,
} from '@madeinventive/core-types';
import { Schema } from '../../../store/slices/exploreExtracts';

const formatFieldString = (variable: DynamicFieldV1) => {
  // In case the field name is in the format of 'table_one.field_one' which Looker uses
  // we want to split it and return 'Table one · Field one'
  if (variable.field.includes('.')) {
    const parts = variable.field.split('.');
    // replace underscores with spaces
    return parts.map((part) => capitalize(part.replace(/_/g, ' '))).join(' · ');
  } else {
    return capitalize(variable.field.replace(/_/g, ' '));
  }
};

const formatConditionValueString = (
  condition: NormalizedConditionExpression,
) => {
  if (
    isValid<SingleConditionValueV1>(
      validators.SingleConditionValueV1,
      condition.value,
    )
  ) {
    return condition.value.value;
  } else if (
    isValid<MultiConditionValueV1>(
      validators.MultiConditionValueV1,
      condition.value,
    )
  ) {
    return condition.value.values.join(', ');
  } else if (
    isValid<RangeConditionValueV1>(
      validators.RangeConditionValueV1,
      condition.value,
    )
  ) {
    return `${condition.value.lowerBound} - ${condition.value.upperBound}`;
  } else if (
    isValid<RelativeDateV1>(validators.RelativeDateV1, condition.value)
  ) {
    return `${condition.value.value} ${condition.value.unit.replace(
      /_/g,
      ' ',
    )}`;
  }
  return '';
};

// Get display strings for dynamic field conditions
export const getDynamicFieldConditionStrings = (
  condition: NormalizedConditionExpression,
  schema?: Schema,
) => {
  const { variable } = condition;
  if (isValid<DynamicFieldV1>(validators.DynamicFieldV1, variable)) {
    const dynamicField = variable;
    const opsDisplaysByType = schema?.opDisplaysByType[variable.normalizedType];
    const currentOperatorKey = condition.operator?.key;
    const operator = opsDisplaysByType?.find(
      (op) => op.key === currentOperatorKey,
    );
    return {
      field: formatFieldString(dynamicField),
      operator: operator?.altLabel ?? operator?.label,
      value: formatConditionValueString(condition),
      normalizedType: variable.normalizedType,
    };
  }
};
