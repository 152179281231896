import React, { useState, useEffect, useMemo } from 'react';
import { MenuItem, Stack, Typography } from '@mui/material';
import Select from '@mui/material/Select';
import { CalendarTodayOutlined } from '@mui/icons-material';

import {
  daysOfWeek,
  daysOfMonth,
  ScheduleState,
  parseCronToState,
  generateCronFromState,
  DaysOfWeek,
  numberToTimeString,
  convertDaysToText,
} from './scheduleUtils';

import FormField from '../Inputs/FormField';
import ToggleButton from '../ToggleButton';
import ToggleChips from '../ToggleChips';

const recurrenceOptions = [
  { label: 'Monthly', value: 'Monthly' },
  { label: 'Weekly', value: 'Weekly' },
  { label: 'Daily', value: 'Daily' },
];

// currentDays is either daysOfWeek[] or daysOfMonth[]
// We're using unknown type to avoid having to create two separate functions
const updateSelectedDays = (currentDays: unknown[], day: unknown) => {
  if (currentDays.includes(day)) {
    if (currentDays.length === 1) return currentDays;
    return currentDays.filter((d) => d !== day);
  } else {
    return [...currentDays, day];
  }
};

export interface ScheduleSelectorProps {
  initialCron?: string;
  onChange: (cron: string) => void;
  readOnly?: boolean;
  showTimeSelector?: boolean;
  hasError?: boolean;
  showOutput?: boolean; // dev only
}

export const ScheduleSelector = ({
  initialCron,
  onChange,
  readOnly = false,
  showTimeSelector = false,
  hasError,
  showOutput = false,
}: ScheduleSelectorProps) => {
  const initialScheduleState = parseCronToState(initialCron);

  const [scheduleState, setScheduleState] = useState<ScheduleState | undefined>(
    initialScheduleState,
  );

  useEffect(() => {
    if (initialCron === undefined) return;
    const newState = parseCronToState(initialCron);

    if (newState === undefined) return;
    setScheduleState(newState);
  }, [initialCron]);

  const updateScheduleState = (changes: Partial<ScheduleState>) => {
    let newState: ScheduleState;
    if (!scheduleState) {
      // if starting point is undefined
      // the changes must include recurrence
      if (!changes.recurrence) return;
      newState = { recurrence: changes.recurrence, ...changes };
    } else {
      newState = { ...scheduleState, ...changes };
    }

    const newCron = generateCronFromState(newState);
    setScheduleState(newState);
    onChange(newCron);
  };

  const handleRecurrenceChange = (recurrence: string) => {
    switch (recurrence) {
      case 'Daily':
        updateScheduleState({
          recurrence,
          daysOfWeek: [
            DaysOfWeek.Sun,
            DaysOfWeek.Mon,
            DaysOfWeek.Tue,
            DaysOfWeek.Wed,
            DaysOfWeek.Thu,
            DaysOfWeek.Fri,
            DaysOfWeek.Sat,
          ],
          daysOfMonth: undefined,
          hour: showTimeSelector ? undefined : '6',
          min: '0',
        });
        break;
      case 'Weekly':
        updateScheduleState({
          recurrence,
          daysOfWeek: undefined,
          daysOfMonth: undefined,
          hour: showTimeSelector ? undefined : '6',
          min: '0',
        });
        break;
      case 'Monthly':
        updateScheduleState({
          recurrence,
          daysOfWeek: undefined,
          daysOfMonth: undefined,
          hour: showTimeSelector ? undefined : '6',
          min: '0',
        });
        break;
    }
  };

  // day type is either 'daysOfWeek' or 'daysOfMonth'
  // We're using unknown type to avoid having to create two separate functions
  const handleDayChange = (
    day: unknown,
    dayType: 'daysOfWeek' | 'daysOfMonth',
  ) => {
    const currentDays = scheduleState?.[dayType] ?? [];
    const updatedDays = updateSelectedDays(currentDays, day);
    updateScheduleState({ [dayType]: updatedDays });
  };

  const showRecurrenceError = hasError && !scheduleState?.recurrence;
  const showDayOfMonthError = hasError && !scheduleState?.daysOfMonth;
  const showDayOfWeekError = hasError && !scheduleState?.daysOfWeek;
  const showTimeError = showTimeSelector && hasError && !scheduleState?.hour;

  const errorMessages = useMemo(
    () => ({
      recurrence: showRecurrenceError
        ? 'Please fill in delivery schedule.'
        : undefined,
      daysOfMonth: showDayOfMonthError
        ? 'Please choose delivery date.'
        : undefined,
      daysOfWeek: showDayOfWeekError
        ? 'Please choose delivery day.'
        : undefined,
      time: showTimeError ? 'Please choose delivery time.' : undefined,
    }),
    [
      showDayOfMonthError,
      showDayOfWeekError,
      showRecurrenceError,
      showTimeError,
    ],
  );

  return (
    <Stack
      role='form'
      aria-label='schedule selector'
      direction='column'
      spacing={3}
      alignItems='flex-start'
      sx={{
        minWidth: '304px',
      }}
    >
      <FormField
        label='How often?'
        errorMessage={errorMessages.recurrence}
        fullWidth
      >
        {readOnly ? (
          <Typography variant='body1'>
            {scheduleState?.recurrence ?? 'Not defined'}
          </Typography>
        ) : (
          <ToggleButton
            size='large'
            selected={scheduleState?.recurrence}
            handleItemClick={handleRecurrenceChange}
            options={recurrenceOptions}
            fullWidth
            aria-label='recurrence'
            hasError={showRecurrenceError}
          />
        )}
      </FormField>

      {/* Monthly options */}
      {scheduleState?.recurrence === 'Monthly' && (
        <FormField
          label='On'
          fullWidth
          aria-label='Monthly options'
          errorMessage={errorMessages.daysOfMonth}
        >
          {readOnly ? (
            <Typography variant='body1'>
              {convertDaysToText(scheduleState.daysOfMonth)}
            </Typography>
          ) : (
            <Stack
              direction='row'
              spacing={1}
              alignItems='center'
              width='100%'
              justifyContent='space-between'
            >
              <ToggleChips
                options={daysOfMonth.map((day) => ({
                  label: day.label,
                  value: day.value as string,
                  icon: <CalendarTodayOutlined />,
                }))}
                selectedValues={scheduleState.daysOfMonth ?? []}
                handleItemClick={(day) => handleDayChange(day, 'daysOfMonth')}
                hasError={showDayOfMonthError}
              />
            </Stack>
          )}
        </FormField>
      )}

      {/* Weekly options*/}
      {scheduleState?.recurrence === 'Weekly' && (
        <FormField
          label='On'
          fullWidth
          aria-label='weekly options'
          errorMessage={errorMessages.daysOfWeek}
        >
          {readOnly ? (
            <Typography variant='body1'>
              {convertDaysToText(scheduleState.daysOfWeek)}
            </Typography>
          ) : (
            <Stack
              direction='row'
              spacing={1}
              alignItems='center'
              width='100%'
              justifyContent='space-between'
            >
              <ToggleChips
                options={daysOfWeek.map((day) => ({
                  label: day.label,
                  value: day.value as string,
                }))}
                selectedValues={scheduleState.daysOfWeek ?? []}
                handleItemClick={(day) => handleDayChange(day, 'daysOfWeek')}
                hasError={showDayOfWeekError}
              />
            </Stack>
          )}
        </FormField>
      )}

      {/* Daily */}
      {scheduleState?.recurrence === 'Daily' && (
        <FormField
          label='On'
          fullWidth
          aria-label='all day selected weekly options'
        >
          {readOnly ? (
            <Typography variant='body1'>
              {convertDaysToText(scheduleState.daysOfWeek)}
            </Typography>
          ) : (
            <Stack
              direction='row'
              spacing={1}
              alignItems='center'
              width='100%'
              justifyContent='space-between'
            >
              <ToggleChips
                options={daysOfWeek.map((day) => ({
                  label: day.label,
                  value: day.value as string,
                }))}
                selectedValues={scheduleState.daysOfWeek ?? []}
                handleItemClick={(day) => handleDayChange(day, 'daysOfWeek')}
              />
            </Stack>
          )}
        </FormField>
      )}

      {/* Modify and enable this when we're ready to set time */}
      {showTimeSelector &&
        (scheduleState?.daysOfMonth || scheduleState?.daysOfWeek) && (
          <FormField label='At' fullWidth errorMessage={errorMessages.time}>
            <Select
              value={scheduleState?.hour}
              onChange={(e) =>
                // selects the hour and sets the minute to 0
                updateScheduleState({ hour: e.target.value, min: '0' })
              }
            >
              {Array.from({ length: 24 }, (_, i) => i).map((num) => (
                <MenuItem key={num} value={num.toString()}>
                  {numberToTimeString(num)}
                </MenuItem>
              ))}
            </Select>
          </FormField>
        )}

      {/* Output the schedule state for dev purpose only*/}
      {showOutput && (
        <Stack pt={4}>
          <div>
            <strong>Cron format:</strong>
            <pre>{generateCronFromState(scheduleState)}</pre>
          </div>

          <div>
            <strong>Schedule state:</strong>
            <pre>{scheduleState && JSON.stringify(scheduleState, null, 2)}</pre>
          </div>
        </Stack>
      )}
    </Stack>
  );
};

export default ScheduleSelector;
