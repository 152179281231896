import {
  BadgeSetting,
  BadgeConfig,
  FieldDatumV1,
} from '@madeinventive/core-types';
import BadgeSettingRow from './BadgeSettingRow';

import { Stack, Box } from '@mui/material';
import Button from '../shared/Button';
import AddIcon from '@mui/icons-material/Add';
import { AvailableColors } from '../shared/ColorSelector';

const thumbDownUnified = '1f44e';
const clapUnified = '1f44f';

const initialConfig: BadgeConfig = {
  badgeSettings: [
    {
      color: AvailableColors.RED,
      emoji: thumbDownUnified,
      operator: undefined,
      value: undefined,
    },
    {
      color: AvailableColors.ORANGE,
      emoji: undefined,
      operator: undefined,
      value: undefined,
    },
    {
      color: AvailableColors.GREEN,
      emoji: clapUnified,
      operator: undefined,
      value: undefined,
    },
  ],
};

interface BadgeConfigPanelProps {
  selectedFieldDatum?: FieldDatumV1;
  config?: BadgeConfig;
  setConfigs: (configs: BadgeConfig) => void;
}

const BadgeConfigPanel = ({
  selectedFieldDatum,
  config = initialConfig,
  setConfigs,
}: BadgeConfigPanelProps) => {
  const updateSettingAt = (setting: BadgeSetting, index: number) => {
    const settings = [...config.badgeSettings];
    settings[index] = setting;
    setConfigs({
      ...config,
      badgeSettings: settings,
    });
  };

  const addSetting = () => {
    const newSetting: BadgeSetting = {
      color: AvailableColors.LIGHT_GRAY,
      emoji: undefined,
      operator: undefined,
      value: undefined,
    };

    setConfigs({
      ...config,
      badgeSettings: [...config.badgeSettings, newSetting],
    });
  };

  const deleteSettingAt = (idxToDelete: number) => {
    const settings = [...config.badgeSettings];
    if (settings.length === 1) {
      const emptySetting: BadgeSetting = {
        color: AvailableColors.LIGHT_GRAY,
        emoji: undefined,
        operator: undefined,
        value: undefined,
      };
      setConfigs({
        ...config,
        badgeSettings: [emptySetting],
      });
    } else {
      settings.splice(idxToDelete, 1);
      setConfigs({
        ...config,
        badgeSettings: settings,
      });
    }
  };

  return (
    <Stack spacing={2}>
      {config.badgeSettings.map((config, index) => {
        return (
          <BadgeSettingRow
            key={config.color}
            defaultSetting={config}
            setSetting={(config) => updateSettingAt(config, index)}
            deleteSetting={() => deleteSettingAt(index)}
            selectedFieldDatum={selectedFieldDatum}
          />
        );
      })}
      <Box>
        <Button
          startIcon={<AddIcon />}
          variant='text'
          sx={{
            lineHeight: 'unset',
          }}
          onClick={addSetting}
        >
          Add condition
        </Button>
      </Box>
    </Stack>
  );
};

export default BadgeConfigPanel;
