import { useCallback, useEffect } from 'react';
import { useAppDispatch } from './index';
import {
  UserNotificationPreferenceEntry,
  setUserNotificationPreferences,
  updateUserNotificationPreference,
} from '../store/slices/userNotificationPreferences';
import {
  UserNotificationPreferencesQuery,
  useUpdateUserNotificationPreferenceMutation,
  useUserNotificationPreferencesLazyQuery,
} from '../generated/types';

export const useUserNotifications = () => {
  const storeDispatch = useAppDispatch();

  const handleData = useCallback(
    (data: UserNotificationPreferencesQuery) => {
      const notificationPreferences = data?.viewer.notificationPreferences;
      if (notificationPreferences) {
        const typedPreferences: UserNotificationPreferenceEntry[] =
          notificationPreferences.map((preference) => {
            return {
              workspaceId: preference.feature.workspaceId,
              featureId: preference.featureId,
              name: preference.feature.name,
              schedule: preference.feature.configJSON.spec.cronSchedule,
              optedOut: preference.optedOut,
              type: preference.type,
            };
          });
        storeDispatch(setUserNotificationPreferences(typedPreferences));
      }
    },
    [storeDispatch],
  );

  const [fetchUserNotificationPreferences, { loading }] =
    useUserNotificationPreferencesLazyQuery({
      fetchPolicy: 'cache-and-network',
      onError: (error) => console.log('error', error),
      onCompleted: handleData,
    });

  const [updateUserNotificationPreferenceMutation] =
    useUpdateUserNotificationPreferenceMutation({
      refetchQueries: ['UserNotificationPreferences'],
      optimisticResponse: {
        updateUserNotificationPreference: {
          success: true,
        },
      },
    });

  useEffect(() => {
    fetchUserNotificationPreferences();
  }, [fetchUserNotificationPreferences]);

  const updateNotificationPreference = useCallback(
    async ({
      featureId,
      optedOut,
    }: {
      featureId: string;
      optedOut: boolean;
    }) => {
      const result = await updateUserNotificationPreferenceMutation({
        variables: {
          input: {
            featureId,
            optOut: optedOut,
          },
        },
      });

      if (result.data?.updateUserNotificationPreference.success) {
        storeDispatch(
          updateUserNotificationPreference({
            featureId,
            optedOut,
          }),
        );
      }
    },
    [storeDispatch, updateUserNotificationPreferenceMutation],
  );

  return {
    loading,
    updateNotificationPreference,
  };
};
