import { noop } from 'lodash';
import { useState, useCallback, useContext, useEffect, useMemo } from 'react';

import {
  Component,
  WorkspaceComponentsDocument,
  useWorkspaceComponentsLazyQuery,
  useSaveChatResponseVisualizationMutation,
  SaveChatResponseVisualizationInput,
  useDeleteComponentMutation,
  useEditComponentMutation,
  ComponentType,
  ComponentAssociation,
} from '../generated/types';
import {
  setComponents,
  updateComponentName,
  addNewComponentId,
  loadInitialNewComponentIds,
  removeNewComponentId,
} from '../store/slices/workspaceComponents';
import { useAppDispatch, useAppSelector } from '.';
import { useDialog } from './useDialog';
import { DIALOG_IDS } from '../components/registeredDialogs/dialogRegistry';
import { WorkspaceContext } from '../components/WorkspaceProvider';

export const useWorkspaceComponents = (workspaceId: string) => {
  const storeDispatch = useAppDispatch();

  const componentsFromStore = useAppSelector(
    (store) => store.workspaceComponents.value,
  ).components;

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [savedComponentName, setSavedComponentName] = useState<string>('');

  const savedDialogContentProps = useMemo(
    () => ({
      bodyText: `${savedComponentName} saved successfully.`,
    }),
    [savedComponentName],
  );

  const { fetchMoreWorkspace } = useContext(WorkspaceContext);
  const { showDialog: showSaveVizSuccessDialog } = useDialog({
    id: DIALOG_IDS.SUCCESS,
    title: 'Save visualization',
    contentProps: savedDialogContentProps,
  });

  const [fetchWorkspaceComponents, { data, loading, error: fetchError }] =
    useWorkspaceComponentsLazyQuery();

  const [saveChatResponseVisualization] =
    useSaveChatResponseVisualizationMutation({
      refetchQueries: [
        {
          query: WorkspaceComponentsDocument,
          variables: {
            id: workspaceId,
          },
        },
      ],
    });

  const [deleteComponent] = useDeleteComponentMutation({
    refetchQueries: [
      {
        query: WorkspaceComponentsDocument,
        variables: {
          id: workspaceId,
        },
      },
    ],
  });

  const [updateComponent] = useEditComponentMutation();

  // this updates the components in the store when the data is fetched and also re-fetched.
  useEffect(() => {
    if (data?.node?.__typename === 'Workspace' && data.node.components) {
      const components = data.node.components.edges.map(
        (comp) => comp.node as Component,
      );
      storeDispatch(setComponents(components));
    }
  }, [data, storeDispatch]);

  const loadNewComponentIdsFromLocalStorage = useCallback(() => {
    storeDispatch(loadInitialNewComponentIds());
  }, [storeDispatch]);

  const fetchComponents = useCallback(async () => {
    await fetchWorkspaceComponents({
      variables: {
        id: workspaceId,
      },
    });
  }, [fetchWorkspaceComponents, workspaceId]);

  const getComponents = useCallback(() => {
    if (componentsFromStore === null) {
      fetchWorkspaceComponents({
        variables: {
          id: workspaceId,
        },
      });
    } else {
      return componentsFromStore;
    }
  }, [componentsFromStore, fetchWorkspaceComponents, workspaceId]);

  const addComponentToWorkspace = useCallback(
    async ({ chatResponseId, name }: SaveChatResponseVisualizationInput) => {
      setIsSaving(true);
      await saveChatResponseVisualization({
        variables: {
          input: {
            chatResponseId,
            name,
          },
        },
      })
        .then((res) => {
          const component = res.data?.saveChatResponseVisualization.component;
          if (component) {
            const { name, id } = component;
            setIsSaving(false);
            setIsSaved(true);
            setSavedComponentName(name);
            showSaveVizSuccessDialog();
            storeDispatch(addNewComponentId(id));
          }
        })
        .catch(noop);
    },
    [saveChatResponseVisualization, showSaveVizSuccessDialog, storeDispatch],
  );

  const dismissNewComponentById = useCallback(
    (id: string) => {
      storeDispatch(removeNewComponentId(id));
    },
    [storeDispatch],
  );

  const deleteComponentFromWorkspace = useCallback(
    async (componentId: string, force?: boolean) => {
      await deleteComponent({
        variables: {
          input: {
            componentId,
            force,
          },
        },
      }).catch(noop);
      fetchMoreWorkspace({}); // a roundabout way to refresh the workspace's feature list
      dismissNewComponentById(componentId);
    },
    [deleteComponent, dismissNewComponentById, fetchMoreWorkspace],
  );

  const editComponentName = useCallback(
    async (component: Component, name: string) => {
      const result = await updateComponent({
        variables: {
          input: {
            componentId: component.id,
            name,
          },
        },
        optimisticResponse: {
          editComponent: {
            __typename: 'EditComponentResult',
            component: {
              __typename: 'Component',
              id: component.id,
              name,
              type: ComponentType.VISUALIZATION,
              association: ComponentAssociation.WORKSPACE,
              associationId: workspaceId,
              configJSON: component.configJSON,
            },
          },
        },
      });

      const updatedName = result?.data?.editComponent.component.name;
      if (updatedName) {
        storeDispatch(
          updateComponentName({ id: component.id, name: updatedName }),
        );
      }
    },
    [storeDispatch, updateComponent, workspaceId],
  );

  return {
    isSaving,
    isSaved,
    loadNewComponentIdsFromLocalStorage,
    fetchComponents,
    getComponents,
    loading,
    error: fetchError,
    addComponentToWorkspace,
    dismissNewComponentById,
    deleteComponentFromWorkspace,
    editComponentName,
  };
};
