import React from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  SxProps,
} from '@mui/material';

import useDialogStyles from './style';

export interface CustomDialogProps {
  open: boolean;
  handleClose: () => void;
  header?: React.ReactNode;
  content?: React.ReactNode;
  actions?: React.ReactNode;
  sx?: SxProps;
  sxContent?: SxProps;
  sxHeader?: SxProps;
  footer?: React.ReactNode;
  noActionsMargin?: boolean;
}

const CustomDialog = (props: CustomDialogProps) => {
  const { title } = useDialogStyles();

  return (
    <Dialog sx={props.sx} open={props.open} onClose={props.handleClose}>
      {props.header && (
        <>
          <DialogTitle sx={props.sxHeader} className={title}>
            {props.header}
          </DialogTitle>
          <Divider />
        </>
      )}
      {props.content && (
        <DialogContent sx={props.sxContent}>{props.content}</DialogContent>
      )}
      {props.footer && (
        <DialogContent sx={props.sxContent}>{props.footer}</DialogContent>
      )}
      {props.actions && (
        <DialogActions
          sx={{
            mb: props.noActionsMargin ? 0 : 2,
            mr: props.noActionsMargin ? 0 : 2,
          }}
        >
          {props.actions}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default CustomDialog;
