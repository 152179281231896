import { createContext, useContext, ReactNode, useState, useMemo } from 'react';
import { useMediaQuery } from '@mui/material';
import { getColorModeSetting, setColorModeSetting } from '../lib/localStorage';

export type ColorModeSetting = 'light' | 'dark' | 'system';
export type ColorMode = 'light' | 'dark';

// Add constants for better maintainability
const VALID_COLOR_MODES: ColorModeSetting[] = ['light', 'dark', 'system'];
const DEFAULT_MODE: ColorModeSetting = 'light';

interface ColorModeContextType {
  modeSetting: ColorModeSetting;
  effectiveMode: ColorMode;
  setColorModeSetting: (mode: ColorModeSetting) => void;
}

const initialValue: ColorModeContextType = {
  modeSetting: 'light',
  effectiveMode: 'light',
  setColorModeSetting: () => {},
};

const ColorModeContext = createContext(initialValue);

export const useColorMode = () => {
  const context = useContext(ColorModeContext);
  if (context === initialValue) {
    throw new Error('useColorMode must be used within a ColorModeProvider');
  }
  return context;
};

interface ColorModeProviderProps {
  children: ReactNode;
}

const ColorModeProvider = ({ children }: ColorModeProviderProps) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [modeSetting, setModeSetting] = useState<ColorModeSetting>(() => {
    const stored = getColorModeSetting();
    return VALID_COLOR_MODES.includes(stored as ColorModeSetting)
      ? (stored as ColorModeSetting)
      : DEFAULT_MODE;
  });

  const effectiveMode: ColorMode = useMemo(
    () =>
      modeSetting === 'system'
        ? prefersDarkMode
          ? 'dark'
          : 'light'
        : modeSetting,
    [modeSetting, prefersDarkMode],
  );

  const value = useMemo(
    () => ({
      modeSetting,
      effectiveMode,
      setColorModeSetting: (newMode: ColorModeSetting) => {
        setModeSetting(newMode);
        setColorModeSetting(newMode);
      },
    }),
    [modeSetting, effectiveMode],
  );

  return (
    <ColorModeContext.Provider value={value}>
      {children}
    </ColorModeContext.Provider>
  );
};

export default ColorModeProvider;
