import Alert, { AlertColor } from '@mui/material/Alert';
import {
  SnackbarContent as MuiSnackbarContent,
  SnackbarAction,
  SnackbarKey,
  SnackbarMessage,
  useSnackbar,
} from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { forwardRef, ReactNode } from 'react';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/material';

interface SnackbarContentProps {
  id: SnackbarKey;
  message: SnackbarMessage;
  action?: SnackbarAction;
  severity?: AlertColor;
  textColor: string;
  backgroundColor: string;
  icon: ReactNode;
}

const SnackbarContent = forwardRef<HTMLDivElement, SnackbarContentProps>(
  (props, ref) => {
    const { id, message, severity, textColor, backgroundColor, icon, action } =
      props;
    const { closeSnackbar } = useSnackbar();

    const handleClose = () => {
      closeSnackbar(id);
    };

    const defaultAction = (
      <>
        <IconButton
          size='small'
          aria-label='close'
          onClick={handleClose}
          sx={{ mb: '.15rem', color: textColor }}
        >
          <CloseIcon fontSize='small' />
        </IconButton>
      </>
    );

    // Handle the action prop: if it's a function, call it with the key; otherwise, use it directly
    const resolvedAction = typeof action === 'function' ? action(id) : action;

    return (
      <MuiSnackbarContent ref={ref} role='alert'>
        <Alert
          action={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {resolvedAction}
              {defaultAction}
            </Box>
          }
          severity={severity}
          icon={icon}
          sx={{
            px: '1rem',
            py: '.15rem',
            width: '100%',
            alignItems: 'center',
            color: textColor,
            backgroundColor: backgroundColor,
            fontSize: (theme) => theme.typography.body2.fontSize,
          }}
        >
          {message}
        </Alert>
      </MuiSnackbarContent>
    );
  },
);

SnackbarContent.displayName = 'SnackbarContent';

export default SnackbarContent;
