import Image from 'next/legacy/image';
import slackSVG from '../../public/integrations/slack.svg';
import gmailPNG from '../../public/integrations/gmail.svg';
import twilioSVG from '../../public/integrations/twilio.svg';
import msTeamsSVG from '../../public/integrations/microsoft-teams.svg';
import gSheetSVG from '../../public/integrations/google-sheet.svg';
import gCalSVG from '../../public/integrations/google-calendar.svg';

import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';

import { ICON_DIMENSIONS, IconSize } from '../../components/shared/dimensions';
import { NavCardProps } from '../../components/Workflow/NavPane/NavCard';
import { ActionSpec } from '../../store/slices/features/helpers';

import {
  ActionSpecValidator,
  checkEmailConfigValidity,
  checkSlackConfigValidity,
} from './validity';

export enum FieldControlType {
  RegularInput,
  DynamicInput,
  DynamicInputArea,
}

export interface FieldControlEntry {
  fieldName: string;
  fieldLabel: string;
  controlType: FieldControlType;
}

export interface FieldControlConfig {
  buttonTextPlaceholder: string;
  fields: FieldControlEntry[];
}

export enum ActionCategories {
  INTEGRATIONS = 'INTEGRATIONS',
}
export interface ActionManifestEntry {
  cardProps: NavCardProps;
  defaultSpec?: ActionSpec;
  controlConfig?: FieldControlConfig;
  checkValidity?: ActionSpecValidator;
  disabled?: boolean;
  category?: ActionCategories;
}

export const actionManifest: ActionManifestEntry[] = [
  {
    cardProps: {
      title: 'Send an email',
      icon: (iconSize: IconSize) => (
        <EmailTwoToneIcon
          sx={{ ...ICON_DIMENSIONS[iconSize], color: 'info.main' }}
        />
      ),
    },
    defaultSpec: {
      kind: 'Email/v2.0',
      spec: {
        to: '',
        subject: '',
        body: '',
        buttonText: '',
        buttonUrl: '',
      },
    },
    controlConfig: {
      buttonTextPlaceholder: 'Send email',
      fields: [
        {
          fieldName: 'to',
          fieldLabel: 'To',
          controlType: FieldControlType.DynamicInput,
        },
        {
          fieldName: 'subject',
          fieldLabel: 'Subject',
          controlType: FieldControlType.DynamicInput,
        },
        {
          fieldName: 'body',
          fieldLabel: 'Body',
          controlType: FieldControlType.DynamicInputArea,
        },
        {
          fieldName: 'buttonUrl',
          fieldLabel: 'Button URL',
          controlType: FieldControlType.DynamicInput,
        },
        {
          fieldName: 'buttonText',
          fieldLabel: 'Button text',
          controlType: FieldControlType.RegularInput,
        },
      ],
    },
    checkValidity: checkEmailConfigValidity,
  },
  {
    cardProps: {
      title: 'Slack: post to a channel',
      icon: (iconSize: IconSize) => (
        <Image
          src={slackSVG}
          {...ICON_DIMENSIONS[iconSize]}
          alt='small_slack_icon'
        />
      ),
    },
    defaultSpec: {
      kind: 'Slack/v1.0',
      spec: {
        actionType: 'Slack/PostMessage/v1.0',
        channelId: '',
        message: '',
      },
    },
    controlConfig: {
      buttonTextPlaceholder: 'Send to Slack',
      fields: [
        {
          fieldName: 'actionType',
          fieldLabel: 'Action Type',
          controlType: FieldControlType.RegularInput,
        },
        {
          fieldName: 'channelId',
          fieldLabel: 'Channel ID',
          controlType: FieldControlType.DynamicInput,
        },
        {
          fieldName: 'message',
          fieldLabel: 'Message',
          controlType: FieldControlType.DynamicInputArea,
        },
      ],
    },
    checkValidity: checkSlackConfigValidity,
    category: ActionCategories.INTEGRATIONS,
  },
  {
    cardProps: {
      title: 'Gmail: send email',
      icon: (iconSize: IconSize) => (
        <Image
          src={gmailPNG}
          {...ICON_DIMENSIONS[iconSize]}
          alt='small_gmail_icon'
        />
      ),
    },
    disabled: true,
    category: ActionCategories.INTEGRATIONS,
  },
  {
    cardProps: {
      title: 'Twilio: send SMS',
      icon: (iconSize: IconSize) => (
        <Image
          src={twilioSVG}
          {...ICON_DIMENSIONS[iconSize]}
          alt='small_twilio_icon'
        />
      ),
    },
    disabled: true,
    category: ActionCategories.INTEGRATIONS,
  },
  {
    cardProps: {
      title: 'Microsoft Teams: send SMS',
      icon: (iconSize: IconSize) => (
        <Image
          src={msTeamsSVG}
          {...ICON_DIMENSIONS[iconSize]}
          alt='small_teams_icon'
        />
      ),
    },
    disabled: true,
    category: ActionCategories.INTEGRATIONS,
  },
  {
    cardProps: {
      title: 'Google Sheets: update record',
      icon: (iconSize: IconSize) => (
        <Image
          src={gSheetSVG}
          {...ICON_DIMENSIONS[iconSize]}
          alt='small_gsheets_icon'
        />
      ),
    },
    disabled: true,
    category: ActionCategories.INTEGRATIONS,
  },
  {
    cardProps: {
      title: 'Google Calendar: add a record',
      icon: (iconSize: IconSize) => (
        <Image
          src={gCalSVG}
          {...ICON_DIMENSIONS[iconSize]}
          alt='small_gcalendar_icon'
        />
      ),
    },
    disabled: true,
    category: ActionCategories.INTEGRATIONS,
  },
];

export const ACTION_MANIFEST_LOOKUP = (() => {
  const lookup: Record<string, ActionManifestEntry> = {};
  actionManifest.forEach((a) => {
    if (a.defaultSpec?.kind) {
      lookup[a.defaultSpec.kind] = a;
    }
  });
  return lookup;
})();
