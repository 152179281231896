import { getNormalizedTypeIcon } from '../../store/slices/exploreExtracts';
import {
  MaterialIcon,
  UrlIcon,
  InventiveIcon,
  TypeIcon,
} from '../../generated/types';
import Image from 'next/image';
import MuiIcon from '@mui/material/Icon';
import Tooltip from '../shared/Tooltip';
import Icon, { isIconName } from '../shared/Icon';
import useDataFieldAccordion from './useDataFieldAccordion';

const isMaterialIcon = (icon?: TypeIcon): icon is MaterialIcon => {
  if (icon && 'ligature' in icon) return true;
  return false;
};

const isUrlIcon = (icon?: TypeIcon): icon is UrlIcon => {
  if (icon && 'url' in icon) return true;
  return false;
};

const isInventiveIcon = (icon?: TypeIcon): icon is InventiveIcon => {
  if (icon && 'name' in icon) return true;
  return false;
};

interface DataFieldTypeIconProps {
  normalizedType: string;
}

// must include dataField or schemaNode
const DataFieldTypeIcon = ({ normalizedType }: DataFieldTypeIconProps) => {
  const { schema } = useDataFieldAccordion();
  if (!schema?.supportedTypes || !normalizedType) return;

  const icon = getNormalizedTypeIcon(schema.supportedTypes, normalizedType);

  const renderIcon = () => {
    if (isMaterialIcon(icon)) {
      return <MuiIcon sx={{ color: icon.color }}>{icon.ligature}</MuiIcon>;
    } else if (isUrlIcon(icon)) {
      return <Image src={icon.url} alt='Icon' width={16} height={16} />;
    } else if (isInventiveIcon(icon) && isIconName(icon.name)) {
      return <Icon name={icon.name} size='small' />;
    } else {
      // fallback to text icon
      return <Icon name='text' size='small' />;
    }
  };

  return <Tooltip title={normalizedType}>{renderIcon()}</Tooltip>;
};

export default DataFieldTypeIcon;
