import { NextRouter } from 'next/router';

export const removeSettingsUrlQuery = (router: NextRouter) => {
  // parameters to be removed
  const paramsToRemove = ['settings', 'settingsTabId', 'chatResponseId'];

  paramsToRemove.forEach(
    (param) => router.query[param] && delete router.query[param],
  );

  router.replace(
    {
      pathname: router.pathname,
      query: router.query,
    },
    undefined,
    { scroll: false },
  );
};
