import React from 'react';
import { motion } from 'framer-motion';
import { Stack, Box, useTheme, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

interface SwitchProps {
  checked: boolean;
  onChange: (value: boolean) => void;
  label?: string;
  size?: 'small' | 'large';
}

const Switch = ({ checked, onChange, label, size = 'small' }: SwitchProps) => {
  // TODO: the animation is not working as expected. Revisit this component later
  const MotionStack = motion(Stack);
  const MotionBox = motion(Box);

  const theme = useTheme();

  const direction = checked ? 'row' : 'row-reverse';
  const bgColor = checked
    ? theme.palette.background.inverted
    : theme.palette.neutrals.b5;

  return (
    <Stack spacing={0.5} direction='row' alignItems='center'>
      <MotionStack
        layout
        id='switch-container'
        animate={{
          flexDirection: direction,
        }}
        bgcolor={bgColor}
        transition={{ layout: { duration: 0.3, type: 'spring' } }}
        width={size === 'large' ? '40px' : '32px'}
        height={size === 'large' ? '20px' : '16px'}
        borderRadius={size === 'large' ? '10px' : '8px'}
        alignItems='center'
        justifyContent='space-between'
        padding={0.25}
        fontSize={size === 'large' ? '16px' : '12px'}
        onClick={() => onChange(!checked)}
        sx={{
          cursor: 'pointer',
        }}
      >
        <MotionStack flex={1} alignItems='center'>
          {checked ? (
            <CheckIcon
              fontSize={'inherit'}
              sx={{ color: (theme) => theme.palette.icon.inverted }}
            />
          ) : (
            <CloseIcon
              fontSize={'inherit'}
              sx={{ color: (theme) => theme.palette.icon.disabled }}
            />
          )}
        </MotionStack>
        <MotionBox
          id='switch-knob'
          width={size === 'large' ? '16px' : '12px'}
          height={size === 'large' ? '16px' : '12px'}
          borderRadius={'50%'}
          bgcolor={(theme) => theme.palette.background.paper}
        />
      </MotionStack>
      {label && (
        <Typography variant='h6' color='text.secondary' sx={{ flexShrink: 0 }}>
          {' '}
          {label}
        </Typography>
      )}
    </Stack>
  );
};

export default Switch;
