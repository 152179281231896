import { noop } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import { validationSchema } from '../../modals/AddTemplateCategory';
// components
import ElementUpdateDialog from '../ElementUpdateDialog';

// gql
import {
  useEditFeatureTemplateCategoryMutation,
  useDeleteFeatureTemplateCategoryMutation,
  FeatureTemplateCategoriesDocument,
} from '../../../generated/types';
import { CategoryItem } from '../../../hooks';

interface EditTemplateCategoryProps {
  open: boolean;
  setOpen: (params: boolean) => void;
  category: CategoryItem;
}

const EditTemplateCategory = ({
  open,
  setOpen,
  category,
}: EditTemplateCategoryProps) => {
  const [success, setSuccess] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [editTemplateCategory] = useEditFeatureTemplateCategoryMutation();
  const [deleteTemplateCategory] = useDeleteFeatureTemplateCategoryMutation();

  const successDialogTitle = success
    ? 'Save changes'
    : 'Edit template category';
  const dialogTitle = isDeleted
    ? 'Delete template category'
    : successDialogTitle;

  const editCategory = async (id: string, name: string) => {
    await editTemplateCategory({
      variables: {
        input: {
          id,
          name,
        },
      },
      refetchQueries: [{ query: FeatureTemplateCategoriesDocument }],
    }).catch(noop);
  };
  const deleteCategory = async () => {
    await deleteTemplateCategory({
      variables: { input: { id: category.id } },
      refetchQueries: [{ query: FeatureTemplateCategoriesDocument }],
    }).catch(noop);
  };

  return (
    <ElementUpdateDialog
      open={open}
      setOpen={setOpen}
      id={category.id}
      selectedItemName={category.name}
      title='Category Name'
      subTitle='Changing the category name will update the category name on all associated templates.'
      submitButtonName='Update category'
      deleteButtonName='Delete category'
      deleteHelperText='Are you sure you want to delete the category'
      deleteSubHelperText={`Deleting this category won't delete the templates associated with it.`}
      dialogTitle={dialogTitle}
      successMessage='was successfully updated.'
      deleteSuccessMessage='was successfully deleted.'
      isDeleteFlow={isDeleted}
      setIsDeleteFlow={setIsDeleted}
      success={success}
      deleteOperation={deleteCategory}
      setSuccess={setSuccess}
      updateItem={editCategory}
      placeholder='Enter category name'
      validationSchema={validationSchema}
    />
  );
};

export default EditTemplateCategory;
