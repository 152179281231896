import { useEffect, useMemo } from 'react';
import { useEnvironmentWorkspacesLazyQuery } from '../generated/types';
import { useAppSelector } from './store';

export const useWorkspaceSelector = () => {
  const { environment } = useAppSelector((store) => store.environment.value);

  const [fetchWorkspaces, { data, loading, fetchMore }] =
    useEnvironmentWorkspacesLazyQuery();

  useEffect(() => {
    if (environment?.id) {
      fetchWorkspaces({
        variables: {
          environmentId: environment.id,
        },
      });
    }
  }, [environment?.id, fetchWorkspaces]);

  const listData = useMemo(() => {
    if (data?.node?.__typename === 'Environment') {
      const {
        edges,
        pageInfo: { hasNextPage, endCursor },
      } = data.node.workspaces;
      return {
        workspaces: edges.map((edge) => edge.node),
        options: edges.map((edge) => ({
          label: edge.node?.name,
          id: edge.node?.id,
        })),
        hasNextPage: hasNextPage,
        endCursor: endCursor,
        loadNextPage: () => {
          if (hasNextPage && environment?.id) {
            fetchMore({
              variables: {
                environmentId: environment.id,
                after: endCursor,
              },
            });
          }
        },
      };
    }
  }, [data, environment, fetchMore]);

  return {
    listData,
    loading,
  };
};
