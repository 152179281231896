import { useCallback } from 'react';
import {
  useEnvironmentExposedLookerDataModelExploresLazyQuery,
  useCreateWorkspaceMutation,
  useCreateWorkspaceLookerDataModelExploreMutation,
  useDeleteWorkspaceMutation,
  DeleteWorkspaceInput,
  CreateWorkspaceInput,
  Workspace,
  Environment,
  EnvironmentLookerDataModelExplore,
  useCreateEnvironmentLookerDataModelExploreMutation,
  CreateEnvironmentLookerDataModelExploreInput,
  useUpdateEnvironmentLookerDataModelExploreMutation,
  UpdateEnvironmentLookerDataModelExploreInput,
  useDeleteEnvironmentLookerDataModelExploreMutation,
  DeleteEnvironmentLookerDataModelExploreInput,
  useEnvironmentByIdLazyQuery,
  useViewerLazyQuery,
  EnvironmentWorkspacesDocument,
  PolicyEdge,
} from '../generated/types';
import { useAppDispatch, useSessionInfo } from '../hooks';
import { covertGQLPolicyToReduxPolicy } from './useEnvironmentPolicies';
import {
  setEnvironment,
  setAgentPersonaConfig,
  setPoliciesAction,
  setIsLoading,
  bumpWsListUpdateCounter,
  setEnvExplores,
  addEnvExplore,
  updateEnvExplore as updateEnvExploreAction,
  removeEnvExplore,
  Policy,
} from '../store/slices/environment';

import { useEmbeddingContext } from '../contexts/EmbeddingProvider';
import { setCustomBranding } from '../store/slices/session';
import { useEnvironmentAgentProfile } from './useEnvironmentAgentProfile';
import { AgentPersona } from '@madeinventive/core-types';

export const useEnvironment = () => {
  const { isEmbedded } = useEmbeddingContext();
  const { environmentId: userEnvironmentId, userType } = useSessionInfo();
  const { handleReceiveAgentPersona } = useEnvironmentAgentProfile();

  const canModifyEnvironment =
    !isEmbedded &&
    (userType === 'EnvironmentMember' || userType === 'SystemUser');

  const storeDispatch = useAppDispatch();

  // environment
  const [fetchEnvByUser, { loading, error }] = useViewerLazyQuery();
  const [fetchEnvById] = useEnvironmentByIdLazyQuery();

  // env explores
  const [
    fetchEnvironmentExposedDataModels,
    { loading: envExploreLoading, error: envExploreError },
  ] = useEnvironmentExposedLookerDataModelExploresLazyQuery();
  const [createEnvironmentLookerDataModelExplore] =
    useCreateEnvironmentLookerDataModelExploreMutation();
  const [updateEnvironmentLookerDataModelExplore] =
    useUpdateEnvironmentLookerDataModelExploreMutation();
  const [deleteEnvironmentLookerDataModelExplore] =
    useDeleteEnvironmentLookerDataModelExploreMutation();

  const fetchEnvExplores = useCallback(
    async (environmentId?: string) => {
      if (!canModifyEnvironment) return;
      if (!userEnvironmentId && !environmentId) return;

      const result = await fetchEnvironmentExposedDataModels({
        variables: {
          environmentId: (userEnvironmentId || environmentId) ?? '',
        },
      });

      const envExposedModels = result?.data;

      if (envExposedModels) {
        const models =
          envExposedModels?.node?.__typename === 'Environment' &&
          envExposedModels?.node?.exposedLookerDataModelExplores
            ? envExposedModels.node.exposedLookerDataModelExplores.map(
                (explore) => explore as EnvironmentLookerDataModelExplore,
              )
            : undefined;
        if (models) {
          storeDispatch(setEnvExplores(models));
        }
      }
    },
    [
      userEnvironmentId,
      fetchEnvironmentExposedDataModels,
      storeDispatch,
      canModifyEnvironment,
    ],
  );

  const createEnvExplore = useCallback(
    async (input: CreateEnvironmentLookerDataModelExploreInput) => {
      if (!canModifyEnvironment) return;

      try {
        const created = await createEnvironmentLookerDataModelExplore({
          variables: {
            input,
          },
        });

        if (created.data?.createEnvironmentLookerDataModelExplore.explore) {
          const explore = created.data.createEnvironmentLookerDataModelExplore
            .explore as EnvironmentLookerDataModelExplore;
          storeDispatch(addEnvExplore(explore));
        }
      } catch {
        // Nothing to do here just make sure any ApolloError is handled.
        // Showing the error to user is already handled by the error link.
      }
    },
    [
      createEnvironmentLookerDataModelExplore,
      canModifyEnvironment,
      storeDispatch,
    ],
  );

  const updateEnvExplore = useCallback(
    async (input: UpdateEnvironmentLookerDataModelExploreInput) => {
      if (!canModifyEnvironment) return;

      try {
        const updated = await updateEnvironmentLookerDataModelExplore({
          variables: {
            input,
          },
        });

        if (updated.data?.updateEnvironmentLookerDataModelExplore.explore) {
          const explore = updated.data.updateEnvironmentLookerDataModelExplore
            .explore as EnvironmentLookerDataModelExplore;
          storeDispatch(updateEnvExploreAction(explore));
        }
      } catch {
        // Nothing to do here just make sure any ApolloError is handled.
        // Showing the error to user is already handled by the error link.
      }
    },
    [
      updateEnvironmentLookerDataModelExplore,
      canModifyEnvironment,
      storeDispatch,
    ],
  );

  const deleteEnvExplore = useCallback(
    async (input: DeleteEnvironmentLookerDataModelExploreInput) => {
      if (!canModifyEnvironment) return;

      try {
        const deleted = await deleteEnvironmentLookerDataModelExplore({
          variables: {
            input,
          },
        });

        if (deleted) {
          storeDispatch(removeEnvExplore(input.id));
        }
      } catch {
        // Nothing to do here just make sure any ApolloError is handled.
        // Showing the error to user is already handled by the error link.
      }
    },
    [
      deleteEnvironmentLookerDataModelExplore,
      canModifyEnvironment,
      storeDispatch,
    ],
  );

  const fetchEnvironment = useCallback(
    async (environmentId?: string) => {
      storeDispatch(setIsLoading(true));
      let environment: Environment | undefined;
      if (userType === 'EnvironmentMember' || userType === 'WorkspaceMember') {
        const result = await fetchEnvByUser();
        storeDispatch(setIsLoading(false));
        const data = result?.data;

        // store environment and workspaces
        if (data) {
          if (data?.viewer.user?.__typename === 'EnvironmentMember') {
            environment = data.viewer.user.environment as Environment;
          } else if (data?.viewer.user?.__typename === 'WorkspaceMember') {
            environment = data.viewer.user.workspace.environment as Environment;
          }
        }
      } else if (userType === 'SystemUser' && environmentId) {
        const result = await fetchEnvById({
          variables: {
            environmentId,
          },
        });
        storeDispatch(setIsLoading(false));
        const data = result?.data;
        environment = data?.node as Environment;
      }
      if (environment) {
        storeDispatch(setEnvironment(environment));
        if (environment.agentPersonaConfig) {
          storeDispatch(setAgentPersonaConfig(environment.agentPersonaConfig));
        }
        if (environment.policies?.edges) {
          const policies: Policy[] = environment.policies.edges.map(
            (policyEdge: PolicyEdge) => {
              const policy = policyEdge.node;
              const reduxPolicy = covertGQLPolicyToReduxPolicy(policy);
              return reduxPolicy;
            },
          );
          storeDispatch(setPoliciesAction(policies));
        }
        handleReceiveAgentPersona(
          environment.agentPersonaConfig as AgentPersona,
        );
        fetchEnvExplores(environmentId);
        storeDispatch(
          setCustomBranding({
            logoUrl: environment.logoUrl || '',
            themeColor: environment.themeColor || '',
            iconUrl: environment.iconUrl || '',
          }),
        );
      }
    },
    [
      storeDispatch,
      userType,
      fetchEnvByUser,
      fetchEnvById,
      handleReceiveAgentPersona,
      fetchEnvExplores,
    ],
  );

  // workspaces
  const [createWorkspaceMutation] = useCreateWorkspaceMutation();
  const [createWorkspaceLookerDataModelExplore] =
    useCreateWorkspaceLookerDataModelExploreMutation();
  const [deleteWS] = useDeleteWorkspaceMutation();

  const createWorkspaceAddExplore = useCallback(
    async (
      input: CreateWorkspaceInput,
      explore?: string,
      customerIdentifiers?: string[],
    ): Promise<Workspace | null> => {
      const effectiveEnvironmentId = userEnvironmentId || input.environmentId;
      try {
        const result = await createWorkspaceMutation({
          variables: {
            input: {
              ...input,
              environmentId: effectiveEnvironmentId,
            },
          },
          // return on this mutation does not include the full workspace object for redux state
          // so we need to refetch the environment from the Viewer Document to get the full workspace object
          refetchQueries: [EnvironmentWorkspacesDocument],
        });
        if (!result?.errors) {
          storeDispatch(bumpWsListUpdateCounter());
        }
        const data = result?.data;

        if (data && explore) {
          await createWorkspaceLookerDataModelExplore({
            variables: {
              input: {
                workspaceId: data.createWorkspace.workspace.id,
                lookerDataModelExploreId: explore,
                customerFilterValues: customerIdentifiers || [],
              },
            },
          });
          fetchEnvironment(effectiveEnvironmentId);

          const workspace = data.createWorkspace.workspace;
          if (workspace.__typename === 'Workspace') {
            return workspace as Workspace;
          }
        }
      } catch {
        // Nothing to do here just make sure any ApolloError is handled.
        // Showing the error to user is already handled by the error link.
      }

      return null;
    },
    [
      userEnvironmentId,
      fetchEnvironment,
      createWorkspaceMutation,
      createWorkspaceLookerDataModelExplore,
      storeDispatch,
    ],
  );

  const deleteWorkspaceFromEnvironment = useCallback(
    async (input: DeleteWorkspaceInput) => {
      try {
        await deleteWS({ variables: { input } });
        storeDispatch(bumpWsListUpdateCounter());
      } catch {
        // Nothing to do here just make sure any ApolloError is handled.
        // Showing the error to user is already handled by the error link.
      }
    },
    [deleteWS, storeDispatch],
  );

  return {
    // requests
    fetchEnvironment,
    fetchEnvExplores,
    createEnvExplore,
    updateEnvExplore,
    deleteEnvExplore,
    createWorkspaceAddExplore,
    deleteWorkspaceFromEnvironment,

    // states
    loading,
    error,
    envExploreLoading,
    envExploreError,
  };
};
