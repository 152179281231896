import React, { SyntheticEvent, useCallback, useState } from 'react';
import { MultiConditionValueV1 } from '@madeinventive/core-types';
import MultiValueAutocomplete from '../shared/MultiValueAutocomplete';

type MultiConditionValueAutocompleteProps = {
  value?: MultiConditionValueV1;
  disabled?: boolean;
  loading?: boolean;
  suggestions?: readonly string[];
  placeholder?: string;
  getSuggestions?: (inputValue: string) => void;
  onChange: (values: MultiConditionValueV1) => void;
  label?: string;
  hasError?: boolean;
};

const MultiConditionValueAutocomplete = (
  props: MultiConditionValueAutocompleteProps,
) => {
  const {
    value = { values: [] } as MultiConditionValueV1,
    disabled,
    loading,
    suggestions = [],
    placeholder,
    getSuggestions,
    onChange,
    label,
    hasError,
  } = props;
  const [inputValue, setInputValue] = useState<string>('');

  const handleOnInputChange = useCallback(
    (_event: SyntheticEvent, newInputValue: string) => {
      if (getSuggestions) {
        getSuggestions(newInputValue);
      }
      setInputValue(newInputValue);
    },
    [getSuggestions],
  );

  const handleOnFocus = useCallback(() => {
    return (
      (!suggestions.length || !inputValue.length) &&
      getSuggestions &&
      getSuggestions(inputValue)
    );
  }, [inputValue, suggestions, getSuggestions]);

  const handleChange = useCallback(
    (_: SyntheticEvent<Element, Event>, value: unknown) => {
      const values: string[] = value as string[];
      const multiConditionValue: MultiConditionValueV1 = { values };
      onChange(multiConditionValue);
    },
    [onChange],
  );

  return (
    <MultiValueAutocomplete
      value={value.values}
      freeSolo
      disabled={disabled}
      loading={loading}
      options={suggestions}
      onChange={handleChange}
      onInputChange={handleOnInputChange}
      onFocus={handleOnFocus}
      placeholder={placeholder}
      label={label}
      hasError={hasError}
    />
  );
};

export default MultiConditionValueAutocomplete;
