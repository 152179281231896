import React, { useCallback, useMemo } from 'react';

// material UI components
import { Grid, Box } from '@mui/material';

// components
import FormInput, {
  FormInputProps,
  CustomFormInputProps,
} from '../shared/Form/FormInput';
import HtmlDynamicFieldInput from '../HtmlDynamicFieldInput';
import DynamicFieldInput from '../DynamicFieldInput';
import DataPreview from '../DataPreview';
import EmailAttachment from './EmailAttachment';

// hooks and types
import { useFeature, useAppSelector } from '../../hooks';
import { EmailInMemConfig } from '../../store/slices/features';
import { ExploreInfo } from '../../store/slices/loadedWorkspaceExplores';
import { EmailAttachmentType } from '../../generated/types';

interface EmailActionConfigurationProps {
  workspaceId: string;
  emailConfig: EmailInMemConfig;
  actionIndex?: number;
  enableDataPreview?: boolean;
  exploreInfo?: ExploreInfo;
  featureId: string;
}

const EmailActionConfiguration = ({
  workspaceId,
  emailConfig,
  actionIndex,
  enableDataPreview,
  exploreInfo,
  featureId,
}: EmailActionConfigurationProps) => {
  const { isLive, setEmailFieldAt } = useFeature();

  const workspaceComponents = useAppSelector(
    (state) => state.workspaceComponents.value.components,
  );

  const componentIds = emailConfig.attachments?.map(
    (attachment) => attachment.spec.componentId,
  );
  const components = useMemo(() => {
    if (!componentIds) return undefined;
    return componentIds.map((componentId) => {
      return workspaceComponents?.find(
        (component) => component.id === componentId,
      );
    });
  }, [componentIds, workspaceComponents]);

  // for now, it supports only a single attached component
  const component = components?.[0];

  const setEmailConfig = useCallback(
    (values: Partial<EmailInMemConfig>) => {
      if (actionIndex === undefined) return;
      // componentIds are not editable
      const keys = Object.keys(values) as Array<
        keyof Omit<EmailInMemConfig, 'attachments'>
      >;
      keys.forEach((key) => {
        const value = values[key] ?? '';
        setEmailFieldAt(actionIndex, key, value);
      });
    },
    [setEmailFieldAt, actionIndex],
  );

  const inputsList = useMemo(
    () => [
      {
        key: `email-config-${actionIndex}-to`,
        customInput: (
          <DynamicFieldInput
            className='dynamicField'
            extractId={exploreInfo?.extractId}
            initialValue={emailConfig.to}
            mixed
            onValueChange={(value) => setEmailConfig({ to: value })}
            placeholder={
              component
                ? 'name@example.com'
                : 'name@example.com , {manager_email}, account+{id}@example.com'
            }
            disabled={isLive}
            disableDynamicField={!!component}
          />
        ),
        marginTop: 0,
        title: 'To',
        tooltipText:
          'Add dynamic recipients by typing { to add data. Separate multiple emails with commas.',
        required: true,
      },
      {
        key: `email-config-${actionIndex}-subject`,
        customInput: (
          <DynamicFieldInput
            className='dynamicField'
            extractId={exploreInfo?.extractId}
            initialValue={emailConfig.subject}
            mixed
            onValueChange={(value) => setEmailConfig({ subject: value })}
            placeholder={'Your account {account_name} needs your attention.'}
            disabled={isLive}
            disableDynamicField={!!component}
          />
        ),
        marginTop: 2,
        title: 'Subject',
        tooltipText:
          'Type { to add data to personalize and/or create distinct email threads.',
        required: true,
      },
      {
        key: `email-config-${actionIndex}-body`,
        customInput: (
          <HtmlDynamicFieldInput
            className='htmlDynamicField'
            extractId={exploreInfo?.extractId}
            initialValue={emailConfig.body}
            InputMode='textarea'
            onValueChange={(value) => setEmailConfig({ body: value })}
            placeholder='The opportunity {opportunity.name} at the {account.name} account has a risk score of {opportunity.riskscore}.'
            disabled={isLive}
            disableDynamicField={!!component}
          />
        ),
        marginTop: 2,
        title: 'Body',
        tooltipText:
          'Type { to add data. Use HTML for formatting: <b>bold</b>, <i>italics</i>, <br> for line break.',
        required: true,
      },
      {
        key: `email-config-${actionIndex}-attachment`,
        customInput: (
          <EmailAttachment
            name={component?.name ?? ''}
            type={component?.emailAttachmentType ?? EmailAttachmentType.OTHER}
            disabled={true}
          />
        ),
        marginTop: 2,
        title: 'Attachment',
        hidden: !component,
      },
      {
        key: `email-config-${actionIndex}-buttonUrl`,
        customInput: (
          <DynamicFieldInput
            className='dynamicField'
            extractId={exploreInfo?.extractId}
            initialValue={emailConfig.buttonUrl}
            mixed
            onValueChange={(value) => setEmailConfig({ buttonUrl: value })}
            placeholder='www.salesforce.com/update/{account_id}'
            disabled={isLive}
            disableDynamicField={!!component}
          />
        ),
        marginTop: 2,
        title: 'Button URL',
        tooltipText: 'Type { to add data to personalize deep-links.',
        required: false,
      },
      {
        key: `email-config-${actionIndex}-buttonText`,
        disabled: isLive,
        headerWidth: 100,
        inputValue: emailConfig.buttonText,
        marginTop: 2,
        placeholder: 'Update account',
        required: false,
        setValue: (value: string) => setEmailConfig({ buttonText: value }),
        title: 'Button text',
        tooltipText: 'Use a short call-to-action word or phrase (max char 25).',
        type: 'text',
      },
    ],
    [
      component,
      emailConfig,
      exploreInfo?.extractId,
      isLive,
      setEmailConfig,
      actionIndex,
    ],
  );

  const isPreviewButtonDisabled = () => {
    if (
      !emailConfig.body.trim() &&
      !emailConfig.buttonUrl.trim() &&
      !emailConfig.buttonText.trim() &&
      !emailConfig.subject.trim() &&
      !emailConfig.to.trim()
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      <Grid
        item
        display='flex'
        justifyContent='space-around'
        flexWrap='nowrap'
        width='100%'
        mb={4}
      >
        <Box width='100%'>
          {inputsList
            .filter((item) => !item.hidden)
            .map(
              ({
                key,
                customInput,
                disabled,
                inputValue,
                marginTop,
                required,
                placeholder,
                setValue,
                sub,
                title = '',
                tooltipText,
              }: CustomFormInputProps | FormInputProps) => (
                <FormInput
                  customInput={customInput}
                  disabled={disabled}
                  id={key}
                  inputValue={inputValue}
                  key={key}
                  marginTop={marginTop}
                  required={required}
                  placeholder={placeholder}
                  setValue={setValue}
                  sub={sub}
                  title={title}
                  tooltipText={tooltipText}
                  type='text'
                />
              ),
            )}
        </Box>
      </Grid>
      {!isLive && enableDataPreview && exploreInfo && (
        <Grid item>
          <DataPreview
            key={featureId}
            workspaceId={workspaceId}
            selectedExploreId={exploreInfo.envExploreId}
            ctaText='Preview data'
            title='Preview data'
            includeActionFields={true}
            isDisabled={isPreviewButtonDisabled()}
          />
        </Grid>
      )}
    </>
  );
};

export default EmailActionConfiguration;
