import React, { useMemo } from 'react';
import { omit } from 'lodash';

import Button, { ButtonProps } from '../shared/Button';

import { usePopupMenu, PopupMenuProps } from '../../hooks';
export type { MenuItemOption } from '../../hooks';

export interface MenuButtonProps extends Omit<PopupMenuProps, 'hostAriaLabel'> {
  children: ButtonProps['children'];
  buttonProps?: Omit<ButtonProps, 'children'>;
}

// DEPRECATED: This component is deprecated and will be removed in the future.
// Use shared/Menu component instead.
const MenuButton = ({
  buttonProps,
  children,
  menuItemOptions,
  useMenuItemCategory,
  onClick,
  onMenuClose,
  fitToButton,
}: MenuButtonProps) => {
  const buttonAriaLabel = buttonProps
    ? buttonProps['aria-label']
    : 'menu-button';

  const { handleClick, renderMenu } = usePopupMenu({
    hostAriaLabel: buttonAriaLabel,
    menuItemOptions,
    useMenuItemCategory,
    onClick,
    onMenuClose,
    fitToButton,
  });

  const restButtonProps = useMemo(() => {
    if (buttonProps) {
      return omit(buttonProps, ['onClick', 'aria-label']);
    }
  }, [buttonProps]);

  return (
    <>
      <Button
        variant='text'
        onClick={handleClick}
        aria-label={buttonAriaLabel}
        {...restButtonProps}
      >
        {children}
      </Button>
      {renderMenu()}
    </>
  );
};

export default MenuButton;
