import { useState } from 'react';
import { useFormikContext } from 'formik';

// components
import { Stack } from '@mui/material';
import ClickableCard from '../../shared/ClickableCard';
import FormField from '../../shared/Inputs/FormField';
import MultiValueDynamicFieldInput from '../../DynamicFieldInputs/MultiValueDynamicFieldInput';
import DynamicFieldEditor from '../../DynamicFieldInputs/DynamicFieldEditor';
import AdvancedMessage from './AdvancedMessage';
import SlackIntegration from './SlackIntegration';

// types
import { KnownAliases } from '@madeinventive/core-types';
import {
  MessageFormValues,
  ActionType,
  DYNAMIC_FIELD_SUB_LABEL,
} from '../types';
import { Mode, OutputFormat } from '../../DynamicFieldInputs/types';
import MultiValueTextField from '../../shared/Inputs/MultiValueTextField';

// hooks
import useDynamicField from '../../DynamicFieldInputs/useDynamicField';
import { FeatureEnum, useFeatureFlags } from '../../../hooks';

interface MessageProps {
  workspaceId: string;
  slackIntegrationId?: string;
  slateEditorResetKey: number;
  disableDynamicField: boolean;
}

const Message = ({
  workspaceId,
  slackIntegrationId,
  slateEditorResetKey,
  disableDynamicField,
}: MessageProps) => {
  const formik = useFormikContext<MessageFormValues>();
  const {
    setFieldValue,
    values,
    errors,
    touched,
    setFieldTouched,
    validateField,
  } = formik;

  const { getValidDynamicFieldTagObjectFromText } = useDynamicField();

  const [useAdvancedEditor, setUseAdvancedEditor] = useState(false);

  const { isFeatureEnabled } = useFeatureFlags();
  const isRecurrency = isFeatureEnabled(FeatureEnum.Recurrency);

  const addRecipients = (value: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (validateRecipientInput(value)) {
        const recipients = [...(values.recipients ?? []), value];
        formik.setFieldValue('recipients', recipients);
        resolve();
      } else {
        formik.setTouched({ recipients: true });
        formik.setFieldError('recipients', 'Invalid email address');
        reject();
      }
    });
  };

  const deleteRecipient = (value: string) => {
    const recipients = values.recipients?.filter((v) => v !== value);
    formik.setFieldValue('recipients', recipients);
  };

  const validateRecipientInput = (value: string) => {
    return (
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ||
      !!getValidDynamicFieldTagObjectFromText(value) ||
      value === KnownAliases.ALL_WORKSPACE_ADMINS
    );
  };

  const openAdvancedEditor = async () => {
    // advanced editor require recipients
    setFieldTouched('recipients', true);
    await validateField('recipients');

    if (errors.recipients) return;
    setUseAdvancedEditor(true);
  };

  if (useAdvancedEditor) {
    return (
      <AdvancedMessage
        formik={formik}
        disableDynamicField={disableDynamicField}
      />
    );
  }

  return (
    <Stack id='message-step' spacing={4} width='100%'>
      <FormField label='Action type' errorMessage={errors.actionType}>
        <Stack id='action-type-buttons' direction='row' spacing={1}>
          <ClickableCard
            label='email'
            selected={values.actionType === ActionType.Email}
            onClick={() => setFieldValue('actionType', ActionType.Email)}
            icon='mail'
            title='Send via email'
            error={!!errors.actionType}
          />
          {!isRecurrency && (
            <ClickableCard
              label='slack'
              selected={values.actionType === ActionType.Slack}
              onClick={() => setFieldValue('actionType', ActionType.Slack)}
              icon='slack'
              title='Send via Slack'
              error={!!errors.actionType}
            />
          )}
        </Stack>
      </FormField>
      {values.actionType === ActionType.Email && (
        <>
          <FormField
            label='Recipient email address'
            subLabel={`Press Enter to add multiple recipients. ${
              disableDynamicField
                ? ''
                : `Type “{“ or use the menu to add dynamic recipients.`
            }`}
            errorMessage={touched.recipients ? errors.recipients : undefined}
          >
            <MultiValueDynamicFieldInput
              values={values.recipients ?? []}
              onValueEntered={addRecipients}
              onDelete={deleteRecipient}
              disableDynamicField={disableDynamicField}
            />
          </FormField>
          <FormField
            label='Message'
            subLabel={disableDynamicField ? undefined : DYNAMIC_FIELD_SUB_LABEL}
            labelOverflow
            action={{
              actionLabel: 'Go to advanced editor',
              onClick: openAdvancedEditor,
            }}
            errorMessage={touched.emailBody ? errors.emailBody : undefined}
          >
            <DynamicFieldEditor
              slateEditorResetKey={slateEditorResetKey}
              defaultValue={values.emailBody ?? ''}
              onChange={(value) => {
                setFieldValue('emailBody', value);
              }}
              mode={Mode.RICH_TEXT}
              outputFormat={OutputFormat.HTML}
              disableDynamicField={disableDynamicField}
            />
          </FormField>
        </>
      )}
      {values.actionType === ActionType.Slack && !slackIntegrationId && (
        <SlackIntegration workspaceId={workspaceId} />
      )}
      {values.actionType === ActionType.Slack && slackIntegrationId && (
        <>
          <MultiValueTextField
            fullWidth
            label='Channel ID'
            subLabel='To find your channel ID, open the channel details in Slack and scroll down to the bottom. Give Inventive permission to post by typing ”/invite @Inventive” into the channel. Press Enter to add multiple channel IDs.'
            labelOverflow
            values={values.slackChannelIds ?? []}
            onValueEntered={(value) => {
              setFieldValue('slackChannelIds', [
                ...(values.slackChannelIds ?? []),
                value,
              ]);
            }}
            onDelete={(value) => {
              setFieldValue(
                'slackChannelIds',
                values.slackChannelIds?.filter((v) => v !== value) ?? [],
              );
            }}
            placeholder='e.g. A12BB0C1234'
            errorMessage={
              touched.slackChannelIds ? errors.slackChannelIds : undefined
            }
          />
          <FormField
            label='Message'
            subLabel={disableDynamicField ? undefined : DYNAMIC_FIELD_SUB_LABEL}
            errorMessage={
              touched.slackMessage ? errors.slackMessage : undefined
            }
            labelOverflow
          >
            <DynamicFieldEditor
              defaultValue={values.slackMessage ?? ''}
              onChange={(value) => {
                setFieldValue('slackMessage', value);
              }}
              mode={Mode.RICH_TEXT}
              outputFormat={OutputFormat.MARKDOWN}
              disableDynamicField={disableDynamicField}
            />
          </FormField>
        </>
      )}
    </Stack>
  );
};

export default Message;
