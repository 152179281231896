import { Box, Typography } from '@mui/material';
import React from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

export interface TreeViewItem {
  icon?: React.ReactNode;
  label: string;
}

interface Props {
  items: TreeViewItem[];
}

const TreeView = ({ items }: Props) => {
  return (
    <>
      {items.map((item, index) => {
        const indentMultiplier = index < 2 ? index : 2;
        return (
          <Box
            key={`${item.label}-${index}`}
            display='flex'
            alignItems='center'
            gap={1}
            mb={0.5}
            ml={index < 1 ? 0 : `${34 * indentMultiplier}px`}
          >
            {index !== 0 && (
              <ChevronLeftIcon
                sx={{
                  transform: 'rotate(-45deg)',
                }}
              />
            )}
            {!!item.icon && item.icon}
            <Typography variant='body2'>{item.label}</Typography>
          </Box>
        );
      })}
    </>
  );
};

export default TreeView;
