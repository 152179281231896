import React, { useEffect } from 'react';

import { FormControl, Stack, Typography } from '@mui/material';
import FormInput from '../Form/FormInput';
import { RangeConditionValueV1 } from '@madeinventive/core-types';

type RangeValueInputProps = {
  value?: RangeConditionValueV1;
  labels?: string[];
  disabled?: boolean;
  onChange: (values?: RangeConditionValueV1) => void;
  placeholder?: string;
  hideLabel?: boolean;
  hasError?: boolean;
};

const RangeValueInput = ({
  value = {
    lowerBound: '',
    upperBound: '',
  } as RangeConditionValueV1,
  labels = ['Min', 'Max'],
  disabled,
  onChange,
  placeholder,
  hideLabel,
  hasError,
}: RangeValueInputProps) => {
  const [firstInputValue, setFirstInputValue] = React.useState(
    value.lowerBound,
  );
  const [secondInputValue, setSecondInputValue] = React.useState(
    value.upperBound,
  );

  useEffect(() => {
    if (firstInputValue === '' || secondInputValue === '') {
      onChange(undefined);
    }

    if (firstInputValue && secondInputValue) {
      onChange({
        lowerBound: firstInputValue,
        upperBound: secondInputValue,
      } as RangeConditionValueV1);
    }
  }, [firstInputValue, secondInputValue, onChange]);

  return (
    <>
      <FormControl size='small' fullWidth error={hasError}>
        <Stack direction='row' spacing={1}>
          <FormInput
            label={hideLabel ? undefined : labels[0]}
            type='text'
            inputValue={firstInputValue}
            disabled={disabled}
            setValue={setFirstInputValue}
            placeholder={placeholder}
            data-testid='first-input-wrapper'
            hasError={hasError}
          />
          <Typography
            sx={{
              '&.MuiTypography-root': {
                marginY: 'auto',
              },
            }}
            variant='body1'
          >
            and
          </Typography>
          <FormInput
            label={hideLabel ? undefined : labels[1]}
            type='text'
            inputValue={secondInputValue}
            disabled={disabled}
            setValue={setSecondInputValue}
            placeholder={placeholder}
            data-testid='second-input-wrapper'
            hasError={hasError}
          />
        </Stack>
      </FormControl>
    </>
  );
};

export default RangeValueInput;
