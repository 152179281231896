import {
  Editor,
  BaseEditor,
  Descendant,
  Transforms,
  createEditor,
  Text,
} from 'slate';
import { capitalize } from 'lodash';
import escapeHtml from 'escape-html'; //serialize
import { ReactEditor, withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import {
  DEFAULT_SLATE_VALUE,
  FieldElement,
  CustomText,
  CustomElement,
  ElementType,
  ParagraphElement,
} from './types';
import {
  DynamicFieldV1MetaData,
  FieldType,
  TagObject,
  isValid,
  validators,
  DynamicFieldV1,
} from '@madeinventive/core-types';
import {
  FieldInfo,
  isFieldInfo,
  SchemaNode,
  SchemaNodeKind,
} from '../../store/slices/exploreExtracts';

const FIELD_REGEX = /\[\[(.*?)\]\]/g;
const BOLD_REGEX = /\*([^*]+)\*/g; // Matches *bold* text
const ITALIC_REGEX = /_([^_]+)_/; // Matches _italic_ text, Note: Do not include `g` flag to avoid being dropped

//// SERIALIZATION: SLATE VALUE => STRING
// 1. for raw text
export const convertSlateValueToRawString = (nodes: Descendant[]): string => {
  const string = nodes.map((node) => serializeNode(node)).join('\n');
  return string;
};

// 2. for Markdown
// Markdown does not support
const decodeHtml = (text: string) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = text;
  return txt.value;
};

export const convertSlateValueToMarkdownString = (
  nodes: Descendant[],
): string => {
  const string = nodes.map((node) => serializeNode(node)).join('\n');
  return decodeHtml(string);
};

// 3. for HTML
export const convertSlateValueToHtmlString = (nodes: Descendant[]): string => {
  const string = nodes.map((node) => serializeNode(node, true)).join('\n');
  return string;
};

const serializeNode = (node: Descendant, isHtml?: boolean): string => {
  const BOLD_START = isHtml ? '<strong>' : '*';
  const BOLD_END = isHtml ? '</strong>' : '*';
  const ITALIC_START = isHtml ? '<em>' : '_';
  const ITALIC_END = isHtml ? '</em>' : '_';
  const PARAGRAPH_START = isHtml ? '<p>' : '';
  const PARAGRAPH_END = isHtml ? '</p>' : '';

  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `${BOLD_START}${string}${BOLD_END}`;
    }
    if (node.italic) {
      string = `${ITALIC_START}${string}${ITALIC_END}`;
    }
    // Add more tags when it is supported
    return string;
  }
  const children = node.children?.map((n) => serializeNode(n, isHtml)).join('');

  switch (node.type) {
    case ElementType.FIELD:
      return convertFieldToString(node as FieldElement);
    case ElementType.PARAGRAPH:
      return `${PARAGRAPH_START}${children}${PARAGRAPH_END}`;
    default:
      return children;
  }
};

const convertFieldToString = (fieldElement: FieldElement): string => {
  const fieldObject = fieldElement.field;
  const fieldMetaData = fieldObject.metaData;

  if (!isFieldInfo(fieldMetaData)) {
    throw new Error('Invalid field metadata');
  }

  const TagMetaData: DynamicFieldV1 = {
    field: fieldMetaData.name,
    normalizedType: fieldMetaData.normalizedType,
  };

  const fieldObj: TagObject = {
    type: FieldType.DYNAMIC_FIELD,
    metaData: TagMetaData,
    value: fieldElement.field.id,
  };

  return `[[${JSON.stringify(fieldObj)}]]`;
};

const convertTagObjectStringToSchemaNode = (
  tagObjectString: string,
): SchemaNode => {
  const tagObject: TagObject = JSON.parse(tagObjectString);

  if (!isValid<DynamicFieldV1>(validators.DynamicFieldV1, tagObject.metaData)) {
    throw new Error('Invalid field tag object');
  }

  const fieldInfo: FieldInfo = {
    name: tagObject.metaData.field,
    shortName: tagObject.metaData.field,
    label: tagObject.metaData.field,
    normalizedType: tagObject.metaData.normalizedType,
  };

  const schemaNode: SchemaNode = {
    id: tagObject.value,
    name: tagObject.metaData.field,
    kind: SchemaNodeKind.FIELD,
    metaData: fieldInfo,
  };

  return schemaNode;

  // TODO: improve the entire util file to a hook to use the schema directly in the context
  // const found = schema.lookup[tagObject.metaData.field];
  // return found;
};

//// DESERIALIZATION: STRING => SLATE VALUE
// 0. for raw text and markdown
const convertStringToSlateValue = (
  value: string,
  isMarkdown: boolean,
): Descendant[] => {
  const lines = value.split('\n');
  if (lines.length === 0) {
    return DEFAULT_SLATE_VALUE;
  }

  const nodes: Descendant[] = [];
  lines.forEach((line) => {
    if (line === '') {
      nodes.push(createElementNode(ElementType.PARAGRAPH));
    } else {
      nodes.push(...deserializeText(line, isMarkdown));
    }
  });
  return nodes;
};

export const convertDynamicFieldStringToChipValue = (text: string): string => {
  const matches = text.matchAll(FIELD_REGEX);
  let lastMatchIndex = 0;
  let chipValue = '';

  for (const match of matches) {
    const matchIndex = match.index ?? 0; // index of the match
    const matchStr = match[1]; // strings inside the match
    const fieldNode = convertTagObjectStringToSchemaNode(matchStr); // convert the object into a SchemaNode
    const textBeforeMatch = text.slice(lastMatchIndex, matchIndex); // text before the match

    if (textBeforeMatch) {
      chipValue += textBeforeMatch;
    }

    chipValue += isFieldInfo(fieldNode.metaData)
      ? `[${fieldNode.metaData.label}]`
      : '';

    lastMatchIndex = matchIndex + match[0].length;
  }

  const textAfterMatch = text.slice(lastMatchIndex);

  if (textAfterMatch) {
    chipValue += textAfterMatch;
  }

  return chipValue;
};

const deserializeText = (text: string, isMarkdown?: boolean): Descendant[] => {
  const startingElement: ParagraphElement = {
    type: ElementType.PARAGRAPH,
    children: [],
  };

  if (text === '') {
    return [startingElement];
  }

  const matches = text.matchAll(FIELD_REGEX);
  let lastMatchIndex = 0;

  for (const match of matches) {
    const matchIndex = match.index ?? 0; // index of the match
    const matchStr = match[1]; // strings inside the match
    const fieldNode = convertTagObjectStringToSchemaNode(matchStr); // convert the object into a SchemaNode
    const textBeforeMatch = text.slice(lastMatchIndex, matchIndex); // text before the match

    if (textBeforeMatch) {
      startingElement.children.push(
        ...(isMarkdown
          ? deserializeStyledText(textBeforeMatch)
          : [createTextNode(textBeforeMatch)]),
      );
    }

    startingElement.children.push(createFieldElement(fieldNode));

    lastMatchIndex = matchIndex + match[0].length;
  }

  const textAfterMatch = text.slice(lastMatchIndex);

  if (textAfterMatch) {
    startingElement.children.push(
      ...(isMarkdown
        ? deserializeStyledText(textAfterMatch)
        : [createTextNode(textAfterMatch)]),
    );
  }

  return [startingElement];
};

// replace field tags with field names for previews
export const convertStringToPreviewString = (text: string) => {
  let treatedText = '';

  if (text === '') {
    return treatedText;
  }

  const matches = text.matchAll(FIELD_REGEX);
  let lastMatchIndex = 0;

  for (const match of matches) {
    const matchIndex = match.index ?? 0; // index of the match
    const matchStr = match[1]; // strings inside the match
    const field = convertTagObjectStringToSchemaNode(matchStr); // convert the object into a SchemaNode
    const fieldName = capitalize(field.name.replace(/_/g, ' '));
    const readableField = `[${fieldName}]`;

    const textBeforeMatch = text.slice(lastMatchIndex, matchIndex); // text before the match

    if (textBeforeMatch) {
      treatedText += textBeforeMatch;
    }

    treatedText += readableField;

    lastMatchIndex = matchIndex + match[0].length;
  }

  const textAfterMatch = text.slice(lastMatchIndex);

  if (textAfterMatch) {
    treatedText += textAfterMatch;
  }

  // Replace slack markdown bold(*) with general markdown bold(**)
  // add more replacements if needed
  treatedText = treatedText.replace(/\*/g, '**');

  return decodeHtml(treatedText);
};

const deserializeStyledText = (text: string): CustomText[] => {
  const nodes: CustomText[] = [];
  let remainingText = text;

  while (remainingText) {
    // Match bold and italic text separately
    const boldMatch = BOLD_REGEX.exec(remainingText);
    const italicMatch = ITALIC_REGEX.exec(remainingText);

    if (!boldMatch && !italicMatch) {
      // No more styles found, push the remaining text as is
      nodes.push(createTextNode(remainingText));
      break;
    }

    // Determine which match comes first
    let closestMatch = boldMatch;
    let style: 'bold' | 'italic' = 'bold';

    if (italicMatch && (!boldMatch || italicMatch.index < boldMatch.index)) {
      closestMatch = italicMatch;
      style = 'italic';
    }

    // Text before the matched style
    if (closestMatch && closestMatch.index > 0) {
      nodes.push(createTextNode(remainingText.slice(0, closestMatch.index)));
    }

    // Add styled text node
    if (closestMatch) {
      nodes.push({
        text: closestMatch[1], // Styled text content
        [style]: true,
      });
      remainingText = remainingText.slice(
        closestMatch.index + closestMatch[0].length,
      );
    } else {
      // No match found, exit loop
      break;
    }
  }

  return nodes;
};

// 1. for raw text
export const convertRawStringToSlateValue = (value: string): Descendant[] => {
  return convertStringToSlateValue(value, false);
};

// 2. for Markdown
export const convertMarkdownStringToSlateValue = (
  value: string,
): Descendant[] => {
  return convertStringToSlateValue(value, true);
};

// 3. for HTML
export const convertHtmlStringToSlateValue = (value: string): Descendant[] => {
  const html = value;
  const parsed = new DOMParser().parseFromString(html, 'text/html');
  const slateValue = deserializeHtml(parsed.body);
  return slateValue;
};

const deserializeHtml = (element: HTMLElement): Descendant[] => {
  const nodes: Descendant[] = [];

  // Helper function to handle text nodes
  const handleTextNode = (textContent: string | null) => {
    if (!textContent) return;

    const parts = textContent.split(FIELD_REGEX);
    parts.forEach((part) => {
      if (isFieldMarker(part)) {
        try {
          const fieldNode = convertTagObjectStringToSchemaNode(part);
          nodes.push(createFieldElement(fieldNode));
        } catch (e) {
          console.error('Failed to parse field element:', e);
          nodes.push(createTextNode(part));
        }
      } else {
        // removes line breaks
        const trimmedPart = part.replace(/\n/g, '');
        if (trimmedPart) {
          nodes.push(createTextNode(trimmedPart));
        }
      }
    });
  };

  // Helper function to handle element nodes
  const handleElementNode = (el: HTMLElement) => {
    if (el.textContent === '') return;

    const childNodes = deserializeHtml(el);
    switch (el.tagName) {
      case 'P':
        nodes.push(createElementNode(ElementType.PARAGRAPH, childNodes));
        break;
      case 'STRONG':
        nodes.push(createTextNode(el.textContent ?? '', { bold: true }));
        break;
      case 'EM':
        nodes.push(createTextNode(el.textContent ?? '', { italic: true }));
        break;
      case 'U':
        nodes.push(createTextNode(el.textContent ?? '', { underline: true }));
        break;
      case 'CODE':
        nodes.push(createTextNode(el.textContent ?? '', { code: true }));
        break;
      default:
        nodes.push(...childNodes);
        break;
    }
  };

  Array.from(element.childNodes).forEach((node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      handleTextNode(node.textContent);
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      handleElementNode(node as HTMLElement);
    }
  });

  return nodes.length ? nodes : DEFAULT_SLATE_VALUE;
};

// Helper functions
const createFieldElement = (data: SchemaNode): FieldElement => ({
  type: ElementType.FIELD,
  field: data,
  children: [{ text: '' }],
});

const createTextNode = (
  text: string,
  attributes: Partial<Text> = {},
): Text => ({
  text,
  ...attributes,
});

const createElementNode = (
  type: ElementType,
  children?: Descendant[],
): CustomElement => ({
  type,
  children: children || [{ text: '' }],
});

const isFieldMarker = (text: string): boolean =>
  text.startsWith('{') && text.endsWith('}');

//// MARK UTILS
export const isMarkActive = (editor: Editor, format: string) => {
  const marks: { [key: string]: boolean } | null = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const toggleMark = (editor: Editor, format: string) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

//// SLATE EDITOR UTILS
const withSingleLine = (editor: BaseEditor & ReactEditor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      if (editor.children.length > 1) {
        Transforms.mergeNodes(editor);
      }
    }

    return normalizeNode([node, path]);
  };

  return editor;
};

export const createSlateEditor = (useSingleLine?: boolean) => {
  const newEditor = useSingleLine
    ? withSingleLine(withHistory(withReact(createEditor())))
    : withHistory(withReact(createEditor()));

  const { isInline, isVoid } = newEditor;

  newEditor.isInline = (element) =>
    element.type === ElementType.FIELD ? true : isInline(element);

  // isVoid makes the chip element acts like a single character
  newEditor.isVoid = (element) =>
    element.type === ElementType.FIELD ? true : isVoid(element);

  return newEditor;
};

export const getValidDynamicFieldTagObjectFromText = (
  text: string,
): TagObject | undefined => {
  if (!text.startsWith('[[') || !text.endsWith(']]')) {
    return undefined;
  }

  const tagObject = JSON.parse(text.slice(2, -2));
  if (
    !tagObject ||
    typeof tagObject !== 'object' ||
    tagObject.type !== FieldType.DYNAMIC_FIELD ||
    !tagObject.metaData
  ) {
    return undefined;
  }

  const fieldMetaData = tagObject.metaData;
  if (!isValid(validators.DynamicFieldV1, fieldMetaData)) {
    return undefined;
  }

  return {
    type: FieldType.DYNAMIC_FIELD,
    metaData: fieldMetaData as DynamicFieldV1MetaData,
    value: tagObject.value,
  } as TagObject;
};
