import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataExploreConfig } from './exploreExtracts';

export enum LoadState {
  INVALID = 'INVALID',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
}

export interface ExploreInfo {
  wsExploreId: string;
  envExploreId: string;
  exploreConfig: DataExploreConfig;
  extractId: string;
  loadState: LoadState;
}

export interface ExploreInfoLookup {
  byWsExploreId: Record<string, ExploreInfo>;
  byEnvExploreId: Record<string, ExploreInfo>;
  byExtractId: Record<string, ExploreInfo>;
}
// <Workspace id, ExploreInfoLookup>
export type LoadedWorkspaceExplores = Record<string, ExploreInfoLookup>;

const initialStateValue: LoadedWorkspaceExplores = {};
const EMPTY_EXPLORE_INFO_LOOKUP: ExploreInfoLookup = {
  byWsExploreId: {},
  byEnvExploreId: {},
  byExtractId: {},
};

export const loadedWorkspaceExploresSlice = createSlice({
  name: 'loadedWorkspaceExplores',
  initialState: { value: initialStateValue },
  reducers: {
    updateExploreForWorkspace: (
      state,
      action: PayloadAction<{
        workspaceId: string;
        exploreInfo: ExploreInfo;
      }>,
    ) => {
      const { payload } = action;
      const oldInfoLookup: ExploreInfoLookup =
        state.value[payload.workspaceId] ?? EMPTY_EXPLORE_INFO_LOOKUP;
      const newInfo: ExploreInfo = payload.exploreInfo;
      const newInfoLookup: ExploreInfoLookup = {
        ...oldInfoLookup,
        byWsExploreId: {
          ...oldInfoLookup.byWsExploreId,
          [newInfo.wsExploreId]: newInfo,
        },
        byEnvExploreId: {
          ...oldInfoLookup.byEnvExploreId,
          [newInfo.envExploreId]: newInfo,
        },
        byExtractId: {
          ...oldInfoLookup.byExtractId,
          [newInfo.extractId]: newInfo,
        },
      };
      state.value = {
        ...state.value,
        [payload.workspaceId]: newInfoLookup,
      };
    },
    updateWorkspaceExploresLoadState: (
      state,
      action: PayloadAction<{
        workspaceId: string;
        extractId: string;
        newState: LoadState;
      }>,
    ) => {
      const { payload } = action;
      const oldInfoLookup: ExploreInfoLookup =
        state.value[payload.workspaceId] ?? EMPTY_EXPLORE_INFO_LOOKUP;
      const newInfo: ExploreInfo = {
        ...oldInfoLookup.byExtractId[payload.extractId],
        loadState: payload.newState,
      };
      const newInfoLookup: ExploreInfoLookup = {
        ...oldInfoLookup,
        byWsExploreId: {
          ...oldInfoLookup.byWsExploreId,
          [newInfo.wsExploreId]: newInfo,
        },
        byEnvExploreId: {
          ...oldInfoLookup.byEnvExploreId,
          [newInfo.envExploreId]: newInfo,
        },
        byExtractId: {
          ...oldInfoLookup.byExtractId,
          [newInfo.extractId]: newInfo,
        },
      };
      state.value = {
        ...state.value,
        [payload.workspaceId]: newInfoLookup,
      };
    },
  },
});

export const getExploreInfoByEnvExploreId = (
  loadedExplores: LoadedWorkspaceExplores,
  workspaceId: string,
  envExploreId: string,
): ExploreInfo | undefined => {
  return loadedExplores[workspaceId]?.byEnvExploreId[envExploreId];
};

export const getExploreInfoByWsExploreId = (
  loadedExplores: LoadedWorkspaceExplores,
  workspaceId: string,
  wsExploreId: string,
): ExploreInfo | undefined => {
  return loadedExplores[workspaceId]?.byWsExploreId[wsExploreId];
};

export const { updateExploreForWorkspace, updateWorkspaceExploresLoadState } =
  loadedWorkspaceExploresSlice.actions;

export default loadedWorkspaceExploresSlice.reducer;
