// @TODO: `makeStyles` is deprecated. Use `styled` from '@mui/material/styles' instead.
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((themes: Theme) => ({
  lightGreyText: {
    color: themes.palette.primary.light,
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
  fontWeightNormal: {
    fontWeight: 'normal',
  },
  shadowBox: {
    boxShadow: '0px 0px 4px rgba(111, 111, 111, 0.25)',
    borderRadius: '9px',
  },
  lightGreyBox: {
    borderRadius: '50px',
    background: themes.palette.secondary.contrastText,
    padding: '5px 10px 5px 10px',
  },
  typeIcon: {
    fontSize: themes.typography.h5.fontSize,
  },
}));

export default useStyles;
