import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { groupBy } from 'lodash';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MuiMenuItem, {
  MenuItemProps as MuiMenuItemProps,
} from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import Tooltip from '../components/shared/Tooltip';

export interface MenuItemOption {
  label: string;
  icon?: ReactNode;
  onClick?: MuiMenuItemProps['onClick'];
  disabled?: boolean;
  category?: string;
  tooltipText?: string;
}

interface MenuItemProps {
  item: MenuItemOption;
  index: number;
  wrappedOnClick: MuiMenuItemProps['onClick'];
}

const MenuItem = ({ item, index, wrappedOnClick }: MenuItemProps) => {
  return (
    <Tooltip
      title={item.disabled ? 'This action must be first enabled.' : ''}
      placement='left'
    >
      <MuiMenuItem
        onClick={wrappedOnClick}
        key={`menu_item_${index}`}
        disabled={item.disabled}
      >
        {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
        <ListItemText>{item.label}</ListItemText>
      </MuiMenuItem>
    </Tooltip>
  );
};

export interface PopupMenuProps {
  hostAriaLabel?: string;
  menuItemOptions: MenuItemOption[];
  useMenuItemCategory?: boolean;
  onClick?: () => void;
  onMenuClose?: () => void;
  fitToButton?: boolean;
}

export const usePopupMenu = ({
  hostAriaLabel,
  menuItemOptions,
  useMenuItemCategory,
  onClick,
  onMenuClose,
  fitToButton,
}: PopupMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    if (onMenuClose) {
      onMenuClose();
    }
  }, [setAnchorEl, onMenuClose]);

  const wrappedOnClick = useCallback(
    (onClick: MuiMenuItemProps['onClick']): MuiMenuItemProps['onClick'] => {
      if (!onClick) return;
      const wrapped: MuiMenuItemProps['onClick'] = (event) => {
        event.stopPropagation();
        handleClose();
        onClick(event);
      };
      return wrapped;
    },
    [handleClose],
  );

  const menuItems = useMemo(() => {
    if (menuItemOptions) {
      if (useMenuItemCategory) {
        const groupedOptions = groupBy(
          menuItemOptions,
          (option) => option.category,
        );
        const keys = Object.keys(groupedOptions);
        return keys.map((key, index) => {
          const options = groupedOptions[key];
          const hasNext = index < keys.length - 1;
          return (
            <div key={key}>
              {key !== 'undefined' ? (
                <Typography
                  variant='body1'
                  sx={(theme) => ({
                    padding: `${theme.spacing(0.75)} ${theme.spacing(2)}`,
                  })}
                >
                  {key}
                </Typography>
              ) : null}
              {options.map((item, idx) => (
                <MenuItem
                  item={item}
                  index={idx}
                  wrappedOnClick={wrappedOnClick(item.onClick)}
                  key={item.label}
                />
              ))}
              {hasNext ? <Divider /> : null}
            </div>
          );
        });
      }

      return menuItemOptions.map((item, idx) => (
        <MenuItem
          item={item}
          index={idx}
          wrappedOnClick={wrappedOnClick(item.onClick)}
          key={item.label}
        />
      ));
    }
  }, [menuItemOptions, useMenuItemCategory, wrappedOnClick]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      onClick && onClick();
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl, onClick],
  );

  const openMenuFor = useCallback(
    (element: HTMLElement) => setAnchorEl(element),
    [setAnchorEl],
  );

  const menuAriaLabel = `${hostAriaLabel}-dropdown`;

  const renderMenu = () => {
    if (!menuItemOptions.length) return <></>;
    return (
      <ClickAwayListener onClickAway={handleClose}>
        <Menu
          id='button-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          aria-label={menuAriaLabel}
          MenuListProps={{
            sx: {
              width:
                fitToButton && anchorEl?.clientWidth
                  ? anchorEl?.clientWidth
                  : undefined,
            },
          }}
          PaperProps={{
            sx: {
              boxShadow: 2,
            },
          }}
        >
          {menuItems}
        </Menu>
      </ClickAwayListener>
    );
  };

  return {
    open,
    handleClick,
    openMenuFor,
    renderMenu,
  };
};
