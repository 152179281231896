import {
  FieldDatumV1,
  GenericActionV1,
  PlaceholderV1MetaData,
  SlackV1,
  TagEntry,
  ensureType,
  findEncodedTags,
  validators,
  NormalizedConditionExpression,
  isValid,
  DynamicFieldV1MetaData,
  DynamicFieldV1,
  EmailAttachmentV1,
} from '@madeinventive/core-types';
import {
  ChainingOperator,
  NormalizedQueryConditionInput,
  Note,
  Placeholder,
} from '../../../generated/types';

// EmailV2 has a slightly different EmailInMemConfig shape, so some parsing has
// to happen to convert it to the EmailInMemConfig shape
export interface EmailInMemConfig {
  to: string;
  subject: string;
  body: string;
  buttonText: string;
  buttonUrl: string;
  attachments?: EmailAttachmentV1[];
}

export type SlackInMemConfig = SlackV1;

export interface ActionSpec {
  kind: GenericActionV1['kind'];
  spec: EmailInMemConfig | SlackInMemConfig;
  integrationId?: string;
  actionLabel?: string;
}

export const isEmailInMemConfig = (
  spec: EmailInMemConfig | SlackInMemConfig,
): spec is EmailInMemConfig => {
  return 'subject' in spec;
};

export const isSlackInMemConfig = (
  spec: EmailInMemConfig | SlackInMemConfig,
): spec is SlackInMemConfig => {
  return 'actionType' in spec;
};

export const parsePlaceholdersFor = (
  actionSpec: ActionSpec,
  placeholders: { id: string; name: string }[],
) => {
  Object.values(actionSpec.spec).forEach((targetedField: string) => {
    if (typeof targetedField !== 'string') return;
    findEncodedTags(targetedField).placeholderEntries.forEach((p: TagEntry) =>
      placeholders.push({
        name: p.name,
        id: ensureType<PlaceholderV1MetaData>(
          validators.PlaceholderV1MetaData,
          p.tag.metaData,
        ).placeholderId,
      }),
    );
  });
  return placeholders;
};

export const parseDynamicFieldsFor = (
  actionSpec: ActionSpec,
  fieldSet: Set<string>,
) => {
  Object.values(actionSpec.spec).forEach((targetedField: string) => {
    if (typeof targetedField !== 'string') return;
    findEncodedTags(targetedField).fieldEntries.forEach((f) =>
      fieldSet.add(f.name),
    );
  });
  return fieldSet;
};

const toChainingOperatorEnum = (
  op: NormalizedConditionExpression['chainingOperator'],
) => {
  switch (op) {
    case 'OR':
      return ChainingOperator.OR;
    case 'AND':
    default:
      return ChainingOperator.AND;
  }
};

export const convertValidConditionExpressions = (
  conditionExpressions: NormalizedConditionExpression[],
) => {
  const conditionInputs: NormalizedQueryConditionInput[] = [];
  conditionExpressions.forEach((c) => {
    if (
      isValid<DynamicFieldV1MetaData>(
        validators.DynamicFieldV1MetaData,
        c.variable,
      ) &&
      c.chainingOperator &&
      c.operator &&
      (c.operator.isUnary || c.value)
    ) {
      conditionInputs.push({
        chainingOperator: toChainingOperatorEnum(c.chainingOperator),
        variable: {
          field: c.variable.field,
          normalizedType: c.variable.normalizedType,
        },
        operator: { key: c.operator.key, isUnary: c.operator.isUnary },
        value: c.value,
      });
    }
  });
  return conditionInputs;
};

export type NoteEntity = Omit<
  Note,
  '__typename' | 'createdById' | 'deletedAt' | 'entityId' | 'entityType'
>;
export type PlaceholderEntity = Omit<
  Placeholder,
  | '__typename'
  | 'createdById'
  | 'deletedAt'
  | 'featureId'
  | 'notes'
  | 'description'
> & { description?: string; notes: NoteEntity[] };

export const toPlaceholderLookup = (
  placeholders: PlaceholderEntity[],
): Record<string, PlaceholderEntity> => {
  const lookup: Record<string, PlaceholderEntity> = {};
  placeholders.forEach((p) => (lookup[p.id] = p));
  return lookup;
};

export const extractConditionExpressionFieldNames = (
  conditionExpressions: NormalizedConditionExpression[],
): string[] => {
  const fieldNames: string[] = [];

  conditionExpressions.forEach((condition) => {
    if (
      isValid<DynamicFieldV1>(validators.DynamicFieldV1, condition.variable)
    ) {
      fieldNames.push(condition.variable.field);
    }
  });

  return fieldNames;
};

export const extractFieldDataFieldNames = (
  fieldData: FieldDatumV1[],
): string[] => {
  const fieldNames: string[] = [];

  fieldData.forEach((fieldDatum) => {
    if (
      isValid<DynamicFieldV1>(validators.DynamicFieldV1, fieldDatum.variable)
    ) {
      fieldNames.push(fieldDatum.variable.field);
    }
  });

  return fieldNames;
};
