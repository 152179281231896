import { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { noop } from 'lodash';
import {
  TextField,
  Box,
  Popper,
  ClickAwayListener,
  FormControl,
  InputAdornment,
  Typography,
} from '@mui/material';

import DataFieldTypeIcon from './DataFieldTypeIcon';
import useDataFieldAccordion from './useDataFieldAccordion';
import { SchemaNode, isFieldInfo } from '../../store/slices/exploreExtracts';
import { useElementWidth } from '../../hooks/useElementWidth';
import Icon from '../shared/Icon';
import DataFieldAccordion from './DataFieldAccordion';
import { IconName } from '../shared/Icon/types';

export interface DataFieldSelectorProps {
  field?: SchemaNode;
  selectedFields?: string[];
  handleFieldSelect: (field?: SchemaNode) => void;
  hasError?: boolean;
  allInOne?: boolean;
}

const DataFieldSelector = ({
  field,
  selectedFields,
  handleFieldSelect,
  hasError,
  allInOne,
}: DataFieldSelectorProps) => {
  const [selectedFieldValue, setSelectedFieldValue] = useState<
    SchemaNode | undefined
  >(field);

  const [containerRef, containerWidth] = useElementWidth();
  const inputRef = useRef<HTMLInputElement>(null);
  const accordionRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const tableNodeRefs = useRef<(HTMLDivElement | null)[]>([]);
  const fieldNodeRefs = useRef<(HTMLLIElement | null)[][]>([]);
  const [areNodeRefsGenerated, setAreNodeRefsGenerated] = useState(false);

  const { fullNodeList, activeNodes, searchPhrase, updateSearchPhrase } =
    useDataFieldAccordion();

  // set the selected field value when the field prop changes
  useEffect(() => {
    setSelectedFieldValue(field);
  }, [field]);

  const fieldMetaData = useMemo(
    () =>
      selectedFieldValue && isFieldInfo(selectedFieldValue.metaData)
        ? selectedFieldValue.metaData
        : undefined,
    [selectedFieldValue],
  );

  const value = fieldMetaData?.label ?? searchPhrase;

  // this ensures that the refs are ready before rendering the accordion
  useEffect(() => {
    // create placeholder arrays for refs
    // make sure the refs are created only once
    if (tableNodeRefs.current.length > 0) return;
    fullNodeList.forEach((node) => {
      tableNodeRefs.current.push(null);
      fieldNodeRefs.current.push([]);
      node.children?.forEach(() => {
        fieldNodeRefs.current[fieldNodeRefs.current.length - 1].push(null);
      });
    });
    setAreNodeRefsGenerated(true);
  }, [fullNodeList]);

  const openPopper = () => {
    setAnchorEl(inputRef.current);
  };

  const closePopper = () => {
    setAnchorEl(null);
  };

  const isPopperOpen = Boolean(anchorEl);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchPhrase = e.target.value;
      updateSearchPhrase(searchPhrase);
    },
    [updateSearchPhrase],
  );

  // key down event handlers for keyboard navigation
  // ArrowDown: search > accordion > list item
  // ArrowUp: list item > accordion > search
  const handleKeyDownInSearch = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      // arrow or tab navigation
      if (e.key === 'ArrowDown' || e.key === 'Tab') {
        // trigger accordion focus
        accordionRef.current?.focus();
      } else if (e.key === 'escape') {
        // close the popper on escape key
        closePopper();
      } else if (e.key === 'Backspace' && selectedFieldValue) {
        // clear selected field on backspace
        handleFieldSelect(undefined);
        setSelectedFieldValue(undefined);
        updateSearchPhrase('');
      }
    },
    [selectedFieldValue, handleFieldSelect, updateSearchPhrase],
  );

  const handleFieldItemClick = useCallback(
    (field: SchemaNode) => {
      const isMetaDataFieldInfo = isFieldInfo(field.metaData);
      if (isMetaDataFieldInfo) {
        setSelectedFieldValue(field);
        handleFieldSelect(field);
        updateSearchPhrase('');
      }
      closePopper();
    },
    [handleFieldSelect, updateSearchPhrase],
  );

  const endArrowIconName: IconName = isPopperOpen ? 'carat-up' : 'carat-down';
  const endIconName: IconName = allInOne ? 'search' : endArrowIconName;

  return (
    <ClickAwayListener onClickAway={allInOne ? noop : closePopper}>
      <FormControl fullWidth ref={containerRef}>
        <TextField
          autoComplete='off'
          aria-label='data field search input'
          ref={inputRef}
          fullWidth
          size='small'
          autoFocus={allInOne}
          value={value}
          onChange={handleInputChange}
          onKeyDown={handleKeyDownInSearch}
          onFocus={openPopper}
          placeholder={'Search by name, category, or value'}
          error={hasError}
          InputProps={{
            startAdornment: selectedFieldValue && (
              <InputAdornment position='start'>
                <DataFieldTypeIcon
                  normalizedType={
                    isFieldInfo(selectedFieldValue?.metaData)
                      ? selectedFieldValue.metaData.normalizedType
                      : ''
                  }
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                <Icon name={endIconName} size='small' />
              </InputAdornment>
            ),
            sx: {
              ...(allInOne
                ? {
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                  }
                : {}),
            },
          }}
        />
        {!allInOne && (
          <Popper
            disablePortal // render in the same DOM hierarchy so that the long list can be scrolled
            open={isPopperOpen}
            anchorEl={anchorEl}
            placement='auto'
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              width: containerWidth,
            }}
          >
            <Box marginBottom={1}>
              <DataFieldListAccordion
                fullNodeList={fullNodeList}
                areNodeRefsGenerated={areNodeRefsGenerated}
                accordionRef={accordionRef}
                activeNodes={activeNodes}
                selectedFields={selectedFields}
                selectedFieldValue={selectedFieldValue}
                handleFieldItemClick={handleFieldItemClick}
                inputRef={inputRef}
                tableNodeRefs={tableNodeRefs}
                fieldNodeRefs={fieldNodeRefs}
              />
            </Box>
          </Popper>
        )}
        {allInOne && (
          <DataFieldListAccordion
            fullNodeList={fullNodeList}
            areNodeRefsGenerated={areNodeRefsGenerated}
            accordionRef={accordionRef}
            activeNodes={activeNodes}
            selectedFields={selectedFields}
            selectedFieldValue={selectedFieldValue}
            handleFieldItemClick={handleFieldItemClick}
            inputRef={inputRef}
            tableNodeRefs={tableNodeRefs}
            fieldNodeRefs={fieldNodeRefs}
            allInOne={allInOne}
          />
        )}
      </FormControl>
    </ClickAwayListener>
  );
};

export default DataFieldSelector;

const DataFieldListAccordion = ({
  fullNodeList,
  areNodeRefsGenerated,
  accordionRef,
  activeNodes,
  selectedFields,
  selectedFieldValue,
  handleFieldItemClick,
  inputRef,
  tableNodeRefs,
  fieldNodeRefs,
  allInOne,
}: {
  fullNodeList: SchemaNode[];
  areNodeRefsGenerated: boolean;
  accordionRef: React.RefObject<HTMLDivElement>;
  activeNodes?: SchemaNode[];
  selectedFields?: string[];
  selectedFieldValue: SchemaNode | undefined;
  handleFieldItemClick: (field: SchemaNode) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  tableNodeRefs: React.MutableRefObject<(HTMLDivElement | null)[]>;
  fieldNodeRefs: React.MutableRefObject<(HTMLLIElement | null)[][]>;
  allInOne?: boolean;
}) => {
  if (fullNodeList.length === 0) {
    return (
      <Box
        padding={2}
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <Typography variant='body2' color='text.tertiary'>
          No data fields found
        </Typography>
      </Box>
    );
  } else if (areNodeRefsGenerated) {
    return (
      <DataFieldAccordion
        ref={accordionRef}
        filteredNodes={activeNodes}
        fullNodes={fullNodeList}
        selectedFields={selectedFields}
        selectedFieldValue={selectedFieldValue}
        handleFieldItemClick={handleFieldItemClick}
        inputRef={inputRef}
        tableNodeRefs={tableNodeRefs}
        fieldNodeRefs={fieldNodeRefs}
        allInOne={allInOne}
      />
    );
  } else {
    return (
      <Box
        padding={2}
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <Typography variant='body2' color='text.tertiary'>
          Loading...
        </Typography>
      </Box>
    );
  }
};
