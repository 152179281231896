import { Stack } from '@mui/material';
import React from 'react';
import { NormalizedConditionExpression } from '@madeinventive/core-types';

import ConditionRow from './ConditionRow';

type ConditionListProps = {
  conditions: NormalizedConditionExpression[];
  inputsDisabled?: boolean;
  workspaceId?: string;
};

const ConditionList = ({
  conditions,
  inputsDisabled,
  workspaceId,
}: ConditionListProps) => {
  return (
    <Stack spacing={3}>
      {conditions.map((condition, index) => {
        return (
          <ConditionRow
            key={index}
            condition={condition}
            index={index}
            inputsDisabled={inputsDisabled}
            workspaceId={workspaceId ?? ''} // this is a hack where we ignore when workspaceId isn't provided - note that it only makes sense if inputsDisabled is true
          />
        );
      })}
    </Stack>
  );
};

export default ConditionList;
