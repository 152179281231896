// material UI components
import { Grid, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface WrapperForSectionProps {
  children?: ReactNode;
  text?: string;
  additionalElement?: React.ReactNode;
}

const WrapperForSection = (props: WrapperForSectionProps) => {
  return (
    <Grid container>
      <Grid mb={3} container display='flex' justifyContent='space-between'>
        <Typography variant='h4'>{props.text}</Typography>
        {props.additionalElement}
      </Grid>
      {props.children}
    </Grid>
  );
};

export default WrapperForSection;
