import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FieldDatumV1,
  NormalizedConditionExpression,
  DynamicFieldV1MetaData,
  PlaceholderUtils,
  isValid,
  validators,
  DynamicFieldV1,
  PlaceholderV1,
} from '@madeinventive/core-types';
import {
  ActionSpec,
  EmailInMemConfig,
  isEmailInMemConfig,
  SlackInMemConfig,
  isSlackInMemConfig,
  PlaceholderEntity,
} from './helpers';
import { FeatureState, FeatureType } from '../../../generated/types';

import { ActionTriggerType } from '../../../components/Workflow/types';

export enum PlaceholderLocation {
  CONDITION = 'CONDITION',
  FIELD_DATA = 'FIELD_DATA',
  ACTION = 'ACTION',
}

export interface FeatureEditDataState {
  // common fields
  featureType: FeatureType;
  id: string;
  name: string;
  prevName?: string;
  description: string;
  mode: FeatureState;
  exploreId?: string; // scheduled workflow doesn't have exploreId
  wsExploreId?: string;
  placeholderLookup: Record<string, PlaceholderEntity>;
  actions: ActionSpec[];
  hasUnsavedChanges?: boolean;
  actionTriggerType?: ActionTriggerType;

  // workflow fields
  conditions: NormalizedConditionExpression[];
  extraFields?: (DynamicFieldV1 | PlaceholderV1)[];
  cronSchedule?: string;

  // table app fields
  fieldData: FieldDatumV1[];
}

type WritableDraft<T> = {
  -readonly [P in keyof T]: T[P];
};

type State = WritableDraft<{
  value: FeatureEditDataState;
}>;

type ReducerFn<T extends State, A> = (state: T, action: A) => void;

function withUnsavedChanges<T extends State, A>(
  reducerFn: ReducerFn<T, A>,
): ReducerFn<T, A> {
  return (state, action) => {
    reducerFn(state, action);
    state.value.hasUnsavedChanges = true;
  };
}

type PayloadForActionField = {
  index: number;
  fieldName?: string;
  fieldValue?: string;
  integrationId?: string;
  actionLabel?: string;
};

// for action fields
type UpdateFieldFn = (
  actionSpec: ActionSpec,
  payload: PayloadForActionField,
) => void;

function createUpdateActionFieldReducer(updateFieldFn: UpdateFieldFn) {
  return withUnsavedChanges<State, PayloadAction<PayloadForActionField>>(
    (state, action) => {
      const { index } = action.payload;
      const actionSpec = state.value.actions[index];
      updateFieldFn(actionSpec, action.payload);
    },
  );
}

function setEmailField(actionSpec: ActionSpec, payload: PayloadForActionField) {
  const fieldName = payload.fieldName as keyof Omit<
    EmailInMemConfig,
    'attachments'
  >;
  const fieldValue = payload.fieldValue as string;
  if (isEmailInMemConfig(actionSpec.spec)) {
    actionSpec.spec[fieldName] = fieldValue;
  }
}

function setSlackField(actionSpec: ActionSpec, payload: PayloadForActionField) {
  const fieldName = payload.fieldName as keyof Omit<
    SlackInMemConfig,
    'actionType'
  >;
  const fieldValue = payload.fieldValue as string;
  if (isSlackInMemConfig(actionSpec.spec)) {
    actionSpec.spec[fieldName] = fieldValue;
  }
}

function setActionIntegrationId(
  actionSpec: ActionSpec,
  payload: PayloadForActionField,
) {
  const { integrationId } = payload;
  actionSpec.integrationId = integrationId;
}

function setActionLabel(
  actionSpec: ActionSpec,
  payload: PayloadForActionField,
) {
  const { actionLabel } = payload;
  actionSpec.actionLabel = actionLabel;
}

// for fields that are arrays
function createAddReducer(key: 'actions' | 'fieldData' | 'conditions') {
  return withUnsavedChanges((state, action: PayloadAction<unknown>) => {
    state.value[key].push(
      action.payload as ActionSpec &
        NormalizedConditionExpression &
        FieldDatumV1,
    );
  });
}

// for fields that are arrays
function createUpdateAtIndexReducer(
  key: 'actions' | 'fieldData' | 'conditions',
) {
  return withUnsavedChanges(
    (state, action: PayloadAction<{ index: number; value: object }>) => {
      const { index, value } = action.payload;
      state.value[key][index] = {
        ...state.value[key][index],
        ...value,
      };
    },
  );
}

// for fields that are arrays
function createDeleteAtIndexReducer(
  key: 'actions' | 'fieldData' | 'conditions',
) {
  return withUnsavedChanges((state, action: PayloadAction<number>) => {
    const index = action.payload;
    state.value[key].splice(index, 1);
  });
}

const blankCondition: NormalizedConditionExpression = {
  chainingOperator: 'AND',
};

const initialStateValue: FeatureEditDataState = {
  featureType: FeatureType.WORKFLOW,
  id: '',
  name: '',
  prevName: '', // name preserved in case we need to revert the name change (updated locally and not saved to the BE)
  description: '',
  mode: FeatureState.DRAFT,
  exploreId: '',
  placeholderLookup: {},
  actions: [],
  actionTriggerType: 'condition', //workflow
  conditions: [], // workflow
  extraFields: [], // workflow
  cronSchedule: '', // workflow
  fieldData: [], // table app
  hasUnsavedChanges: false,
};

export const featureEditDataSlice = createSlice({
  name: 'featureEditData',
  initialState: { value: initialStateValue },
  reducers: {
    setFeatureEditData: (
      state,
      action: PayloadAction<FeatureEditDataState>,
    ) => {
      state.value = action.payload;
      state.value.prevName = action.payload.name;
      state.value.hasUnsavedChanges = false;
    },

    resetFeatureEditData: (state) => {
      state.value = {
        ...initialStateValue,
      };
    },

    updateFeatureEditData: withUnsavedChanges(
      (state, action: PayloadAction<Partial<FeatureEditDataState>>) => {
        state.value = {
          ...state.value,
          ...action?.payload,
        };
      },
    ),

    // feature mode change should not have unsaved changes
    updateFeatureMode: (state, action: PayloadAction<FeatureState>) => {
      state.value.mode = action.payload;
    },

    insertPlaceholder: withUnsavedChanges(
      (state, action: PayloadAction<PlaceholderEntity>) => {
        if (action) {
          const entity = action.payload;
          state.value.placeholderLookup[entity.id] = entity;
        }
      },
    ),

    updatePlaceholderInfo: withUnsavedChanges(
      (
        state,
        action?: PayloadAction<{
          location: PlaceholderLocation;
          placeholderId: string;
          name?: string;
          description?: string;
        }>,
      ) => {
        if (!action) return;
        const { location, placeholderId, name, description } = action.payload;
        const updates = { name, description };

        let config: object | undefined;
        switch (location) {
          case PlaceholderLocation.CONDITION:
            config = state.value.conditions;
            break;
          case PlaceholderLocation.FIELD_DATA:
            config = state.value.fieldData;
            break;
          case PlaceholderLocation.ACTION:
            config = state.value.actions;
            break;
          // no default
        }

        if (
          config &&
          PlaceholderUtils.updatePropsById(config, placeholderId, updates)
        ) {
          if (state.value.placeholderLookup[placeholderId]) {
            PlaceholderUtils.updatePlaceholderProps(
              state.value.placeholderLookup[placeholderId],
              updates,
            );
          }
        }
      },
    ),

    //Section: Schedule Trigger
    updateTriggerSchedule: withUnsavedChanges(
      (state, action: PayloadAction<string>) => {
        state.value.cronSchedule = action.payload;
      },
    ),

    //Section: Condition Trigger
    resetFeatureEditDataConditions: withUnsavedChanges((state) => {
      state.value.conditions = [];
    }),
    addTriggerCondition: withUnsavedChanges((state) => {
      state.value.conditions.push(blankCondition);
    }),
    updateTriggerConditionAtIndex: createUpdateAtIndexReducer('conditions'),
    deleteTriggerConditionAtIndex: createDeleteAtIndexReducer('conditions'),

    updateChainingOperator: withUnsavedChanges(
      (
        state,
        action: PayloadAction<{
          index: number;
          chainingOperator: NormalizedConditionExpression['chainingOperator'];
        }>,
      ) => {
        if (action) {
          if (!state.value.conditions[action.payload.index]) return; // noop if index is out of bounds
          state.value.conditions[action.payload.index].chainingOperator =
            action.payload.chainingOperator;
        }
      },
    ),

    //Section: Action (V2)
    setEmailFieldByIndex: createUpdateActionFieldReducer(setEmailField),
    setSlackFieldByIndex: createUpdateActionFieldReducer(setSlackField),
    setActionIntegrationIdByIndex: createUpdateActionFieldReducer(
      setActionIntegrationId,
    ),
    setActionLabelByIndex: createUpdateActionFieldReducer(setActionLabel),

    // actions
    addActionSpec: createAddReducer('actions'),
    updateActionSpecAtIndex: createUpdateAtIndexReducer('actions'),
    deleteActionSpecAtIndex: createDeleteAtIndexReducer('actions'),

    // field data
    addFieldDatum: createAddReducer('fieldData'),
    updateFieldDatumAtIndex: createUpdateAtIndexReducer('fieldData'),
    deleteFieldDatumAtIndex: createDeleteAtIndexReducer('fieldData'),

    setFieldVisibilityInDetailView: withUnsavedChanges(function (
      state,
      action: PayloadAction<{
        field: string;
        isVisible: boolean;
      }>,
    ) {
      if (!action) return;
      const { field, isVisible } = action.payload;
      const index = state.value.fieldData.findIndex((datum) => {
        if (
          isValid<DynamicFieldV1MetaData>(
            validators.DynamicFieldV1,
            datum.variable,
          )
        )
          return datum.variable.field === field;
      });

      if (index !== -1) {
        state.value.fieldData[index].isHiddenInDetailView = !isVisible;
      }
    }),

    setHasUnsavedChanges(state, action: PayloadAction<boolean>) {
      state.value.hasUnsavedChanges = action.payload;
    },
  },
});

export const {
  updateFeatureEditData,
  updateFeatureMode,
  insertPlaceholder,
  updateTriggerSchedule,
  addTriggerCondition,
  updateTriggerConditionAtIndex,
  deleteTriggerConditionAtIndex,
  resetFeatureEditDataConditions,
  updatePlaceholderInfo,
  setFeatureEditData,
  resetFeatureEditData,
  updateChainingOperator,
  setEmailFieldByIndex,
  setSlackFieldByIndex,
  setActionIntegrationIdByIndex,
  setActionLabelByIndex,
  addActionSpec,
  updateActionSpecAtIndex,
  deleteActionSpecAtIndex,
  addFieldDatum,
  updateFieldDatumAtIndex,
  deleteFieldDatumAtIndex,
  setFieldVisibilityInDetailView,
  setHasUnsavedChanges,
} = featureEditDataSlice.actions;

export default featureEditDataSlice.reducer;
