import { SnackbarKey, SnackbarMessage } from 'notistack';
import { forwardRef } from 'react';
import SnackbarContent from './SnackbarContent';

interface SnackbarInfoProps {
  id: SnackbarKey;
  message: SnackbarMessage;
}

const SnackbarInfo = forwardRef<HTMLDivElement, SnackbarInfoProps>(
  (props, ref) => {
    const { id, message } = props;

    return (
      <SnackbarContent
        id={id}
        message={message}
        ref={ref}
        icon={false}
        textColor='text.inverted'
        backgroundColor='background.inverted'
      />
    );
  },
);

SnackbarInfo.displayName = 'SnackbarInfo';

export default SnackbarInfo;
