import { noop } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import * as yup from 'yup';

// components
import ElementUpdateDialog from '../ElementUpdateDialog';

// gql
import {
  useCreateFeatureTemplateCategoryMutation,
  FeatureTemplateCategoriesDocument,
  FeatureTemplateType,
} from '../../../generated/types';

interface AddTemplateCategoryProps {
  open: boolean;
  setOpen: (params: boolean) => void;
}

export const validationSchema = yup.object({
  name: yup
    .string()
    .label('Name')
    .min(3)
    .max(24)
    .test(
      'uncategorizedCheck',
      '"Uncategorized" is not a valid category name',
      (value) => value?.toLowerCase().trim() !== 'uncategorized',
    )
    .test(
      'allTemplatesCheck',
      '"All templates" is not a valid category name',
      (value) => value?.toLowerCase().trim() !== 'all templates',
    )
    .matches(/^\S+.+$/, "Name can't start with a space")
    .required(),
});

const AddTemplateCategory = ({ open, setOpen }: AddTemplateCategoryProps) => {
  const [success, setSuccess] = useState(false);
  const [createTemplateCategory] = useCreateFeatureTemplateCategoryMutation();

  const addCategory = async (id: string, name: string) => {
    await createTemplateCategory({
      variables: {
        input: {
          entityId: id,
          type: FeatureTemplateType.ENVIRONMENT,
          name: name,
        },
      },
      refetchQueries: [{ query: FeatureTemplateCategoriesDocument }],
    }).catch(noop);
  };
  return (
    <ElementUpdateDialog
      open={open}
      setOpen={setOpen}
      title='New template category'
      subTitle='Categories give you the ability to filter your templates easily.'
      submitButtonName='Add category'
      dialogTitle='Add template category'
      successMessage='was successfully added as new a category.'
      success={success}
      setSuccess={setSuccess}
      updateItem={addCategory}
      placeholder='Enter category name'
      validationSchema={validationSchema}
    />
  );
};

export default AddTemplateCategory;
