import {
  MultiConditionValueV1,
  NormalizedConditionValueV1,
  RangeConditionValueV1,
  SingleConditionValueV1,
  RelativeDateV1,
  RelativeDateUnit,
  SINGULAR_RELATIVE_DATE_UNIT_LABELS,
} from '@madeinventive/core-types';
import FormInput from '../../shared/Form/FormInput';
import MultiConditionValueAutocomplete from '../../ConditionForm/MultiConditionValueAutocomplete';
import RangeValueInput from '../../shared/RangeValueInput';
import SimpleDropdown from '../../shared/SimpleDropdown';
import RelativeDateInput from '../../shared/RelativeDateInput';
import { ValueInputType } from '../../../hooks/useConditionEditor';

export interface RelativeDateDropdownOption {
  value: RelativeDateV1['unit'];
  label: string;
}

const singularRelativeDateUnitOptions: RelativeDateDropdownOption[] =
  Object.entries(SINGULAR_RELATIVE_DATE_UNIT_LABELS).map(([value, label]) => ({
    value: value as RelativeDateUnit,
    label,
  }));

interface ConditionValueProps {
  value?: NormalizedConditionValueV1;
  suggestions?: readonly string[];
  getSuggestions?: () => void;
  loading?: boolean;
  setValue: (value?: NormalizedConditionValueV1) => void;
  valueInputType: ValueInputType;
  hasError?: boolean;
}

const ConditionValueInputV2 = ({
  value,
  suggestions,
  getSuggestions,
  loading,
  setValue,
  valueInputType,
  hasError,
}: ConditionValueProps) => {
  const handleValueChange = (value?: NormalizedConditionValueV1) => {
    setValue(value);
  };

  switch (valueInputType) {
    case ValueInputType.DEFAULT: {
      const typedValue = value as SingleConditionValueV1 | undefined;
      return (
        <FormInput
          type='text'
          inputValue={typedValue?.value ?? ''}
          setValue={(value: string) => {
            if (value === '') {
              handleValueChange();
            } else {
              handleValueChange({ value: value });
            }
          }}
          hasError={hasError}
        />
      );
    }
    case ValueInputType.DATE: {
      const typedValue = value as SingleConditionValueV1 | undefined;
      return (
        <FormInput
          type='date'
          inputValue={typedValue?.value ?? ''}
          setValue={(value: string) => {
            if (value === '') {
              handleValueChange();
            } else {
              handleValueChange({ value: value });
            }
          }}
          hasError={hasError}
        />
      );
    }
    case ValueInputType.MULTI_VALUE: {
      const typedValue = value as MultiConditionValueV1 | undefined;
      return (
        <MultiConditionValueAutocomplete
          value={typedValue}
          onChange={handleValueChange}
          hasError={hasError}
        />
      );
    }

    case ValueInputType.MULTI_VALUE_SUGGESTABLE: {
      const typedValue = value as MultiConditionValueV1 | undefined;
      return (
        <MultiConditionValueAutocomplete
          value={typedValue}
          onChange={(value) => {
            if (value.values.length === 0) {
              handleValueChange();
            } else {
              handleValueChange(value);
            }
          }}
          loading={loading}
          suggestions={suggestions}
          getSuggestions={getSuggestions}
          hasError={hasError}
        />
      );
    }
    case ValueInputType.RANGE: {
      const typedValue = value as RangeConditionValueV1 | undefined;
      return (
        <RangeValueInput
          value={typedValue}
          onChange={setValue}
          hideLabel={true}
          hasError={hasError}
        />
      );
    }
    case ValueInputType.SINGULAR_RELATIVE_DATE: {
      const typedValue = value as RelativeDateV1;
      return (
        <SimpleDropdown
          value={typedValue?.unit ?? undefined}
          menuOptions={singularRelativeDateUnitOptions}
          hasError={hasError}
          setValue={(newValue: string) =>
            handleValueChange({
              unit: newValue as RelativeDateV1['unit'],
              value: 1,
            })
          }
        />
      );
    }
    case ValueInputType.PLURAL_RELATIVE_DATE: {
      const typedValue = value as RelativeDateV1 | undefined;
      return (
        <RelativeDateInput
          value={typedValue}
          onChange={setValue}
          hideLabel={true}
          hasError={hasError}
        />
      );
    }
    default:
      return null;
  }
};

export default ConditionValueInputV2;
