import React, { useContext } from 'react';
import { noop } from 'lodash';

import { useChat } from '../hooks/useChat';

export type UseChatReturnType = ReturnType<typeof useChat>;

const initialValue: UseChatReturnType = {
  isLoading: false,
  initializeChat: () => Promise.resolve(),
  handleUserMessage: (_: string) => Promise.resolve(),
  stopReceivingChatResponse: noop,
  regenerateResponse: () => Promise.resolve(),
  handleStopOrRegenerate: noop,
  resetChatMemory: noop,
  handleChatMessageForCurrentAndLoadedChat: noop,
  rateChatResponse: () => Promise.resolve(),
  updateCurrentAndLoadedChatState: noop,
  updateLastMsgInView: noop,
};

const ChatContext = React.createContext(initialValue);

export const useChatContext = () => useContext(ChatContext);

interface ChatProviderProps {
  children: React.ReactNode;
  workspaceId: string;
}

export const ChatProvider = ({ children, workspaceId }: ChatProviderProps) => {
  const chatLogic = useChat(workspaceId);

  return (
    <ChatContext.Provider value={chatLogic}>{children}</ChatContext.Provider>
  );
};
