import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { Stack } from '@mui/material';
import { useAppSelector, useSessionInfo } from '../../hooks';
import { SideBarItemId } from '../modals/SettingsModal/getSidebarItems';
import Alert from '../shared/Alert';
import WorkspaceNavBar from './LayoutComponents/WorkspaceNavBar';
import { ChatProvider } from '../../contexts/ChatProvider';

interface WorkspaceLayoutProps {
  children: React.ReactNode;
  workspaceId: string;
}

const WorkspacePageLayout = ({
  children,
  workspaceId,
}: WorkspaceLayoutProps) => {
  const router = useRouter();
  const { workspaceExplores } = useAppSelector(
    (state) => state.workspace.value,
  );
  const { isWorkspaceViewer } = useSessionInfo();

  const showNoDataLinkedAlert = useMemo(
    () => workspaceExplores?.length === 0,
    [workspaceExplores],
  );

  const openSettings = useCallback(() => {
    router.replace(
      `/workspace/${workspaceId}?settings=true&settingsTabId=${SideBarItemId.WORKSPACE_DATA}`,
    );
  }, [router, workspaceId]);

  return (
    <Stack direction='column' position='relative' height='100%' display='flex'>
      {showNoDataLinkedAlert && (
        <Alert appLevel severity='warning' variant='filled'>
          This workspace doesn’t have any data connected yet.{' '}
          {isWorkspaceViewer ? (
            'Ask admin.'
          ) : (
            <>
              Add data{' '}
              <a
                href='#'
                onClick={openSettings}
                style={{ textDecoration: 'underline' }}
              >
                here
              </a>
            </>
          )}
        </Alert>
      )}
      <Stack flex={1} minHeight={0}>
        <ChatProvider workspaceId={workspaceId}>{children}</ChatProvider>
      </Stack>
      <WorkspaceNavBar workspaceId={workspaceId} />
    </Stack>
  );
};

export default WorkspacePageLayout;
