import { useState, useEffect } from 'react';
import { AlertTitle } from '@mui/material';
import { NormalizedConditionExpression } from '@madeinventive/core-types';
import {
  NormalizedOperatorInput,
  NormalizedQueryConditionInput,
  NormalizedQueryInput,
  useWorkspaceQueryDataCountLazyQuery,
} from '../../../generated/types';

// components
import Alert from '../../shared/Alert';

// hooks and utils
import { useFeature } from '../../../hooks/feature';

const DATA_PREVIEW_FETCH_LIMIT = 200;

const MatchedData = ({
  workspaceId,
  conditions,
  extraFields,
}: {
  workspaceId: string;
  conditions: NormalizedConditionExpression[];
  extraFields: string[];
}) => {
  const { featureEditData } = useFeature();
  const envExploreId = featureEditData?.exploreId;
  const [count, setCount] = useState<number | undefined>(undefined);
  const [strings, setStrings] = useState<MatchAlertString>(undefined);

  const [fetchQueryDataCount, { loading, error }] =
    useWorkspaceQueryDataCountLazyQuery({
      onCompleted: (data) => {
        setCount(
          data?.node?.__typename === 'Workspace'
            ? data.node.queryData.count
            : 0,
        );
      },
      onError: () => {
        setCount(undefined);
      },
    });

  useEffect(() => {
    if (!envExploreId || conditions.length === 0) {
      setCount(undefined);
      setStrings(undefined);
      return;
    }

    // make sure if all condition values are filled before fetching data
    for (const condition of conditions) {
      if (!condition.variable || !condition.operator) return;
      if (!condition.operator.isUnary && !condition.value) return;
    }

    const queryParams: NormalizedQueryInput = {
      conditions: conditions.map((condition) => {
        return {
          variable: condition.variable,
          operator: {
            isUnary: condition.operator?.isUnary,
            key: condition.operator?.key,
          } as NormalizedOperatorInput,
          value: condition.value,
          chainingOperator: condition.chainingOperator,
        } as NormalizedQueryConditionInput;
      }),
      extraFields,
      limit: DATA_PREVIEW_FETCH_LIMIT,
    };

    fetchQueryDataCount({
      variables: {
        workspaceId,
        envExploreId,
        queryParams,
      },
    });
  }, [conditions, envExploreId, extraFields, fetchQueryDataCount, workspaceId]);

  useEffect(() => {
    if (loading) {
      setStrings({
        title: 'Loading...',
        message: 'Checking for conditions...',
      });
    } else if (error && !count) {
      setStrings({
        severity: 'error',
        title: 'Error',
        message: 'There is an error checking for conditions.',
      });
    } else if (count === 0) {
      setStrings({
        title: 'No matches found',
        message: 'No records match the conditions.',
      });
    } else if (count && count > 0) {
      if (count >= DATA_PREVIEW_FETCH_LIMIT) {
        setStrings({
          title: `${DATA_PREVIEW_FETCH_LIMIT}+ matches found`,
          message: `We found ${DATA_PREVIEW_FETCH_LIMIT}+ records matching your conditions. ${DATA_PREVIEW_FETCH_LIMIT}+ alerts will be sent today, and any future notifications whenever new records qualify.`,
        });
      } else {
        setStrings({
          title: `${count} ${count > 1 ? 'matches' : 'match'} found`,
          message: `We found ${count} ${
            count > 1 ? 'records' : 'record'
          } matching your conditions. ${count} ${
            count > 1 ? 'alerts' : 'alert'
          } will be sent today, and any future notifications whenever new records qualify.`,
        });
      }
    } else {
      setStrings(undefined);
    }
  }, [count, loading, error, setStrings]);

  type MatchAlertString =
    | {
        severity?: 'success' | 'info' | 'warning' | 'error';
        title: string;
        message: string;
      }
    | undefined;

  if (!strings) return null;

  return (
    <Alert
      severity={strings.severity ?? 'info'}
      sx={{
        width: '100%',
      }}
    >
      <AlertTitle>{strings.title}</AlertTitle>
      {strings.message}
    </Alert>
  );
};

export default MatchedData;
