import { useCallback, useState } from 'react';
import { Stack } from '@mui/material';

import { ChatResponseRating } from '../../generated/types';

// components
import TextField from '../shared/Inputs/TextField';
import ToggleChips from '../shared/ToggleChips';
import DialogActions from '../shared/Dialog/DialogActions';

// hooks and context
import { UseChatReturnType } from '../../contexts/ChatProvider';

// utils
import { BaseRegisteredDialogComponentProps } from './types';

const positiveTags = ['Fast', 'Relevant data', 'Good analysis', 'Delightful'];
const negativeTags = ['Slow', 'Irrelevant data', 'Bad analysis', 'Frustrating'];

export interface RatingReasons {
  tags?: string;
  summary?: string;
  comment?: string;
}

export interface ChatRatingReasonsProps
  extends BaseRegisteredDialogComponentProps {
  chatResponseId: string;
  rating: ChatResponseRating;
  rateChatResponse: UseChatReturnType['rateChatResponse'];
}

const ChatRatingReasons = ({
  chatResponseId,
  rating,
  rateChatResponse,
  hideDialog,
}: ChatRatingReasonsProps) => {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [summary, setSummary] = useState<string>('');
  const [comment, setComment] = useState<string>('');

  const updateSummary = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSummary(event.target.value);
    },
    [setSummary],
  );

  const updateComment = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setComment(event.target.value);
    },
    [setComment],
  );

  const handleTagClick = useCallback(
    (tag: string) => {
      setSelectedTags((prev) =>
        prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag],
      );
    },
    [setSelectedTags],
  );

  const handleSubmit = useCallback(() => {
    const reasons: RatingReasons = {};
    if (selectedTags.length > 0) reasons.tags = selectedTags.join(',');
    if (summary) reasons.summary = summary;
    if (comment) reasons.comment = comment;
    rateChatResponse(chatResponseId, rating, reasons);
  }, [
    chatResponseId,
    comment,
    rateChatResponse,
    rating,
    selectedTags,
    summary,
  ]);

  return (
    <>
      <Stack spacing={4} paddingY={1}>
        <ToggleChips
          options={(rating === ChatResponseRating.THUMBS_UP
            ? positiveTags
            : negativeTags
          ).map((tag) => ({
            label: tag,
            value: tag,
          }))}
          selectedValues={selectedTags}
          handleItemClick={handleTagClick}
          flexWrap
        />
        <TextField
          label='Summary (optional)'
          value={summary}
          onChange={updateSummary}
        />
        <TextField
          label='Description (optional)'
          value={comment}
          multiline
          onChange={updateComment}
          inputProps={{ rows: 4 }}
        />
      </Stack>
      <DialogActions
        closeDialog={hideDialog}
        secondaryAction={{
          text: 'Skip',
          disabled: false,
        }}
        primaryAction={{
          text: 'Submit',
          action: handleSubmit,
          disabled: false,
        }}
      />
    </>
  );
};

export default ChatRatingReasons;
