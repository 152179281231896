import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { omit, sortBy } from 'min-dash';
import {
  ExternalProviderType,
  LookerQueryResultField,
  NormalizedOperator,
  NormalizedType,
  PickerSchema,
  PickerColumnDataQuery,
  RankableOperator,
  Scalars,
} from '../../generated/types';

/**
 *   Extract Helpers
 **/

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ViewInfo {}
export interface FieldInfo {
  name: string; // qualified field name
  shortName: string; // non-qualified field name
  label: string; // label for human
  normalizedType: string;
  example?: string;
}

export const isFieldInfo = (info?: ViewInfo | FieldInfo): info is FieldInfo => {
  if (!info) return false;
  return (info as FieldInfo).normalizedType !== undefined;
};

export type FieldValue =
  | Required<LookerQueryResultField>['value']
  | Scalars['String'];
export type ColumnarSlices = {
  rowCount: number;
  slices: Record<string, FieldValue[]>;
};

export interface DataExploreConfig {
  providerId: string;
  modelName: string;
  modelExploreName: string;
  customerFilterName?: string;
  customerFilterValues?: string[];
}

export interface OpDisplay {
  key: string;
  label: string;
  altLabel?: string;
}

export const makeExtractId = (cfg: DataExploreConfig) => {
  const base = `${cfg.providerId}_${cfg.modelName}_${cfg.modelExploreName}`;
  return cfg.customerFilterName
    ? base + `_${cfg.customerFilterName}_${cfg.customerFilterValues?.join('_')}`
    : base;
};

export const toColumnarSlices = (columDataResult?: PickerColumnDataQuery) => {
  const slices: Record<string, FieldValue[]> = {};
  let maxRowCount = 0;
  columDataResult?.pickerColumnData?.map((colEntry) => {
    slices[colEntry.fieldName] = colEntry.columnValues;
    if (colEntry.columnValues.length > maxRowCount) {
      maxRowCount = colEntry.columnValues.length;
    }
  });
  return {
    rowCount: maxRowCount,
    slices,
  };
};

export const createViewNodeId = (viewName: string) => `view_${viewName}`;

export const getNormalizedTypeIcon = (
  typeLookup: Record<string, NormalizedType>,
  typeId: string,
) => {
  const typeObject = typeLookup[typeId];
  if (!typeObject || !typeObject.icon) return;
  return typeObject.icon;
};

const toSupportedTypeLookup = (typeList: NormalizedType[]) => {
  const typeLookup: Record<string, NormalizedType> = {};
  typeList.forEach((t) => {
    // to avoid downstream config issues,
    // purge in-mem data of any GQL query artifacts (e.g. `__typename`)
    typeLookup[t.key] = omit(t, ['__typename']);
  });
  return typeLookup;
};

const createOpListAndLookups = (rankableOpList: RankableOperator[]) => {
  const rawOpsByType: Record<string, RankableOperator[]> = {};
  const opList: NormalizedOperator[] = [];
  const opLookup: Record<string, NormalizedOperator> = {};
  rankableOpList.forEach((ro) => {
    const rOp = omit(ro, ['__typename']);
    const { operator: op } = rOp;
    opList.push(op);
    opLookup[op.key] = op;
    op.typesSupported.forEach((t) => {
      if (!rawOpsByType[t]) {
        rawOpsByType[t] = [rOp];
      } else {
        rawOpsByType[t].push(rOp);
      }
    });
  });
  // now sort and populate opDisplaysByType
  const opDisplaysByType: Record<string, OpDisplay[]> = {};
  Object.entries(rawOpsByType).forEach(([t, rawOps]) => {
    opDisplaysByType[t] = sortBy(rawOps, (ro) => ro.ordinal)
      .reverse()
      .map((ro) => {
        const opDisplay: OpDisplay = {
          key: ro.operator.key,
          label: ro.operator.label,
        };
        if (ro.altReadOnlyLabel != null) {
          opDisplay.altLabel = ro.altReadOnlyLabel;
        }
        return opDisplay;
      });
  });
  return {
    opList,
    opLookup,
    opDisplaysByType,
  };
};

export const toExploreExtract = (
  extractId: string,
  pickerSchema: PickerSchema,
): ExploreExtract => {
  const {
    providerType,
    supportedTypes: { typeList, opList: rawOpList },
    views,
  } = pickerSchema;

  const { opList, opLookup, opDisplaysByType } =
    createOpListAndLookups(rawOpList);

  const dateTypeSet: Set<string> = new Set<string>();
  typeList.forEach((t) => {
    if (t.capabilities?.asDate) {
      dateTypeSet.add(t.key);
    }
  });

  type FieldInfoList = FieldInfo[];
  const nodeLookup: Record<string, SchemaNode> = {};

  const createViewNode = (viewName: string): SchemaNode => {
    const node = {
      id: createViewNodeId(viewName),
      name: viewName,
      kind: SchemaNodeKind.VIEW,
    };
    nodeLookup[node.id] = node;
    return node;
  };

  const createFieldNode = (info: FieldInfo): SchemaNode => {
    const node = {
      id: info.name,
      name: info.shortName,
      kind: SchemaNodeKind.FIELD,
      metaData: info,
      label: info.label,
    };
    nodeLookup[node.id] = node;
    return node;
  };

  const topLevelNodes = views.map((v) => {
    const viewNode = createViewNode(v.viewName);
    const fieldInfoList: FieldInfoList = [];
    v.fields.forEach((pickerField) => {
      const { field, example } = pickerField;
      if (field.name) {
        // qualified field names are expected to be in the form of [view].[field]
        const components = field.name.split('.');
        if (components.length === 2) {
          const [, nonQualifiedFieldName] = components;
          fieldInfoList.push({
            name: field.name,
            shortName: nonQualifiedFieldName,
            label: field.label || nonQualifiedFieldName,
            normalizedType: field.normalizedType,
            example: example || undefined,
          });
        } else {
          console.warn(
            `Non-conforming Looker Explore field: ${field.name}, for extract id = ${extractId}`,
          );
        }
      }
    });
    viewNode.children = fieldInfoList.map(createFieldNode);
    return viewNode;
  });

  const schema = {
    providerType,
    supportedTypes: toSupportedTypeLookup(typeList),

    opList,
    opLookup,
    opDisplaysByType,

    dateTypes: Array.from(dateTypeSet),

    nodes: topLevelNodes,
    lookup: nodeLookup,
  };
  return {
    id: extractId,
    schema,
  };
};

/**
 *   Slice creation
 **/

export enum SchemaNodeKind {
  VIEW = 'VIEW',
  FIELD = 'FIELD',
}

export interface SchemaNode {
  id: string;
  name: string;
  kind: SchemaNodeKind;
  children?: SchemaNode[];
  metaData?: ViewInfo | FieldInfo;
}

export interface Schema {
  providerType: ExternalProviderType;
  supportedTypes: Record<string, NormalizedType>;

  opList: NormalizedOperator[];
  opLookup: Record<string, NormalizedOperator>;
  opDisplaysByType: Record<string, OpDisplay[]>;

  dateTypes: string[];

  nodes: SchemaNode[];
  lookup: Record<string, SchemaNode>;
}

export interface ExploreExtract {
  id: string;
  schema: Schema;
  columnarSlices?: ColumnarSlices;
}

type ExploreExtracts = Record<string, ExploreExtract>;

const initialStateValue: ExploreExtracts = {};

export const exploreExtractsSlice = createSlice({
  name: 'exploreExtracts',
  initialState: { value: initialStateValue },
  reducers: {
    updateExploreExtract: (state, action: PayloadAction<ExploreExtract>) => {
      const { payload } = action;
      state.value = {
        ...state.value,
        [payload.id]: {
          ...state.value[payload.id],
          ...action.payload,
        },
      };
    },
    clearExploreExtracts: (state) => {
      state.value = initialStateValue;
    },
    setColumnarSlices: (
      state,
      action: PayloadAction<{ id: string; sliceInfo: ColumnarSlices }>,
    ) => {
      const { payload } = action;
      state.value = {
        ...state.value,
        [payload.id]: {
          ...state.value[payload.id],
          columnarSlices: payload.sliceInfo,
        },
      };
    },
  },
});

export const { updateExploreExtract, clearExploreExtracts, setColumnarSlices } =
  exploreExtractsSlice.actions;

export default exploreExtractsSlice.reducer;
