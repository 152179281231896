// @TODO: `makeStyles` is deprecated. Use `styled` from '@mui/material/styles' instead.
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useDialogStyles = makeStyles((theme: Theme) => ({
  titleWrapper: {
    marginBottom: theme.spacing(1),
  },
  title: {
    fontWeight: 700,
    lineHeight: '16px',
    color: '#525152',
  },
  input: {
    width: '100%',
    boxSizing: 'border-box',
    borderRadius: '4px',
    textTransform: 'none',
    '& .MuiOutlinedInput-input': {
      height: 4,
    },
  },
  inputTitle: {
    fontWeight: 500,
    color: '#525152',
  },
  filledButton: {
    textTransform: 'none',
    fontWeight: '500',
    lineHeight: '20px',
    transition: '0.4s',
    background: '#525152',
    color: '#fff',
    '&:hover': {
      background: theme.palette.primary.dark,
      transition: '0.4s',
      color: theme.palette.text.primary,
    },
  },
  outlinedButton: {
    textTransform: 'none',
    fontWeight: '500',
    lineHeight: '20px',
    transition: '0.4s',
    background: theme.palette.primary.light,
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.primary.dark}`,
    '&:hover': {
      background: theme.palette.primary.main,
      transition: '0.4s',
      color: theme.palette.text.primary,
    },
  },
}));

export default useDialogStyles;
