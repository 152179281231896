import { Stack, Typography } from '@mui/material';
import Tooltip from '../../Tooltip';

interface FormFieldLabelProps {
  text: string;
  subText?: string;
  required?: boolean;
  tooltipText?: string;
  variant?: 'section';
}

const FormFieldLabel = ({
  text,
  subText,
  required,
  tooltipText = '',
  variant,
}: FormFieldLabelProps) => {
  const isGroupLabel = variant === 'section';
  return (
    <Stack spacing={0.5}>
      <Stack spacing={0.5} direction='row'>
        <Tooltip title={tooltipText} placement='right-end'>
          <Typography
            variant='h6'
            color={isGroupLabel ? 'text.secondary' : 'text.primary'}
            sx={{
              textTransform: `${isGroupLabel ? 'uppercase' : 'none'}`,
            }}
          >
            {text}
          </Typography>
        </Tooltip>
        {required && (
          <Typography variant='h6' color='error' display='inline'>
            {' '}
            *
          </Typography>
        )}
      </Stack>

      {subText && <Typography color='text.secondary'>{subText}</Typography>}
    </Stack>
  );
};

export default FormFieldLabel;
