import { useState } from 'react';
import {
  DialogActions as MuiDialogActions,
  DialogActionsProps as MuiDialogActionsProps,
} from '@mui/material';
import {
  BaseDialogActionType,
  DialogActionType,
  AsyncResult,
} from '../../../contexts/DialogProvider';
import { CheckCircleOutline, WarningAmberOutlined } from '@mui/icons-material';
import Button from '../Button';

// isBuiltIn: used to determine its padding.
// When it is built in by Dialog and is a direct child of Dialog, Dialog > DialogAction
// it can have proper paddings.
// when it is added by DialogContent and is a child of DialogContext, Dialog > DialogContext > DialogAction,
// it should have 0 padding to mitigate the padding from DialogContext
// but it should give a vertical space between Content and Actions
// In most cases, isBuiltIn should be false or undefined.
interface DialogActionsProps extends MuiDialogActionsProps {
  secondaryAction?: BaseDialogActionType;
  primaryAction?: DialogActionType;
  closeDialog: () => void;
  isBuiltIn?: boolean;
  handleResult?: (result: AsyncResult) => void; // this is only used for async actions
}

const DialogActions = ({
  secondaryAction,
  primaryAction,
  closeDialog,
  isBuiltIn,
  handleResult,
  ...rest
}: DialogActionsProps) => {
  const [loading, setLoading] = useState(false);
  const [hasSuccessResponse, setHasSuccessResponse] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  const handleSecondaryButtonClick = () => {
    secondaryAction?.action && secondaryAction.action();
    closeDialog();
  };

  const handlePrimaryButtonClick = async () => {
    if (primaryAction?.action) {
      primaryAction.action();
      closeDialog();
    } else if (primaryAction?.asyncAction) {
      setLoading(true);
      const result = await primaryAction.asyncAction();
      setLoading(false);
      if (handleResult) {
        handleResult(result);
      }
      if (typeof result === 'boolean' && result) {
        setHasSuccessResponse(true);
        setTimeout(() => {
          closeDialog();
        }, 1500);
      }
      if (typeof result !== 'boolean' && result.length > 0) {
        setHasErrors(true);
      }
    }
  };

  const errorButtonText = hasErrors ? 'Error' : undefined;
  const successButtonText = hasSuccessResponse ? 'Completed' : undefined;
  const primaryButtonText =
    errorButtonText ?? successButtonText ?? primaryAction?.text;

  const errorIcon = hasErrors ? <WarningAmberOutlined /> : null;
  const successIcon = hasSuccessResponse ? <CheckCircleOutline /> : null;
  const startIcon = errorIcon ?? successIcon;

  return (
    <MuiDialogActions
      sx={isBuiltIn ? { p: 3, pt: 2 } : { p: 0, pt: 4 }}
      {...rest}
    >
      {secondaryAction ? (
        <Button
          variant='outlined'
          onClick={handleSecondaryButtonClick}
          disabled={secondaryAction.disabled}
        >
          {secondaryAction.text}
        </Button>
      ) : null}
      {primaryAction ? (
        <Button
          type='submit'
          startIcon={startIcon}
          isLoading={loading}
          variant='contained'
          onClick={handlePrimaryButtonClick}
          disabled={primaryAction.disabled || hasErrors}
        >
          {primaryButtonText}
        </Button>
      ) : null}
    </MuiDialogActions>
  );
};

export default DialogActions;
