import { Box, Stack, Typography } from '@mui/material';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

import { styled } from '@mui/system';
interface ProgressBarProps {
  number: number;
  formatted?: string;
  divideBy: number;
  showNumber?: boolean;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.background.default,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.success.main,
  },
}));

const ProgressBar = ({
  number,
  formatted,
  divideBy,
  showNumber,
}: ProgressBarProps) => {
  const value = number / divideBy < 1 ? (number / divideBy) * 100 : 100;

  return (
    <Stack direction='column' width='100%' alignItems='flex-start'>
      {showNumber ? (
        <Typography variant='body2'>{formatted || number}</Typography>
      ) : (
        ''
      )}
      <Box width={'100%'}>
        <BorderLinearProgress variant='determinate' value={value} />
      </Box>
    </Stack>
  );
};

export default ProgressBar;
