import { MouseEvent } from 'react';
import { Avatar as MuiAvatar, Tooltip, Typography } from '@mui/material';
import { AvatarProps as MuiAvatarProps } from '@mui/material/Avatar';

export type AvatarSize = 'large' | 'medium' | 'small';

export const AVATAR_DIMENSIONS = {
  large: {
    iconSize: 60,
    fontSize: '28px',
  },
  medium: {
    iconSize: 30,
    fontSize: '14px',
  },
  small: {
    iconSize: 15,
    fontSize: '8px',
  },
};
interface AvatarProps extends MuiAvatarProps {
  name: string;
  showTooltip?: boolean;
  size?: AvatarSize;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
}

// This is deprecated, use AvatarV2/index.tsx instead
const Avatar = ({
  name,
  showTooltip = true,
  size = 'medium',
  onClick,
  ...rest
}: AvatarProps) => {
  const renderAvatar = () => {
    const initials = name
      .split(' ')
      .map((n) => n[0])
      .join('');

    return (
      <MuiAvatar
        role={onClick ? 'button' : 'img'}
        onClick={onClick}
        data-testid='avatar'
        sx={{
          width: AVATAR_DIMENSIONS[size].iconSize,
          height: AVATAR_DIMENSIONS[size].iconSize,
          cursor: onClick ? 'pointer' : 'default',
        }}
        alt={name}
        {...rest}
      >
        <Typography
          sx={{
            fontSize: AVATAR_DIMENSIONS[size].fontSize,
            fontWeight: 500,
          }}
        >
          {initials?.toUpperCase()}
        </Typography>
      </MuiAvatar>
    );
  };
  return (
    <>
      {showTooltip ? (
        <Tooltip title={name}>{renderAvatar()}</Tooltip>
      ) : (
        renderAvatar()
      )}
    </>
  );
};

export default Avatar;
