import { NormalizedType } from '../../generated/types';
import {
  FieldInfo,
  isFieldInfo,
  ViewInfo,
} from '../../store/slices/exploreExtracts';

export interface ConfigOptions {
  idFieldsOnly?: boolean;
}

// Disable the TreeNode/Column if
// a) it's related to a field (not), and
// b) its normalized type isn't among those supported by the provider
// c) the field name is in the disabledFields array
export const isFieldDisabled = (
  metaData: ViewInfo | FieldInfo | undefined,
  supportedTypes: Record<string, NormalizedType>,
  idFieldsOnly: boolean | undefined,
  disabledFields: Array<string> = [],
): boolean => {
  // deal with fields only
  if (!isFieldInfo(metaData)) return false;

  // fields passed in from VFP that should also be disabled
  if (disabledFields.includes(metaData?.name)) return true;

  const supportedType = supportedTypes[metaData.normalizedType];

  // disable if `idFieldsOnly` option is specified, id types are defined and the field isn't one of them
  if (idFieldsOnly && !supportedType?.capabilities?.asID) {
    return true;
  }

  // otherwise, disable fields whose types are not among the supported
  return !supportedType;
};
