import React, { useCallback, useMemo } from 'react';

// material UI components
import { Grid, Box } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// components
import DynamicFieldInput from '../DynamicFieldInput';
import SimpleDropdown, { OptionItem } from '../shared/SimpleDropdown';
import FormInput from '../shared/Form/FormInput';
import DataPreview from '../DataPreview';

// hooks and types
import { useAppSelector, useFeature } from '../../hooks';
import { SlackInMemConfig } from '../../store/slices/features';
import { ExploreInfo } from '../../store/slices/loadedWorkspaceExplores';
import { IntegrationType } from '../../generated/types';

interface InputList {
  id?: string;
  marginTop?: number;
  header: string;
  sub?: string;
  fieldIsRequired?: boolean;
  customInput: React.ReactNode;
  tooltipText?: string;
}

interface SlackActionConfigurationProps {
  workspaceId: string;
  slackConfig: SlackInMemConfig;
  actionIndex: number;
  enableDataPreview?: boolean;
  exploreInfo?: ExploreInfo;
  featureId: string;
}

const SlackActionConfiguration = ({
  workspaceId,
  slackConfig,
  actionIndex,
  enableDataPreview,
  exploreInfo,
  featureId,
}: SlackActionConfigurationProps) => {
  const { featureEditData, isLive, setActionIntegrationIdAt, setSlackFieldAt } =
    useFeature();

  const { integrations } = useAppSelector((store) => store.workspace.value);

  const integrationOptions: OptionItem[] = useMemo(() => {
    const options: OptionItem[] = [];
    for (const integration of integrations) {
      if (integration.type === IntegrationType.SLACK_V1) {
        options.push({ value: integration.id, label: integration.name });
      }
    }
    return options;
  }, [integrations]);

  const integrationId = featureEditData.actions[actionIndex].integrationId;

  const setIntegrationId = useCallback(
    (integrationId: string) =>
      setActionIntegrationIdAt(actionIndex, integrationId),
    [actionIndex, setActionIntegrationIdAt],
  );

  const setChannelId = useCallback(
    (channelId: string) => setSlackFieldAt(actionIndex, 'channelId', channelId),
    [actionIndex, setSlackFieldAt],
  );

  const setMessage = useCallback(
    (message: string) => setSlackFieldAt(actionIndex, 'message', message),
    [actionIndex, setSlackFieldAt],
  );

  const customInputsList: InputList[] = useMemo(
    () => [
      {
        marginTop: 0,
        header: 'Integration',
        fieldIsRequired: true,
        customInput: (
          <SimpleDropdown
            disabled={integrationOptions.length === 0 || isLive}
            setValue={setIntegrationId}
            menuOptions={integrationOptions}
            value={integrationId ?? ''}
            IconComponent={KeyboardArrowDownIcon}
          />
        ),
      },
      {
        marginTop: 3,
        header: 'Channel ID',
        fieldIsRequired: true,
        customInput: (
          <DynamicFieldInput
            extractId={exploreInfo?.extractId}
            initialValue={slackConfig.channelId}
            onValueChange={setChannelId}
            className='dynamicField'
            placeholder='Where the message will be posted'
            mixed
            disabled={isLive}
          />
        ),
        tooltipText:
          'View the channel details in Slack and scroll to the bottom to get the Channel ID. \n\nMake sure to allow Inventive to post in this Slack channel by typing ‘/invite @Inventive’ in the channel.',
      },
      {
        marginTop: 3,
        header: 'Message',
        fieldIsRequired: true,
        customInput: (
          <DynamicFieldInput
            extractId={exploreInfo?.extractId}
            initialValue={slackConfig.message}
            onValueChange={setMessage}
            className='dynamicField'
            placeholder='Your account {account_name} needs your attention.'
            mixed
            disabled={isLive}
          />
        ),
      },
    ],
    [
      integrationId,
      exploreInfo?.extractId,
      slackConfig.channelId,
      slackConfig.message,
      isLive,
      integrationOptions,
      setChannelId,
      setIntegrationId,
      setMessage,
    ],
  );

  const isPreviewButtonDisabled = () => {
    if (!slackConfig.channelId.trim() && !slackConfig.message.trim()) {
      return true;
    }

    return false;
  };

  return (
    <>
      <Grid
        item
        display='flex'
        justifyContent='space-around'
        flexWrap='nowrap'
        width='100%'
        mb={4}
      >
        <Box width='100%'>
          {customInputsList.map((value: InputList, index: number) => (
            <FormInput
              key={value.header + index}
              id={value.header + index}
              marginTop={value.marginTop}
              tooltipText={value.tooltipText}
              title={value.header}
              label={value.header}
              sub={value.sub}
              customInput={value.customInput}
              disabled={isLive}
            />
          ))}
        </Box>
      </Grid>
      {!isLive && enableDataPreview && exploreInfo && (
        <Grid item>
          <DataPreview
            key={featureId}
            workspaceId={workspaceId}
            selectedExploreId={exploreInfo.envExploreId}
            ctaText='Preview data'
            title='Preview data'
            includeActionFields={true}
            isDisabled={isPreviewButtonDisabled()}
          />
        </Grid>
      )}
    </>
  );
};

export default SlackActionConfiguration;
