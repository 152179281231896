import { useCallback, useContext, useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import {
  DIALOG_IDS,
  DialogComponentPropsMapping,
} from '../components/registeredDialogs/dialogRegistry';
import {
  DialogContext,
  DialogConfig,
  DialogActionType,
  BaseDialogActionType,
} from '../contexts/DialogProvider';

interface UseDialogProps<ID extends DIALOG_IDS> {
  id: ID;
  title: string;
  fullDisplay?: boolean;
  contentProps: Omit<DialogComponentPropsMapping[ID], 'hideDialog'>;
  primaryAction?: DialogActionType;
  secondaryAction?: BaseDialogActionType;
}

type ShowDialogArgs<ID extends DIALOG_IDS> = {
  title?: string;
  fullDisplay?: boolean;
  contentProps?: Omit<DialogComponentPropsMapping[ID], 'hideDialog'>;
  primaryAction?: DialogActionType;
  secondaryAction?: BaseDialogActionType;
};

export const useDialog = <ID extends DIALOG_IDS>({
  id,
  title,
  fullDisplay,
  contentProps,
  primaryAction,
  secondaryAction,
}: UseDialogProps<ID>) => {
  const { dispatch, dialogs } = useContext(DialogContext);

  // creates a unique id for each instance of the dialog
  const instanceId = useRef(Symbol());

  const showDialog = useCallback(
    (args?: ShowDialogArgs<ID>) => {
      const {
        title: titleOverride,
        primaryAction: primaryActionOverride,
        secondaryAction: secondaryActionOverride,
        contentProps: contentPropsOverride,
      } = args ?? {};

      const dialog: DialogConfig<ID> = {
        id,
        title: titleOverride ?? title,
        fullDisplay,
        contentProps: contentPropsOverride ?? contentProps,
        primaryAction: primaryActionOverride ?? primaryAction,
        secondaryAction: secondaryActionOverride ?? secondaryAction,
        instanceId: instanceId.current,
      };
      dispatch({ type: 'SHOW_DIALOG', dialog });
    },
    [
      contentProps,
      dispatch,
      id,
      primaryAction,
      secondaryAction,
      title,
      fullDisplay,
    ],
  );

  // update the dialog if contentProps changes
  useEffect(() => {
    if (!dialogs.length) return;

    const matchingDialog = dialogs.find(
      (dialog) => dialog.instanceId === instanceId.current,
    );

    if (matchingDialog && !isEqual(contentProps, matchingDialog.contentProps)) {
      dispatch({
        type: 'UPDATE_DIALOG_CONTENT',
        id: matchingDialog.id,
        contentProps,
      });
    }
  }, [contentProps, dialogs, dispatch, id]);

  const hideDialog = useCallback(() => {
    dispatch({ type: 'HIDE_DIALOG', id });
  }, [dispatch, id]);

  return {
    showDialog,
    hideDialog,
  };
};
