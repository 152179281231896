import { useCallback } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListSubheader,
  Typography,
} from '@mui/material';
import Button from '../../shared/Button';

// icon
import AddIcon from '@mui/icons-material/Add';

import { CategoryItem } from '../../../hooks';
import { UserType } from '../../../store/slices/session';

interface TemplateSidebarProps {
  sidebarItems: Array<CategoryItem>;
  sidebarHeader: string;
  selectedSidebarItemId: string;
  setSelectedSidebarItemId: (params: string) => void;
  setOpen: (params: boolean) => void;
  userType: UserType;
}

const TemplateSidebar = ({
  sidebarItems,
  sidebarHeader,
  selectedSidebarItemId,
  setSelectedSidebarItemId,
  setOpen,
  userType,
}: TemplateSidebarProps) => {
  const openTemplateCategory = useCallback(() => setOpen(true), [setOpen]);

  const renderSidebarItems = useCallback(() => {
    return (
      <>
        {sidebarItems.map((item) => (
          <ListItem
            button
            onClick={() => setSelectedSidebarItemId(item.id)}
            selected={item.id === selectedSidebarItemId}
            key={item.id}
            sx={{
              wordWrap: 'break-word',
            }}
          >
            <ListItemText
              disableTypography
              primary={
                <Typography
                  variant='body1'
                  sx={{
                    fontWeight: 500,
                    lineHeight: '14px',
                    color:
                      selectedSidebarItemId === item.id
                        ? (theme) => theme.palette.text.primary
                        : (theme) => theme.palette.text.secondary,
                  }}
                >
                  {item.name}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </>
    );
  }, [sidebarItems, selectedSidebarItemId, setSelectedSidebarItemId]);

  return (
    <Box height='100%' display='flex'>
      <List
        sx={{ p: 3, width: '249px' }}
        subheader={
          <ListSubheader
            sx={{
              fontWeight: 700,
              fontSize: 16,
            }}
          >
            {sidebarHeader}
          </ListSubheader>
        }
      >
        {renderSidebarItems()}

        {userType !== 'WorkspaceMember' && (
          <Button
            variant='outlined'
            onClick={openTemplateCategory}
            startIcon={<AddIcon />}
            sx={{
              mt: 2,
            }}
          >
            Add category
          </Button>
        )}
      </List>
      <Divider orientation='vertical' flexItem />
    </Box>
  );
};

export default TemplateSidebar;
