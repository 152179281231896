import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Component } from '../../generated/types';

interface WorkspaceComponentState {
  component: Component | null;
}

const initialState: WorkspaceComponentState = {
  component: null,
};

const workspaceComponentSlice = createSlice({
  name: 'workspaceComponent',
  initialState,
  reducers: {
    setComponent: (state, action: PayloadAction<Component>) => {
      state.component = action.payload;
    },
  },
});

export const { setComponent } = workspaceComponentSlice.actions;

export default workspaceComponentSlice.reducer;
