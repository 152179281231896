import { ReactNode } from 'react';
import {
  DynamicFieldV1MetaData,
  FieldDatumV1,
  PlaceholderV1MetaData,
  NormalizedConditionExpression,
  isValid,
  validators,
} from '@madeinventive/core-types';
import {
  ActionSpec,
  isEmailInMemConfig,
  isSlackInMemConfig,
  parsePlaceholdersFor,
} from '../../store/slices/features/helpers';

export interface ValidityState {
  result: boolean;
  tooltip?: ReactNode;
}

export type ConditionsValidity = ValidityState;
export type ActionValidity = ValidityState;

interface ValidityCheckOptions {
  skipPlaceholders?: boolean;
  skipTooltip?: boolean;
}

export type TriggerValidator = (
  conditions: NormalizedConditionExpression[],
  options?: ValidityCheckOptions,
) => ValidityState;
export type ActionSpecValidator = (
  actionSpec: ActionSpec,
  options?: ValidityCheckOptions,
) => ValidityState;
export type FieldDataValidator = (
  fieldData: FieldDatumV1[],
  options?: ValidityCheckOptions,
) => ValidityState;

const getValidityTooltip = (violations: string[]): ReactNode | undefined => {
  if (!violations.length) return;
  return (
    <ul style={{ marginLeft: -20 }}>
      {violations.map((v, index) => (
        <li key={`violation_${index}`}>{v}</li>
      ))}
    </ul>
  );
};

export const checkTriggerConditionsExist: TriggerValidator = (
  conditions: NormalizedConditionExpression[],
) => {
  if (conditions.length > 0) {
    return {
      result: true,
    };
  }

  return {
    result: false,
  };
};

export const checkTriggerConditionsValidity: TriggerValidator = (
  conditions: NormalizedConditionExpression[],
  options?: ValidityCheckOptions,
) => {
  const violations: string[] = [];

  let hasUnresolvedField = false;
  let hasPlaceholder = false;
  for (const condition of conditions) {
    if (
      isValid<DynamicFieldV1MetaData>(
        validators.DynamicFieldV1MetaData,
        condition.variable,
      )
    ) {
      // found a dynamic field, check further only if 'hasUnresolvedField' hasn't been marked yet
      if (hasUnresolvedField) continue;
      if (
        !condition.operator ||
        (!condition.operator.isUnary && condition.value === undefined)
      ) {
        hasUnresolvedField = true;
      }
    } else if (
      // found a placeholder
      isValid<PlaceholderV1MetaData>(
        validators.PlaceholderV1MetaData,
        condition.variable,
      )
    ) {
      hasPlaceholder = true;
    } else {
      // the condition is unresolved because no variable has been specified
      hasUnresolvedField = true;
    }
  }
  if (hasUnresolvedField) violations.push('Has unresolved conditions');
  if (!options?.skipPlaceholders && hasPlaceholder)
    violations.push(`Unresolved placeholders`);

  return {
    result: !violations.length,
    tooltip: !options?.skipTooltip && getValidityTooltip(violations),
  };
};

export const checkFieldDataValidity: FieldDataValidator = (
  fieldData: FieldDatumV1[],
  options?: ValidityCheckOptions,
) => {
  const violations: string[] = [];
  if (fieldData.length <= 0) {
    violations.push('Needs at least 1 valid field datum');
  } else {
    let hasUnresolvedField = false;
    let hasPlaceholder = false;
    for (const fieldDatum of fieldData) {
      if (
        isValid<DynamicFieldV1MetaData>(
          validators.DynamicFieldV1MetaData,
          fieldDatum.variable,
        )
      ) {
        // Dynamic field found means the field is resolved
      } else if (
        isValid<PlaceholderV1MetaData>(
          validators.PlaceholderV1MetaData,
          fieldDatum.variable,
        )
      ) {
        hasPlaceholder = true;
      } else {
        // the condition is unresolved because no variable has been specified
        hasUnresolvedField = true;
      }
    }
    if (hasUnresolvedField) violations.push('Has unresolved field datum');
    if (!options?.skipPlaceholders && hasPlaceholder)
      violations.push(`Unresolved placeholders`);
  }

  return {
    result: !violations.length,
    tooltip: !options?.skipTooltip && getValidityTooltip(violations),
  };
};

export const checkEmailConfigValidity: ActionSpecValidator = (
  actionSpec: ActionSpec,
  options?: ValidityCheckOptions,
) => {
  if (
    !['Email/v1.0', 'Email/v2.0'].includes(actionSpec.kind) ||
    !isEmailInMemConfig(actionSpec.spec)
  ) {
    return {
      result: false,
      tooltip: getValidityTooltip(['Unsupported/invalid email config']),
    };
  }

  const violations: string[] = [];
  const { to, subject, body, buttonText, buttonUrl } = actionSpec.spec;

  // if any of the following is not set, the email action isn't considered valid
  if (!to) violations.push(`'To' is required`);
  if (!subject) violations.push(`'Subject' is required`);
  if (!body || !body.trim()) violations.push(`'Body' is required`);
  if (buttonUrl && !buttonText)
    violations.push(`'Button link' requires non-empty 'Button text'`);

  if (!options?.skipPlaceholders) {
    // not skipping the check for placeholders
    const placeholders: { id: string; name: string }[] = [];
    parsePlaceholdersFor(actionSpec, placeholders);
    if (placeholders.length > 0) violations.push(`Unresolved placeholders`);
  }

  return {
    result: !violations.length,
    tooltip: !options?.skipTooltip && getValidityTooltip(violations),
  };
};

export const checkSlackConfigValidity: ActionSpecValidator = (
  actionSpec: ActionSpec,
  options?: ValidityCheckOptions,
) => {
  if (
    actionSpec.kind !== 'Slack/v1.0' ||
    !isSlackInMemConfig(actionSpec.spec)
  ) {
    return {
      result: false,
      tooltip: getValidityTooltip(['Unsupported/invalid slack config']),
    };
  }

  const violations: string[] = [];
  const { channelId, message } = actionSpec.spec;

  if (!actionSpec.integrationId)
    violations.push(`'Integration Id' is required`);
  if (!channelId) violations.push(`'channelId' is required`);
  if (!message) violations.push(`'message' is required`);

  if (!options?.skipPlaceholders) {
    // not skipping placeholders
    const placeholders: { id: string; name: string }[] = [];
    parsePlaceholdersFor(actionSpec, placeholders);
    if (placeholders.length > 0) violations.push(`Unresolved placeholders`);
  }

  return {
    result: !violations.length,
    tooltip: !options?.skipTooltip && getValidityTooltip(violations),
  };
};
