import { Alert, Stack, Typography } from '@mui/material';
import { BaseRegisteredDialogComponentProps } from './types';

export interface DeleteConfirmationProps
  extends BaseRegisteredDialogComponentProps {
  name: string;
  warningMessage?: string;
}

const DeleteConfirmation = ({
  name,
  warningMessage,
}: DeleteConfirmationProps) => {
  return (
    <Stack spacing={2}>
      {warningMessage && <Alert severity='warning'>{warningMessage}</Alert>}
      <Typography variant='body1' color='text.secondary'>
        <strong>{name}</strong> will be removed from your workspace. All
        workspace members will lose access.
      </Typography>
    </Stack>
  );
};

export default DeleteConfirmation;
