import { ReactNode } from 'react';
import { PickerDataContext, usePickerDataContext } from '../../hooks/picker';

interface PickerDataProviderProps {
  children?: ReactNode;
}

const PickerDataProvider = (props: PickerDataProviderProps) => {
  const service = usePickerDataContext();

  return (
    <PickerDataContext.Provider value={service}>
      {props.children}
    </PickerDataContext.Provider>
  );
};

export default PickerDataProvider;
