import React from 'react';
import {
  SnackbarOrigin,
  SnackbarProvider as NotistackSnackbarProvider,
  SnackbarProviderProps,
} from 'notistack';

import SnackbarError from './SnackbarError';
import SnackbarInfo from './SnackbarInfo';
import SnackbarSuccess from './SnackbarSuccess';

import { useAppSelector } from '../../hooks';
import { store } from '../../store';
import { dismissError } from '../../store/slices/error';

declare module 'notistack' {
  interface VariantOverrides {
    info: true;
    error: true;
  }
}

const SnackbarProvider = (props: SnackbarProviderProps) => {
  const providerRef = React.useRef<NotistackSnackbarProvider>(null);
  const errMessage = useAppSelector((state) => state.error.value);

  const snackbarProps = {
    preventDuplicate: true,
    maxSnack: 2,
    anchorOrigin: { horizontal: 'right', vertical: 'top' } as SnackbarOrigin,
    Components: {
      info: SnackbarInfo,
      error: SnackbarError,
      success: SnackbarSuccess,
    },
    ...props,
  };

  // Errors can alternatively be triggered by putting them into the redux store via `store.dispatch(setError('string'))`
  // This allows for easy triggering of errors that happen "above" this provider, such as in apollo/errorLink.ts
  if (providerRef.current && errMessage) {
    providerRef.current.enqueueSnackbar(errMessage, { variant: 'error' });
    store.dispatch(dismissError());
  }

  return (
    <NotistackSnackbarProvider ref={providerRef} {...snackbarProps}>
      {props.children}
    </NotistackSnackbarProvider>
  );
};

export default SnackbarProvider;
