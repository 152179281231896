import React from 'react';

// mui components
import { Typography, Box, SxProps } from '@mui/material';

interface FieldLabelProps {
  title: string;
  sx?: SxProps;
  showAsterisk?: boolean;
}
const FieldLabel = ({ title, sx, showAsterisk }: FieldLabelProps) => {
  return (
    <Box display='flex' sx={sx}>
      <Typography variant='h5'>{title}</Typography>
      {showAsterisk && (
        <Typography
          variant='h3'
          sx={{
            ml: 0.5,
            color: (theme) => theme.palette.error.main,
          }}
        >
          *
        </Typography>
      )}
    </Box>
  );
};

export default FieldLabel;
