import { FieldInfo } from '../../store/slices/exploreExtracts';

const isFieldDisabled = (
  fieldInfo: FieldInfo,
  disabledFields?: string[],
): boolean => {
  if (!disabledFields) return false;
  return disabledFields.includes(fieldInfo.shortName);
};

const focusDelayed = (element: HTMLElement | null) => {
  if (!element) return;
  setTimeout(() => element.focus(), 100);
};

export { isFieldDisabled, focusDelayed };
