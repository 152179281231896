import * as React from 'react';

// mui components
import { Box, Typography } from '@mui/material';

// components
import Button from '../../shared/Button';

interface DeleteDialogBodyProps {
  helperText: string;
  subHelperText: string;
  selectedItemName?: string;
}

const DeleteDialogBody = ({
  selectedItemName,
  helperText,
  subHelperText,
}: DeleteDialogBodyProps) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      pt={3}
      pb={1.5}
      pl={9}
      pr={9}
    >
      <Box display='flex' mb={0.5} textAlign='center'>
        <Typography
          variant='h5'
          sx={{ fontWeight: 500, color: 'text.primary' }}
          mr={0.5}
        >
          {helperText}{' '}
          <Typography
            variant='h5'
            component='span'
            sx={{ fontWeight: 700, color: 'text.primary' }}
          >
            {selectedItemName}
          </Typography>
        </Typography>
      </Box>
      <Typography variant='body1' color='text.secondary' mb={1.5}>
        {subHelperText}
      </Typography>
    </Box>
  );
};

interface DialogButtonsProps {
  setSuccess: (params: boolean) => void;
  setIsDeleteFlow?: (params: boolean) => void;
  cancelDeletion?: (params: boolean) => void;
  deleteOperation: () => void;
  closeDialog?: () => void;
  deleteButtonName: string;
}

const DeleteDialogButtons = ({
  deleteOperation,
  setSuccess,
  setIsDeleteFlow,
  cancelDeletion,
  closeDialog,
  deleteButtonName,
}: DialogButtonsProps) => {
  const confirmDeletion = async () => {
    try {
      deleteOperation();
      setSuccess(true);
    } catch (e) {
      console.log(e);
    }
  };

  const cancelDeleteCategory = () => {
    const cancelDelete = setIsDeleteFlow
      ? setIsDeleteFlow(false)
      : cancelDeletion && cancelDeletion(false);
    closeDialog ? closeDialog && closeDialog() : cancelDelete;
  };

  return (
    <Box
      display='flex'
      width='100%'
      justifyContent='center'
      pb={3}
      pl={9}
      pr={9}
      gap={'12px'}
    >
      <Button
        size='small'
        sx={{ width: '125px' }}
        variant='outlined'
        onClick={cancelDeleteCategory}
      >
        Cancel{' '}
      </Button>
      <Button
        size='small'
        onClick={confirmDeletion}
        sx={{ width: '125px' }}
        variant='contained'
      >
        {deleteButtonName}
      </Button>
    </Box>
  );
};

export { DeleteDialogBody, DeleteDialogButtons };
