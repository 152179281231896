import { useCallback, useMemo } from 'react';
import BadgeConfigPanel from './BadgeConfigPanel';
import ProgressBarConfigPanel from './ProgressBarConfigPanel';
import FormatNumberPanel from './FormatNumberPanel';

import { Schema } from '../../store/slices/exploreExtracts';
import useActiveExplore from '../../hooks/useActiveExplore';
import {
  FieldAppearanceType,
  FieldAppearanceConfig,
  BadgeConfig,
  DynamicFieldV1MetaData,
  FieldDatumV1,
  ProgressBarConfig,
  NumberFormatConfigV1,
  isValid,
  validators,
} from '@madeinventive/core-types';

const isNumeric = (schema?: Schema, typeKey?: string) => {
  if (!schema || !typeKey) return false;
  const type = schema.supportedTypes[typeKey];
  return !!type.capabilities?.asNumeric;
};

interface AppearanceConfigsProps {
  selectedAppearanceType: FieldAppearanceType;
  selectedFieldDatum?: FieldDatumV1;
  appearanceConfig: FieldAppearanceConfig;
  setAppearanceConfig: (config: FieldAppearanceConfig) => void;
}

const AppearanceConfigs = ({
  selectedAppearanceType,
  selectedFieldDatum,
  appearanceConfig,
  setAppearanceConfig,
}: AppearanceConfigsProps) => {
  const { exploreExtract } = useActiveExplore();

  const schema = exploreExtract ? exploreExtract.schema : undefined;

  const setBadgeConfig = useCallback(
    (config: BadgeConfig) => {
      setAppearanceConfig({
        ...appearanceConfig,
        BADGE: config,
      });
    },
    [appearanceConfig, setAppearanceConfig],
  );

  const setProgressBarConfig = useCallback(
    (config: ProgressBarConfig) => {
      setAppearanceConfig({
        ...appearanceConfig,
        PROGRESS_BAR: config,
      });
    },
    [appearanceConfig, setAppearanceConfig],
  );

  const setNumberFormatConfig = useCallback(
    (config: NumberFormatConfigV1) => {
      setAppearanceConfig({
        ...appearanceConfig,
        numberFormat: config,
      });
    },
    [appearanceConfig, setAppearanceConfig],
  );

  const isSelectedFieldNumericType = useMemo(() => {
    if (!selectedFieldDatum || !schema) return false;
    if (
      isValid<DynamicFieldV1MetaData>(
        validators.DynamicFieldV1MetaData,
        selectedFieldDatum.variable,
      )
    ) {
      return isNumeric(schema, selectedFieldDatum.variable.normalizedType);
    }
    return false;
  }, [schema, selectedFieldDatum]);

  //render
  if (!exploreExtract) return null;

  return (
    <>
      {isSelectedFieldNumericType ? (
        <FormatNumberPanel
          numberConfig={appearanceConfig.numberFormat}
          setFormat={setNumberFormatConfig}
        />
      ) : (
        ''
      )}
      {selectedAppearanceType === FieldAppearanceType.BADGE ? (
        <BadgeConfigPanel
          selectedFieldDatum={selectedFieldDatum}
          config={appearanceConfig.BADGE}
          setConfigs={setBadgeConfig}
        />
      ) : (
        ''
      )}
      {selectedAppearanceType === FieldAppearanceType.PROGRESS_BAR ? (
        <ProgressBarConfigPanel
          config={appearanceConfig.PROGRESS_BAR}
          setConfig={setProgressBarConfig}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default AppearanceConfigs;
