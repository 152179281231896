import { useFormikContext } from 'formik';
import { Stack } from '@mui/material';
import TextField from '../../shared/Inputs/TextField';
// types
import { InsightsFormValues } from './types';
import { GLOBAL_CONTENT_WIDTH } from '../../Layout/constants';

const SUB_LABEL_TEXT =
  'Brief the AI on the intended use case of this data to help it deliver meaningful insights to your users. Provide examples of outputs that match your desired outcomes, considering elements such as structure, formatting, brevity, and style.';

const InsightsForm = () => {
  const { setFieldValue, values, errors } =
    useFormikContext<InsightsFormValues>();

  return (
    <Stack id='insights-step' maxWidth={GLOBAL_CONTENT_WIDTH} height='100%'>
      <TextField
        label='Prompt'
        labelOverflow
        subLabel={SUB_LABEL_TEXT}
        errorMessage={errors.prompt}
        value={values.prompt}
        onChange={(e) => setFieldValue('prompt', e.target.value)}
        fullHeight
        inputProps={{
          'aria-label': 'ai prompt input',
        }}
      />
    </Stack>
  );
};

export default InsightsForm;
