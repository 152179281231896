import { Stack } from '@mui/material';

import MultiValueTextField from '../shared/Inputs/MultiValueTextField';
import ScheduleSelector from '../shared/ScheduleSelector';
import { FormikProps } from 'formik';
import { ScheduleVizFormValues } from './types';
import { KnownAliases } from '@madeinventive/core-types';

interface ScheduleVizEmailProps {
  formik: FormikProps<ScheduleVizFormValues>;
  readOnly?: boolean;
  onScheduleChange?: (cron: string) => void;
  onRecipientsChange?: (recipients: string) => void;
}

const ScheduleVizEmail = ({
  formik,
  readOnly,
  onScheduleChange,
  onRecipientsChange,
}: ScheduleVizEmailProps) => {
  const addRecipients = (value: string) => {
    // if valid add to recipients
    if (validateRecipient(value)) {
      const recipients = [...formik.values.recipients, value];
      formik.setFieldValue('recipients', recipients);
      onRecipientsChange && onRecipientsChange(recipients.join(','));
    } else {
      return;
    }
  };

  const deleteRecipient = (value: string) => {
    const recipients = formik.values.recipients.filter((v) => v !== value);
    formik.setFieldValue('recipients', recipients);
    onRecipientsChange && onRecipientsChange(recipients.join(','));
  };

  const validateRecipient = (value: string) => {
    return (
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ||
      value === KnownAliases.ALL_WORKSPACE_ADMINS
    );
  };

  const handleCronScheduleChange = (cron: string) => {
    formik.setFieldValue('cron', cron);
    onScheduleChange && onScheduleChange(cron);
  };

  return (
    <Stack spacing={3}>
      <MultiValueTextField
        label='Recipient email address'
        subLabel={`Press Enter to add multiple recipients or use "${KnownAliases.ALL_WORKSPACE_ADMINS}" to send to everyone with workspace access.`}
        labelOverflow
        values={formik.values.recipients}
        onValueEntered={addRecipients}
        onDelete={deleteRecipient}
        valueValidator={validateRecipient}
        onValidationError={() =>
          formik.setFieldError(
            'recipients',
            `Please enter a valid email address or "${KnownAliases.ALL_WORKSPACE_ADMINS}".`,
          )
        }
        clearError={() => formik.setFieldError('recipients', undefined)}
        errorMessage={formik.errors.recipients as string}
        readOnly={readOnly}
      />

      <ScheduleSelector
        onChange={handleCronScheduleChange}
        initialCron={formik.values.cron}
        readOnly={readOnly}
      />
    </Stack>
  );
};

export default ScheduleVizEmail;
