// mui components
import { Box, Typography } from '@mui/material';

// icon
import DoneIcon from '@mui/icons-material/Done';

interface SuccessDialogContentProps {
  boldText?: string;
  regularText?: string;
}
const SuccessDialogContent = ({
  boldText,
  regularText,
}: SuccessDialogContentProps) => {
  return (
    <Box
      display='flex'
      justifyContent='center'
      flexDirection='column'
      pt={2}
      pb={2}
    >
      <Box display='flex' justifyContent='center' mb={2.5}>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          sx={{
            height: '35px',
            width: '35px',
            borderRadius: '50%',
            background: (theme) => theme.palette.success.light,
          }}
        >
          <DoneIcon
            sx={{
              color: (theme) => theme.palette.success.dark,
            }}
          />
        </Box>
      </Box>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        textAlign='center'
      >
        <Typography variant='h5'>
          <Typography
            component='span'
            variant='h5'
            sx={{
              fontWeight: 800,
              mr: 0.5,
            }}
          >
            {boldText}
          </Typography>
          {regularText}
        </Typography>
      </Box>
    </Box>
  );
};

export default SuccessDialogContent;
