import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { WS_URI } from '../endpoints';
import { getTokens } from '../localStorage';

export const wsLink = new GraphQLWsLink(
  createClient({
    url: WS_URI,
    connectionParams: () => {
      // this function gets called every time when a new subscription
      // is initiated to populate the connection params
      const params: {
        'X-INVENTIVE-TOKEN'?: string;
        accessToken?: string;
      } = {};
      if (process.env.NEXT_PUBLIC_API_TOKEN) {
        params['X-INVENTIVE-TOKEN'] = process.env.NEXT_PUBLIC_API_TOKEN;
      }
      const tokens = getTokens();
      if (tokens.accessToken) {
        params.accessToken = tokens.accessToken;
      }
      return params;
    },
    retryAttempts: 2,
  }),
);
