import { useState, useEffect, useCallback, useMemo } from 'react';
import { SxProps, Theme } from '@mui/material';
import { useDebounce } from 'usehooks-ts';
import { useElementWidth } from './useElementWidth';
import { FLOATING_BUTTON_SPACE } from '../components/App/Table/utils/constants';

// It is used to position the floating button on the right end of the table row
// even when it is scrolled or the table size changes.
// It works with Mui DataGrid component.
// Follow these steps to make the floating button work:
// 1. pass the tableRef to DataGrid's ref prop
// 2. pass the floatingButtonStyle to DataGrid's sx prop
// 3. add `floatingButton` class name to the button component

export const useDataGridFloatingButton = () => {
  const [tableRef, width] = useElementWidth();
  const [floatingButtonX, setFloatingButtonX] = useState(0);
  const buttonLeftPosition = useDebounce(floatingButtonX, 100);

  // wrapper
  const scrollWrapper = document.getElementsByClassName(
    'MuiDataGrid-virtualScroller',
  )[0];
  const wrapperRects = scrollWrapper?.getClientRects();
  const offset = wrapperRects?.length > 0 ? wrapperRects[0].x : 0;

  // scrolled content
  const scrolledContent = document.getElementsByClassName(
    'MuiDataGrid-virtualScrollerContent',
  )[0];
  const scrolledRects = scrolledContent?.getClientRects();
  const initialScrolledX =
    scrolledRects?.length > 0 ? scrolledContent.getClientRects()[0].x : 0;
  const initialOffsetScrolledX =
    initialScrolledX && offset ? initialScrolledX - offset : 0;

  useEffect(() => {
    // set initial x position
    setFloatingButtonX(width - initialOffsetScrolledX - FLOATING_BUTTON_SPACE);
  }, [scrolledContent, initialOffsetScrolledX, width]);

  const handleScroll = useCallback(() => {
    if (scrolledContent) {
      const currentScrolledX = scrolledContent.getClientRects()[0].x;
      const currentOffset = offset - currentScrolledX;
      setFloatingButtonX(width + currentOffset - FLOATING_BUTTON_SPACE);
    }
  }, [scrolledContent, width, offset]);

  useEffect(() => {
    if (scrollWrapper) {
      scrollWrapper.addEventListener('scroll', handleScroll);
    }

    return () => {
      // remove event listener
      if (scrollWrapper) {
        scrollWrapper.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollWrapper, handleScroll]);

  const floatingButtonStyle: SxProps<Theme> = useMemo(() => {
    return {
      '& .MuiDataGrid-row': {
        position: 'relative',
        '.floatingButton': {
          position: 'absolute',
          left: buttonLeftPosition,
          transform: 'translate(-100%)',
          visibility: 'hidden',
        },
        '&:hover': {
          '.floatingButton': {
            visibility: 'visible',
          },
        },
        '&.Mui-selected': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
          },
        },
      },
    };
  }, [buttonLeftPosition]);

  return [tableRef, floatingButtonStyle] as const;
};
