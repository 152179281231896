import { useCallback, useEffect, useMemo, useState } from 'react';
import { useWorkspaceLookerDataModelExploreFieldSuggestionsLazyQuery } from '../generated/types';
import { omit, throttle } from 'lodash';
import useExplore from './useActiveExplore';
import { Schema } from '../store/slices/exploreExtracts';
import { OptionItem } from '../components/shared/SimpleDropdown';
import {
  DynamicFieldV1MetaData,
  PlaceholderV1MetaData,
  isValid,
  validators,
  NormalizedOperatorV1,
} from '@madeinventive/core-types';

// @return: true if the op is one that's only for date type
const isDateOnlyOp = (schema?: Schema, opKey?: string) => {
  if (!schema || !opKey) return false;
  const op = schema.opLookup[opKey];
  if (!op) return false;

  for (const t of op.typesSupported) {
    if (!schema.dateTypes.includes(t)) return false;
  }
  return true;
};

// @return: true if the field type and operator combination support multiple value input
const isMultiValueSupport = (
  schema?: Schema,
  typeKey?: string,
  opKey?: string,
) => {
  if (!schema || !typeKey || !opKey) return false;
  const type = schema.supportedTypes[typeKey];
  if (!type?.capabilities?.multiValueOps) return false;
  return type.capabilities.multiValueOps.includes(opKey);
};

const isRangeValueSupport = (
  schema?: Schema,
  typeKey?: string,
  opKey?: string,
) => {
  if (!schema || !typeKey || !opKey) return false;
  const type = schema.supportedTypes[typeKey];
  if (!type?.capabilities?.rangeValueOps) return false;
  return type.capabilities.rangeValueOps.includes(opKey);
};

const isSingularRelativeDateValueSupport = (
  schema?: Schema,
  typeKey?: string,
  opKey?: string,
) => {
  if (!schema || !typeKey || !opKey) return false;
  const type = schema.supportedTypes[typeKey];
  if (!type?.capabilities?.singularRelativeDateOps) return false;
  return type.capabilities.singularRelativeDateOps.includes(opKey);
};

const isPluralRelativeDateValueSupport = (
  schema?: Schema,
  typeKey?: string,
  opKey?: string,
) => {
  if (!schema || !typeKey || !opKey) return false;
  const type = schema.supportedTypes[typeKey];
  if (!type?.capabilities?.pluralRelativeDateOps) return false;
  return type.capabilities.pluralRelativeDateOps.includes(opKey);
};

const useFieldVariable = (
  fieldData?: DynamicFieldV1MetaData | PlaceholderV1MetaData,
  operator?: NormalizedOperatorV1,
) => {
  const { exploreInfo, exploreExtract } = useExplore();
  const wsExploreId = exploreInfo ? exploreInfo.wsExploreId : undefined;
  const schema = exploreExtract ? exploreExtract.schema : undefined;

  // Field states
  const [fieldName, setFieldName] = useState('');
  const [isFieldDataValid, setIsFieldDataValid] = useState(false);

  // Operator states
  const [operators, setOperators] = useState<OptionItem[]>([]);

  // Value states
  const [isDateType, setIsDateType] = useState(false);
  const [isMultiValue, setIsMultiValue] = useState(false);
  const [isRangeValue, setIsRangeValue] = useState(false);
  const [isSingularRelativeDateValue, setIsSingularRelativeDateValue] =
    useState(false);
  const [isPluralRelativeDateValue, setIsPluralRelativeDateValue] =
    useState(false);
  const [isSuggestable, setIsSuggestable] = useState(false);
  const [suggestions, setSuggestions] = useState<readonly string[]>([]);
  const isValueDisabled = useMemo(
    () => !isFieldDataValid || (operator && operator.isUnary),
    [isFieldDataValid, operator],
  );

  // Setup for Operator/Value based on the field data
  useEffect(() => {
    if (schema && fieldData) {
      if (
        // op support for field
        isValid<DynamicFieldV1MetaData>(
          validators.DynamicFieldV1MetaData,
          fieldData,
        )
      ) {
        // field states
        setFieldName(fieldData.field);
        setIsFieldDataValid(true);

        const fieldType = fieldData.normalizedType; // fieldType can be ''
        if (fieldType) {
          // operator states
          const normalizedType = schema.supportedTypes[fieldType];
          const operatorsForType = schema.opDisplaysByType[fieldType];
          setOperators(
            operatorsForType.map((op) => ({ label: op.label, value: op.key })),
          );

          // value states
          setIsMultiValue(
            isMultiValueSupport(schema, fieldType, operator?.key),
          );
          setIsRangeValue(
            isRangeValueSupport(schema, fieldType, operator?.key),
          );
          setIsSingularRelativeDateValue(
            isSingularRelativeDateValueSupport(
              schema,
              fieldType,
              operator?.key,
            ),
          );
          setIsPluralRelativeDateValue(
            isPluralRelativeDateValueSupport(schema, fieldType, operator?.key),
          );
          setIsSuggestable(!!normalizedType?.capabilities?.suggestable);
          setIsDateType(!!normalizedType?.capabilities?.asDate);
        }
      } else if (
        // op support for placeholder
        isValid<PlaceholderV1MetaData>(
          validators.PlaceholderV1MetaData,
          fieldData,
        )
      ) {
        // field states
        setIsFieldDataValid(true);

        // operator states
        const genericOperators = schema.opList;
        setOperators(
          genericOperators.map((op) => ({ label: op.label, value: op.key })),
        );

        // value states
        setIsDateType(isDateOnlyOp(schema, operator?.key));
      }
    }
  }, [operator?.key, fieldData, schema]);

  // get suggestions
  const [
    fetchWsFieldSuggestions,
    { data, error, loading: isSuggestionLoading },
  ] = useWorkspaceLookerDataModelExploreFieldSuggestionsLazyQuery();

  useEffect(() => {
    if (data?.node?.__typename === 'WorkspaceLookerDataModelExplore') {
      const { suggestions } = data.node.getSuggestions;
      setSuggestions(suggestions ?? []);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      console.error(
        `Error '${error}' encountered while getting suggestion for field ${fieldName}`,
      );
    }
  }, [error, fieldName]);

  const getIsMultiValue = useCallback(
    (operator: string) => {
      if (
        fieldData &&
        isValid<DynamicFieldV1MetaData>(
          validators.DynamicFieldV1MetaData,
          fieldData,
        )
      ) {
        return isMultiValueSupport(schema, fieldData.normalizedType, operator);
      } else {
        return false;
      }
    },
    [fieldData, schema],
  );

  const getIsRangeValue = useCallback(
    (operator: string) => {
      if (
        fieldData &&
        isValid<DynamicFieldV1MetaData>(
          validators.DynamicFieldV1MetaData,
          fieldData,
        )
      ) {
        return isRangeValueSupport(schema, fieldData.normalizedType, operator);
      } else {
        return false;
      }
    },
    [fieldData, schema],
  );

  const getNormalizedOperator = useCallback(
    (operator: string, minimized = false) => {
      const fullOp = schema?.opLookup[operator];
      return minimized
        ? omit(fullOp, ['__typename', 'typesSupported'])
        : fullOp;
    },
    [schema],
  );

  useEffect(() => {
    if (data?.node?.__typename === 'WorkspaceLookerDataModelExplore') {
      const suggestions = data?.node?.getSuggestions?.suggestions;
      if (suggestions) {
        setSuggestions(suggestions);
      }
    }
  }, [data]);

  const fetchSuggestions = useMemo(() => {
    if (
      fieldData &&
      isValid<DynamicFieldV1MetaData>(
        validators.DynamicFieldV1MetaData,
        fieldData,
      )
    ) {
      const suggestionInput = fieldData.field;
      return throttle((matchPattern?: string) => {
        if (wsExploreId) {
          fetchWsFieldSuggestions({
            variables: {
              id: wsExploreId,
              suggestionInputArgs: {
                field: suggestionInput,
                term: matchPattern,
              },
            },
          });
        }
      });
    }
  }, [fetchWsFieldSuggestions, fieldData, wsExploreId]);

  return {
    // field
    isFieldDataValid,
    fieldName,
    // operators
    operators,
    // value
    fetchSuggestions,
    isDateType,
    isMultiValue,
    isRangeValue,
    isSingularRelativeDateValue,
    isPluralRelativeDateValue,
    isSuggestable,
    isSuggestionLoading,
    isValueDisabled,
    suggestions,
    // utils
    getIsMultiValue,
    getIsRangeValue,
    getNormalizedOperator,
  };
};

export default useFieldVariable;
