import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import Tags, { TagifyTagsReactProps } from '@yaireo/tagify/dist/react.tagify';
import Tagify, {
  BaseTagData,
  ChangeEventData,
  ClickEventData,
  TagData,
  TagifySettings,
} from '@yaireo/tagify';

import { PopperProps } from '@mui/material/Popper';

const getCaretBoundingRect = () => {
  const sel = document.getSelection();
  if (!sel?.rangeCount) return;

  const r = sel.getRangeAt(0);
  const node: Node = r.startContainer;
  const offset = r.startOffset;

  if (offset > 0) {
    const r2 = document.createRange();
    r2.setStart(node, offset - 1);
    r2.setEnd(node, offset);
    return r2.getBoundingClientRect();
  }
  return r.getBoundingClientRect();
};

export interface BaseHandlerArgs<T extends BaseTagData = TagData> {
  anchorEl: PopperProps['anchorEl'] | HTMLElement;
  hostEl: HTMLInputElement | HTMLTextAreaElement | HTMLSpanElement | undefined;
  onNewTagData: (newTagData: T) => void;
  removeTag?: () => void;
}

export interface TagClickHandlerArgs<T extends BaseTagData = TagData>
  extends BaseHandlerArgs<T> {
  tag: HTMLElement;
  tagData: T;
}

export interface LaunchKeyHandlerArgs<T extends BaseTagData = TagData>
  extends BaseHandlerArgs<T> {
  key: string;
}

export type TagClickHandler<T extends BaseTagData = TagData> = (
  args: TagClickHandlerArgs<T>,
) => void;
export type LaunchKeyHandler<T extends BaseTagData = TagData> = (
  args: LaunchKeyHandlerArgs<T>,
) => void;

export type RichTagsInputProps<T extends BaseTagData = TagData> =
  TagifyTagsReactProps<T> & {
    disabled?: boolean;
    onValueChange?: (value: string) => void;
    onTagClicked?: TagClickHandler<T>;
    launchKeys?: Set<string>;
    onLaunchKeyActivated?: LaunchKeyHandler<T>;
    placeholder?: string;
  };

const RichTagsInput = <T extends BaseTagData = TagData>(
  props: RichTagsInputProps<T>,
) => {
  const tagifyRef = useRef<Tagify<T> | undefined>(undefined);

  const {
    onValueChange,
    onTagClicked,
    settings: settingsProp,
    onChange: onChangeProp,
    onKeydown: onKeydownProp,
    launchKeys,
    onLaunchKeyActivated,
    placeholder,
    disabled,
    ...restProps
  } = props;

  const tagifySettings: TagifySettings<T> = useMemo(() => {
    const effectiveSettings = launchKeys
      ? {
          ...settingsProp,
          pattern: new RegExp(Array.from(launchKeys).join('|')),
          whitelist: [],
        }
      : settingsProp;

    return {
      // list all default settings at the top
      editTags: false,
      ...effectiveSettings,
    };
  }, [settingsProp, launchKeys]);

  const onChange = useCallback(
    (e: CustomEvent<ChangeEventData<T>>): void => {
      if (onValueChange) onValueChange(e.detail.value);

      // give user defined handler a shot at handling the event
      if (onChangeProp) onChangeProp(e);
    },
    [onChangeProp, onValueChange],
  );

  const onClick = useCallback(
    (e: CustomEvent<ClickEventData<T>>): void => {
      // give user defined handler a shot at handling the event
      if (onTagClicked) {
        const { tag, data: tagData } = e.detail;
        onTagClicked({
          anchorEl: tag,
          hostEl: tagifyRef.current?.DOM.input,
          tag,
          tagData: tagData,
          removeTag: () => tagifyRef.current?.removeTags([tag]),
          onNewTagData: (newTagData: T) =>
            tagifyRef.current?.replaceTag(tag, newTagData),
        });
      }
    },
    [onTagClicked],
  );

  const onKeydown = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any): void => {
      if (
        !!onLaunchKeyActivated &&
        launchKeys?.has(e.detail.originalEvent.key)
      ) {
        // need to get the caret position on next tick since on key-down
        // caret position hasn't been updated yet
        setTimeout(() => {
          const rect = getCaretBoundingRect();
          if (!rect) return;

          onLaunchKeyActivated({
            key: e.detail.originalEvent.key,
            anchorEl: { getBoundingClientRect: () => rect },
            hostEl: tagifyRef.current?.DOM.input,
            onNewTagData: (tagData: T) =>
              tagifyRef.current?.addMixTags([tagData]),
          });
        });
      }
      // give user defined handler a shot at handling the event
      if (onKeydownProp) onKeydownProp(e);
    },
    [onKeydownProp, launchKeys, onLaunchKeyActivated],
  );

  // Let the <Tags> component initialize without a disabled attribute, and then set it after component initialization.
  // There's a bug where if we initialize <Tags> with {disabled: true}, it won't accept input when set back to {disabled: false}.
  // However, if we intialize <Tags> with no disabled attribute, we can freely toggle the disabled state with no issues.
  useEffect(() => {
    tagifyRef?.current?.setDisabled(disabled ?? false);
  }, [tagifyRef, disabled]);

  return (
    <Tags<T>
      {...restProps}
      settings={tagifySettings}
      onChange={onChange}
      onKeydown={onKeydown}
      onClick={onClick}
      tagifyRef={tagifyRef}
      placeholder={placeholder}
    />
  );
};

export default RichTagsInput;
