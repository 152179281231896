import Head from 'next/head';
import { useSessionInfo } from '../../hooks';
import { EnvironmentContext } from '../EnvironmentProvider';
import { useContext } from 'react';

const Header = () => {
  const { workspaceName, customBranding } = useSessionInfo();
  const { environment } = useContext(EnvironmentContext);

  let title;
  if (environment?.name) {
    title = `${environment.name} | Inventive`;
  } else if (workspaceName) {
    title = `${workspaceName} | Inventive`;
  } else {
    title = 'Inventive';
  }

  const favicon = customBranding?.iconUrl || '/favicon.ico';

  return (
    <Head>
      <title>{title}</title>
      <meta name='description' content={`Landing page for ${title}`} />
      <link rel='icon' href={favicon} />
    </Head>
  );
};

export default Header;
