import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ViewerQuery } from '../../generated/types';
import {
  getTokenState,
  getCurrentUserDetails,
  TokenState,
  getSystemUserSelectedEnvironmentId,
  setSystemUserSelectedEnvironmentId,
} from '../../lib/localStorage';

export type UserType =
  | 'EnvironmentMember'
  | 'WorkspaceMember'
  | 'SystemUser'
  | '';

export interface CustomBrandingState {
  themeColor?: string;
  iconUrl?: string;
  logoUrl?: string;
}
export interface SessionUserState {
  id: string;
  isSignedIn: boolean;
  firstName: string;
  lastName: string;
  email: string;
  userType: UserType;
  systemSelectedEnvironmentId: string;
  environmentRole: string;
  environmentName: string;
  environmentId: string;
  workspaceRole: string;
  workspaceId: string;
  workspaceName: string;
  customBranding?: CustomBrandingState;
}
let localStorageUser: ViewerQuery['viewer']['user'] | null = null;
let localStorageSystemSelectedEnvironmentId: string | null = null;
let validToken = false;

if (typeof window !== 'undefined') {
  localStorageUser = getCurrentUserDetails();
  localStorageSystemSelectedEnvironmentId =
    getSystemUserSelectedEnvironmentId();
  validToken = getTokenState() !== TokenState.INVALID;
}

const initialStateValue: SessionUserState = {
  id: localStorageUser?.id || '',
  isSignedIn: validToken,
  firstName: localStorageUser?.firstname || '',
  lastName: localStorageUser?.lastname || '',
  email: localStorageUser?.email || '',
  userType: localStorageUser?.__typename || '',
  systemSelectedEnvironmentId:
    (localStorageUser?.__typename === 'SystemUser' &&
      localStorageSystemSelectedEnvironmentId) ||
    '',
  environmentRole:
    (localStorageUser?.__typename === 'EnvironmentMember' &&
      localStorageUser.environmentRole) ||
    '',
  customBranding: {
    themeColor:
      (localStorageUser?.__typename === 'EnvironmentMember' &&
        localStorageUser.environment.themeColor) ||
      (localStorageUser?.__typename === 'WorkspaceMember' &&
        localStorageUser?.workspace?.environment?.themeColor) ||
      '',
    logoUrl:
      (localStorageUser?.__typename === 'EnvironmentMember' &&
        localStorageUser.environment.logoUrl) ||
      (localStorageUser?.__typename === 'WorkspaceMember' &&
        localStorageUser?.workspace?.environment?.logoUrl) ||
      '',
    iconUrl:
      (localStorageUser?.__typename === 'EnvironmentMember' &&
        localStorageUser.environment.iconUrl) ||
      (localStorageUser?.__typename === 'WorkspaceMember' &&
        localStorageUser?.workspace?.environment?.iconUrl) ||
      '',
  },
  environmentId:
    (localStorageUser?.__typename === 'EnvironmentMember' &&
      localStorageUser.environment.id) ||
    (localStorageUser?.__typename === 'WorkspaceMember' &&
      localStorageUser.workspace.environment.id) ||
    '',
  environmentName:
    (localStorageUser?.__typename === 'EnvironmentMember' &&
      localStorageUser.environment.name) ||
    (localStorageUser?.__typename === 'WorkspaceMember' &&
      localStorageUser.workspace.environment.name) ||
    '',
  workspaceRole:
    (localStorageUser?.__typename === 'WorkspaceMember' &&
      localStorageUser.workspaceRole) ||
    '',
  workspaceId:
    (localStorageUser?.__typename === 'WorkspaceMember' &&
      localStorageUser.workspace.id) ||
    '',
  workspaceName:
    (localStorageUser?.__typename === 'WorkspaceMember' &&
      localStorageUser.workspace.name) ||
    '',
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState: { value: initialStateValue },
  reducers: {
    setSessionUser: (state, action: PayloadAction<SessionUserState>) => {
      state.value = action.payload;
    },

    clearSessionUser: (state) => {
      state.value = { ...initialStateValue, isSignedIn: false };
    },

    setColor: (state, action: PayloadAction<string>) => {
      const customBranding = {
        ...state.value.customBranding,
        color: action.payload,
      };
      state.value.customBranding = customBranding;
    },

    setCompanyName: (state, action: PayloadAction<string>) => {
      state.value.environmentName = action.payload;
    },

    setCustomBranding: (state, action: PayloadAction<CustomBrandingState>) => {
      const customBranding = {
        ...state.value.customBranding,
        ...action.payload,
      };
      state.value.customBranding = customBranding;
    },

    setSystemSelectedEnvironmentId: (state, action: PayloadAction<string>) => {
      state.value.systemSelectedEnvironmentId = action.payload;
      setSystemUserSelectedEnvironmentId(action.payload);
    },
  },
});

export const {
  setSessionUser,
  clearSessionUser,
  setColor,
  setCompanyName,
  setCustomBranding,
  setSystemSelectedEnvironmentId,
} = sessionSlice.actions;

export default sessionSlice.reducer;
