import { Stack, Typography } from '@mui/material';

interface StepperProps {
  activeStep: number;
  steps: string[];
}

const Stepper = ({ activeStep, steps }: StepperProps) => {
  return (
    <Stack
      direction='row'
      paddingX={2}
      paddingTop={2}
      spacing={1}
      justifyContent='space-between'
      borderBottom={(theme) => `1px solid ${theme.palette.border.light}`}
    >
      {steps.map((step, index) => (
        <Step key={index} step={step} activeStep={activeStep} index={index} />
      ))}
    </Stack>
  );
};

export default Stepper;

interface StepProps {
  step: string;
  activeStep: number;
  index: number;
}

const Step = ({ step, activeStep, index }: StepProps) => {
  return (
    <Stack
      direction='row'
      alignItems='center'
      spacing={1}
      padding={1}
      flex={1}
      justifyContent='center'
    >
      <Stack
        width={16}
        height={16}
        borderRadius='50%'
        bgcolor={activeStep === index ? 'primary.light' : 'background.default'}
        alignItems='center'
      >
        <Typography variant='caption' color='text.secondary'>
          {index + 1}
        </Typography>
      </Stack>
      <Typography variant='h6'>{step}</Typography>
    </Stack>
  );
};
