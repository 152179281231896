import * as React from 'react';

// mui components
import { Box, TextField } from '@mui/material';

// components
import FormFieldLabel from '../FormFieldLabel';

interface BaseFormInputProps {
  className?: string;
  'data-testid'?: string;
  disabled?: boolean;
  id?: string;
  inputClassName?: string;
  key?: string;
  label?: string;
  marginTop?: number;
  maxLength?: number;
  multiline?: boolean;
  placeholder?: string;
  required?: boolean;
  rows?: number;
  sub?: string;
  title?: string;
  tooltipText?: string;
  hidden?: boolean;
  hasError?: boolean;
}

export interface FormInputProps extends BaseFormInputProps {
  customInput?: React.ReactNode;
  inputValue: string;
  setValue: (value: string) => void;
  type: string;
}

export interface CustomFormInputProps extends BaseFormInputProps {
  customInput: React.ReactNode;
  inputValue?: string;
  setValue?: (value: string) => void;
  type?: string;
}

const FormInput = ({
  className,
  customInput,
  'data-testid': dataTestid,
  disabled,
  id,
  inputValue,
  label,
  maxLength = 524288, //same as W3 default value for HTML maxlength
  multiline,
  placeholder = '',
  required,
  rows,
  setValue,
  sub = '',
  title,
  tooltipText = '',
  type,
  marginTop,
  hasError,
}: FormInputProps | CustomFormInputProps) => {
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    return setValue ? setValue(e.target.value) : null;
  }

  return (
    <Box key={id} className={className} mt={marginTop}>
      {title && (
        <Box mb={1}>
          <FormFieldLabel
            text={title}
            subText={sub}
            required={required}
            tooltipText={tooltipText}
          />
        </Box>
      )}

      <Box>
        {customInput ? (
          customInput
        ) : (
          <TextField
            // label overlaps with date placeholder when Input is date
            label={type !== 'date' ? label : undefined}
            id={id}
            data-testid={dataTestid}
            color='neutral'
            value={inputValue}
            onChange={handleChange}
            placeholder={placeholder}
            type={type}
            multiline={multiline}
            rows={rows}
            fullWidth
            disabled={disabled}
            inputProps={{ maxLength: maxLength }}
            size='small'
            required={required}
            error={hasError}
          />
        )}
      </Box>
    </Box>
  );
};

export default FormInput;
