import { useCallback, useContext } from 'react';

// types
import { FeatureConfig, Routes } from '@madeinventive/core-types';
import {
  useCreateWorkflowFromVizMutation,
  Feature,
  FeatureCreateMode,
  useEditWorkspaceFeatureMutation,
  FeatureDocument,
} from '../generated/types';

// hooks
import { useAppDispatch, useToast, useSessionInfo } from '../hooks';

// slices
import { addFeature } from '../store/slices/workspace';
import { addNewComponentId } from '../store/slices/workspaceComponents';
import { EnvironmentContext } from '../components/EnvironmentProvider';

// Do not use `useVisualization` or `useVisualizationContext` here
// because this hook is not being called under the `VisualizationProvider`
export const useAlert = (workspaceId: string) => {
  const { showInfoToast } = useToast();
  const { email: userEmail } = useSessionInfo();
  const { environment } = useContext(EnvironmentContext);

  const storeDispatch = useAppDispatch();

  const [createAlert, { error: createAlertError }] =
    useCreateWorkflowFromVizMutation();

  const [updateAlert] = useEditWorkspaceFeatureMutation();

  // it can't use entityId from the context
  // because the useVisualization hook is used in the AlertSteppedForm
  // which is not a child of the VisualizationProvider
  const createLiveAlert = useCallback(
    async ({
      workflowConfig,
      entityId,
    }: {
      workflowConfig: FeatureConfig;
      entityId: string;
    }) => {
      // create alert with publish mode
      const result = await createAlert({
        variables: {
          input: {
            workspaceId,
            entityId,
            createMode: FeatureCreateMode.PUBLISH,
            workflowConfig,
          },
        },
      });

      if (result.data?.createWorkflowFromViz.component) {
        storeDispatch(
          addNewComponentId(result.data.createWorkflowFromViz.component.id),
        );
        showInfoToast('Saved');
      }

      if (result.data?.createWorkflowFromViz.feature) {
        storeDispatch(
          addFeature(result.data.createWorkflowFromViz.feature as Feature),
        );
        showInfoToast('Alert created.');
      }

      return result;
    },
    [createAlert, showInfoToast, storeDispatch, workspaceId],
  );

  const updateLiveOrDraftAlert = useCallback(
    async ({
      featureId,
      workflowConfig,
    }: {
      featureId: string;
      workflowConfig: FeatureConfig;
    }) => {
      await updateAlert({
        variables: {
          input: {
            featureId: featureId,
            config: workflowConfig,
          },
        },
        refetchQueries: [
          {
            query: FeatureDocument,
            variables: {
              id: featureId,
            },
          },
        ],
      });
      showInfoToast('Updated.');
    },
    [updateAlert, showInfoToast],
  );

  const saveAsDraft = useCallback(
    async ({
      workflowConfig,
      entityId,
    }: {
      workflowConfig: FeatureConfig;
      entityId: string;
    }) => {
      // create alert with draft mode
      const result = await createAlert({
        variables: {
          input: {
            workspaceId,
            entityId,
            createMode: FeatureCreateMode.DRAFT,
            workflowConfig,
          },
        },
      });
      if (result.data?.createWorkflowFromViz.feature) {
        showInfoToast('Saved as draft.');
      }
      return result;
    },
    [createAlert, showInfoToast, workspaceId],
  );

  const getDefaultAlertEmailContent = useCallback(
    (vizTitle: string, entityId: string) => {
      const base = window.location.origin;

      return {
        recipients: [userEmail],
        emailSubject: `${environment?.name} Alert: ${vizTitle}`,
        buttonText: 'Review',
        buttonLink: new URL(
          Routes.chat(workspaceId, undefined, undefined, entityId),
          base,
        ).toString(),
      };
    },
    [userEmail, environment?.name, workspaceId],
  );

  return {
    getDefaultAlertEmailContent,
    createLiveAlert,
    updateLiveOrDraftAlert,
    saveAsDraft,
    createAlertError,
  };
};
