import { SnackbarKey, SnackbarMessage } from 'notistack';
import { forwardRef } from 'react';
import SnackbarContent from './SnackbarContent';
import Icon from '../shared/Icon';

interface SnackbarSuccessProps {
  id: SnackbarKey;
  message: SnackbarMessage;
}

const SnackbarSuccess = forwardRef<HTMLDivElement, SnackbarSuccessProps>(
  (props, ref) => {
    const { id, message } = props;

    return (
      <SnackbarContent
        id={id}
        message={message}
        ref={ref}
        icon={<Icon name='check-circle' size='small' color='text.inverted' />}
        textColor='text.inverted'
        backgroundColor='background.inverted'
      />
    );
  },
);

SnackbarSuccess.displayName = 'SnackbarSuccess';

export default SnackbarSuccess;
