export const getFormattedRecipientString = (
  recipientListString: string,
  length: number,
) => {
  const restRecipients = recipientListString.split(',');
  const firstThreeRecipients = restRecipients
    .splice(0, length)
    .map((recipient) => recipient.trim());
  let renderedRecipientString = firstThreeRecipients.join(', ');
  if (restRecipients.length) {
    renderedRecipientString += `...`;
  }
  return {
    renderedRecipientString: renderedRecipientString,
    restRecipientCount: restRecipients.length
      ? `+${restRecipients.length}`
      : null,
  };
};

export const isSlackMessage = (actionPayload: object) => {
  return (
    Object.prototype.hasOwnProperty.call(actionPayload, 'channelId') &&
    Object.prototype.hasOwnProperty.call(actionPayload, 'message')
  );
};

export const isEmail = (actionPayload: object) => {
  return (
    Object.prototype.hasOwnProperty.call(actionPayload, 'to') &&
    Object.prototype.hasOwnProperty.call(actionPayload, 'subject') &&
    Object.prototype.hasOwnProperty.call(actionPayload, 'bodyHTML')
  );
};
