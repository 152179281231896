import { useMemo } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { Stack } from '@mui/material';
import { DynamicFieldV1, isValid, validators } from '@madeinventive/core-types';

// components
import Condition from './Condition';
import FormField from '../../shared/Inputs/FormField';
import Button from '../../shared/Button';
import Icon from '../../shared/Icon';
import MatchedData from './MatchedData';

import { extractConditionExpressionFieldNames } from '../../../store/slices/features';
import { convertSchemaNodeToDynamicField } from '../utils';
import { ConditionFormValues, SettingsFormValues } from '../types';

const emptyConditionExpression: ConditionFormValues = {
  chainingOperator: 'AND',
  variable: undefined,
  operator: undefined,
  value: undefined,
  isEditing: true,
};

interface ConditionsProps {
  workspaceId: string;
  label?: string;
}

const Conditions = ({ workspaceId, label }: ConditionsProps) => {
  const formik = useFormikContext<SettingsFormValues>();
  const {
    values: { conditions, alertsAbout },
  } = formik;

  // selectedFields prevents the user from selecting the same field twice
  const selectedFields = useMemo(
    () => (conditions ? extractConditionExpressionFieldNames(conditions) : []),
    [conditions],
  );

  const extraFields = useMemo(() => {
    if (alertsAbout === undefined) {
      return [];
    }
    const field = convertSchemaNodeToDynamicField(alertsAbout);
    return [JSON.stringify(field)];
  }, [alertsAbout]);

  if (conditions === undefined) {
    return <></>;
  }

  return (
    <FieldArray name='conditions'>
      {({ remove, push }) => {
        if (conditions.length === 0) {
          push({
            ...emptyConditionExpression,
          });
        }
        return (
          <FormField label={label}>
            <Stack id='conditions-form' spacing={2} alignItems='flex-start'>
              <Stack id='conditions-list' width='100%'>
                {conditions?.map((condition, index) => {
                  const { variable } = condition;
                  const key = isValid<DynamicFieldV1>(
                    validators.DynamicFieldV1,
                    variable,
                  )
                    ? variable.field
                    : index;

                  return (
                    <Condition
                      key={key}
                      condition={condition}
                      index={index}
                      selectedFields={selectedFields}
                      deleteCondition={(index: number) => {
                        remove(index);
                      }}
                    />
                  );
                })}
              </Stack>
              <Button
                variant='text'
                startIcon={<Icon name='add' />}
                onClick={() => {
                  push({
                    ...emptyConditionExpression,
                  });
                }}
              >
                Add new condition
              </Button>
              <MatchedData
                workspaceId={workspaceId}
                conditions={conditions}
                extraFields={extraFields}
              />
            </Stack>
          </FormField>
        );
      }}
    </FieldArray>
  );
};

export default Conditions;
