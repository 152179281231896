import { useCallback, useState } from 'react';

import {
  PlaceholderV1MetaData,
  TagMetaData,
  isValid,
  validators,
} from '@madeinventive/core-types';

import CommentsPopper, {
  CommentsPopperProps,
} from '../components/shared/Commenting/CommentsPopper';
import { CommentProps } from '../components/shared/Commenting/Comment';
import { MenuItemOption } from '../components/MenuButton';
import { useAppSelector, useAppDispatch } from './store';
import {
  PlaceholderLocation,
  updatePlaceholderInfo,
} from '../store/slices/features';

export { PlaceholderLocation };

const isPlaceholder = (variable: TagMetaData | undefined) =>
  isValid<PlaceholderV1MetaData>(validators.PlaceholderV1MetaData, variable);

export const usePlaceholder = (location: PlaceholderLocation) => {
  const [open, setOpen] = useState(false);

  const openPlaceholderComments = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const closePlaceholderComments = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const storeDispatch = useAppDispatch();

  const featureEditData = useAppSelector(
    (store) => store.featureEditData.value,
  );

  const { placeholderLookup } = featureEditData;

  const renderPlaceholderPopper = useCallback(
    (
      popupAnchorEl: CommentsPopperProps['popupAnchorEl'],
      variable: TagMetaData | undefined,
      menuOptions: MenuItemOption[],
    ) => {
      if (
        isValid<PlaceholderV1MetaData>(
          validators.PlaceholderV1MetaData,
          variable,
        )
      ) {
        const updateTitle = (newTitle: string) => {
          storeDispatch(
            updatePlaceholderInfo({
              location,
              placeholderId: variable.placeholderId,
              name: newTitle,
            }),
          );
        };

        const updateDescription = (newDescription: string) => {
          storeDispatch(
            updatePlaceholderInfo({
              location,
              placeholderId: variable.placeholderId,
              description: newDescription,
            }),
          );
        };

        const attachComment = (newComment: string) => {
          console.info(`Not actually adding comment: ${newComment}`);
          // @TODO: when we are ready to add commenting
        };

        const entity = placeholderLookup[variable.placeholderId];
        if (entity) {
          const comments: CommentProps[] = entity.notes.map((n) => ({
            data: {
              id: n.id,
              commenter: {
                id: n.createdBy?.id || 'fix-me', // @TODO: createdBy shouldn't be optional
                firstname: n.createdBy?.firstname || 'John',
                lastname: n.createdBy?.lastname || 'Doe',
              },
              comment: n.comment,
              createdAt: n.updatedAt,
            },
          }));
          return (
            <CommentsPopper
              open={open}
              setOpen={setOpen}
              popupAnchorEl={popupAnchorEl}
              title={entity.name}
              onNewTitle={updateTitle}
              description={entity.description ?? undefined}
              onNewDescription={updateDescription}
              comments={comments}
              onNewComment={attachComment}
              editableTitleEnabled={true}
              menuItemOptions={menuOptions.length > 0 ? menuOptions : undefined}
            />
          );
        }
        // a new entry
        return (
          <CommentsPopper
            open={open}
            setOpen={setOpen}
            popupAnchorEl={popupAnchorEl}
            title={variable.name}
            onNewTitle={updateTitle}
            description={variable.description}
            onNewDescription={updateDescription}
            comments={[]}
            onNewComment={attachComment}
            editableTitleEnabled={true}
            menuItemOptions={menuOptions.length > 0 ? menuOptions : undefined}
          />
        );
      }
      return <></>;
    },
    [open, setOpen, location, placeholderLookup, storeDispatch],
  );

  return {
    isPlaceholder,
    placeholderOpen: open,
    setPlaceholderOpen: setOpen,
    openPlaceholderComments,
    closePlaceholderComments,
    renderPlaceholderPopper,
  };
};
