import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { omit, throttle } from 'lodash';

import {
  Schema,
  SchemaNode,
  SchemaNodeKind,
  isFieldInfo,
} from '../store/slices/exploreExtracts';
import { OptionItem } from '../components/shared/SimpleDropdown';

import useExplore from './useActiveExplore';
import { useWorkspaceLookerDataModelExploreFieldSuggestionsLazyQuery } from '../generated/types';
import {
  areTheSame,
  isValid,
  validators,
  DynamicFieldV1MetaData,
  NormalizedConditionValueV1,
  NormalizedOperatorV1,
  DynamicFieldV1,
} from '@madeinventive/core-types';
import {
  ConditionFormValues,
  SettingsFormValues,
} from '../components/AlertsWorkflow/types';

export enum ValueInputType {
  DEFAULT = 'DEFAULT', // single value input
  MULTI_VALUE = 'MULTI_VALUE',
  MULTI_VALUE_SUGGESTABLE = 'MULTI_VALUE_SUGGESTABLE',
  DATE = 'DATE',
  RANGE = 'RANGE',
  SINGULAR_RELATIVE_DATE = 'SINGULAR_RELATIVE_DATE',
  PLURAL_RELATIVE_DATE = 'PLURAL_RELATIVE_DATE',
}

// @return: true if the field type and operator combination support multiple value input
const isMultiValueSupport = (
  schema?: Schema,
  typeKey?: string,
  opKey?: string,
) => {
  if (!schema || !typeKey || !opKey) return false;
  const type = schema.supportedTypes[typeKey];
  if (!type?.capabilities?.multiValueOps) return false;
  return type.capabilities.multiValueOps.includes(opKey);
};

const isRangeValueSupport = (
  schema?: Schema,
  typeKey?: string,
  opKey?: string,
) => {
  if (!schema || !typeKey || !opKey) return false;
  const type = schema.supportedTypes[typeKey];
  if (!type?.capabilities?.rangeValueOps) return false;
  return type.capabilities.rangeValueOps.includes(opKey);
};

const isSingularRelativeDateValueSupport = (
  schema?: Schema,
  typeKey?: string,
  opKey?: string,
) => {
  if (!schema || !typeKey || !opKey) return false;
  const type = schema.supportedTypes[typeKey];
  if (!type?.capabilities?.singularRelativeDateOps) return false;
  return type.capabilities.singularRelativeDateOps.includes(opKey);
};

const isPluralRelativeDateValueSupport = (
  schema?: Schema,
  typeKey?: string,
  opKey?: string,
) => {
  if (!schema || !typeKey || !opKey) return false;
  const type = schema.supportedTypes[typeKey];
  if (!type?.capabilities?.pluralRelativeDateOps) return false;
  return type.capabilities.pluralRelativeDateOps.includes(opKey);
};

const isDateType = (schema?: Schema, typeKey?: string) => {
  if (!schema || !typeKey) return false;

  const type = schema.supportedTypes[typeKey];
  return type?.capabilities?.asDate ?? false;
};

const isSuggestable = (schema?: Schema, typeKey?: string) => {
  if (!schema || !typeKey) return false;
  const type = schema.supportedTypes[typeKey];
  return type?.capabilities?.suggestable ?? false;
};

const getValueInputTypeByOperator = (
  schema: Schema,
  fieldType: string,
  operatorKey?: string,
): ValueInputType => {
  if (isMultiValueSupport(schema, fieldType, operatorKey)) {
    if (isSuggestable(schema, fieldType)) {
      return ValueInputType.MULTI_VALUE_SUGGESTABLE;
    }
    return ValueInputType.MULTI_VALUE;
  }

  if (isRangeValueSupport(schema, fieldType, operatorKey)) {
    return ValueInputType.RANGE;
  }

  if (isSingularRelativeDateValueSupport(schema, fieldType, operatorKey)) {
    return ValueInputType.SINGULAR_RELATIVE_DATE;
  }

  if (isPluralRelativeDateValueSupport(schema, fieldType, operatorKey)) {
    return ValueInputType.PLURAL_RELATIVE_DATE;
  }

  if (isDateType(schema, fieldType)) {
    return ValueInputType.DATE;
  }

  return ValueInputType.DEFAULT;
};

const getNormalizedOperator = (
  schema: Schema,
  operator: string,
  minimized = false,
) => {
  const fullOp = schema?.opLookup[operator];
  return minimized ? omit(fullOp, ['__typename', 'typesSupported']) : fullOp;
};

const getSchemaNodeByField = (schema: Schema, field: string) => {
  function traverseNodes(nodes: SchemaNode[]): SchemaNode | undefined {
    for (const node of nodes) {
      if (
        node.kind === SchemaNodeKind.FIELD &&
        isFieldInfo(node.metaData) &&
        node.metaData.name === field
      ) {
        return node;
      }
      if (node.children) {
        const result = traverseNodes(node.children);
        if (result) {
          return result;
        }
      }
    }
    return undefined;
  }

  return traverseNodes(schema.nodes);
};

// This hook manages the state of a single condition
// It includes the logic between field variable, operator, and value
// Only the DynamicFieldV1 type is supported for field variable in this hook
// PlaceholderV1 is not supported.
// This should be independent of feature edit data
const useConditionEditor = (index: number) => {
  const formik = useFormikContext<SettingsFormValues>();
  const {
    values,
    errors: settingsFormErrors,
    touched,
    setFieldValue,
    setFieldTouched,
  } = formik;
  const { conditions } = values;
  const {
    variable: fieldData,
    operator,
    value,
  } = conditions
    ? conditions[index]
    : {
        variable: undefined,
        operator: undefined,
        value: undefined,
      };
  const conditionErrors = Array.isArray(settingsFormErrors.conditions)
    ? settingsFormErrors.conditions[index]
    : undefined;
  const conditionTouched = Array.isArray(touched.conditions)
    ? touched.conditions[index]
    : undefined;

  const setFieldData = useCallback(
    (fieldData?: DynamicFieldV1) => {
      setFieldValue(`conditions.${index}.variable`, fieldData);
    },
    [index, setFieldValue],
  );

  const setOperator = useCallback(
    (operator?: NormalizedOperatorV1) => {
      setFieldValue(`conditions.${index}.operator`, operator);
    },
    [index, setFieldValue],
  );

  const setValue = useCallback(
    (value?: NormalizedConditionValueV1) => {
      setFieldValue(`conditions.${index}.value`, value);
    },
    [index, setFieldValue],
  );

  const [valueInputType, setValueInputType] = useState<ValueInputType>(
    ValueInputType.DEFAULT,
  );

  const { exploreInfo, exploreExtract } = useExplore();
  const wsExploreId = exploreInfo ? exploreInfo.wsExploreId : undefined;
  const schema = exploreExtract ? exploreExtract.schema : undefined;

  const fieldSchemaNode = useMemo(() => {
    if (!schema || !fieldData) return undefined;
    if (
      isValid<DynamicFieldV1MetaData>(
        validators.DynamicFieldV1MetaData,
        fieldData,
      )
    ) {
      return getSchemaNodeByField(schema, fieldData.field);
    }
  }, [fieldData, schema]);

  // Operator states
  const [operatorOptions, setOperatorOptions] = useState<OptionItem[]>([]);

  // Value states
  const [suggestions, setSuggestions] = useState<readonly string[]>([]);

  const setOperatorOptionsAndValueInputType = useCallback(
    (
      schema: Schema,
      fieldData: DynamicFieldV1,
      operator?: NormalizedOperatorV1,
    ) => {
      const fieldType = fieldData.normalizedType;
      // setOperatorOptions
      const operatorsForType = schema.opDisplaysByType[fieldType];
      if (operatorsForType) {
        setOperatorOptions(
          operatorsForType.map((op) => ({ label: op.label, value: op.key })),
        );
      }

      // setValueInputType
      const valueInputType = getValueInputTypeByOperator(
        schema,
        fieldType,
        operator?.key,
      );
      if (valueInputType) {
        setValueInputType(valueInputType);
      }
    },
    [],
  );

  // get suggestions
  const [
    fetchWsFieldSuggestions,
    { data, error, loading: isSuggestionLoading },
  ] = useWorkspaceLookerDataModelExploreFieldSuggestionsLazyQuery();

  // handle fetch suggestion response data
  useEffect(() => {
    if (data?.node?.__typename === 'WorkspaceLookerDataModelExplore') {
      const suggestions = data?.node?.getSuggestions?.suggestions;
      if (suggestions) {
        setSuggestions(suggestions);
      }
    }
  }, [data]);

  // handle fetch suggestion error
  useEffect(() => {
    if (
      error &&
      isValid<DynamicFieldV1MetaData>(
        validators.DynamicFieldV1MetaData,
        fieldData,
      )
    ) {
      console.error(
        `Error '${error}' encountered while getting suggestion for field ${fieldData.field}`,
      );
    }
  }, [error, fieldData]);

  // fetch suggestion function
  const fetchSuggestions = useMemo(() => {
    if (
      fieldData &&
      isValid<DynamicFieldV1MetaData>(
        validators.DynamicFieldV1MetaData,
        fieldData,
      )
    ) {
      const suggestionInput = fieldData.field;
      return throttle((matchPattern?: string) => {
        if (wsExploreId) {
          fetchWsFieldSuggestions({
            variables: {
              id: wsExploreId,
              suggestionInputArgs: {
                field: suggestionInput,
                term: matchPattern,
              },
            },
          });
        }
      });
    }
  }, [fetchWsFieldSuggestions, fieldData, wsExploreId]);

  const handleFieldChange = useCallback(
    (fieldSchemaNode?: SchemaNode) => {
      if (!schema) return;
      if (!fieldSchemaNode) {
        setFieldData();
        setOperator();
        setValue();
        return;
      }

      if (!isFieldInfo(fieldSchemaNode.metaData)) return;

      const newFieldData: DynamicFieldV1 = {
        field: fieldSchemaNode.metaData.name,
        normalizedType: fieldSchemaNode.metaData.normalizedType,
      };
      if (!fieldData || !areTheSame(newFieldData, fieldData)) {
        setFieldData(newFieldData);
        setOperator();
        setValue();
      }

      setOperatorOptionsAndValueInputType(schema, newFieldData, operator);
    },
    [
      fieldData,
      operator,
      schema,
      setFieldData,
      setOperator,
      setOperatorOptionsAndValueInputType,
      setValue,
    ],
  );

  const handleOperatorChange = useCallback(
    (opKey: string) => {
      if (!schema || !fieldData) return;
      if (
        !isValid<DynamicFieldV1MetaData>(
          validators.DynamicFieldV1MetaData,
          fieldData,
        )
      )
        return;

      // if the current operator and the new operator has different supported value types
      // reset the value
      const currentOperatorKey = operator?.key;
      const newOperatorKey = opKey;

      const newInputValueType = getValueInputTypeByOperator(
        schema,
        fieldData?.normalizedType,
        newOperatorKey,
      );

      if (newInputValueType !== valueInputType) {
        setValue();
        setValueInputType(newInputValueType);
      }

      if (currentOperatorKey !== newOperatorKey) {
        const operator = getNormalizedOperator(schema, opKey, true);
        setOperator(operator);
      }
    },
    [fieldData, operator?.key, schema, setOperator, setValue, valueInputType],
  );

  const handleValueChange = useCallback(
    (value?: NormalizedConditionValueV1) => {
      setValue(value);
    },
    [setValue],
  );

  // set operator options and value input type on the first load
  useEffect(() => {
    if (!schema) return;
    if (
      !isValid<DynamicFieldV1MetaData>(
        validators.DynamicFieldV1MetaData,
        fieldData,
      )
    )
      return;
    setOperatorOptionsAndValueInputType(schema, fieldData, operator);
  }, [schema, fieldData, operator, setOperatorOptionsAndValueInputType]);

  const resetCondition = useCallback(
    (initialCondition?: ConditionFormValues) => {
      if (initialCondition) {
        setFieldData(initialCondition.variable);
        setOperator(initialCondition.operator);
        setValue(initialCondition.value);
      } else {
        setFieldData();
        setOperator();
        setValue();
      }
    },
    [setFieldData, setOperator, setValue],
  );

  const showOperatorInput = useMemo(() => {
    return !!fieldData && operatorOptions.length > 0;
  }, [fieldData, operatorOptions]);

  const isValueNotRequired = useMemo(
    () => !operator || operator.isUnary,
    [operator],
  );

  const showValueInput = useMemo(() => {
    return showOperatorInput && !isValueNotRequired;
  }, [isValueNotRequired, showOperatorInput]);

  const isConditionValid = useMemo(() => {
    return fieldData && operator && (isValueNotRequired || value);
  }, [fieldData, isValueNotRequired, operator, value]);

  const setConditionDirty = useCallback(() => {
    setFieldTouched('variable', true);
    setFieldTouched('operator', true);
    setFieldTouched('value', true);
  }, [setFieldTouched]);

  const errors = useMemo(() => {
    if (typeof conditionErrors === 'object') {
      return {
        fieldData: conditionTouched?.variable
          ? conditionErrors.variable
          : undefined,
        operator: conditionTouched?.operator
          ? conditionErrors.operator
          : undefined,
        value: conditionTouched?.value ? conditionErrors.value : undefined,
      };
    } else {
      return {
        fieldData: undefined,
        operator: undefined,
        value: undefined,
      };
    }
  }, [conditionErrors, conditionTouched]);

  return {
    fieldData,
    fieldSchemaNode,
    operator,
    value,
    errors,
    resetCondition,
    setConditionDirty,
    handleFieldChange,
    handleOperatorChange,
    handleValueChange,
    // operators
    operatorOptions,
    // value suggestions
    fetchSuggestions,
    isSuggestionLoading,
    suggestions,
    // input visibility
    showOperatorInput,
    showValueInput,
    valueInputType,
    isConditionValid,
  };
};

export default useConditionEditor;
