import { useState, useCallback, useContext } from 'react';
import { Stack, Typography } from '@mui/material';

import { BaseRegisteredDialogComponentProps } from './types';
import TextField from '../shared/Inputs/TextField';
import FormActionFooter from '../shared/FormActionFooter';
import { useQueries } from '../../hooks';
import { EnvironmentContext } from '../EnvironmentProvider';
export interface SaveToCreateProps extends BaseRegisteredDialogComponentProps {
  chatResponseId: string;
}

const SaveToCreate = ({ chatResponseId, hideDialog }: SaveToCreateProps) => {
  const [title, setTitle] = useState('');

  const { environment } = useContext(EnvironmentContext);
  const environmentName = environment?.name;
  const { createQuery } = useQueries(environment?.id);

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    await createQuery(chatResponseId, title);
    setLoading(false);
    hideDialog();
  }, [createQuery, chatResponseId, title, hideDialog]);

  return (
    <Stack spacing={2}>
      <Typography color='text.secondary'>
        {`${environmentName} administrators can view and share saved items or use
        them to create topics and stacks.`}
      </Typography>
      <TextField
        label='Title'
        subLabel='Choose a clear, descriptive title that reflects the purpose of this content.'
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <FormActionFooter
        noPadding
        cancelAction={{
          label: 'Cancel',
          action: hideDialog,
        }}
        submitAction={{
          label: 'Save to Create',
          action: handleSubmit,
          isLoading: loading,
          disabled: !title.trim(),
        }}
      />
    </Stack>
  );
};

export default SaveToCreate;
