import { Routes } from '@madeinventive/core-types';
import { useRouter } from 'next/router';
import { useSessionInfo } from '../../hooks';
import { useEmbeddingContext } from '../../contexts/EmbeddingProvider';

export const REDIRECT_PARAMETER = 'redirectPath';

export function AuthGuard({
  requireAuth,
  requiredUserType,
  children,
}: {
  requireAuth?: boolean;
  requiredUserType?: string;
  children: JSX.Element;
}) {
  const { isSignedIn, userType, workspaceId } = useSessionInfo();
  const { isEmbedded } = useEmbeddingContext();

  const router = useRouter();

  // router not ready, no point trying to render the children
  if (!router.isReady) return <></>;

  if (!isEmbedded) {
    if (requireAuth && !isSignedIn) {
      // router.pathname should not be used for redirecting with router.push().
      // Pathname can be used to check if path is root ('/').
      // Use router.asPath for redirecting.
      if (router.pathname !== '/') {
        const redirectParam = `${REDIRECT_PARAMETER}=${encodeURIComponent(
          router.asPath,
        )}`;

        router.push(`${Routes.root()}?${redirectParam}`);
        return <></>;
      }
    } else if (
      requiredUserType === 'EnvironmentMember' &&
      userType !== requiredUserType &&
      userType !== 'SystemUser'
    ) {
      router.push(Routes.workspace(workspaceId));
      return <></>;
    }
  }

  return <>{children}</>;
}
