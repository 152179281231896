import { useRef } from 'react';
import { validators, DynamicFieldV1, isValid } from '@madeinventive/core-types';

// material UI components
import { Stack } from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';

// components
import SimpleDropdown from '../../shared/SimpleDropdown';
import ConditionValueInputV2 from './ConditionValueInputV2';
import FormField from '../../shared/Inputs/FormField';
import Button from '../../shared/Button';
import DataFieldSelector from '../../DataFieldSelector';

// hooks and more
import { useAppSelector } from '../../../hooks';
import useConditionEditor from '../../../hooks/useConditionEditor';
import { ConditionFormValues } from '../types';

interface ConditionEditorProps {
  condition: ConditionFormValues;
  index: number;
  selectedFields: string[];
  deleteCondition: (index: number) => void;
  closeEditing: () => void;
}

const ConditionEditor = ({
  condition,
  index,
  selectedFields,
  deleteCondition,
  closeEditing,
}: ConditionEditorProps) => {
  const {
    // condition
    fieldData,
    fieldSchemaNode,
    operator,
    value,
    errors,
    resetCondition,
    handleFieldChange,
    handleOperatorChange,
    handleValueChange,
    // operators
    operatorOptions,
    // value suggestions
    fetchSuggestions,
    isSuggestionLoading,
    suggestions,
    // input visibility
    showOperatorInput,
    showValueInput,
    valueInputType,
    isConditionValid,
  } = useConditionEditor(index);

  const conditionRef = useRef<ConditionFormValues>(condition);

  const cancelEditing = () => {
    const isNew = condition.variable === undefined;
    const isFirst = index === 0;

    if (isNew) {
      resetCondition();
      if (!isFirst) {
        deleteCondition(index);
      }
    } else {
      resetCondition(conditionRef.current);
      closeEditing();
    }
  };

  const workspaceId = useAppSelector(
    (state) => state.workspace.value.workspace?.id,
  );

  const showDeleteButton = condition.variable !== undefined;

  if (!workspaceId) {
    return null;
  }

  // This UI supports only DynamicFieldV1 for fieldData
  // It doesn't support PlaceholderV1
  if (
    fieldData &&
    !isValid<DynamicFieldV1>(validators.DynamicFieldV1, fieldData)
  ) {
    return null;
  }

  return (
    <Stack direction={'column'} key={index} spacing={2}>
      <Stack spacing={3}>
        <FormField label='Data field' errorMessage={errors.fieldData}>
          <DataFieldSelector
            field={fieldSchemaNode}
            selectedFields={selectedFields}
            handleFieldSelect={handleFieldChange}
            hasError={!!errors.fieldData}
          />
        </FormField>
        {showOperatorInput && (
          <FormField label='Operator' errorMessage={errors.operator}>
            <SimpleDropdown
              value={operator?.key ?? ''}
              menuOptions={operatorOptions}
              placeholder='Pick operator'
              setValue={handleOperatorChange}
              hasError={!!errors.operator}
            />
          </FormField>
        )}
        {showValueInput && (
          <FormField label='Value' errorMessage={errors.value}>
            <ConditionValueInputV2
              value={value}
              suggestions={suggestions}
              getSuggestions={fetchSuggestions}
              loading={isSuggestionLoading}
              setValue={handleValueChange}
              valueInputType={valueInputType}
              hasError={!!errors.value}
            />
          </FormField>
        )}
      </Stack>
      <Stack direction='row' justifyContent='space-between' spacing={1.5}>
        <Stack>
          {showDeleteButton && (
            <Button
              startIcon={<DeleteOutline />}
              size='small'
              variant='text'
              title='Delete'
              onClick={() => deleteCondition(index)}
            />
          )}
        </Stack>

        <Stack direction='row' spacing={1.5}>
          <Button
            size='small'
            variant='outlined'
            title='Cancel'
            onClick={cancelEditing}
          />
          <Button
            size='small'
            variant='contained'
            title='Close'
            disabled={!isConditionValid}
            onClick={() => {
              closeEditing();
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ConditionEditor;
