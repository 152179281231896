import Icon, { IconProps } from '../Icon';
import { IconName } from '../Icon/types';
import { Typography, TypographyProps, Stack } from '@mui/material';

interface BadgeBaseProps {
  color?: 'default' | 'draft' | 'error' | 'warning' | 'info' | 'success';
  size?: 'small' | 'large';
  position?: 'top-right' | 'bottom-right';
}

interface IconBadgeProps extends BadgeBaseProps {
  label?: string;
  iconName: IconName;
}

interface LabelBadgeProps extends BadgeBaseProps {
  label: string;
  iconName?: IconName;
}

export type BadgeProps = IconBadgeProps | LabelBadgeProps;

const sizeMap = {
  large: {
    spacing: 0.5,
    paddingY: 0.5,
    paddingX: 1,

    height: 28,
    fontVariant: 'h6' as TypographyProps['variant'],
    iconSize: 'small' as IconProps['size'],
  },
  small: {
    spacing: 0.25,
    paddingY: 0,
    paddingX: 0.5,
    height: 14,
    fontVariant: 'caption' as TypographyProps['variant'],
    iconSize: 'xsmall' as IconProps['size'],
  },
};

const colorMap = {
  default: {
    background: 'primary.wash',
    font: 'text.primary',
  },
  draft: {
    background: 'background.default',
    font: 'text.tertiary',
  },
  error: {
    background: 'error.wash',
    font: 'error.dark',
  },
  warning: {
    background: 'warning.wash',
    font: 'warning.dark',
  },
  info: {
    background: 'info.wash',
    font: 'info.dark',
  },
  success: {
    background: 'success.wash',
    font: 'success.dark',
  },
};

const Badge = ({
  color = 'default',
  size = 'small',
  iconName,
  label,
  position,
}: BadgeProps) => {
  return (
    <Stack
      direction='row'
      spacing={sizeMap[size].spacing}
      height={sizeMap[size].height}
      // when there is only icon, use the fixed width as same as height
      // so that icon only badges can be a perfect circle.
      width={label ? 'fit-content' : sizeMap[size].height}
      // When there is only icon, ignore the paddings and
      // make the icon align center
      paddingX={label ? sizeMap[size].paddingX : 0}
      paddingY={label ? sizeMap[size].paddingY : 0}
      alignItems='center'
      justifyContent='center'
      borderRadius={6}
      bgcolor={colorMap[color].background}
      sx={{
        ...(position === 'top-right' && {
          position: 'absolute',
          top: -8,
          right: -8,
        }),
        ...(position === 'bottom-right' && {
          position: 'absolute',
          bottom: -8,
          right: -8,
        }),
      }}
    >
      {iconName && (
        <Icon
          name={iconName}
          size={sizeMap[size].iconSize}
          color={colorMap[color].font}
        />
      )}
      {label && (
        <Typography
          variant={sizeMap[size].fontVariant}
          color={colorMap[color].font}
        >
          {label}
        </Typography>
      )}
    </Stack>
  );
};

export default Badge;
