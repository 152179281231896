// material UI components
import {
  Card as MuiCard,
  CardActionArea,
  CardHeader,
  Grid,
  SxProps,
} from '@mui/material';

// styles
import useStyles from '../../styles/style';

interface CardProps {
  sx?: SxProps;
  sxHeader?: SxProps;
  sxBody?: SxProps;
  backgroundColor?: string;
  bodyItems: React.ReactNode;
  action?: React.ReactNode;
  title: React.ReactNode;
  subheader?: React.ReactNode;
  cardOnClick?: () => void;
}

const Card = ({
  sx,
  sxHeader,
  sxBody,
  backgroundColor,
  action,
  title,
  subheader,
  bodyItems,
  cardOnClick,
}: CardProps) => {
  const { shadowBox } = useStyles();

  return (
    <MuiCard
      data-testid='template-card'
      className={shadowBox}
      sx={{
        ...sx,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {cardOnClick ? (
        <CardActionArea
          component='div'
          onClick={cardOnClick}
          data-testid='template-card-action-area'
        >
          <CardHeader
            sx={sxHeader}
            action={action}
            title={title}
            subheader={subheader}
            className={backgroundColor}
          />
          <Grid sx={sxBody} justifyContent='space-between' alignItems='center'>
            {bodyItems}
          </Grid>
        </CardActionArea>
      ) : (
        <>
          <CardHeader
            sx={sxHeader}
            action={action}
            title={title}
            subheader={subheader}
            className={backgroundColor}
          />
          <Grid sx={sxBody} justifyContent='space-between' alignItems='center'>
            {bodyItems}
          </Grid>
        </>
      )}
    </MuiCard>
  );
};

Card.defaultProps = {
  sxHeader: {
    p: 0,
    pl: 0,
    pt: 0,
    pb: 0,
    pr: 0,
  },
  sxBody: {
    p: 0,
    pl: 0,
    pt: 0,
    pb: 0,
    pr: 0,
  },
};

export default Card;
